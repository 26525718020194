import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import NoImage from '../../../assets/no-image.svg';
import { Switch } from '@headlessui/react';
import { PencilSquareIcon, CalendarDaysIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import AddOnImage from '../../../assets/add-on.svg';
import { Tooltip } from 'react-tooltip';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProductCard({
  id,
  index,
  product,
  selected,
  setSelected,
  onChangeProductVisible,
  onClickEditProduct,
  onClickOpenHidedateModal,
  moveProductItem,
  dropProductItem,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'product',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropProductItem(item.index, product);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveProductItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'product',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      onClick={() => {
        setSelected((prev) => ({
          ...prev,
          product: product,
        }));
      }}
      className={classNames(
        product.id === selected.product?.id
          ? 'text-gray-800 bg-slate-50 border-r-2 hover:bg-slate-50  border-blue-600 dark:text-sky-400 dark:bg-slate-700 dark:border-sky-400'
          : 'text-slate-500  cursor-pointer dark:text-slate-200 hover:bg-slate-50 dark:hover:bg-slate-700 hover:text-gray-900',
        isDragging ? 'shadow-lg' : '',
        'flex items-center p-5 space-x-5 h-[6rem]',
        product.items.length === 0 ? 'bg-red-50 dark:bg-red-950' : ''
      )}
    >
      <div className="relative inline-block">
        <img
          className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full object-cover"
          src={product.imagepath || NoImage}
          alt=""
        />
        {product.isupsell && (
          <img
            className="absolute bottom-0 right-0 block h-6 w-6 rounded-full"
            alt="addOnImage"
            src={AddOnImage}
          />
        )}
      </div>
      <div className="flex-1">
        <div
          key={product.name}
          className={classNames(
            'flex items-center cursor-pointer  py-2 text-sm font-medium'
          )}
          aria-current={
            product.id === selected.product?.id ? 'page' : undefined
          }
        >
          <div className=" overflow-hidden">
            <span className="truncate font-bold whitespace-normal max-h-[2.5em]">
              {product.name}
            </span>
            <p
              className={classNames(
                product.id === selected.product?.id
                  ? 'text-slate-500'
                  : 'text-slate-400',
                'text-xs font-light whitespace-normal max-h-[2.5em]'
              )}
            >
              {product.description}
            </p>
          </div>
        </div>
      </div>
      {product.hidedate && new Date() < new Date(product.hidedate) && (
        <>
          <Tooltip id="hidedate" />
          <div className="flex">
            <CalendarDaysIcon
              onClick={() => onClickOpenHidedateModal(product, true, index)}
              data-tooltip-id="hidedate"
              data-tooltip-content={`This product will be hidden on ${dayjs(
                product.hidedate,
                'YYYY-MM-DD HH:mm'
              ).format('MM/DD/YYYY HH:mm')}`}
              className={classNames(
                'text-slate-500 z-10',
                'w-5 h-5 hover:text-gray-800 cursor-pointer dark:text-sky-400 dark:hover:text-sky-900'
              )}
            />
          </div>
        </>
      )}
      <div className="pt-1">
        <Tooltip id="visibility" />
        <Switch
          key={index}
          data-tooltip-id="visibility"
          data-tooltip-content="It is used to change the visibility of the product in the menu (active - passive)."
          checked={product.visible}
          onChange={(val) => {
            onChangeProductVisible(product, val, index);
          }}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full "
        >
          <span
            aria-hidden="true"
            className={classNames(
              product.id === selected.product?.id
                ? 'bg-gray-100'
                : product.items.length === 0
                ? 'bg-red-50'
                : 'bg-white',
              'pointer-events-none absolute h-full w-full rounded-md hover:bg-gray-100 dark:bg-slate-700'
            )}
          />
          <span
            aria-hidden="true"
            className={classNames(
              product.visible ? 'bg-blue-600 dark:bg-sky-400' : 'bg-red-400',
              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
            )}
          />
          <span
            aria-hidden="true"
            className={classNames(
              product.visible ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
            )}
          />
        </Switch>
      </div>

      <div className="flex">
        <PencilSquareIcon
          onClick={() => onClickEditProduct(product)}
          className={classNames(
            product.id === selected.product?.id
              ? 'text-gray-800'
              : 'text-slate-500',
            'w-5 h-5 hover:text-gray-800 cursor-pointer dark:text-sky-400 dark:hover:text-sky-900'
          )}
        />
      </div>
    </div>
  );
}

export default ProductCard;
