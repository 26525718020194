import axios from 'axios';
import tokenService from '../utils/tokenService';
import store from '../store/store';
import { loginFailure } from '../store/slices/authSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = tokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && [401].includes(response.status)) {
      tokenService.removeToken();
      tokenService.removeComp();
      tokenService.removeRole();
      tokenService.removeRolePermissions();
      store.dispatch(loginFailure());
    }
    return Promise.reject(error);
  }
);

export default api;
