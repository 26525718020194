import React, { useEffect, useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';
import { Pagination, Spin, Select } from 'antd';
import { Link } from 'react-router-dom';
import financeApi from '../../../services/financeApi';
import Notification from '../../../components/notification/notification';
import companyApi from '../../../services/companyApi';

dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Finance = () => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [loading, setLoading] = useState({
    listLoading: true,
  });
  const [financeTypeList, setFinanceTypeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [search, setSearch] = useState({
    financetypeid: '',
    companyid: '',
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });
  const [financeList, setFinanceList] = useState([]);

  const getFinanceList = async () => {
    try {
      let query = `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`;

      if (search.financetypeid)
        query += `&financetypeids=${search.financetypeid}`;

      if (search.companyid) query += `&companyid=${search.companyid}`;

      const list = await financeApi.list(query);
      if (list.data.data.length > 0) {
        setFinanceList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      } else {
        setFinanceList([]);
        setPagination({
          pageNumber: 1,
          pageSize: 10,
          totalRecords: null,
        });
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };
  const getFinanceTypeList = async () => {
    try {
      setLoading((prev) => ({ ...prev, listLoading: true }));
      const list = await financeApi.getTypeList();
      setFinanceTypeList(list.data.data);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getCompanyList = async () => {
    try {
      const query = `?pageSize=1000`;
      const list = await companyApi.list(query);
      if (list.data.data.length > 0) {
        setCompanyList(list.data.data);
      } else {
        setCompanyList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  useEffect(() => {
    getFinanceTypeList();
    getCompanyList();
    getFinanceList();
  }, [globalCompany.id, pagination.pageNumber, pagination.pageSize, search]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
      {loading.listLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Account Activites
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                View and keep track of all company financial transactions.
              </p>
            </div>
            <div className="flex-1 w-full pt-5 sm:pt-0">
              <label
                htmlFor="image"
                className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
              >
                Company
              </label>
              <Select
                allowClear
                size="large"
                style={{
                  width: '100%',
                }}
                onChange={(e) => {
                  setSearch((prev) => ({
                    ...prev,
                    companyid: e,
                  }));
                }}
                options={companyList.map((a) => ({
                  label: a.name,
                  value: a.id,
                }))}
                value={search.companyid}
                placeholder="Companies"
              />
            </div>
            <div className="flex-1 w-full sm:ml-3 pt-5 sm:pt-0">
              <label
                htmlFor="image"
                className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
              >
                Finance Types
              </label>
              <Select
                allowClear
                size="large"
                style={{
                  width: '100%',
                }}
                onChange={(e) => {
                  setSearch((prev) => ({
                    ...prev,
                    financetypeid: e,
                  }));
                }}
                options={financeTypeList.map((a) => ({
                  label: a.name,
                  value: a.id,
                }))}
                value={search.financetypeid}
                placeholder="Finance Types"
              />
            </div>
          </div>
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                      >
                        Company
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Explanation
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      ></th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Create Date
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                    {financeList.map((finance) => (
                      <tr key={finance.id}>
                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              {finance.company.logo ? (
                                <img
                                  alt="logo"
                                  className="w-8 h-8 rounded-full"
                                  src={finance.company.logo}
                                />
                              ) : (
                                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                  <span className="text-xs font-medium leading-none text-white">
                                    {finance.company.name
                                      .split(' ')
                                      .map((x) => x[0])}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="ml-4">
                              <div className="font-semibold text-gray-900 dark:text-slate-200">
                                <Link
                                  to={`/dashboard/companies/${finance.company.id}`}
                                  className="hover:text-blue-600"
                                >
                                  {finance.company.name}
                                </Link>
                              </div>
                              <div className="text-gray-500">
                                {finance.company.subscription?.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                          {finance.financetype.name}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {finance.details.length > 0 ? (
                            <Link
                              to={`/dashboard/companies/${finance.companyid}/orders?financeid=${finance.id}`}
                              className="text-blue-600 dark:text-sky-400"
                            >
                              {finance.description}
                            </Link>
                          ) : (
                            finance.description
                          )}
                        </td>
                        <td className="whitespace-nowrap font-bold py-4 pl-6 pr-3 text-sm text-gray-900 sm:pl-0 dark:text-slate-200">
                          {finance.code}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {moment
                            .utc(finance.createdate)
                            .local()
                            .format('MM/DD/YYYY')}
                        </td>
                        <td className="whitespace-nowrap font-bold py-4 pl-6 pr-3 text-sm text-gray-900 sm:pl-0 dark:text-slate-200">
                          {finance.amount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="w-full text-end">
                  <Pagination
                    current={pagination.pageNumber}
                    total={pagination.totalRecords}
                    defaultPageSize={pagination.pageSize}
                    onChange={(page) =>
                      setPagination((prev) => ({
                        ...prev,
                        pageNumber: page,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default Finance;
