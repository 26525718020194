import dayjs from 'dayjs';

export const setStartAndEndDate = (date, key, timezone) => {
  timezone = timezone || new Date().getTimezoneOffset() / -60;

  if (key === 'startdate') {
    const formattedDate = dayjs(date)
      .startOf('day')
      .add(timezone, 'hour')
      .format('YYYY-MM-DD HH:mm:ss');
    return formattedDate;
  }
  if (key === 'enddate') {
    const formattedDate = dayjs(date)
      .endOf('day')
      .add(timezone, 'hour')
      .format('YYYY-MM-DD HH:mm:ss');

    return formattedDate;
  }
};
