import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  PhoneIcon,
  HomeIcon,
  UserIcon,
  EnvelopeIcon,
} from '@heroicons/react/20/solid';
import {
  TrashIcon,
  LinkIcon,
  CheckIcon,
  ReceiptRefundIcon,
  EnvelopeIcon as EnvelopeIconOutline,
  ChatBubbleBottomCenterTextIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { BellAlertIcon, BellSlashIcon } from '@heroicons/react/24/solid';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import ModalDelete from '../../../components/modal-delete/modal-delete';
import Notification from '../../../components/notification/notification';
import ReactInputMask from 'react-input-mask';
import { UserRoles, PaymentStatus } from '../../../data/enums';
import SlideOver from './slide-overs';
import orderApi from '../../../services/orderApi';
import NoStoreLogo from '../../../assets/no-store-logo.svg';
import NoImageCircle from '../../../assets/no-product-image.svg';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import OrderLogs from '../components/OrderLogs';
import PaymentLogs from '../components/PaymentLogs';
import { formatPhoneNumber } from '../../../utils/baseUtils';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Tooltip } from 'react-tooltip';
import Modal from '../../../components/modal/modal';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.Ls.en.weekStart = 1;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const extraTimes = [
  {
    name: '10 min',
    value: 10,
  },
  {
    name: '15 min',
    value: 15,
  },
  {
    name: '20 min',
    value: 20,
  },
  {
    name: '25 min',
    value: 25,
  },
  {
    name: '30+ min',
    value: 30,
  },
];

const OrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const userRoles = useSelector((state) => state.authReducer.roles);
  const cancelButtonRef = useRef(null);
  const cancelButtonFeeRef = useRef(null);
  const [orderDetail, setOrderDetail] = useState({});
  const [selectedExtraTimes, setSelectedExtraTimes] = useState(null);
  const [showSlide, setShowSlide] = useState(false);
  const [form, setForm] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    refund: '',
  });
  const [sendEmailForm, setSendEmailForm] = useState({
    email: '',
  });
  const [isSendSms, setIsSendSms] = useState(true);
  const [loading, setLoading] = useState({
    initialLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    smsLoading: false,
    emailLoading: false,
  });

  const [errors, setErrors] = useState({
    code: false,
    name: false,
    refund: false,
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
    refund: false,
    taxandFees: false,
    sendEmailConfirm: false,
    sendSmsConfirm: false,
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const paymentStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-red-100 text-red-800';
      case 20:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onFormSubmit = async () => {
    if (!form.name) {
      setErrors((prev) => ({
        ...prev,
        emaildomain: !form.emaildomain ? true : false,
        name: !form.name ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, emaildomain: false, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      const res = await orderApi.upsert(body);
      if (res.data.data) {
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getOrderDetail();
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({ ...prev, code: false, name: false }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const getOrderDetail = async () => {
    try {
      setLoading((prev) => ({ ...prev, initialLoading: true }));
      const res = await orderApi.getById(id);
      if (res.data) {
        setOrderDetail(res.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const onClickDelete = () => {
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await orderApi.deleteById(orderDetail.id);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      navigate(-1);
    }
  };

  const onClickOpenSideBarReceipt = () => {
    setShowSlide(true);
  };

  const onClickSendEmail = async (email) => {
    try {
      setLoading((prev) => ({ ...prev, emailLoading: true }));
      const res = await orderApi.sendInvoiceByEmail(
        orderDetail.id,
        sendEmailForm.email ||
          orderDetail.email ||
          orderDetail.customer?.email ||
          email
      );
      if (res.data) {
        setNotf({
          display: true,
          type: 'success',
          message: 'E-mail successfully sent',
        });
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, emailLoading: false }));
      setDialogs((prev) => ({ ...prev, sendEmailConfirm: false }));
    }
  };

  const onClickSendSms = async () => {
    try {
      setLoading((prev) => ({ ...prev, smsLoading: true }));
      const res = await orderApi.sendInvoiceBySms(
        orderDetail.id,
        orderDetail.phone
      );
      if (res.data) {
        setNotf({
          display: true,
          type: 'success',
          message: 'SMS successfully sent',
        });
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, smsLoading: false }));
      setDialogs((prev) => ({ ...prev, sendSmsConfirm: false }));
    }
  };

  const onClickOpenReceiptNewTab = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/receipt/${orderDetail.id}`,
      '_blank'
    );
  };

  const onClickOpenStripeNewTab = () => {
    window.open(
      `${
        process.env.REACT_APP_BASE_STRIPE_PAYMENT_URL + orderDetail.paymentid
      }`,
      '_blank'
    );
  };

  const onChangeOrderStatus = async (orderstatus, acceptplus) => {
    try {
      const body = {
        orderid: orderDetail.id,
        orderstatus,
        acceptplus: acceptplus || 0,
        smsbypass: isSendSms,
      };
      await orderApi.changeOrderStatus(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setSelectedExtraTimes(null);
      getOrderDetail();
    }
  };

  const onClickRefund = () => {
    setForm({
      refund: (orderDetail.total - orderDetail.refund).toFixed(2),
      orderstatus: 40,
      orderid: orderDetail.id,
    });
    setDialogs((prev) => ({ ...prev, refund: true }));
  };

  const onFormRefundSubmit = async () => {
    if (!form.refund || form.refund > orderDetail.total || form.refund < 0) {
      setErrors((prev) => ({
        ...prev,
        refund:
          !form.refund || form.refund > orderDetail.total || form.refund < 0
            ? true
            : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        refund: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, initialLoading: true }));
      const body = { ...form };
      await orderApi.changeOrderStatus(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
      setDialogs((prev) => ({ ...prev, refund: false }));
      getOrderDetail();
    }
  };

  const onCloseRefundUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      refund: false,
    }));
    setDialogs((prev) => ({ ...prev, refund: false }));
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  // Order Log Tabs Start //
  const [currentLogTab, setCurrentLogTab] = useState('tab1');
  const tabs = [
    {
      name: 'Order Logs',
      value: 'tab1',
      component: <OrderLogs logs={orderDetail.logs} />,
    },
    {
      name: 'Payment Logs',
      value: 'tab2',
      component: (
        <PaymentLogs
          payments={orderDetail.payments}
          onClickOpenStripeNewTab={onClickOpenStripeNewTab}
        />
      ),
    },
  ];

  // Order Log Tabs End //

  const onClickOpenEmailModal = () => {
    setSendEmailForm((prev) => ({
      ...prev,
      email: orderDetail.email || orderDetail.customer?.email || '',
    }));
    setDialogs((prev) => ({ ...prev, sendEmailConfirm: true }));
  };

  const onClickOpenSmsModal = () => {
    setDialogs((prev) => ({ ...prev, sendSmsConfirm: true }));
  };

  return (
    <div className="p-4 sm:p-8 lg:p-8 bg-white justify-center flex mx-auto xl:max-w-[60%] dark:bg-slate-800">
      {loading.initialLoading ? (
        <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
          <Spin />
        </div>
      ) : (
        <div className="space-y-10">
          <div className=" md:flex flex-col md:items-center md:justify-between">
            <div className="mt-0  sm:mt-6 flex space-x-2 md:mt-0 md:ml-4 w-full border-b border-gray-200 dark:border-slate-600 bg-white py-5 dark:bg-slate-800">
              {orderDetail.orderstatus === 0 && (
                <div className="">
                  <div className="py-5 text-left font-medium text-[12px]">
                    <p>Select a time to Confirm</p>
                  </div>
                  <div className="justify-between flex flex-wrap items-center  sm:flex-nowrap">
                    <div>
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        {extraTimes.map((time, i) => (
                          <button
                            key={i}
                            onClick={() => {
                              onChangeOrderStatus(10, time.value);
                            }}
                            type="button"
                            className={classNames(
                              time.value === selectedExtraTimes
                                ? 'bg-green-500'
                                : 'bg-white dark:bg-slate-900',
                              i === 0 ? 'rounded-l-md' : '',
                              i === extraTimes.length - 1 ? 'rounded-r-md' : '',
                              'relative -ml-px inline-flex items-center  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-green-200 dark:hover:bg-green-600 focus:z-10 dark:text-slate-200'
                            )}
                          >
                            {time.name}
                          </button>
                        ))}
                      </span>
                    </div>
                    <div>
                      <Button
                        type="ghost"
                        onClick={() => {
                          onChangeOrderStatus(30);
                        }}
                        className={classNames(
                          'mt-3 sm:ml-3 ml-0 inline-flex justify-center rounded-md border border-red-400 bg-red-400 px-4 h-9 items-center   text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:border-none'
                        )}
                      >
                        <p className="font-bold  text-[16px] mr-1">X</p>
                        Reject
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {orderDetail.orderstatus &&
              ![0].includes(orderDetail.orderstatus) ? (
                <div className="flex w-full justify-between items-center">
                  <div className="text-end">
                    {orderDetail.orderstatus === 20 && (
                      <Button
                        type="ghost"
                        onClick={() => {
                          onChangeOrderStatus(100);
                        }}
                        className={classNames(
                          'mt-3 mr-3 inline-flex justify-center rounded-md border border-blue-300 bg-white px-4 h-10 items-center   text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:border-none'
                        )}
                      >
                        <CheckIcon
                          className="h-5 w-5 mr-1"
                          aria-hidden="true"
                        />
                        Complete
                      </Button>
                    )}
                  </div>
                  <div>
                    {orderDetail.orderstatus === 10 && (
                      <div className="inline-flex items-center">
                        {!isSendSms ? (
                          <>
                            <Tooltip id="sendSms" />
                            <BellAlertIcon
                              data-tooltip-id="sendSms"
                              data-tooltip-content={
                                'This will send a text message to the customer'
                              }
                              onClick={() => setIsSendSms(true)}
                              className="h-5 w-5 mr-2 text-green-600 cursor-pointer"
                            />
                          </>
                        ) : (
                          <>
                            <Tooltip id="noSendSms" />
                            <BellSlashIcon
                              data-tooltip-id="noSendSms"
                              data-tooltip-content={
                                'This will not send a text message to the customer'
                              }
                              onClick={() => setIsSendSms(false)}
                              className="h-5 w-5 mr-2 cursor-pointer"
                            />
                          </>
                        )}
                        <Button
                          disabled={loading.listLoading}
                          onClick={() => {
                            onChangeOrderStatus(20);
                          }}
                          type="ghost"
                          className="min-w-[140px] inline-flex justify-center h-10 items-center rounded-md border-none bg-green-600 hover:bg-green-400 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
                        >
                          Ready
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="grid lg:flex w-full py-4">
              <div className="min-w-0 flex-1">
                <div className="flex items-center">
                  <img
                    className="h-20 w-20 rounded-full"
                    src={orderDetail.store.storelogo || NoStoreLogo}
                    alt=""
                  />
                  <div className="ml-3">
                    <div className="flex items-center">
                      <h1 className="ml-0 text-2xl font-bold leading-7 flex space-x-3 text-gray-900 sm:truncate sm:leading-9 dark:text-slate-200">
                        <span>{orderDetail.store.name}</span>
                      </h1>
                    </div>
                    <dl className="flex flex-col sm:mt-1 ">
                      <dd className="flex items-center text-sm font-medium capitalize space-x-2 text-gray-500 dark:text-slate-400">
                        {!orderDetail.store.address &&
                        !orderDetail.store.address ? null : (
                          <HomeIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400 "
                            aria-hidden="true"
                          />
                        )}
                        <span>
                          {orderDetail.store.address || null}{' '}
                          {orderDetail.store.apartment &&
                          orderDetail.store.apartment !== 'null'
                            ? orderDetail.store.apartment
                            : ''}
                          {'  '}
                          {orderDetail.store.city}
                          {'  '}
                          {orderDetail.store.state}
                          {'  '}
                          {orderDetail.store.postalcode}
                        </span>
                      </dd>
                    </dl>
                    <dl className="flex flex-col  sm:mt-1 ">
                      <dd className="flex items-center text-sm font-medium capitalize space-x-2 text-gray-500 dark:text-slate-400">
                        {!orderDetail.store.phone &&
                        !orderDetail.store.phone ? null : (
                          <PhoneIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                        <span>{orderDetail.store.phone}</span>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mt-6  space-x-2 md:mt-6 self-center flex">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <Tooltip id="sendEmail" />
                  <button
                    data-tooltip-id="sendEmail"
                    data-tooltip-content={
                      'This will send an email to the customer'
                    }
                    type="button"
                    onClick={() => onClickOpenEmailModal()}
                    className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    <EnvelopeIconOutline
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                  <Tooltip id="sendSms" />
                  <button
                    type="button"
                    data-tooltip-id="sendEmail"
                    data-tooltip-content={
                      'This will send a text message to the customer'
                    }
                    onClick={() => onClickOpenSmsModal()}
                    className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    <ChatBubbleBottomCenterTextIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                  <Tooltip id="openPublicLinkNewTab" />
                  <button
                    type="button"
                    data-tooltip-id="openPublicLinkNewTab"
                    data-tooltip-content={
                      'This will open the public link in a new tab'
                    }
                    onClick={() => onClickOpenReceiptNewTab()}
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    <LinkIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-y-6 gap-x-6">
            <div className="sm:col-span-6 md:col-span-6 lg:col-span-3 col-span-6 overflow-hidden shadow sm:rounded-lg bg-[#f9fafb] dark:bg-slate-700">
              <div className="flex justify-between px-4 py-2 sm:px-6 dark:bg-slate-700">
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Status
                </h3>
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Receipt Number
                </h3>
              </div>
              <div className="border-t border-gray-200 dark:border-slate-600 px-4 py-2 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
                  <div className="py-2 flex justify-between sm:grid sm:grid-cols-2  sm:gap-4 sm:py-2 sm:px-6 dark:bg-slate-700">
                    <div>
                      <dt className="font-bold text-2xl text-gray-900 dark:text-slate-200">
                        {orderDetail.ordertype === 10 ? 'Dine-In' : 'To Go'}
                      </dt>
                      <dd className="mt-1  font-bold text-xl text-gray-900 col-span-1  sm:col-span-1 sm:mt-0 dark:text-slate-200">
                        {orderDetail.table
                          ? `(Table ${orderDetail.table})`
                          : ''}
                      </dd>
                    </div>
                    <div>
                      <dd className="mt-1 font-bold text-4xl text-gray-900 text-end sm:col-span-1 sm:mt-0 dark:text-slate-200">
                        {orderDetail.ordernumberstr || ''}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <div className="sm:col-span-6 md:col-span-6 lg:col-span-3 col-span-6 overflow-hidden shadow sm:rounded-lg bg-[#f9fafb] dark:bg-slate-700">
              <div className="px-4 py-2 sm:px-6">
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Order Details
                </h3>
              </div>
              <div className="border-t border-gray-200 dark:border-slate-600 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
                  <div className="py-2 flex justify-between sm:grid sm:grid-cols-2 sm:gap-4 sm:py-2 sm:px-6 dark:bg-slate-700">
                    <div>
                      <dt className=" text-lg text-gray-900 dark:text-slate-200">
                        {`Order#${orderDetail.orderid}`}
                      </dt>
                      <dd className="mt-1  text-lg text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-200">
                        {dayjs(orderDetail.createdate)
                          .utc()
                          .utcOffset(orderDetail?.timezone * 60)
                          .format('MM/DD/YYYY hh:mm A') || ''}
                      </dd>
                    </div>
                    <div className="flex justify-self-center">
                      <dd className="mt-1 self-center font-bold text-6xl text-gray-900 text-end sm:col-span-1 sm:mt-0">
                        <span
                          className={classNames(
                            paymentStatusColor(orderDetail.paymentstatus),
                            'flex items-center rounded-full px-3 py-0.5 text-xl font-medium'
                          )}
                        >
                          {PaymentStatus[orderDetail.paymentstatus]}
                        </span>
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <div className="sm:col-span-6 col-span-6 overflow-hidden shadow sm:rounded-lg bg-[#f9fafb] dark:bg-slate-700">
              <div className="flex justify-between px-4 py-2 sm:px-6 dark:bg-slate-700">
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Customer
                </h3>
              </div>
              <div className="border-t border-gray-200 dark:border-slate-600 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
                  <div className="py-2 sm:grid sm:grid-cols-1 sm:gap-4 sm:py-5 sm:px-6">
                    <div
                      className={classNames(
                        orderDetail.email || orderDetail.customer.email
                          ? 'grid lg:flex'
                          : 'flex',
                        'gap-x-6'
                      )}
                    >
                      <dt className="flex items-center font-bold text-lg text-gray-900 dark:text-slate-200">
                        <UserIcon className="h-8 w-8 pr-2 text-gray-400 " />
                        {orderDetail.name ||
                          orderDetail.customer?.fullname ||
                          '-'}
                      </dt>
                      <dt className="flex items-center font-bold text-lg text-gray-900 dark:text-slate-200">
                        <PhoneIcon className="h-8 w-8 pr-2 text-gray-400" />
                        <Link
                          to={`/dashboard/customers/${orderDetail.customerid}/orders`}
                        >
                          {formatPhoneNumber(
                            orderDetail.phone || orderDetail.customer.phone
                          )}
                        </Link>
                      </dt>
                      {orderDetail.email ||
                        (orderDetail.customer?.email && (
                          <dt className="flex items-center font-bold text-lg text-gray-900 dark:text-slate-200">
                            <EnvelopeIcon className="h-8 w-8 pr-2 text-gray-400" />
                            {orderDetail.email || orderDetail.customer?.email}
                          </dt>
                        ))}
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            {orderDetail.orderitems.map((item, i) => (
              <div
                key={i}
                className={classNames(
                  'text-gray-600 dark:text-slate-300',
                  'flex items-center col-span-6 '
                )}
              >
                <div className="px-6 hidden lg:block">
                  <img
                    className=" h-16 w-16 rounded-full min-w-[4rem] object-cover"
                    src={
                      item.item.product?.imagepath
                        ? item.item.product?.imagepath
                        : NoImageCircle
                    }
                    alt=""
                  />
                </div>
                <div className="px-2 font-bold text-black dark:text-slate-200 text-sm mb-auto pt-2">
                  {`${item.quantity} x`}
                </div>
                <div
                  key={item.name}
                  className={classNames(
                    'block items-center  px-3 py-2 text-sm font-medium flex-1 mb-auto'
                  )}
                >
                  <div>
                    <div className="flex">
                      <h1 className="font-bold text-black dark:text-slate-200">
                        {item.item.product?.name}
                      </h1>
                    </div>
                    {item.item.product?.name !== item.name && (
                      <div className="px-3 gap-x-5 flex">
                        <p className="truncate">{item.name} </p>
                        <p className="truncate font-bold">
                          {(item.price + orderDetail?.itemfee).toLocaleString(
                            'en-US',
                            {
                              style: 'currency',
                              currency: 'USD',
                            }
                          )}
                        </p>
                      </div>
                    )}
                    {item.orderitemoptions
                      .sort((a, b) => {
                        if (
                          a.optiongroupdisplayorder ===
                          b.optiongroupdisplayorder
                        ) {
                          return a.displayorder - b.displayorder;
                        }
                        return (
                          a.optiongroupdisplayorder - b.optiongroupdisplayorder
                        );
                      })
                      .map((option, i) => (
                        <div
                          key={i}
                          className={classNames(
                            'block items-center  px-3 text-sm font-medium'
                          )}
                        >
                          <div className="flex gap-x-5">
                            <p className="truncate">{option.name}</p>
                            {option.price > 0 && (
                              <p className="truncate font-bold">
                                {option.price.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}

                    <p className=" font-light italic">{item.note || ''}</p>
                  </div>
                </div>

                <div className="px-6 mb-auto pt-2">
                  <p className="truncate font-bold text-black dark:text-slate-200 text-sm">
                    {(
                      item.quantity *
                      (item.price +
                        orderDetail?.itemfee +
                        item.orderitemoptions.reduce((total, currentOption) => {
                          return total + currentOption.price;
                        }, 0))
                    ).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </p>
                </div>
              </div>
            ))}
            <div className="col-span-6 overflow-hidden border-t dark:border-slate-600pb-2" />
            {orderDetail.note && (
              <div className=" col-span-6 lg:col-span-4 overflow-hidden">
                <div className="pb-5 px-4">
                  <h1 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-200">
                    Order Note
                  </h1>
                </div>
                <div className="border border-dashed border-black min-h-[3.5rem]   bg-white  rounded-lg ">
                  <p className="p-4 italic text-sm">{orderDetail.note || ''}</p>
                </div>
              </div>
            )}
            <div
              className={classNames(
                !orderDetail.note
                  ? ' lg:col-span-3 lg:col-start-4 xl:col-span-2 xl:col-start-5 '
                  : '',
                'lg:mx-2 min-w-[180px] mx-0 grid lg:col-span-2 col-span-3 col-start-4 overflow-hidden shadow rounded-lg bg-[#f9fafb] dark:bg-slate-700'
              )}
            >
              <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4  sm:px-4 ">
                <dt className="text-sm  font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                  Subtotal
                </dt>
                <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                  {orderDetail.subtotal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </dd>
              </div>
              <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
                {orderDetail.fee !== 0 || orderDetail.crvtax ? (
                  <dt
                    onClick={() =>
                      setDialogs((prev) => ({
                        ...prev,
                        taxandFees: !prev.taxandFees,
                      }))
                    }
                    className="text-sm font-medium text-gray-500 sm:col-span-2 flex items-center cursor-pointer dark:text-slate-200"
                  >
                    Tax {orderDetail.fee !== 0 && '& Fees'}{' '}
                    <Icon
                      className="text-sm ml-1"
                      component={InfoCircleOutlined}
                    />
                  </dt>
                ) : (
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Tax
                  </dt>
                )}
                {orderDetail.fee !== 0 || orderDetail.crvtax ? (
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {(
                      orderDetail.tax +
                      orderDetail.fee +
                      orderDetail.crvtax
                    ).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || ''}
                  </dd>
                ) : (
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {orderDetail.tax.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || ''}
                  </dd>
                )}
              </div>
              {orderDetail.tip !== 0 && (
                <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4  sm:px-4">
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Tip
                  </dt>
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {orderDetail.tip.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </dd>
                </div>
              )}
              {orderDetail.discount !== 0 && (
                <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4   sm:px-4 ">
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Discount ({orderDetail.discountcode})
                  </dt>
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    -{' '}
                    {orderDetail.discount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </dd>
                </div>
              )}
              {orderDetail.refund !== 0 && (
                <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4  sm:px-4">
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Refund
                  </dt>
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {'- ' +
                      orderDetail.refund.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                  </dd>
                </div>
              )}
              <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-4 border-t dark:border-slate-600">
                <dt className="text-sm font-bold text-gray-500 sm:col-span-2 dark:text-slate-200">
                  Total Amount
                </dt>
                <dd className="mt-1 text-sm text-end text-gray-900 font-bold sm:col-span-1 sm:mt-0 dark:text-slate-300">
                  {(orderDetail.refund
                    ? orderDetail.total - orderDetail.refund
                    : orderDetail.total
                  ).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </dd>
              </div>
            </div>
          </div>
          <div className="border-t pt-5">
            {![30].includes(orderDetail.orderstatus) &&
              !(
                orderDetail.orderstatus === 40 &&
                orderDetail.total === orderDetail.refund
              ) && (
                <Button
                  type="ghost"
                  onClick={() => {
                    onClickRefund();
                  }}
                  className={classNames(
                    'mt-3 mr-3 inline-flex justify-center rounded-md border border-red-300 bg-white px-4 h-10 items-center   text-base font-medium text-red-700 shadow-sm hover:bg-red-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:border-none'
                  )}
                >
                  <ReceiptRefundIcon
                    className="h-5 w-5 mr-1"
                    aria-hidden="true"
                  />
                  Make a Refund
                </Button>
              )}
          </div>
          {userRoles.includes(UserRoles.ADMIN) && (
            <div>
              <div className="sm:hidden">
                <Select
                  size="large"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Please select"
                  value={currentLogTab}
                  onChange={(val) => setCurrentLogTab(val)}
                  options={tabs.map((x, idx) => ({
                    label: x.name,
                    value: 'tab' + (idx + 1),
                  }))}
                />
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <div
                        key={tab.name}
                        className={classNames(
                          tab.value === currentLogTab
                            ? 'border-blue-500 text-blue-600 dark:border-sky-400 dark:text-sky-500'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-slate-300',
                          'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                        )}
                        onClick={() => setCurrentLogTab(tab.value)}
                      >
                        {tab.name}
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="p-5">
                {tabs.find((a) => a.value === currentLogTab).component}
              </div>
            </div>
          )}

          <Transition.Root show={dialogs.taxandFees} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-20"
              initialFocus={cancelButtonRef}
              onClose={() => {
                setDialogs((prev) => ({
                  ...prev,
                  taxandFees: !prev.taxandFees,
                }));
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div
                        ref={cancelButtonFeeRef}
                        className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
                      >
                        <div className="sm:flex sm:items-start">
                          <div className="space-y-4 w-full text-start">
                            <h1 className="font-bold text-xl">
                              Sales Tax & Fees
                            </h1>
                            <div className="">
                              <p className="font-medium text-lg mb-1">
                                Sales Tax{' '}
                                {orderDetail.store.customerfeeisrate &&
                                  '(' + orderDetail.store.tax + '%)'}
                                : ${orderDetail.tax.toFixed(2)}
                              </p>
                              <p className="text-gray-500 text-sm mb-1">
                                Sales tax is added.
                              </p>
                              {orderDetail.crvtax !== 0 && (
                                <p className="font-medium text-lg">
                                  CRV Tax: ${orderDetail.crvtax.toFixed(2)}
                                </p>
                              )}
                              {orderDetail.crvtax !== 0 && (
                                <p className="text-gray-500 text-sm mb-1">
                                  The CRV fee, included in the price of
                                  beverages in California
                                </p>
                              )}
                              <p className="font-medium text-lg">
                                Fee: ${orderDetail.fee.toFixed(2)}
                              </p>
                              <p className="text-gray-500 text-sm mb-1">
                                A small Service Charge is applied to each order.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <Button
                          type="ghost"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4  text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent  focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() =>
                            setDialogs((prev) => ({
                              ...prev,
                              taxandFees: !prev.taxandFees,
                            }))
                          }
                        >
                          Ok
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="John Doe"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">Name can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="ownername"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.email}
                        onChange={(e) => onFormChange(e.target.value, 'email')}
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="ownerphone"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Phone
                    </label>
                    <div className="mt-1">
                      <ReactInputMask
                        mask="(999) 999 9999"
                        maskPlaceholder={' '}
                        value={form.phone}
                        onChange={(e) => onFormChange(e.target.value, 'phone')}
                        type="text"
                        name="phone"
                        id="phone"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                      />
                    </div>
                  </div>
                  <div className="mt-5 pt-2 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-12 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.deleteLoading}
                      loading={loading.deleteLoading}
                      onClick={() => onClickDelete()}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 sm:text-sm"
                    >
                      <TrashIcon className="text-white w-5 h-5" />
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        'sm:col-span-5',
                        'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        'sm:col-span-5',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      Edit
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <ModalDelete
        title="Delete Order"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={orderDetail}
      />
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
      <SlideOver
        orderDetail={orderDetail}
        showSlide={showSlide}
        setShowSlide={setShowSlide}
        onClickSendEmail={onClickSendEmail}
        onClickSendSms={onClickSendSms}
        onClickOpenReceiptNewTab={onClickOpenReceiptNewTab}
        onClickOpenStripeNewTab={onClickOpenStripeNewTab}
      />
      <Transition.Root show={dialogs.refund} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="pb-2 block text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Refund Amount
                    </label>
                    <div className="relative  rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        value={form.refund}
                        onChange={(e) => onFormChange(e.target.value, 'refund')}
                        type="number"
                        name="refund"
                        id="refund"
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                      />
                    </div>
                    <p className="text-xs pt-2">
                      Available balance is{' '}
                      {(orderDetail.total - orderDetail.refund).toLocaleString(
                        'en-US',
                        {
                          style: 'currency',
                          currency: 'USD',
                        }
                      )}
                    </p>
                    <div className="h-2 mt-1  text-xs">
                      {errors.refund && (
                        <p className="text-red-700">
                          You cant refund more than{' '}
                          {orderDetail.total.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.initialLoading}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseRefundUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.initialLoading}
                      loading={loading.initialLoading}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormRefundSubmit()}
                    >
                      Refund
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={dialogs.sendEmailConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setDialogs((prev) => ({
              ...prev,
              sendEmailConfirm: false,
            }));
            setSendEmailForm((prev) => ({
              ...prev,
              email: '',
            }));
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg sm:p-10">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 dark:text-slate-200"
                      >
                        Send E-Mail
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-slate-400">
                          {`Are you sure you want to send an email to`}
                        </p>
                      </div>
                      <div>
                        <div className="mt-2">
                          <input
                            value={sendEmailForm.email}
                            onChange={(e) =>
                              setSendEmailForm((prev) => ({
                                ...prev,
                                email: e.target.value,
                              }))
                            }
                            type="text"
                            name="email"
                            id="email"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="e.g. 'owner@abc.com'"
                          />
                          <div className="h-2 mt-1  text-xs"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white dark:bg-slate-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <Button
                      loading={loading.emailLoading}
                      disabled={loading.emailLoading}
                      type="ghost"
                      className="inline-flex w-full cursor-pointer justify-center disabled:bg-[#d9d9d9] disabled:hover:border-[#d9d9d9] disabled:text-zinc-400 disabled:cursor-not-allowed rounded-md border border-transparent bg-blue-600 px-4  text-base font-medium text-white hover:text-white shadow-sm hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400"
                      onClick={() => {
                        onClickSendEmail(sendEmailForm.email);
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      disabled={loading.emailLoading}
                      type="ghost"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4  text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent  focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-800"
                      onClick={() => {
                        setDialogs((prev) => ({
                          ...prev,
                          sendEmailConfirm: false,
                        }));
                        setSendEmailForm((prev) => ({
                          ...prev,
                          email: '',
                        }));
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal
        title="Send Sms"
        text={`Are you sure you want to send an sms to ${
          orderDetail.phone || orderDetail.customer?.phone
        }?`}
        loading={loading.smsLoading}
        open={dialogs.sendSmsConfirm}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, sendSmsConfirm: false }))
        }
        item={{
          name: orderDetail?.phone,
          id: orderDetail?.id,
        }}
        onOk={onClickSendSms}
      />
    </div>
  );
};

export default OrderDetail;
