import api from './api';

const resource = 'Finance/';

export const list = async (query) => {
  return await api.get(resource + 'List' + query);
};

export const getById = async (query) => {
  return await api.get(resource + 'Get' + query);
};

export const getBalanceById = async (companyId) => {
  return await api.get(resource + 'GetBalance?companyid=' + companyId);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const getTypeList = async () => {
  return await api.get(resource + 'List/Type');
};

export const getTypeById = async (id) => {
  return await api.get(resource + 'GetType?id=' + id);
};

export const upsertType = async (body) => {
  return await api.post(resource + 'UpsertType', body);
};

export const deleteTypeById = async (id) => {
  return await api.delete(resource + 'DeleteType?id=' + id);
};

const financeApi = {
  list,
  getById,
  getBalanceById,
  upsert,
  deleteById,
  getTypeList,
  getTypeById,
  upsertType,
  deleteTypeById,
};

export default financeApi;
