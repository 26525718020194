import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import Notification from '../../notification/notification';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import menuApi from '../../../services/menuApi';
import { MenuUpsertModal } from './menu-upsert-modal';
import ModalDelete from '../../modal-delete/modal-delete';
import MenuCard from './menu-card';
import { Spin, Select, Button } from 'antd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MenuList({ selected, setSelected }) {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [deletedItem, setDeletedItem] = useState({ name: '', id: '' });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    displayorder: false,
    stores: false,
  });
  const [menuForm, setMenuForm] = useState({
    id: '',
    name: '',
    description: '',
    displayorder: 0,
    stores: [],
    days: [],
  });
  const [menuDialogs, setMenuDialogs] = useState({
    menuUpsert: false,
    menuDelete: false,
  });
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState({
    menuLoading: true,
    categoryLoading: false,
    upsertLoading: false,
    deleteLoading: false,
  });

  const getMenuList = async (method) => {
    try {
      setLoading((prev) => ({ ...prev, menuLoading: true }));

      const query = `?companyid=${globalCompany.id}`;
      const list = await menuApi.list(query);

      setMenuList(list.data.data);
      if (list.data.data.length > 0) {
        setSelected((prev) => ({
          ...prev,
          menuid:
            method?.method === 'update' ? prev?.menuid : list.data.data[0]?.id,
        }));
      } else {
        setSelected((prev) => ({
          ...prev,
          menuid: '',
          category: {},
          itemid: '',
          product: {},
          optiongroupid: '',
          optionid: '',
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, menuLoading: false }));
    }
  };
  const onClickAddMenu = () => {
    setMenuForm({
      id: '',
      name: '',
      description: '',
      displayorder: menuList.length > 0 ? (menuList.length + 1) * 1024 : 1024,
      stores: [],
      days: null,
    });

    setMenuDialogs((prev) => ({ ...prev, menuUpsert: true }));
  };

  const onFormChange = (val, key) => {
    setMenuForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickDeleteMenu = (menu) => {
    setDeletedItem(menu);
    setMenuDialogs((prev) => ({
      ...prev,
      menuUpsert: false,
      menuDelete: true,
    }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await menuApi.deleteById(deletedItem.id);
      getMenuList();
    } catch (error) {
    } finally {
      setMenuDialogs((prev) => ({
        ...prev,
        menuDelete: false,
        menuUpsert: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const onClickEditMenu = (val) => {
    let stores = [];
    val.menustores.map((x) => stores.push(x.storeid));
    setMenuForm({
      id: val.id,
      name: val.name,
      description: val.description,
      displayorder: val.displayorder,
      stores,
      days: val.menudays,
    });

    setMenuDialogs((prev) => ({ ...prev, menuUpsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      displayorder: false,
      name: false,
      stores: false,
    }));
    setMenuDialogs((prev) => ({ ...prev, menuUpsert: false }));
  };
  const onFormSubmit = async (days) => {
    if (
      !menuForm.name ||
      menuForm.displayorder === '' ||
      menuForm.stores.length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        name: !menuForm.name ? true : false,
        displayorder: menuForm.displayorder === '' ? true : false,
        stores: menuForm.stores.length === 0 ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: false,
        displayorder: false,
        stores: false,
      }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...menuForm };
      if (days) {
        let daysPayload = {
          days: days,
          menuid: menuForm.id,
        };
        await menuApi.upsertHours(daysPayload);
      }
      const res = await menuApi.upsert(body);
      setSelected((prev) => ({ ...prev, menuid: res.data.data.id }));
      if (res.data.data) {
        setMenuDialogs((prev) => ({ ...prev, menuUpsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getMenuList({ method: menuForm.id ? 'update' : 'create' });
    }
  };

  const moveMenuItem = async (dragIndex, hoverIndex) => {
    setMenuList((prevMenus) =>
      update(prevMenus, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevMenus[dragIndex]],
        ],
      })
    );
  };

  const dropMenuItem = async (index, menu) => {
    if (index === menuList.length - 1) {
      menu.displayorder = Math.round(menuList[index - 1].displayorder + 1024);
    } else if (index === 0) {
      menu.displayorder = Math.round(menuList[1].displayorder / 2);
    } else {
      menu.displayorder = Math.round(
        (menuList[index - 1].displayorder + menuList[index + 1].displayorder) /
          2
      );
    }

    try {
      setLoading((prev) => ({ ...prev, menuLoading: true }));
      await menuApi.upsert(menu);
      getMenuList();
    } catch (error) {}
  };

  useEffect(() => {
    getMenuList();
  }, [globalCompany.id]);

  return (
    <div className="h-15 flex items-center overflow-x-auto overflow-y-hidden">
      {loading.menuLoading ? (
        <div className="w-full flex justify-center p-3">
          <Spin />
        </div>
      ) : (
        <>
          <div className="lg:hidden flex w-full items-center justify-between">
            <Select
              size="large"
              value={selected.menuid}
              className="block min-w-[10rem] rounded-md border-gray-300 my-2 ml-3 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              placeholder=""
              onChange={(e) => {
                setSelected((prev) => ({
                  ...prev,
                  menuid: e,
                  category: {},
                  product: {},
                  productid: '',
                }));
              }}
              options={menuList.map((menu) => ({
                label: menu.name,
                value: menu.id,
              }))}
            />
            {/*  <Button
              type="ghost"
              className="mx-5 flex justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
              onClick={() => onClickAddMenu()}
            >
              + Add Menu
            </Button> */}
            <div
              onClick={() => onClickAddMenu()}
              className="flex-col justify-center hover:bg-slate-50 
          flex items-center cursor-pointer mt-0 p-2 text-sm font-medium  text-blue-600 dark:text-slate-200 dark:hover:bg-slate-700"
            >
              <button
                type="button"
                className="rounded-full bg-blue-600 p-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className="">
              <nav className="-mb-px flex pb-[5px]" aria-label="Tabs">
                {menuList.map((menu, i) => (
                  <MenuCard
                    key={menu.id}
                    index={i}
                    id={menu.id}
                    selected={selected}
                    setSelected={setSelected}
                    onClickEditMenu={onClickEditMenu}
                    menu={menu}
                    moveMenuItem={moveMenuItem}
                    dropMenuItem={dropMenuItem}
                  />
                ))}
                <div
                  onClick={() => onClickAddMenu()}
                  className=" hover:bg-gray-100 justify-center dark:hover:bg-slate-700
          flex items-center cursor-pointer px-3 py-2 text-sm font-medium rounded-md text-blue-600 dark:text-slate-200 min-h-[4rem]"
                >
                  <button
                    type="button"
                    className="rounded-full bg-blue-600 p-1 text-white shadow-smfocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <span className="pl-1 truncate font-semibold text-[0.925rem]">
                    Add Menu
                  </span>
                </div>
              </nav>
            </div>
          </div>
          <MenuUpsertModal
            show={menuDialogs.menuUpsert}
            loading={loading.upsertLoading}
            menuForm={menuForm}
            errors={errors}
            onCloseUpsert={onCloseUpsert}
            onFormChange={onFormChange}
            onFormSubmit={onFormSubmit}
            onClickDeleteMenu={onClickDeleteMenu}
          />

          <ModalDelete
            title="Delete Menu"
            loading={loading.deleteLoading}
            open={menuDialogs.menuDelete}
            onCloseRequest={() =>
              setMenuDialogs((prev) => ({ ...prev, menuDelete: false }))
            }
            onOk={onConfirmDelete}
            item={deletedItem}
            shouldConfirm={true}
          />
          <Notification
            display={notf.display}
            type={notf.type}
            message={notf.message}
            onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
          />
        </>
      )}
    </div>
  );
}
