import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Switch } from '@headlessui/react';
import MenuList from '../../components/menu/menu-list/menu-list';
import CategoryList from '../../components/menu/category-list/category-list';
import ProductList from '../../components/menu/product-list/product-list';
import ItemList from '../../components/menu/item-list/item-list';
import OptionGroupList from '../../components/menu/option-group-list/option-group-list';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Menus = () => {
  const [selected, setSelected] = useState({
    menuid: '',
    productid: '',
    category: {},
    optiongroupid: '',
    optionid: '',
    product: {},
    isSettingsUpdated: false,
    isCategoryFetch: false,
    onlyDisabled: false,
  });

  const onFormChange = (e, name) => {
    setSelected((prev) => ({
      ...prev,
      [name]: e,
    }));
  };

  return (
    <div className="flex flex-1 flex-col  min-h-[92vh]  relative z-0 overflow-hidden  bg-white dark:bg-slate-800 ">
      <div className="flex border dark:border-slate-600 justify-between">
        <DndProvider
          backend={TouchBackend}
          options={{
            enableMouseEvents: true,
            delayTouchStart: 1000,
          }}
        >
          <MenuList selected={selected} setSelected={setSelected} />
        </DndProvider>
        <div className="self-center border-l-2">
          <div className="flex p-3">
            <label
              htmlFor="image"
              className="hidden sm:block text-sm font-semibold text-gray-700 dark:text-slate-400 whitespace-nowrap"
            >
              Unavailable Items
            </label>
            <div className="ml-2">
              <Switch
                checked={selected.onlyDisabled}
                onChange={(e) => {
                  onFormChange(e, 'onlyDisabled');
                }}
                className={classNames(
                  selected.onlyDisabled ? 'bg-blue-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    selected.onlyDisabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 grid grid-cols-12 flex-row ">
        <div
          className={classNames(
            selected.category.id ? 'hidden lg:block' : 'block',
            'col-span-12 border dark:border-slate-600 border-t-0 border-l-0 sm:col-span-12 md:col-span-12 lg:col-span-2 border-r-0 h-screen overflow-y-auto hide-scrollbar'
          )}
        >
          {selected.menuid && (
            <DndProvider
              backend={TouchBackend}
              options={{ enableMouseEvents: true }}
            >
              <CategoryList selected={selected} setSelected={setSelected} />
            </DndProvider>
          )}
        </div>
        <div
          className={classNames(
            selected.category.id && !selected.itemid && !selected.product.id
              ? 'block'
              : 'hidden lg:block h-screen',
            'col-span-12 border dark:border-slate-600 border-l-1 sm:col-span-12 md:col-span-12 lg:col-span-5 border-r-0 overflow-y-auto hide-scrollbar'
          )}
        >
          <div className="lg:hidden  p-5 flex justify-between border-b border-gray-200 dark:border-slate-700">
            <ChevronLeftIcon
              onClick={() => {
                setSelected((prev) => ({
                  ...prev,
                  category: {},
                  product: {},
                }));
              }}
              className=" h-8 w-8 flex-shrink-0 text-black cursor-pointer"
              aria-hidden="true"
            />
            <span className="text-[20px] font-[800] leading-6">
              {selected.category.name}
            </span>
          </div>
          <div
            className={classNames(
              selected.product.id ? 'hidden lg:block' : 'block',
              'col-span-12 border dark:border-slate-600 border-t-0 border-l-0 sm:col-span-12 md:col-span-12 lg:col-span-2 border-r-0 h-screen overflow-y-auto hide-scrollbar'
            )}
          >
            {selected.category.id && (
              <DndProvider
                backend={TouchBackend}
                options={{ enableMouseEvents: true }}
              >
                <ProductList selected={selected} setSelected={setSelected} />
              </DndProvider>
            )}
          </div>
        </div>
        <div
          className={classNames(
            selected.product.id ? 'block' : 'hidden lg:block h-screen',
            'col-span-12 lg:grid lg:grid-rows-2 border dark:border-slate-600 sm:col-span-12 md:col-span-12 lg:col-span-5 h-screen overflow-y-auto hide-scrollbar'
          )}
        >
          <div className="lg:hidden  p-5 flex justify-between border-b border-gray-200 dark:border-slate-700">
            <ChevronLeftIcon
              onClick={() => {
                setSelected((prev) => ({
                  ...prev,
                  category: selected.category,
                  itemid: '',
                  product: {},
                }));
              }}
              className=" h-8 w-8 flex-shrink-0 text-black cursor-pointer"
              aria-hidden="true"
            />
            <span className="text-[20px] font-[800] leading-6">
              {selected.product.name}
            </span>
          </div>
          {selected.product.id && selected.category.id && (
            <>
              <div className="row-span-1 border-b dark:border-slate-600 bg-slate-50 dark:bg-slate-700 overflow-y-auto overflow-x-hidden hide-scrollbar">
                <DndProvider
                  backend={TouchBackend}
                  options={{ enableMouseEvents: true }}
                >
                  <ItemList selected={selected} setSelected={setSelected} />
                </DndProvider>
              </div>
              <DndProvider
                backend={TouchBackend}
                options={{ enableMouseEvents: true }}
              >
                <div className="h-full row-span-1 bg-slate-50 dark:bg-slate-700 overflow-y-auto overflow-x-hidden hide-scrollbar">
                  <OptionGroupList
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </DndProvider>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menus;
