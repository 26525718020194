import api from "./api";

const resource = "Option/";

// Option

export const list = async (optiongroupid) => {
  return await api.get(
    resource + "List/Option?optiongroupid=" + optiongroupid + "&pageSize=100"
  );
};

export const getById = async (id) => {
  return await api.get(resource + "Get/Option?id=" + id);
};

export const upsert = async (body) => {
  return await api.post(resource + "Upsert/Option", body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + "Delete/Option?id=" + id);
};

// Option Group

export const groupList = async (query) => {
  return await api.get(resource + `List/OptionGroup` + query);
};

export const getGroupById = async (id) => {
  return await api.get(resource + "Get/OptionGroup?id=" + id);
};

export const upsertGroup = async (body) => {
  return await api.post(resource + "Upsert/OptionGroup", body);
};

export const deleteGroupById = async (id) => {
  return await api.delete(resource + "Delete/OptionGroup?id=" + id);
};

const optionApi = {
  list,
  getById,
  upsert,
  deleteById,
  groupList,
  getGroupById,
  upsertGroup,
  deleteGroupById,
};

export default optionApi;
