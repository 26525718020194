class TokenService {
  getLocalAccessToken() {
    const jwtToken = localStorage.getItem('accessToken');
    return jwtToken;
  }

  updateLocalAccessToken(token) {
    let accessToken = localStorage.getItem('accessToken');
    accessToken = token;
    localStorage.setItem('accessToken', accessToken);
  }

  removeToken() {
    localStorage.removeItem('accessToken');
  }

  setTheme(mode) {
    let theme = localStorage.getItem('theme');
    theme = mode;
    localStorage.setItem('theme', theme);
  }

  getTheme() {
    const theme = localStorage.getItem('theme');
    return theme;
  }

  setRole(code) {
    let roles = localStorage.getItem('roles');
    roles = code;
    localStorage.setItem('roles', roles);
  }

  setRolePermissions(permissions) {
    let rolepermissions = localStorage.getItem('rolepermissions');
    rolepermissions = permissions;
    localStorage.setItem('rolepermissions', rolepermissions);
  }

  getRole() {
    const roles = localStorage.getItem('roles');
    return roles;
  }

  getRolePermissions() {
    const rolepermissions = localStorage.getItem('rolepermissions');
    return rolepermissions;
  }

  removeRole() {
    localStorage.removeItem('roles');
  }

  removeRolePermissions() {
    localStorage.removeItem('rolepermissions');
  }

  setComp(id) {
    let compId = localStorage.getItem('compId');
    compId = id;
    localStorage.setItem('compId', compId);
  }

  getComp() {
    const compId = localStorage.getItem('compId');
    return compId;
  }

  removeComp() {
    localStorage.removeItem('compId');
  }
}
export default new TokenService();
