import React from "react";
import InputMask from "react-input-mask";

const Owner = ({ responsiblename, responsiblephone, onChange, errors }) => {
  const handleChange = (value, key) => {
    onChange(value, key);
  };

  return (
    <div className="absolute w-full mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
      <div className="sm:col-span-2">
        <label
          htmlFor="responsiblename"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Owner/Manager Name*
        </label>
        <div className="mt-1">
          <input
            onChange={(e) => handleChange(e.target.value, "responsiblename")}
            value={responsiblename}
            id="responsiblename"
            name="responsiblename"
            type="text"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
          />
        </div>
        <div className="h-2 mt-1  text-xs">
          {errors.responsiblename && (
            <p className="text-red-700">Enter your owner/manager name</p>
          )}
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="responsiblephone"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Owner/Manager Phone*
        </label>
        <div className="mt-1">
          <InputMask
            id="responsiblephone"
            mask="(999) 999 9999"
            maskPlaceholder={" "}
            name="responsiblephone"
            value={responsiblephone}
            onChange={(e) => handleChange(e.target.value, "responsiblephone")}
            style={{
              letterSpacing: "0.2rem",
            }}
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500 "
          />
        </div>
        <div className="h-2 mt-1  text-xs">
          {errors.responsiblephone && (
            <p className="text-red-700">
              Enter your correct owner/manager phone
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Owner;
