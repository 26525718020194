import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { Button, Pagination, Spin } from "antd";
import React, { Fragment, useEffect, useState, useRef } from "react";
import ModalDelete from "../../components/modal-delete/modal-delete";
import settingsApi from "../../services/settingsApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const CreditCardFees = () => {
  const [creditCardFeesList, setCreditCardFees] = useState([]);
  const [deletedSubscription, setDeletedSubscription] = useState({});
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [form, setForm] = useState({
    id: "",
    name: "",
    code: undefined,
    value: undefined,
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    code: false,
    value: false,
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });

  const cancelButtonRef = useRef(null);

  const getCreditCardFeesList = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setCreditCardFees([]);
    try {
      const query = `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`;
      const list = await settingsApi.list(query);
      if (list.data.data.length > 0) {
        setCreditCardFees(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  useEffect(() => {
    getCreditCardFeesList();
  }, [pagination.pageNumber]);

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickEdit = (val) => {
    setForm({
      id: val.id,
      name: val.name,
      code: val.code,
      value: val.value,
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    if (!form.name || !form.code || !form.value) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
        code: !form.code ? true : false,
        value: !form.value ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: false,
        code: false,
        value: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      await settingsApi.upsert(form);
    } catch (error) {
    } finally {
      await getCreditCardFeesList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };
  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      code: false,
      value: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onClickDelete = (sub) => {
    setDeletedSubscription(sub);
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await settingsApi.deleteById(deletedSubscription.id);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false, upsert: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getCreditCardFeesList();
    }
  };

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Templates
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                A list of all the templates in your account.
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                type="button"
                className="block rounded-md bg-blue-600 h-10  px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => onClickAdd()}
              >
                New
              </Button>
            </div> */}
          </div>
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Code
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Value
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-6 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      {creditCardFeesList.map((sub) => (
                        <tr key={sub.id}>
                          <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                            {sub.name}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {sub.code}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {sub.value.length > 30
                              ? sub.value.substring(0, 30) + "..."
                              : sub.value}
                          </td>
                          <td className="relative flex justify-between whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                            <div className="flex text-right justify-center  items-center">
                              <PencilSquareIcon
                                onClick={() => onClickEdit(sub)}
                                className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full text-end">
                    <Pagination
                      current={pagination.pageNumber}
                      total={pagination.totalRecords}
                      defaultPageSize={pagination.pageSize}
                      onChange={(page) =>
                        setPagination((prev) => ({ ...prev, pageNumber: page }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, "name")}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">Name can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Code
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.code}
                        onChange={(e) => onFormChange(e.target.value, "code")}
                        type="text"
                        name="code"
                        id="code"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.code && (
                          <p className="text-red-700">Code can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="value"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Value
                    </label>
                    <div className="mt-1">
                      <textarea
                        onChange={(e) => onFormChange(e.target.value, "value")}
                        value={form.value}
                        rows={3}
                        type="text"
                        name="value"
                        id="value"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-md dark:focus:border-sky-400 dark:focus:ring-sky-400"
                        placeholder="e.g ''Served with chef's special sauce and fries''"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.value && (
                          <p className="text-red-700">Value can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {form.id && (
                      <Button
                        type="ghost"
                        disabled={loading.deleteLoading}
                        loading={loading.deleteLoading}
                        onClick={() => onClickDelete(form)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        form.id ? "sm:col-span-5 col-span-12" : "col-span-6",
                        "inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200"
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        form.id ? "sm:col-span-5 col-span-12" : "col-span-6",
                        "inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      {form.id
                        ? "Edit Credit Card Fees"
                        : "Add Credit Card Fees"}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ModalDelete
        title="Delete Subscription"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedSubscription}
      />
    </div>
  );
};

export default CreditCardFees;
