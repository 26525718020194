import { RadioGroup, Switch } from '@headlessui/react';
import { Button } from 'antd';
import React, { useState } from 'react';
import storeApi from '../../../../services/storeApi';
import { Radio, Checkbox } from 'antd';
import { Tooltip } from 'react-tooltip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const tipRateOptions = [
  { key: 'tiprate1' },
  { key: 'tiprate2' },
  { key: 'tiprate3' },
  { key: 'tiprate4' },
];

const autoAcceptDurations = [
  { name: '10 min', value: 10 },
  { name: '15 min', value: 15 },
  { name: '20 min', value: 20 },
  { name: '25 min', value: 25 },
  { name: '30+ min', value: 30 },
];

const OrderTab = ({ storeDetail, checkUpdate }) => {
  const isNewStore =
    storeDetail.tiprate1 === 0 &&
    storeDetail.tiprate2 === 0 &&
    storeDetail.tiprate3 === 0 &&
    storeDetail.tiprate4 === 0
      ? true
      : false;

  const [switches, setSwitches] = useState({
    customerfeeactive: storeDetail.customerfeeactive,
    customerfeeisrate: storeDetail.customerfeeisrate,
    tipactive: storeDetail.tipactive,
    customernoteactive: storeDetail.customernoteactive,
    customernameactive: storeDetail.customernameactive,
    tablenumberactive: storeDetail.tablenumberactive,
    tablenumberrequire: storeDetail.tablenumberrequire,
    customerfee: storeDetail.customerfee,
    selectedtip: storeDetail.selectedtip,
    autocompleteorder: storeDetail.autocompleteorder,
    autoacceptordermin: storeDetail.autoacceptordermin,
    tiprate1: isNewStore ? 25 : storeDetail.tiprate1,
    tiprate2: isNewStore ? 20 : storeDetail.tiprate2,
    tiprate3: isNewStore ? 15 : storeDetail.tiprate3,
    tiprate4: isNewStore ? 10 : storeDetail.tiprate4,
    tiprate5: storeDetail.tiprate5,
  });

  const onChangeTipRate = (key, val) => {
    setSwitches((prev) => ({ ...prev, [key]: val }));
  };

  const onChangeTipType = (val) => {
    setSwitches((prev) => ({
      ...prev,
      selectedtip: switches.selectedtip === val ? 0 : val,
    }));
  };

  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });

  const onFormSubmit = async () => {
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = {
        ...storeDetail,
        ...switches,
      };

      const formData = new FormData();

      for (const key of Object.keys(body)) {
        if (body[key] && body[key] !== 'null') {
          formData.append(key, body[key]);
        }
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false);
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
    }
  };

  return (
    <div className="absolute w-full p-4 space-y-10">
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-4 sm:col-span-3">
          <h1 className="text-lg font-semibold">Customer Note</h1>
          <p className="text-sm text-gray-500">
            Allow customers to add item notes(Example: "Please do not put onion
            in this item.")
          </p>
        </div>
        <div className="grid justify-end col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <Switch
            checked={switches.customernoteactive}
            onChange={(e) => {
              setSwitches({
                ...switches,
                customernoteactive: !switches.customernoteactive,
              });
            }}
            className={classNames(
              switches.customernoteactive ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                switches.customernoteactive ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-4 sm:col-span-3">
          <h1 className="text-lg font-semibold">Tipping</h1>
          <p className="text-sm text-gray-500">
            Allow customers to enter tip for kiosk orders. Selected tip will be
            added to all orders when customer proceed to checkout. Customer can
            easily remove, modify, or update the tip according to their
            preference.
          </p>
          <div className="flex items-center gap-4 flex-wrap">
            {tipRateOptions.map((x) => (
              <div
                key={x.key}
                className="flex justify-start w-1/5 items-center space-x-1 min-w-[80px]"
              >
                <Radio
                  onClick={(e) => onChangeTipType(switches[x.key])}
                  defaultChecked={switches.selectedtip === switches[x.key]}
                  checked={switches.selectedtip === switches[x.key]}
                  className="h-3 w-3 mt-2 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                ></Radio>
                <div className="relative mt-2 rounded-md shadow-sm w-4/5">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">%</span>
                  </div>
                  <input
                    onChange={(e) => onChangeTipRate(x.key, e.target.value)}
                    type="number"
                    name="price"
                    id="price"
                    value={switches[x.key]}
                    className="flex items-center max-w-full justify-center text-center rounded-md border py-3 pl-6 pr-2 text-xs font-semibold sm:flex-1 dark:bg-slate-800 dark:border-slate-500"
                    placeholder="0"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="grid justify-end col-span-1 sm:col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <Switch
            checked={switches.tipactive}
            onChange={(e) => {
              setSwitches({ ...switches, tipactive: !switches.tipactive });
            }}
            className={classNames(
              switches.tipactive ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                switches.tipactive ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-4 sm:col-span-3">
          <h1 className="text-lg font-semibold">Auto Complete</h1>
          <p className="text-sm text-gray-500">
            Allow automatically complete orders when they marked "Ready"
          </p>
        </div>
        <div className="grid justify-end col-span-1 sm:col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <Switch
            checked={switches.autocompleteorder}
            onChange={(e) => {
              setSwitches({
                ...switches,
                autocompleteorder: !switches.autocompleteorder,
              });
            }}
            className={classNames(
              switches.autocompleteorder ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                switches.autocompleteorder ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-4 sm:col-span-3">
          <h1 className="text-lg font-semibold">Auto Accept Order</h1>
          <p className="text-sm text-gray-500">
            Allow automatically "Accept" all new orders
          </p>
          <RadioGroup
            value={switches.autoacceptordermin}
            onChange={(val) =>
              setSwitches({ ...switches, autoacceptordermin: Number(val) })
            }
            className="mt-8"
          >
            <RadioGroup.Label className="sr-only">
              Preselected Tip
            </RadioGroup.Label>
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
              {autoAcceptDurations.map((option) => (
                <RadioGroup.Option
                  key={option.name}
                  value={option.value}
                  className={({ active, checked }) =>
                    classNames(
                      active ? 'ring-2 ring-blue-500 ring-offset-2' : '',
                      checked
                        ? 'border-transparent bg-blue-600 text-white hover:bg-blue-700 dark:bg-slate-600'
                        : 'border-gray-200 dark:border-slate-700 bg-white text-gray-900 hover:bg-gray-50 dark:bg-slate-900 dark:text-slate-200',
                      'flex items-center justify-center text-center rounded-md border py-3 px-3 text-xs font-semibold sm:flex-1'
                    )
                  }
                >
                  <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <div className="grid justify-end col-span-1 sm:col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <Switch
            checked={switches.autoacceptordermin > 0 ? true : false}
            onChange={(e) => {
              setSwitches({
                ...switches,
                autoacceptordermin:
                  switches.autoacceptordermin > 0
                    ? 0
                    : autoAcceptDurations[0].value,
              });
            }}
            className={classNames(
              switches.autoacceptordermin > 0 ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                switches.autoacceptordermin > 0
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3 sm:col-span-2">
          <h1 className="text-lg font-semibold">Table Number</h1>
          <p className="text-sm text-gray-500">
            Allow customers to enter table number for kiosk orders
          </p>
        </div>
        <div className="inline-flex items-start justify-end col-span-1 sm:col-span-1 md:col-span-1 md:col-start-4 lg:col-start-3">
          <Tooltip id="tablenumberrequire" />
          <Checkbox
            data-tooltip-id="tablenumberrequire"
            data-tooltip-html="Require Table Number"
            checked={switches.tablenumberrequire}
            onChange={(e) => {
              setSwitches({
                ...switches,
                tablenumberrequire: !switches.tablenumberrequire,
              });
            }}
          ></Checkbox>
          <div className="flex pl-2 items-center">
            <p className="text-sm font-medium text-gray-700 dark:text-slate-300">
              Required
            </p>
          </div>
        </div>
        <div className="grid justify-end col-span-1 sm:col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <Switch
            checked={switches.tablenumberactive}
            onChange={(e) => {
              setSwitches({
                ...switches,
                tablenumberactive: !switches.tablenumberactive,
              });
            }}
            className={classNames(
              switches.tablenumberactive ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                switches.tablenumberactive ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 mt-4">
        <Button
          type="ghost"
          disabled={loading.upsertLoading}
          loading={loading.upsertLoading}
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          onClick={() => onFormSubmit()}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default OrderTab;
