import React, { Fragment, useRef, useState } from 'react';
import storeApi from '../../../../services/storeApi';
import { Button } from 'antd';
import { Dialog, Transition } from '@headlessui/react';
import CurrencyInput from 'react-currency-input-field';

const StoreTab = ({ storeDetail, checkUpdate }) => {
  const [form, setForm] = useState({ ...storeDetail });
  const [errors, setErrors] = useState({
    tax: false,
  });
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [dialogs, setDialogs] = useState({
    upsert: false,
  });

  const cancelButtonRef = useRef(null);

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickEdit = () => {
    setForm({
      ...storeDetail,
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    if (!form.tax) {
      setErrors((prev) => ({
        ...prev,
        tax: !form.tax ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        tax: false,
      }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      const formData = new FormData();

      for (const key of Object.keys(body)) {
        formData.append(key, body[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(body, config);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false, error.response.data);
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      tax: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-6 sm:gap-x-8">
      <div>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-slate-300">
            Taxes
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
        </div>
        <div className="mt-5 border-t border-gray-200 dark:border-slate-700">
          <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                Sales tax %
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-300">
                {storeDetail.tax} %
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 mt-4 mb-4">
        <Button
          type="ghost"
          onClick={() => onClickEdit()}
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
        >
          Update
        </Button>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="grid grid-cols-8 gap-6">
                    <div className="col-span-8">
                      <label
                        htmlFor="tax"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Sales Tax (%) *
                      </label>
                      <CurrencyInput
                        id="input-price"
                        name="tax"
                        placeholder="0.00"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        prefix="%"
                        value={form.tax}
                        decimalSeparator="."
                        groupSeparator=","
                        decimalScale={2}
                        step={1}
                        defaultValue={0}
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                          onFormChange(value, 'tax')
                        }
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.tax && (
                          <p className="text-red-500 text-xs">
                            This field is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => onFormSubmit()}
                    >
                      {'Update'}
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default StoreTab;
