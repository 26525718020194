import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { MinusCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { TrashIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import ModalDelete from '../../modal-delete/modal-delete';
import { useSelector } from 'react-redux';
import optionApi from '../../../services/optionApi';
import { Spin } from 'antd';
import { Button } from 'antd';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import OptionVisibleModal from './components/option-visible-modal';
import DeleteHidedateModal from './components/delete-hidedate-modal';

dayjs.extend(customParseFormat);

const timezone = new Date().getTimezoneOffset() / -60;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function OptionGroupUpsertModal({
  show,
  optionGroupForm,
  setOptionGroupForm,
  onClickOptionGroupFormSubmit,
  getOptionGroupList,
  loading,
  setLoading,
  dialogs,
  setDialogs,
  errors,
  setErrors,
}) {
  const globalCompany = useSelector((state) => state.companyReducer.selected);

  const cancelButtonRef = useRef(null);
  const [deletedItem, setDeletedItem] = useState({ name: '', id: '' });
  const [selectedOptionVisible, setSelectedOptionVisible] = useState({});

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const onOptionGroupFormChange = (val, key) => {
    setOptionGroupForm((prev) => ({ ...prev, [key]: val }));
  };

  const onOptionFormChange = (val, key, index) => {
    if (key === 'visible' && !val) {
      onClickOpenVisibleModal(optionGroupForm.options[index], index);
    } else {
      let newOptionList = [...optionGroupForm.options];
      newOptionList[index][key] = val;
      setOptionGroupForm((prev) => ({ ...prev, options: newOptionList }));
    }
  };

  const onClickOpenVisibleModal = (option, index) => {
    setSelectedOptionVisible({ option, index });

    setDialogs((prev) => ({ ...prev, optionVisible: true }));
  };

  const onSelectVisible = async (time) => {
    let { option, index } = selectedOptionVisible;
    let hidedate;

    option.visible = true;

    if (time === 'tomorrow') {
      hidedate = dayjs().add(1, 'day').startOf('day').add(timezone, 'hour');
      option.hidedate = dayjs(hidedate, 'YYYY-MM-DD HH:mm:ss');
    } else if (time === 'forever') {
      option.visible = false;
    } else {
      hidedate = dayjs().add(time + timezone, 'hour');
      option.hidedate = dayjs(hidedate, 'YYYY-MM-DD HH:mm:ss');
    }

    const newOptionList = optionGroupForm.options.map((item, idx) =>
      idx === index ? option : item
    );

    setOptionGroupForm((prev) => ({ ...prev, options: newOptionList }));
    setDialogs((prev) => ({ ...prev, optionVisible: false }));
    setSelectedOptionVisible({});
  };

  const onClickOpenHidedateModal = (option, val, i) => {
    setSelectedOptionVisible({ option, val, i });

    setDialogs((prev) => ({ ...prev, hidedateDelete: true }));
  };

  const onCloseHidedateModal = () => {
    setDialogs((prev) => ({ ...prev, hidedateDelete: false }));

    setSelectedOptionVisible({});
  };

  const onCloseVisible = () => {
    setDialogs((prev) => ({ ...prev, optionVisible: false }));
  };

  const onSubmitDeleteHideDate = () => {
    let { option, index } = selectedOptionVisible;

    option.hidedate = null;

    const newOptionList = optionGroupForm.options.map((item, idx) =>
      idx === index ? option : item
    );

    setOptionGroupForm((prev) => ({ ...prev, options: newOptionList }));

    setDialogs((prev) => ({ ...prev, hidedateDelete: false }));

    setSelectedOptionVisible({});
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      price: false,
    }));
    setOptionGroupForm((prev) => ({
      ...prev,
      name: '',
      decription: '',
      displayorder: '',
      min: '',
      max: '',
      companyid: globalCompany.id,
      options: [],
      productoptiongroups: prev.productoptiongroups,
    }));
    setDialogs((prev) => ({ ...prev, optionGroupUpsert: false }));
  };

  const onClickDeleteOptionGroup = (optionGroup) => {
    setDeletedItem(optionGroup);
    setDialogs((prev) => ({ ...prev, optionGroupDelete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await optionApi.deleteGroupById(deletedItem.id);
    } catch (error) {
    } finally {
      await getOptionGroupList();
      setDialogs((prev) => ({
        ...prev,
        optionGroupDelete: false,
        optionGroupUpsert: false,
      }));
      setLoading((prev) => ({
        ...prev,
        deleteLoading: false,
        upsertLoading: false,
      }));
    }
  };

  const onClickDeleteOption = async (option) => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      if (option.id) {
        await optionApi.deleteById(option.id);
      }
      const newOptionList = optionGroupForm.options.filter(
        (item) => item !== option
      );

      setOptionGroupForm((prev) => ({ ...prev, options: newOptionList }));
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const onClickAddOption = () => {
    setOptionGroupForm((prev) => ({
      ...prev,
      options: [
        ...prev.options,
        {
          name: '',
          displayorder:
            prev.options.length > 0 ? (prev.options.length + 1) * 1024 : 1024,
          price: 0,
          visible: true,
          optiongroupid: optionGroupForm.id,
          hidedate: '',
        },
      ],
    }));
  };

  const getOptionList = async () => {
    try {
      setLoading((prev) => ({ ...prev, optionsLoading: true }));

      const list = await optionApi.list(optionGroupForm.id);
      if (list.data.data.length > 0) {
        setOptionGroupForm((prev) => ({
          ...prev,
          options: list.data.data,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, optionsLoading: false }));
    }
  };

  useEffect(() => {
    if (optionGroupForm.id) {
      getOptionList();
    }
  }, [optionGroupForm.id]);

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => onCloseUpsert()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Modifier Group Name
                      </label>
                      <div className="my-2">
                        <input
                          value={optionGroupForm.name}
                          onChange={(e) =>
                            onOptionGroupFormChange(e.target.value, 'name')
                          }
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="Option Name"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.name && (
                            <p className="text-red-700">
                              Modifier Group name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Modifier Group Description (Optional)
                      </label>
                      <div className="my-2">
                        <input
                          value={optionGroupForm.description}
                          onChange={(e) =>
                            onOptionGroupFormChange(
                              e.target.value,
                              'description'
                            )
                          }
                          type="text"
                          name="description"
                          id="description"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="Modifier Group Description"
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                    </div>
                    <div className="justify-between pb-2 grid grid-cols-2 border-b-2 gap-x-5 dark:border-slate-500">
                      <div className="col-span-2 sm:col-span-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Min Select
                        </label>
                        <div className="my-2">
                          <input
                            value={optionGroupForm.min}
                            onChange={(e) =>
                              onOptionGroupFormChange(e.target.value, 'min')
                            }
                            type="number"
                            name="min"
                            id="min"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Min Option"
                          />
                          <div className="h-2 my-2  text-xs">
                            {errors.min && (
                              <p className="text-red-700">
                                Min Select can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 sm:col-span-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Max Select
                        </label>
                        <div className="my-2">
                          <input
                            value={optionGroupForm.max}
                            onChange={(e) =>
                              onOptionGroupFormChange(e.target.value, 'max')
                            }
                            type="number"
                            name="max"
                            id="max"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Max Option"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.max && (
                              <p className="text-red-700">
                                Max Select can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading.optionsLoading ? (
                      <div className="w-full flex justify-center py-4">
                        <Spin />
                      </div>
                    ) : (
                      <>
                        {optionGroupForm.options.length > 0 && (
                          <div className="pt-2">
                            {optionGroupForm.options.map((option, i) => {
                              return (
                                <div
                                  key={i}
                                  className="flex items-center justify-start pb-2 sm:col-span-1 col-span-2 gap-x-2"
                                >
                                  <div className="col-span-1 sm:col-span-2">
                                    <label
                                      htmlFor="name"
                                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                    >
                                      Option Name
                                    </label>
                                    <div className="mt-1 col-span-1">
                                      <input
                                        value={option.name}
                                        onChange={(e) =>
                                          onOptionFormChange(
                                            e.target.value,
                                            'name',
                                            i
                                          )
                                        }
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                        placeholder="Option Name"
                                      />
                                      <div className="h-2 mt-1  text-xs">
                                        {errors.optionname && (
                                          <p className="text-red-700">
                                            Option Name can not be empty
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-1 sm:col-span-2">
                                    <label
                                      htmlFor="price"
                                      className="block text-sm font-medium text-gray-900 dark:text-slate-400"
                                    >
                                      Price
                                    </label>
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span className="text-gray-500 sm:text-sm dark:text-slate-200">
                                          $
                                        </span>
                                      </div>
                                      <input
                                        value={option.price}
                                        onChange={(e) =>
                                          onOptionFormChange(
                                            e.target.value,
                                            'price',
                                            i
                                          )
                                        }
                                        type="number"
                                        name="price"
                                        id="price"
                                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-slate-500 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                                        placeholder="0.00"
                                        aria-describedby="price-currency"
                                      />
                                    </div>
                                    <div className="h-2 mt-1  text-xs"></div>
                                  </div>
                                  {option.hidedate &&
                                    new Date() < new Date(option.hidedate) && (
                                      <>
                                        <Tooltip id="hidedate" />
                                        <div className="flex mt-4">
                                          <CalendarDaysIcon
                                            onClick={() =>
                                              onClickOpenHidedateModal(
                                                option,
                                                true,
                                                i
                                              )
                                            }
                                            data-tooltip-id="hidedate"
                                            data-tooltip-content={`This option will be hidden on ${dayjs(
                                              option.hidedate,
                                              'YYYY-MM-DD HH:mm'
                                            ).format('MM/DD/YYYY HH:mm')}`}
                                            className={classNames(
                                              'text-slate-500 z-10',
                                              'w-5 h-5 hover:text-gray-800 cursor-pointer dark:text-sky-400 dark:hover:text-sky-900'
                                            )}
                                          />
                                        </div>
                                      </>
                                    )}
                                  <div className="col-span-1 px-2">
                                    <label
                                      htmlFor="visible"
                                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                    >
                                      Visible
                                    </label>
                                    <div className="mt-1">
                                      <Switch
                                        checked={option.visible}
                                        onChange={(e) => {
                                          onOptionFormChange(e, 'visible', i);
                                        }}
                                        className={classNames(
                                          option?.visible
                                            ? 'bg-blue-600'
                                            : 'bg-gray-200',
                                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                                        )}
                                      >
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            option?.visible
                                              ? 'translate-x-5'
                                              : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>
                                  <div>
                                    <MinusCircleIcon
                                      onClick={() =>
                                        onClickDeleteOption(option)
                                      }
                                      className="text-red-500 ml-3 mt-5 w-5 h-5 hover:text-red-900 cursor-pointer"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                    <div>
                      <div className="pt-2 justify-center dark:hover:bg-slate-700 flex p-1 text-sm font-medium rounded-md text-blue-500 dark:text-slate-200">
                        <div
                          className="flex items-center cursor-pointer justify-center gap-1 hover:bg-slate-50"
                          onClick={() => onClickAddOption()}
                        >
                          <button
                            type="button"
                            className="rounded-full bg-blue-600 p-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          >
                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                          <span className="truncate font-semibold text-[0.925rem]">
                            Add Option
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      <Button
                        type="ghost"
                        disabled={loading.deleteLoading}
                        loading={loading.deleteLoading}
                        onClick={() =>
                          onClickDeleteOptionGroup(optionGroupForm)
                        }
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 col-span-2 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        className={classNames(
                          'col-span-5 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                        )}
                        onClick={() => onCloseUpsert()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        loading={loading.upsertLoading}
                        className={classNames(
                          'col-span-5 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onClickOptionGroupFormSubmit()}
                      >
                        {optionGroupForm.id ? 'Save' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <OptionVisibleModal
        onSelectVisible={onSelectVisible}
        show={dialogs.optionVisible}
        loading={loading.visibleLoading}
        errors={errors}
        onCloseVisible={onCloseVisible}
      />
      <DeleteHidedateModal
        show={dialogs.hidedateDelete}
        loading={loading.upsertLoading}
        onClose={onCloseHidedateModal}
        onSubmit={onSubmitDeleteHideDate}
      />
      <ModalDelete
        title="Delete Confirmation"
        text={`Are you sure you want to delete "${deletedItem.name}"? All associated data will be permanently removed.`}
        loading={loading.deleteLoading}
        open={dialogs.optionGroupDelete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, optionGroupDelete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedItem}
      />
    </div>
  );
}
