import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Spin, Button, Radio } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Tooltip } from 'react-tooltip';
import Notification from '../../components/notification/notification';
import MasterCard from '../../assets/mastercard.svg';
import Visa from '../../assets/visa.svg';
import AmericanExpress from '../../assets/american-express.svg';
import Discover from '../../assets/discover.svg';
import CreditCardSvg from '../../assets/credit-card-icon.svg';
import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import ModalDelete from '../../components/modal-delete/modal-delete';
import Modal from '../../components/modal/modal';
import NoCreditCard from '../../assets/no-credit-card.svg';
import NoBankAccount from '../../assets/no-bank-account.svg';
import { useSelector } from 'react-redux';
import bankAccountApi from '../../services/bankAccountApi';
import cardApi from '../../services/cardApi';
import { getSubString } from '../../utils/getSubString';
import InputMask from 'react-input-mask';
import {
  CreditCardIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';

dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BankAccounts = () => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [bankAccountList, setBankAccountList] = useState([]);
  const [creditCardList, setCreditCardList] = useState([]);
  const [deletedBankAccount, setDeletedBankAccount] = useState({});
  const [deletedCreditCard, setDeletedCreditCard] = useState({});
  const [defaultBankAccount, setDefaultBankAccount] = useState({});
  const [defaultCreditCard, setDefaultCreditCard] = useState({});
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [bankAccountForm, setBankAccountForm] = useState({
    id: '',
    accounttype: 10,
    accountholdername: '',
    isdefault: false,
    routingnumber: '',
    accountnumber: '',
    companyid: globalCompany.id,
    reprensentativefullname: '',
    taxid: '',
    last4ssn: '',
    reprensentativebirthday: '',
  });
  const [creditCardForm, setCreditCardForm] = useState({
    id: '',
    isdefault: false,
    createcardmodel: {
      expyear: 0,
      expmonth: 0,
      number: '',
      cvc: '',
      expdate: '',
    },
    companyid: globalCompany.id,
  });

  const [dialogs, setDialogs] = useState({
    upsertBankAccount: false,
    upsertCreditCard: false,
    deleteBankAccount: false,
    deleteCreditCard: false,
    defaultCreditCard: false,
    defaultBankAccount: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    state: false,
    statecode: false,
    displayorder: false,
    zipcode: false,
    companyid: false,
    accountholdername: false,
    routingnumber: false,
    accountnumber: false,
    expyear: false,
    expmonth: false,
    number: false,
    cvc: false,
    reprensentativefullname: false,
    taxid: false,
    last4ssn: false,
    reprensentativebirthday: false,
    confirmaccountnumber: false,
  });

  const cancelButtonRef = useRef(null);

  const getBankAccountList = async () => {
    try {
      const query = `?companyid=${globalCompany.id}`;
      const list = await bankAccountApi.list(query);
      if (list.data.data.length > 0) {
        setBankAccountList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        display: true,
        message: error?.response?.data?.Message || 'Error',
        type: 'error',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const getCreditCardList = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setCreditCardList([]);
    try {
      const query = `?companyid=${globalCompany.id}`;
      const list = await cardApi.list(query);
      if (list.data.data.length > 0) {
        setCreditCardList(list.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const onFormChangeBankAccount = (val, key) => {
    if (key === 'taxid') {
      const numbersOnly = val.replace(/\D/g, '');

      let formattedValue = '';
      for (let i = 0; i < numbersOnly.length; i++) {
        if (i === 2) {
          formattedValue += '-';
        }
        formattedValue += numbersOnly[i];
      }
      val = formattedValue;
    }

    setBankAccountForm((prev) => ({ ...prev, [key]: val }));
  };

  const onFormChangeCreditCard = (val, key) => {
    setCreditCardForm((prev) => ({
      ...prev,
      createcardmodel: { ...prev.createcardmodel, [key]: val },
    }));
  };

  const onClickAddBankAccount = () => {
    setBankAccountForm({
      id: '',
      accounttype: 10,
      accountholdername: '',
      routingnumber: '',
      accountnumber: '',
      companyid: globalCompany.id,
      reprensentativefullname: '',
      taxid: '',
      last4ssn: '',
      reprensentativebirthday: '',
    });
    setDialogs((prev) => ({ ...prev, upsertBankAccount: true }));
  };

  const onClickAddCreditCard = () => {
    setCreditCardForm({
      id: '',
      isdefault: false,
      createcardmodel: {
        expyear: 0,
        expmonth: 0,
        number: '',
        cvc: '',
        expdate: '',
      },
      companyid: globalCompany.id,
    });
    setDialogs((prev) => ({ ...prev, upsertCreditCard: true }));
  };

  const onClickEdit = (val) => {
    setBankAccountForm({
      id: val.id,
      accounttype: val.accounttype,
      accountholdername: val.accountholdername,
      routingnumber: val.routingnumber,
      accountnumber: val.accountnumber,
      companyid: globalCompany.id,
    });
    setDialogs((prev) => ({ ...prev, upsertBankAccount: true }));
  };

  const onFormSubmitBankAccount = async () => {
    if (
      !bankAccountForm.companyid ||
      !bankAccountForm.accountholdername ||
      !bankAccountForm.routingnumber ||
      !bankAccountForm.accountnumber ||
      !bankAccountForm.taxid ||
      !bankAccountForm.last4ssn ||
      !bankAccountForm.reprensentativefullname ||
      !bankAccountForm.reprensentativebirthday ||
      bankAccountForm.accountnumber !== bankAccountForm.confirmaccountnumber
    ) {
      setErrors((prev) => ({
        ...prev,
        companyid: !bankAccountForm.companyid,
        accountholdername: !bankAccountForm.accountholdername,
        routingnumber: !bankAccountForm.routingnumber,
        accountnumber: !bankAccountForm.accountnumber,
        taxid: !bankAccountForm.taxid,
        last4ssn: !bankAccountForm.last4ssn,
        reprensentativefullname: !bankAccountForm.reprensentativefullname,
        reprensentativebirthday: !bankAccountForm.reprensentativebirthday,
        confirmaccountnumber:
          bankAccountForm.accountnumber !==
          bankAccountForm.confirmaccountnumber,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        companyid: false,
        accountholdername: false,
        routingnumber: false,
        accountnumber: false,
        taxid: false,
        last4ssn: false,
        reprensentativefullname: false,
        reprensentativebirthday: false,
        confirmaccountnumber: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...bankAccountForm };
      if (bankAccountList.length === 0) {
        body.isdefault = true;
      }
      await bankAccountApi.upsert(body);
    } catch (error) {
    } finally {
      await getBankAccountList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsertBankAccount: false }));
    }
  };
  const onFormSubmitCreditCard = async () => {
    if (creditCardForm.createcardmodel?.expdate) {
      if (creditCardForm.createcardmodel?.expdate.length > 5) {
        creditCardForm.createcardmodel.expdate =
          creditCardForm.createcardmodel.expdate.slice(0, 5);
      }

      const date = creditCardForm.createcardmodel.expdate.split('/');
      if (date.length === 2) {
        creditCardForm.createcardmodel.expmonth = date[0];
        creditCardForm.createcardmodel.expyear = date[1];
      }
    }

    if (
      !creditCardForm.companyid ||
      !creditCardForm.createcardmodel.expyear ||
      !creditCardForm.createcardmodel.expmonth ||
      !creditCardForm.createcardmodel.number ||
      !creditCardForm.createcardmodel.cvc ||
      creditCardForm.createcardmodel.number.length < 15 ||
      creditCardForm.createcardmodel.cvc.length < 3 ||
      creditCardForm.createcardmodel.zipcode.length < 5
    ) {
      setErrors((prev) => ({
        ...prev,
        companyid: !creditCardForm.companyid ? true : false,
        expyear: !creditCardForm.createcardmodel.expyear ? true : false,
        expmonth: !creditCardForm.createcardmodel.expmonth ? true : false,
        zipcode:
          !creditCardForm.createcardmodel.zipcode ||
          creditCardForm.createcardmodel.zipcode.length < 5
            ? true
            : false,
        number:
          !creditCardForm.createcardmodel.number ||
          creditCardForm.createcardmodel.number.length < 15
            ? true
            : false,
        cvc:
          !creditCardForm.createcardmodel.cvc ||
          creditCardForm.createcardmodel.cvc.length < 3
            ? true
            : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        companyid: false,
        expyear: false,
        expmonth: false,
        number: false,
        cvc: false,
        zipcode: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...creditCardForm };
      if (creditCardList.length === 0) {
        body.isdefault = true;
      }
      await cardApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        display: true,
        message: error?.response?.data?.Message || 'Error',
        type: 'error',
      }));
    } finally {
      await getCreditCardList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsertCreditCard: false }));
    }
  };

  const onCloseUpsertBankAccount = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      state: false,
      statecode: false,
      displayorder: false,
      zipcode: false,
      companyid: false,
      accountholdername: false,
      routingnumber: false,
      accountnumber: false,
      expyear: false,
      expmonth: false,
      number: false,
      cvc: false,
      reprensentativefullname: false,
      taxid: false,
      last4ssn: false,
      reprensentativebirthday: false,
      confirmaccountnumber: false,
    }));
    setDialogs((prev) => ({ ...prev, upsertBankAccount: false }));
  };

  const onCloseUpsertCreditCard = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      state: false,
      statecode: false,
      displayorder: false,
      zipcode: false,
      companyid: false,
      accountholdername: false,
      routingnumber: false,
      accountnumber: false,
      expyear: false,
      expmonth: false,
      number: false,
      cvc: false,
      reprensentativefullname: false,
      taxid: false,
      last4ssn: false,
      reprensentativebirthday: false,
      confirmaccountnumber: false,
    }));
    setDialogs((prev) => ({ ...prev, upsertCreditCard: false }));
  };

  const onClickDeleteBankAccount = (bankAccount) => {
    if (bankAccount.isdefault) {
      setNotf({
        display: true,
        type: 'error',
        message:
          'Default bank cannot be removed. Please add a new checking account and set it as default if needed. Contact customer support for assistance.',
      });
      return;
    }
    setDeletedBankAccount(bankAccount);
    setDialogs((prev) => ({ ...prev, deleteBankAccount: true }));
  };

  const onClickDeleteCreditCard = (creditCard) => {
    if (creditCard.isdefault) {
      setNotf({
        display: true,
        type: 'error',
        message:
          'Default credit card cannot be removed. Please add a new card and set it as default if needed. Contact customer support for assistance.',
      });
      return;
    }
    setDeletedCreditCard(creditCard);
    setDialogs((prev) => ({ ...prev, deleteCreditCard: true }));
  };

  const onConfirmDeleteBankAccount = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await bankAccountApi.deleteById(deletedBankAccount.id);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({
        ...prev,
        deleteBankAccount: false,
        upsertBankAccount: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getBankAccountList();
    }
  };

  const onConfirmDeleteCreditCard = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await cardApi.deleteById(deletedCreditCard.id);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({
        ...prev,
        deleteCreditCard: false,
        upsertCreditCard: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getCreditCardList();
    }
  };

  const onChangeDefaultBankAccount = async () => {
    const { account, val, index } = defaultBankAccount;

    if (val === false) {
      setNotf({
        display: true,
        type: 'error',
        message: 'Cannot uncheck default bank account',
      });
      return;
    }
    setLoading((prev) => ({ ...prev, upsertLoading: true }));
    const defaultAccount = bankAccountList.find((x) => x.isdefault === true);
    try {
      setBankAccountList((prev) => {
        const newList = [...prev];
        newList[index].isdefault = val;
        return newList;
      });
      if (defaultAccount) {
        setBankAccountList((prev) => {
          const newList = [...prev];
          const defaultIndex = newList.findIndex(
            (x) => x.id === defaultAccount.id
          );
          newList[defaultIndex].isdefault = false;
          return newList;
        });
        const body = { ...defaultAccount, isdefault: false };
        await bankAccountApi.upsert(body);
      }
      const body = { ...account, isdefault: val };
      await bankAccountApi.upsert(body);
    } catch (error) {
    } finally {
      setLoading((prev) => ({
        ...prev,
        upsertLoading: false,
        listLoading: true,
      }));
      setDialogs((prev) => ({ ...prev, defaultBankAccount: false }));
      await getBankAccountList();
    }
  };

  const onClickDefaultBankAccount = (account, val, index) => {
    setDefaultBankAccount({
      account,
      val,
      index,
    });
    setDialogs((prev) => ({ ...prev, defaultBankAccount: true }));
  };

  const onClickDefaultCreditCard = (card, val, index) => {
    setDefaultCreditCard({
      card,
      val,
      index,
    });
    setDialogs((prev) => ({ ...prev, defaultCreditCard: true }));
  };

  const onChangeDefaultCreditCard = async () => {
    const { card, val, index } = defaultCreditCard;

    if (val === false) {
      setNotf({
        display: true,
        type: 'error',
        message: 'Cannot uncheck default credit card',
      });
      return;
    }
    setLoading((prev) => ({ ...prev, upsertLoading: true }));
    const defaultCard = creditCardList.find((x) => x.isdefault === true);
    try {
      setCreditCardList((prev) => {
        const newList = [...prev];
        newList[index].isdefault = val;
        return newList;
      });
      if (defaultCard) {
        setCreditCardList((prev) => {
          const newList = [...prev];
          const defaultIndex = newList.findIndex(
            (x) => x.id === defaultCard.id
          );
          newList[defaultIndex].isdefault = false;
          return newList;
        });
        const body = { ...defaultCard, isdefault: false };
        await cardApi.upsert(body);
      }
      const body = { ...card, isdefault: val };
      await cardApi.upsert(body);
    } catch (error) {
      setNotf({
        display: true,
        type: 'error',
        message: error?.response?.data?.Message || 'Error',
      });
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, defaultCreditCard: false }));
      await getCreditCardList();
    }
  };

  const cardImage = (brand) => {
    switch (brand) {
      case 'Visa':
        return Visa;
      case 'MasterCard':
        return MasterCard;
      case 'American Express':
        return AmericanExpress;
      case 'Discover':
        return Discover;
      default:
        return CreditCardSvg;
    }
  };

  const expriyFormat = (expdate) => {
    if (!expdate) return '';
    const expDateFormatter =
      expdate?.replace(/\//g, '').substring(0, 2) +
      (expdate?.length > 2 ? '/' : '') +
      expdate?.replace(/\//g, '').substring(2, 4);

    return expDateFormatter;
  };

  const onClickConnectStipeAccount = async (account) => {
    setLoading((prev) => ({ ...prev, upserLoading: true }));
    try {
      await bankAccountApi.connectStipeBankAccount(account.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        display: true,
        message: error?.response?.data?.Message || 'Error',
        type: 'error',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upserLoading: false }));
      await getBankAccountList();
    }
  };

  useEffect(() => {
    getCreditCardList();
    getBankAccountList();
  }, [globalCompany.id]);

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl dark:bg-slate-800">
          <div className="sm:flex sm:items-center">
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              <div className="bg-white  sm:rounded-lg">
                <div
                  className={classNames(
                    creditCardList.length === 0 ? '' : 'min-h-[20rem]',
                    'dark:bg-slate-800 '
                  )}
                >
                  <div className="sm:flex">
                    <div className="sm:flex-auto dark:bg-slate-800">
                      <CreditCardIcon className="h-10 w-10 dark:text-slate-200 mb-3" />
                      <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                        Credit Cards
                      </h1>
                      <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                        View and manage all your credit cards in one place.
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none self-center">
                      <Button
                        disabled={loading.listLoading}
                        onClick={() => onClickAddCreditCard()}
                        type="ghost"
                        className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
                      >
                        Add Credit Card
                      </Button>
                    </div>
                  </div>
                  <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

                  {creditCardList.map((card, cardIdx) => (
                    <div key={cardIdx} className="mt-5 shadow">
                      <div className=" sm:items-center rounded-md bg-white dark:bg-slate-700 px-6 py-5 sm:flex  sm:justify-between">
                        <div className=" sm:flex sm:items-center">
                          <img
                            className="pl-5"
                            src={cardImage(card.brand)}
                            alt="Card Brand"
                          />
                          <div className="mt-3 sm:mt-0 sm:ml-4">
                            <div className="text-sm font-medium text-gray-900 dark:text-slate-400">
                              Ending with {card.last4}
                            </div>
                          </div>
                        </div>
                        <div className="flex space-x-4 mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          {!card.isdefault ? (
                            <div>
                              <Tooltip id="setdefault" />
                              <span
                                data-tooltip-id="setdefault"
                                data-tooltip-content="You can set default credit card"
                                onClick={(val) => {
                                  onClickDefaultCreditCard(card, true, cardIdx);
                                }}
                                className="bg-blue-100 text-blue-800 cursor-pointer items-center rounded-full px-3 py-0.5 text-sm font-medium"
                              >
                                Set Default
                              </span>
                            </div>
                          ) : (
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
                              Default
                            </span>
                          )}
                          {!card.isdefault && (
                            <>
                              <Tooltip id="delete" />
                              <span
                                data-tooltip-id="delete"
                                data-tooltip-content="You can set delete credit card"
                                onClick={() => onClickDeleteCreditCard(card)}
                                className="bg-red-100 text-red-800 cursor-pointer items-center rounded-full px-3 py-0.5 text-sm font-medium"
                              >
                                Delete
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {creditCardList.length === 0 && (
                  <div className="grid justify-center pt-20 dark:bg-slate-800">
                    <img
                      src={NoCreditCard}
                      alt="NoCreditCard"
                      className="h-24 pb-2"
                    />
                    <p className=" text-gray-500 font-light text-center text-sm dark:text-slate-400">
                      No Credit Card
                    </p>
                  </div>
                )}
                <div className="dark:bg-slate-800">
                  <div className="sm:flex pt-20">
                    <div className="sm:flex-auto dark:bg-slate-800">
                      <CurrencyDollarIcon className="h-10 w-10 dark:text-slate-200 mb-3" />
                      <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                        Bank Accounts
                      </h1>
                      <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                        View and manage all your bank accounts for payouts.
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none self-center">
                      <Button
                        disabled={loading.listLoading}
                        onClick={() => onClickAddBankAccount()}
                        type="ghost"
                        className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
                      >
                        Add Bank Account
                      </Button>
                    </div>
                  </div>
                  <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

                  {bankAccountList.map((account, accountIdx) => (
                    <div key={accountIdx} className="mt-5 shadow">
                      <div className=" sm:items-center rounded-md bg-white dark:bg-slate-700 px-6 py-5 sm:flex  sm:justify-between">
                        <div className=" sm:flex sm:items-center">
                          <div className="mt-3 sm:mt-0 sm:ml-4">
                            <div className="text-sm font-bold text-gray-900 dark:text-slate-200">
                              {account.accountholdername}
                            </div>
                            <div className="text-xs  text-gray-400 dark:text-slate-200">
                              {account.accounttype === 10
                                ? 'Company'
                                : 'Individual'}
                            </div>
                            <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center dark:text-slate-400">
                              <div className="font-bold text-base">
                                ···· {getSubString(account.accountnumber, 4)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex space-x-4 mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          {/*  {!account.company.stripeaccountid && (
                            <>
                              <Tooltip id="connectStripe" />
                              <span
                                data-tooltip-id="connectStripe"
                                data-tooltip-content="You can connect stripe account"
                                onClick={() =>
                                  onClickConnectStipeAccount(account)
                                }
                                className="bg-yellow-100 text-yellow-800 cursor-pointer items-center rounded-full px-3 py-0.5 text-sm font-medium"
                              >
                                Connect Stripe
                              </span>
                            </>
                          )} */}
                          {!account.isdefault ? (
                            <div>
                              <Tooltip id="setdefaultaccount" />
                              <span
                                data-tooltip-id="setdefaultaccount"
                                data-tooltip-content="You can set default bank account"
                                onClick={(val) => {
                                  onClickDefaultBankAccount(
                                    account,
                                    true,
                                    accountIdx
                                  );
                                }}
                                className="bg-blue-100 text-blue-800 cursor-pointer items-center rounded-full px-3 py-0.5 text-sm font-medium"
                              >
                                Set Default
                              </span>
                            </div>
                          ) : (
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
                              Default
                            </span>
                          )}
                          {!account.isdefault && (
                            <>
                              <Tooltip id="deleteAccount" />
                              <span
                                data-tooltip-id="deleteAccount"
                                data-tooltip-content="You can set delete bank account"
                                onClick={() =>
                                  onClickDeleteBankAccount(account)
                                }
                                className="bg-red-100 text-red-800 cursor-pointer items-center rounded-full px-3 py-0.5 text-sm font-medium"
                              >
                                Delete
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {bankAccountList.length > 0 && (
                  <div className="pt-5 text-sm text-gray-500 dark:text-slate-400">
                    <p>
                      We automatically process payouts every <b>Monday</b>,
                      which may take up to <b>two business</b> days to arrive.
                      Please note that there may be delays on public holidays.
                    </p>
                  </div>
                )}

                {bankAccountList.length === 0 && (
                  <div className="grid justify-center py-20 dark:bg-slate-800">
                    <img
                      src={NoBankAccount}
                      alt="NoBankAccount"
                      className="h-24 pb-2"
                    />
                    <p className=" text-gray-500 font-light text-center text-sm  dark:text-slate-400">
                      No Bank Account
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={dialogs.upsertBankAccount} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="flex">
                    <div className="grid pr-2 pb-1">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Account Type*
                        </label>
                      </div>
                      <div className="flex">
                        <Radio.Group
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'accounttype'
                            )
                          }
                          value={bankAccountForm.accounttype}
                        >
                          <Radio value={10}>Company</Radio>
                          <Radio value={20} disabled>
                            Individual
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-x-5">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Legal Name*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.accountholdername}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'accountholdername'
                            )
                          }
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="Acme Inc"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.accountholdername && (
                            <p className="text-red-700">
                              Legal Name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Tax ID*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.taxid}
                          onChange={(e) =>
                            onFormChangeBankAccount(e.target.value, 'taxid')
                          }
                          maxLength={10}
                          type="text"
                          name="taxid"
                          id="taxid"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="12-3456789"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.taxid && (
                            <p className="text-red-700">
                              Tax ID can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Routing Number*
                    </label>
                    <div className="mt-1">
                      <input
                        value={bankAccountForm.routingnumber}
                        onChange={(e) =>
                          onFormChangeBankAccount(
                            e.target.value,
                            'routingnumber'
                          )
                        }
                        type="number"
                        name="routingnumber"
                        id="routingnumber"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="1234567890"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.routingnumber && (
                          <p className="text-red-700">
                            Routing Number can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-x-5">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Account Number*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.accountnumber}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'accountnumber'
                            )
                          }
                          type="number"
                          name="accountnumber"
                          id="accountnumber"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="1234567890"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.accountnumber && (
                            <p className="text-red-700">
                              Account Number can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Confirm Account Number*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.confirmaccountnumber}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'confirmaccountnumber'
                            )
                          }
                          type="number"
                          name="confirmaccountnumber"
                          id="confirmaccountnumber"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="1234567890"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.confirmaccountnumber && (
                            <p className="text-red-700">
                              Account Number does not match
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-bold text-gray-700 dark:text-slate-200"
                    >
                      Account Representative
                    </label>
                    <span className="text-xs text-gray-500 dark:text-slate-400">
                      Payout needs to be activated by a person, The
                      representative must either be an owner or an executive
                      (manager,etc).
                    </span>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Representative Full Name*
                    </label>
                    <div className="mt-1">
                      <input
                        value={bankAccountForm.reprensentativefullname}
                        onChange={(e) =>
                          onFormChangeBankAccount(
                            e.target.value,
                            'reprensentativefullname'
                          )
                        }
                        type="text"
                        name="reprensentativefullname"
                        id="reprensentativefullname"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="John Doe"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.reprensentativefullname && (
                          <p className="text-red-700">
                            Representative Full Name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-x-5">
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Representative Birthday*
                      </label>
                      <div className="mt-1">
                        <InputMask
                          mask="99/99/9999"
                          maskChar=""
                          value={bankAccountForm.reprensentativebirthday}
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'reprensentativebirthday'
                            )
                          }
                          placeholder="MM/DD/YYYY"
                          alwaysShowMask
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.reprensentativebirthday && (
                            <p className="text-red-700">
                              Select a Representative Birthday
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Last 4 Digit of SSN*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.last4ssn}
                          onChange={(e) =>
                            onFormChangeBankAccount(e.target.value, 'last4ssn')
                          }
                          maxLength={4}
                          type="text"
                          name="last4ssn"
                          id="last4ssn"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="1234"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.last4ssn && (
                            <p className="text-red-700">
                              Last 4 Digit of SSN can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="text-xs text-gray-500 dark:text-slate-400 ">
                    By clicking 'Add Bank Account' you agree to the terms in the{' '}
                    <a
                      className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500 dark:hover:text-sky-400"
                      href="https://stripe.com/legal/connect-account"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stripe Agreement
                    </a>{' '}
                    and certify that the provided information is accurate and
                    complete.
                  </span>

                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {bankAccountForm.id && (
                      <Button
                        type="ghost"
                        disabled={loading.deleteLoading}
                        loading={loading.deleteLoading}
                        onClick={() =>
                          onClickDeleteBankAccount(bankAccountForm)
                        }
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        bankAccountForm.id
                          ? 'sm:col-span-5 col-span-12'
                          : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsertBankAccount()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        bankAccountForm.id
                          ? 'sm:col-span-5 col-span-12'
                          : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmitBankAccount()}
                    >
                      {bankAccountForm.id
                        ? 'Edit Bank Account'
                        : 'Add Bank Account'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ModalDelete
        title="Delete Bank Account"
        text={`"Are you sure you want to remove this checking? Once removed, all information related to account ending in ${deletedBankAccount.accountnumber} will be deleted."`}
        loading={loading.deleteLoading}
        open={dialogs.deleteBankAccount}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, deleteBankAccount: false }))
        }
        onOk={onConfirmDeleteBankAccount}
        item={{
          name: deletedBankAccount.accountnumber,
          id: deletedBankAccount.id,
        }}
      />
      <Transition.Root show={dialogs.upsertCreditCard} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg sm:p-10">
                  <div className="mt-2 -space-y-px rounded-md bg-white dark:bg-slate-800 shadow-sm">
                    <div>
                      <label
                        htmlFor="cc-number"
                        className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Card Details*
                      </label>
                      <input
                        value={creditCardForm.createcardmodel?.number}
                        onChange={(e) =>
                          onFormChangeCreditCard(e.target.value, 'number')
                        }
                        type="text"
                        maxLength={16}
                        minLength={15}
                        name="cc-number"
                        autoComplete="cc-number"
                        id="cc-number"
                        className="focus:z-10 pl-2 relative block w-full rounded-md rounded-b-none border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="Card number"
                      />
                    </div>
                    <div className="flex -space-x-px">
                      <div className="w-1/2 min-w-0 flex-1">
                        <label
                          htmlFor="card-expiration-date"
                          className="sr-only"
                        >
                          Expiration date
                        </label>
                        <input
                          value={expriyFormat(
                            creditCardForm.createcardmodel.expdate
                          )}
                          onChange={(e) => {
                            onFormChangeCreditCard(e.target.value, 'expdate');
                          }}
                          type="text"
                          autoComplete="cc-exp"
                          name="card-expiration-date"
                          id="card-expiration-date"
                          className="focus:z-10 pl-2 relative block w-full rounded-md rounded-t-none rounded-r-none border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="MM / YY"
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <label htmlFor="card-cvc" className="sr-only">
                          CVC
                        </label>
                        <input
                          value={creditCardForm.createcardmodel?.cvc}
                          onChange={(e) =>
                            onFormChangeCreditCard(e.target.value, 'cvc')
                          }
                          type="text"
                          autoComplete="cc-csc"
                          name="cvc"
                          id="cvc"
                          maxLength={4}
                          className="focus:z-10 pl-2 relative block w-full rounded-md rounded-t-none rounded-l-none border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="CVC"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {errors.number && (
                      <div className="h-2 mt-1 text-xs">
                        <p className="text-red-700">
                          *Please enter a valid credit card number
                        </p>
                      </div>
                    )}
                    {errors.expyear && (
                      <div className="h-2 mt-1  text-xs">
                        <p className="text-red-700">
                          *Expyear can not be empty
                        </p>
                      </div>
                    )}
                    {errors.cvc && (
                      <div className="h-2 mt-1  text-xs">
                        <p className="text-red-700">
                          *Please enter a valid CVC
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="space-x-2">
                    <div className="mt-2  -space-y-px rounded-md">
                      <label
                        htmlFor="email"
                        className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Billing address*
                      </label>
                      <div>
                        <label htmlFor="country" className="sr-only">
                          Country
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className="focus:z-10 pl-2 relative block w-full rounded-md rounded-b-none border bg-white dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        >
                          <option>United States</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="postal-code" className="sr-only">
                          ZIP / Postal code*
                        </label>
                        <input
                          value={creditCardForm.createcardmodel?.zipcode}
                          onChange={(e) =>
                            onFormChangeCreditCard(e.target.value, 'zipcode')
                          }
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          maxLength={5}
                          autoComplete="postal-code"
                          className="focus:z-10 pl-2 relative block w-full rounded-md rounded-t-none border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="ZIP / Postal code"
                        />
                      </div>
                      <span className="text-xs text-gray-500 dark:text-slate-400">
                        By clicking 'Add Credit Card' you agree{' '}
                        <a
                          className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500 dark:hover:text-sky-400"
                          href="https://stripe.com/legal/ssa"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Stripe Terms.
                        </a>
                      </span>
                      <div className="mt-1 text-xs">
                        {errors.zipcode && (
                          <p className="text-red-700">
                            *Please enter a valid zip code
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsertCreditCard()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        creditCardForm.id ? 'sm:col-span-5' : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmitCreditCard()}
                    >
                      Add Credit Card
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ModalDelete
        title="Delete Credit Card"
        text={`Are you sure you want to remove this credit card? Once removed, all information related to card ending in ${deletedCreditCard.last4} will be deleted`}
        loading={loading.deleteLoading}
        open={dialogs.deleteCreditCard}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, deleteCreditCard: false }))
        }
        onOk={onConfirmDeleteCreditCard}
        item={{
          name: deletedCreditCard.last4,
          id: deletedCreditCard.id,
        }}
      />
      <Modal
        title="Make Default Payment Option?"
        text={`Are you sure you want to make this credit card your default payment option? This means that this card will be automatically charged for future invoices unless you choose a different payment method. You can still change this later.`}
        loading={loading.upsertLoading}
        open={dialogs.defaultCreditCard}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, defaultCreditCard: false }))
        }
        item={{
          name: defaultCreditCard.card?.last4,
          id: deletedCreditCard.card?.id,
        }}
        onOk={onChangeDefaultCreditCard}
      />
      <Modal
        title="Make Default Payment Option?"
        text={`Are you sure you want to make this account your default payment option? This means that this account will be automatically deposit for future payouts unless you choose a different payment method. You can still change this later.`}
        loading={loading.upsertLoading}
        open={dialogs.defaultBankAccount}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, defaultBankAccount: false }))
        }
        item={{
          name: defaultCreditCard.account?.number,
          id: deletedCreditCard.account?.id,
        }}
        onOk={onChangeDefaultBankAccount}
      />
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default BankAccounts;
