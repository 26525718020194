import api from './api';

const resource = 'Category/';

export const list = async (menuid) => {
  return await api.get(resource + 'List?PageSize=1000&menuid=' + menuid);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

const categoryApi = { list, getById, upsert, deleteById };

export default categoryApi;
