import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function CategoryCard({
  id,
  index,
  category,
  selected,
  setSelected,
  onClickEditCategory,
  moveCategoryItem,
  dropCategoryItem,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'category',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropCategoryItem(item.index, category);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCategoryItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'category',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      onClick={() => {
        setSelected((prev) => ({
          ...prev,
          category: category,
          product: {},
        }));
      }}
      className={classNames(
        category.id === selected.category.id
          ? 'border-blue-600 text-gray-800 border-r-2 dark:border-sky-400 dark:text-sky-500 bg-slate-50 dark:bg-slate-700'
          : 'text-slate-500 hover:text-gray-900 hover:border-gray-300 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-600',
        isDragging ? 'shadow-lg' : '',
        'flex  h-[6rem] p-5 cursor-pointer'
      )}
    >
      <div className="flex-1">
        <div
          key={category.name}
          className={classNames(
            'flex cursor-pointer h-16 text-sm font-medium my-2'
          )}
          aria-current={category.id ? 'page' : undefined}
        >
          <div className=" overflow-hidden mt-2">
            <span className="truncate font-semibold whitespace-normal ">
              {category.name}
            </span>
            <p
              className={classNames(
                category.id === selected.category?.id
                  ? 'text-slate-500'
                  : 'text-slate-400',
                'text-xs font-light whitespace-normal max-h-[2.5em]'
              )}
            >
              {category.description?.length > 40 ? (
                <span>{category.description.slice(0, 40)}...</span>
              ) : (
                <span>{category.description}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="flex  items-center">
        <PencilSquareIcon
          onClick={() => onClickEditCategory(category)}
          className={classNames(
            category.id === selected.category.id
              ? 'text-gray-800'
              : 'text-slate-500',
            'hover:text-gray-900 w-5 h-5 cursor-pointer dark:text-sky-400'
          )}
        />
      </div>
    </div>
  );
}

export default CategoryCard;
