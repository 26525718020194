import { useEffect } from 'react';
import './App.css';
import Router from './router';
import tokenService from './utils/tokenService';

const App = () => {
  useEffect(() => {
    const theme = tokenService.getTheme();
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      document.body.classList.add('bg-slate-800');
    } else {
      document.documentElement.classList.remove('dark');
      document.body.classList.remove('bg-slate-800');
    }
  }, []);

  return <Router />;
};

export default App;
