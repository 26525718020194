import api from './api';

const resource = 'Discount/';

export const list = async (query = '') => {
  return await api.get(resource + 'List' + query);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

const discountApi = { list, getById, upsert, deleteById };

export default discountApi;
