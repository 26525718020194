import React, { useState, Fragment, useRef, useEffect } from 'react';
import moment from 'moment';
import storeApi from '../../../../services/storeApi';
import bannerApi from '../../../../services/bannerApi';
import { SketchPicker } from 'react-color';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { Upload, Button, DatePicker, Spin } from 'antd';
import { Dialog, Transition } from '@headlessui/react';
import {
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/outline';

dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const { RangePicker } = DatePicker;

dayjs.extend(utc);
dayjs.extend(timezone);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BrandingTab = ({ storeDetail, checkUpdate }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [loading, setLoading] = useState({
    initialLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    bannerLoading: false,
  });
  const [form, setForm] = useState({
    terminallogo: storeDetail.terminallogo,
    sitecolor: storeDetail.sitecolor || '#ff5f5f',
  });
  const [bannerForm, setBannerForm] = useState({
    storeid: storeDetail.id,
    displayorder: 0,
    image: '',
  });
  const [bannerList, setBannerList] = useState([]);
  const [dialogs, setDialogs] = useState({
    bannerUpload: false,
  });
  const cancelButtonRef = useRef(null);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const onClickSubmit = async () => {
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = {
        ...storeDetail,
        ...form,
      };
      const formData = new FormData();

      for (const key of Object.keys(body)) {
        if (
          key === 'terminallogo' &&
          body['terminallogo'] !== '' &&
          body['terminallogo'] !== storeDetail.terminallogo
        ) {
          if (body.terminallogoid) {
            await storeApi.deleteTerminalLogoById(body.id);
          }
          formData.append('terminallogo', body.terminallogo);
        } else {
          if (body[key] && body[key] !== 'null') {
            formData.append(key, body[key]);
          }
        }
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false);
    } finally {
      setDisplayColorPicker(false);
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
    }
  };

  const handleChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const handleBannerFormChange = (val, key) => {
    setBannerForm((prev) => ({ ...prev, [key]: val }));
  };

  const onCloseUpsert = () => {
    setDialogs((prev) => ({ ...prev, bannerUpload: false }));
  };

  const onClickUploadBanner = async () => {
    try {
      setLoading((prev) => ({ ...prev, bannerLoading: true }));

      const formData = new FormData();

      for (const key of Object.keys(bannerForm)) {
        formData.append(key, bannerForm[key]);
      }

      const res = await bannerApi.upsert(formData);
      if (res.status === 200) {
        checkUpdate(true);
      }

      getBannerList();
      onCloseUpsert();
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, bannerLoading: false }));
    }
  };

  const onClickOpenBannerUpload = () => {
    setDialogs((prev) => ({ ...prev, bannerUpload: true }));
  };

  const getBannerList = async () => {
    try {
      setLoading((prev) => ({ ...prev, bannerLoading: true }));
      const query = `?storeid=${storeDetail.id}&isdeleted=false`;
      const res = await bannerApi.list(query);

      setBannerList(res.data.data);
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, bannerLoading: false }));
    }
  };

  const onChangeBannerForm = (dateString) => {
    setBannerForm((prev) => ({
      ...prev,
      startdate: dateString[0],
      enddate: dateString[1],
    }));
  };

  const onClickDeleteBannerImage = async (bannerId) => {
    try {
      setLoading((prev) => ({ ...prev, bannerLoading: true }));
      const res = await bannerApi.deleteById(bannerId);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
    } finally {
      getBannerList();
      setLoading((prev) => ({ ...prev, bannerLoading: false }));
    }
  };

  const uploadButton = (
    <div>
      {loading.upsertLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-200"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    getBannerList();
  }, []);

  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-6 sm:gap-x-8 p-4">
      <div className="grid grid-cols-5 gap-x-4 gap-y-10">
        <div className="col-span-4 sm:col-span-3">
          <h1 className="text-lg font-semibold">Kiosk Color</h1>
          <p className="text-sm text-gray-500">
            Easily customize your kiosk color
          </p>
        </div>
        <div className="grid items-center justify-center col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <div>
            <div
              className="p-1 rounded-sm bg-white inline-block cursor-pointer"
              style={{ boxShadow: '0 0 0 1px rgba(0,0,0,.1)' }}
              onClick={handleClick}
            >
              <div
                style={{
                  background: `${form.sitecolor}`,
                }}
                className="w-8 h-4 rounded-sm"
              />
            </div>
            {displayColorPicker ? (
              <div className="z-10 absolute">
                <div
                  className="inset-0 fixed h-[60vh]"
                  onClick={onClickSubmit}
                />
                <SketchPicker
                  color={form.sitecolor}
                  onChange={(e) => handleChange(e.hex, 'sitecolor')}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-span-4 sm:col-span-3 self-center">
          <h1 className="text-lg font-semibold">Kiosk Image</h1>
          <p className="text-sm text-gray-500">
            Easily customize your kiosk image background
          </p>
        </div>
        <div className="grid items-center justify-center col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <div>
            <Upload
              multiple={false}
              maxCount={1}
              name="avatar"
              onChange={(e) => handleChange(e.file, 'terminallogo')}
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={() => false}
            >
              {storeDetail.terminallogo ? (
                <img
                  src={storeDetail.terminallogo}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
        </div>
        <div className="col-span-4 sm:col-span-3 self-center">
          <h1 className="text-lg font-semibold">Banner Images</h1>
          <p className="text-sm text-gray-500">
            Easily customize your banner images background
          </p>
        </div>
        <div className="grid items-center justify-center col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4">
          <div>
            <Button
              onClick={() => onClickOpenBannerUpload()}
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          </div>
        </div>
        <div className="col-span-4 sm:col-span-4">
          <div className="col-span-4 sm:col-span-4 self-center">
            {loading.listLoading ? (
              <div className="w-full flex justify-center py-4">
                <Spin />
              </div>
            ) : (
              <div className="mt-2 flow-root">
                {bannerList.length > 0 && (
                  <div className="-mx-6 overflow-x-auto lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                            >
                              Image
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Date
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        {loading.listLoading ? (
                          <div className="w-full flex justify-center py-4">
                            <Spin />
                          </div>
                        ) : (
                          <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                            {bannerList.map((banner) => (
                              <tr key={banner.id}>
                                <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                                  <div className="flex-shrink-0">
                                    <img
                                      className="h-14 w-18 rounded-sm object-cover"
                                      src={banner.imagepath}
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                  {moment
                                    .utc(banner.startdate)
                                    .local()
                                    .format('MM/DD/YYYY')}{' '}
                                  -{' '}
                                  {moment
                                    .utc(banner.enddate)
                                    .local()
                                    .format('MM/DD/YYYY')}
                                </td>

                                <td className="relative flex justify-center whitespace-nowrap py-4 text-center text-sm font-medium sm:pr-6 md:pr-0">
                                  <div className="flex w-1/2 text-center justify-center  pr-1 items-center">
                                    <TrashIcon
                                      onClick={() =>
                                        onClickDeleteBannerImage(banner.id)
                                      }
                                      className="text-black cursor-pointer w-5 h-5 dark:text-slate-200"
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 mt-4">
        <Button
          type="ghost"
          disabled={loading.upsertLoading}
          loading={loading.upsertLoading}
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          onClick={() => onClickSubmit()}
        >
          Update
        </Button>
      </div>
      <Transition.Root show={dialogs.bannerUpload} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="col-span-12 w-full">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Image
                    </label>
                    <Upload
                      multiple={false}
                      maxCount={1}
                      name="avatar"
                      onChange={(e) => handleBannerFormChange(e.file, 'image')}
                      listType="picture-card"
                      className="avatar-uploader mt-3"
                      beforeUpload={() => false}
                    >
                      {uploadButton}
                    </Upload>
                  </div>

                  <div className="col-span-12 w-full mt-6 ">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Date Range
                    </label>
                    <div className="my-3  w-full">
                      <RangePicker
                        style={{ width: '100%' }}
                        size="large"
                        format="MM/DD/YYYY"
                        onChange={(e) => onChangeBannerForm(e)}
                      />
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-5 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.csvLoading}
                      className={classNames(
                        'sm:col-span-6 col-span-12',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading.bannerLoading}
                      type="ghost"
                      className={classNames(
                        'sm:col-span-6 col-span-12',
                        'mr-3 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onClickUploadBanner()}
                    >
                      <ArrowDownTrayIcon
                        className="h-5 w-5 mr-1"
                        aria-hidden="true"
                      />
                      Upload Banner
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default BrandingTab;
