import React, { Fragment, useRef, useState } from 'react';
import storeApi from '../../../../services/storeApi';
import { Button, Upload } from 'antd';
import InputMask from 'react-input-mask';
import AddressAutocomplete from '../../../../components/address-autocomplete/address-autocomplete';
import { Dialog, Transition } from '@headlessui/react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

const StoreTab = ({ storeDetail, checkUpdate }) => {
  const [form, setForm] = useState({ ...storeDetail });

  const [errors, setErrors] = useState({
    name: false,
    responsiblename: false,
    responsiblephone: false,
    address: false,
    phone: false,
    slug: false,
  });

  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
  });

  const cancelButtonRef = useRef(null);

  const onFormChange = (val, key) => {
    if (key === 'name') {
      const numAndLetters = /^[A-Za-z0-9 _]*$/;
      setForm((prev) => ({
        ...prev,
        [key]: numAndLetters.test(val) ? val : prev[key],
      }));
    } else {
      setForm((prev) => ({ ...prev, [key]: val }));
    }
  };

  const onClickEdit = () => {
    setForm({ ...storeDetail });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    if (
      !form.name ||
      !form.responsiblename ||
      !form.responsiblephone ||
      !form.address ||
      !form.phone
    ) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
        responsiblename: !form.responsiblename ? true : false,
        responsiblephone: !form.responsiblephone ? true : false,
        address: !form.address ? true : false,
        phone: !form.phone ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        responsiblephone: false,
        name: false,
        responsiblename: false,
        address: false,
        phone: false,
      }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      const formData = new FormData();

      for (const key of Object.keys(body)) {
        formData.append(key, body[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(body, config);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false, error.response.data);
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };

  const onAddressSelect = (obj) => {
    if (typeof obj === 'object') {
      setForm((prev) => ({
        ...prev,
        address: obj.street,
        city: obj.city,
        state: obj.state,
        postalcode: obj.zipCode,
        longitude: obj.longitude,
        latitude: obj.latitude,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        address: obj,
      }));
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      address: false,
      name: false,
      responsiblename: false,
      responsiblephone: false,
      phone: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const uploadButton = (
    <div>
      {loading.upsertLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-200"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-6 sm:gap-x-8">
      <div>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-slate-300">
            Store Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
        </div>
        <div className="mt-5 border-t border-gray-200 dark:border-slate-700">
          <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 ">
              <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                Store name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-300">
                {storeDetail.name}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                Store phone number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-300">
                {storeDetail.phone}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                Store address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-300">
                <span>{storeDetail.address}</span>
                {storeDetail.apartment && storeDetail.apartment !== 'null' && (
                  <span>, {storeDetail.apartment}</span>
                )}
                <br />
                {storeDetail.city && (
                  <span>{storeDetail.city + (storeDetail.state && ', ')}</span>
                )}
                {storeDetail.state && (
                  <span>
                    {storeDetail.state + (storeDetail.postalcode && ' ')}
                  </span>
                )}
                {storeDetail.postalcode && (
                  <span>{storeDetail.postalcode}</span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                Manager's full name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-300">
                {storeDetail.responsiblename}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                Manager's phone number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-300">
                {storeDetail.responsiblephone}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 mt-4 mb-4">
        <Button
          type="ghost"
          onClick={() => onClickEdit()}
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
        >
          Update
        </Button>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="grid grid-cols-8 gap-6">
                    <div className="col-span-8 sm:col-span-8">
                      <label
                        htmlFor="logo"
                        className="block pb text-sm font-medium text-gray-700 dark:text-slate-200"
                      >
                        Store Logo
                      </label>
                      <div className="mt-5">
                        <>
                          <Upload
                            multiple={false}
                            maxCount={1}
                            name="avatar"
                            onChange={(e) => onFormChange(e.file, 'storelogo')}
                            listType="picture-circle"
                            className="avatar-uploader"
                            beforeUpload={() => false}
                          >
                            {storeDetail.storelogo ? (
                              <img
                                src={storeDetail.storelogo}
                                alt="avatar"
                                className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                        <div className="h-2 mt-1  text-xs">
                          {errors.imageid && (
                            <p className="text-red-700">
                              Image can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-8">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Store Name *
                      </label>
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'ABC Pizza'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Store name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Store Phone Number *
                      </label>
                      <InputMask
                        value={form.phone}
                        mask="(999) 999 9999"
                        maskPlaceholder={' '}
                        onChange={(e) => onFormChange(e.target.value, 'phone')}
                        type="text"
                        name="phone"
                        id="phone"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.phone && (
                          <p className="text-red-700">
                            Store phone can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-8">
                      <h1 className="text-base font-extrabold text-gray-900 dark:text-zinc-200">
                        Store Address
                      </h1>
                      <p className=" text-sm text-gray-500 dark:text-zinc-300">
                        Your address will be displayed publicly!
                      </p>
                    </div>
                    <div className="col-span-8 sm:col-span-6">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Street Name
                      </label>
                      <AddressAutocomplete
                        value={form.address}
                        onChange={(e) => onAddressSelect(e)}
                      />

                      <div className="h-2 mt-1  text-xs">
                        {errors.address ? (
                          <p className="text-red-700">
                            Address can not be empty
                          </p>
                        ) : (
                          <p className="text-gray-500">
                            Please type your street name here e.g. '100 Main St'
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-2">
                      <label
                        htmlFor="apartment"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apt / Suite
                      </label>
                      <input
                        value={form.apartment}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'apartment')
                        }
                        type="text"
                        name="apartment"
                        id="apartment"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder=""
                      />
                      <div className="h-2 mt-1  text-xs"></div>
                    </div>

                    <div className="col-span-8 grid grid-cols-8 gap-6">
                      <div className="col-span-8 sm:col-span-4">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <input
                          value={form.city}
                          onChange={(e) => onFormChange(e.target.value, 'city')}
                          type="text"
                          name="city"
                          id="city"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder=""
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                      <div className="col-span-8 sm:col-span-2">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium text-gray-700"
                        >
                          State
                        </label>
                        <input
                          value={form.state}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'state')
                          }
                          type="text"
                          name="state"
                          id="state"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder=""
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                      <div className="col-span-8 sm:col-span-2">
                        <label
                          htmlFor="postalcode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Zip Code
                        </label>
                        <input
                          value={form.postalcode}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'postalcode')
                          }
                          type="text"
                          name="postalcode"
                          id="postalcode"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder=""
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-8">
                      <h1 className="text-base font-extrabold text-gray-900 dark:text-zinc-200">
                        Store Manager
                      </h1>
                      <p className=" text-sm text-gray-500 dark:text-zinc-300">
                        This information <strong>will not</strong> be displayed
                        publicly.
                      </p>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="responsiblename"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Manager's Full Name
                      </label>
                      <input
                        value={form.responsiblename}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'responsiblename')
                        }
                        type="text"
                        name="responsiblename"
                        id="responsiblename"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'John Doe'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.responsiblename && (
                          <p className="text-red-700">
                            Manager's full name can not be empty
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="responsiblephone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Manager's Phone Number
                      </label>
                      <InputMask
                        value={form.responsiblephone}
                        mask="(999) 999 9999"
                        maskPlaceholder={' '}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'responsiblephone')
                        }
                        type="text"
                        name="responsiblephone"
                        id="responsiblephone"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.responsiblephone && (
                          <p className="text-red-700">
                            Manager's phone number can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => onFormSubmit()}
                    >
                      {'Update'}
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default StoreTab;
