import { Dialog, Popover, Transition } from '@headlessui/react';
import { Button, Upload } from 'antd';
import React, { Fragment, useState, useRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import companyApi from '../../services/companyApi';
import { loginFailure } from '../../store/slices/authSlice';
import { setCompany, setGlobalCompany } from '../../store/slices/companySlice';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import tokenService from '../../utils/tokenService';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CompanyChanger = ({ position, onChange }) => {
  const dispatch = useDispatch();
  const { list, selected } = useSelector((state) => state.companyReducer);
  const { roles, user } = useSelector((state) => state.authReducer);
  const isAdmin = roles.includes(100);
  const isStoreOwner = roles.includes(150);

  const [form, setForm] = useState({
    id: '',
    logo: '',
    emaildomain: '',
    name: '',
    users: [],
    isactive: true,
    ownername: '',
    ownerphone: '',
    owneremail: '',
    subscriptionid: '',
    creditcardfeeper: null,
    creditcardfeerate: null,
  });

  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    loginAsCompany: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    ownername: false,
    ownerphone: false,
  });

  const [dialogs, setDialogs] = useState({
    storeList: false,
    userList: false,
    upsert: false,
  });

  const cancelButtonRef = useRef(null);
  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const getList = async () => {
    try {
      const list = await companyApi.list();
      if (list.data.data.length > 0 && !roles.includes(100)) {
        dispatch(setCompany(list.data.data));
      } else {
        dispatch(setCompany([]));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onClickAddCompany = () => {
    setForm({
      id: '',
      logo: '',
      emaildomain: 'deneme',
      name: '',
      ownername: selected.ownername,
      ownerphone: selected.ownerphone,
      owneremail: selected.owneremail,
      isactive: true,
      users: [user.id],
    });

    setDialogs((prev) => ({ ...prev, upsert: true }));
  };
  const onFormSubmit = async () => {
    if (!form.name || !form.ownername || !form.ownerphone) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
        ownername: !form.ownername ? true : false,
        ownerphone: !form.ownerphone ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      const formData = new FormData();

      Object.keys(body).forEach((key) => {
        if (body[key]) {
          formData.append(key, body[key]);
        }
      });

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const res = await companyApi.upsert(formData, config);
      if (res.data.data) {
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getList();
    }
  };
  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      ownername: false,
      ownerphone: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onSignout = () => {
    tokenService.removeToken();
    tokenService.removeComp();
    tokenService.removeRole();
    tokenService.removeRolePermissions();
    dispatch(loginFailure());
  };

  const uploadButton = (
    <div>
      {loading.upsertLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-200"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [isDarkMode, setIsDarkMode] = useState(
    tokenService.getTheme() === 'dark' ? true : false
  );

  const handleCompanyChange = (company) => {
    dispatch(setGlobalCompany(company));
    // navigate(0);
  };

  return (
    <>
      <Popover className="relative">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white hover:bg-gray-100 dark:hover:bg-slate-600 rounded-md p-2">
          {!isAdmin &&
            (selected.logo ? (
              <img
                className="h-8 w-8 rounded-full"
                src={selected.logo}
                alt=""
              />
            ) : (
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                <span className="text-xs font-medium leading-none text-white">
                  {selected.name?.split(' ').map((x) => x[0])}
                </span>
              </span>
            ))}
          <span
            className={`ml-2 ${
              isAdmin ? 'block' : 'hidden'
            } text-sm font-bold text-gray-700 lg:block dark:text-sky-400`}
          >
            {isAdmin ? 'Admin' : selected.name}
          </span>
          <ChevronDownIcon
            className="h-5 w-5 text-gray-700 dark:text-sky-400"
            aria-hidden="true"
          />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute right-0 rounded-md bg-white dark:bg-slate-700 z-10 mt-4 w-80 origin-top-right shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="flex-auto overflow-hidden rounded-md  text-sm leading-6">
              {!isAdmin && (
                <div className="p-4">
                  {list.map((item) => (
                    <div
                      key={item.name}
                      className={`group relative flex items-center gap-x-3 mb-1 rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-slate-600 cursor-pointer ${
                        selected.id === item.id
                          ? 'bg-gray-200 dark:bg-slate-600'
                          : ''
                      }`}
                      onClick={() => {
                        handleCompanyChange(item);
                      }}
                    >
                      {item.logo ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={item.logo}
                          alt=""
                        />
                      ) : (
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                          <span className="text-xs font-medium leading-none text-white ">
                            {item.name?.split(' ').map((x) => x[0])}
                          </span>
                        </span>
                      )}
                      <div>
                        <span className="font-semibold text-gray-900 dark:text-slate-200 ">
                          {item.name}
                          <span className="absolute inset-0" />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div
                className={`grid ${
                  !isAdmin && isStoreOwner ? 'grid-cols-2' : 'grid-cols-1'
                } divide-x divide-gray-900/5 dark:divide-slate-600 bg-gray-50 dark:bg-slate-600 `}
              >
                {!isAdmin && isStoreOwner && (
                  <span
                    className="cursor-pointer flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100 dark:text-slate-200 dark:hover:bg-slate-500"
                    onClick={() => onClickAddCompany()}
                  >
                    + Add Company
                  </span>
                )}
                <span
                  className=" cursor-pointer flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100 dark:text-slate-200 dark:hover:bg-slate-500"
                  onClick={() => onSignout()}
                >
                  Sign Out
                </span>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      {/* <Menu as="div" className="relative ml-3">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 min-w-max origin-top-right rounded-md bg-white dark:bg-slate-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {!roles.includes(100) && (
              <div className="relative">
                {list.map((item, itemIdx) => (
                  <Menu.Item key={itemIdx}>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          dispatch(setGlobalCompany(item));
                        }}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "relative pl-4 py-2 text-sm text-gray-700 flex items-center space-x-2 pr-9 cursor-pointer dark:hover:bg-slate-700"
                        )}
                      >
                        {item.logo ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={item.logo}
                            alt=""
                          />
                        ) : (
                          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                            <span className="text-xs font-medium leading-none text-white ">
                              {item.name?.split(" ").map((x) => x[0])}
                            </span>
                          </span>
                        )}
                        <span className="pr-2 dark:text-slate-400">
                          {item.name}
                        </span>
                        {selected.id === item.id && (
                          <span
                            className={classNames(
                              active ? "text-blue-600" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </a>
                    )}
                  </Menu.Item>
                ))}

                {!roles.some((role) => [200, 250].includes(role)) && (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => onClickAddCompany()}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          " pl-4 pr-9 py-2 text-sm text-gray-700 flex items-center space-x-2 cursor-pointer dark:text-slate-400 dark:hover:bg-slate-700",
                          "after:absolute after:w-[90%] after:bg-gray-200 after:bottom-0 after:left-[5%] after:h-[2px]"
                        )}
                      >
                        <div
                          className={classNames(
                            active ? "bg-gray-100" : "bg-white",
                            "h-8 w-8 rounded-full "
                          )}
                        ></div>

                        <span className="">+ Add Company</span>
                      </a>
                    )}
                  </Menu.Item>
                )}
              </div>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => onSignout()}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer dark:text-slate-400 dark:hover:bg-slate-700"
                  )}
                >
                  Sign Out
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu> */}
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="logo"
                      className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Company Logo
                    </label>
                    <div className="mt-1">
                      <>
                        <Upload
                          multiple={false}
                          maxCount={1}
                          name="avatar"
                          onChange={(e) => onFormChange(e.file, 'logo')}
                          listType="picture-circle"
                          className="avatar-uploader"
                          beforeUpload={() => false}
                        >
                          {form.imageid ? (
                            <img
                              src={form.logo}
                              alt="avatar"
                              className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full"
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </>
                      <div className="h-2 mt-1  text-xs"></div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Company Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'Abc Pizza'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Company name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label
                        htmlFor="ownername"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Owner Name
                      </label>
                      <div className="mt-1">
                        <input
                          value={form.ownername}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'ownername')
                          }
                          type="text"
                          name="ownername"
                          id="ownername"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="e.g. 'John Doe'"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.ownername && (
                            <p className="text-red-700">
                              Owner name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label
                        htmlFor="ownerphone"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Owner Phone
                      </label>
                      <div className="mt-1">
                        <ReactInputMask
                          mask="(999) 999 9999"
                          maskPlaceholder={' '}
                          value={form.ownerphone}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'ownerphone')
                          }
                          type="text"
                          name="ownerphone"
                          id="ownerphone"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.ownerphone && (
                            <p className="text-red-700">
                              Owner phone can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="owneremail"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Owner E-mail
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.owneremail}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'owneremail')
                        }
                        type="text"
                        name="owneremail"
                        id="owneremail"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'owner@abc.com'"
                      />
                      <div className="h-2 mt-1  text-xs"></div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 pt-2 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-12 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        'col-span-6 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        'col-span-6 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      Add Company
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CompanyChanger;
