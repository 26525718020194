import React, { useState, useEffect, useRef, Fragment } from 'react';
import companyApi from '../../services/companyApi';
import Notification from '../../components/notification/notification';
import { Button, Pagination, Select, Spin, Upload } from 'antd';
import ModalUserList from '../../components/modal-user-list/modal-user-list';
import ModalList from '../../components/modal-list/modal-list';
import ModalDelete from '../../components/modal-delete/modal-delete';
import { Dialog, Transition, Switch } from '@headlessui/react';
import userApi from '../../services/userApi';
import storeApi from '../../services/storeApi';
import subscriptionApi from '../../services/subscriptionApi';
import { useSelector } from 'react-redux';
import {
  ArrowRightOnRectangleIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import tokenService from '../../utils/tokenService';
import { useDispatch } from 'react-redux';
import {
  loginSuccess,
  setRole,
  setRolePermissions,
} from '../../store/slices/authSlice';
import { Link } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { setGlobalCompany, setCompany } from '../../store/slices/companySlice';
import { useTransition, animated, useSpringRef } from '@react-spring/web';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const headers = [
  'Logo',
  'Company Name',
  'Owner Name',
  'Owner Phone',
  'Stores',
  'Users',
];
const userHeaders = ['Name', 'Email', 'Role', 'Status'];
const userKeys = ['fullname', 'email', 'userroles', 'status'];
const storeHeaders = ['Name', 'Address', 'Manager Name', 'Manager Phone'];
const storeKeys = ['name', 'address', 'responsiblename', 'responsiblephone'];

const Company = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roles, user } = useSelector((state) => state.authReducer);
  const { list, selected } = useSelector((state) => state.companyReducer);
  const [showFilters, setShowFilters] = useState(false);
  const userRoles = useSelector((state) => state.authReducer.roles);
  const isAdmin = userRoles.includes(100);

  const [search, setSearch] = useState({
    name: '',
    isdeleted: 'false',
  });

  const [form, setForm] = useState({
    id: '',
    logo: '',
    emaildomain: '',
    name: '',
    users: [],
    subdomain: '',
    isactive: true,
    ownername: '',
    ownerphone: '',
    owneremail: '',
    subscriptionid: '',
    creditcardfeeper: 0,
    creditcardfeerate: 0,
  });

  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    loginAsCompany: false,
  });

  const [errors, setErrors] = useState({
    emaildomain: false,
    name: false,
    subdomain: false,
  });

  const [companyList, setCompanyList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [dialogs, setDialogs] = useState({
    storeList: false,
    userList: false,
    upsert: false,
    delete: false,
  });
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedStoreList, setSelectedStoreList] = useState(null);
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });

  const cancelButtonRef = useRef(null);
  useEffect(() => {
    const query = searchQuery();
    getList(query);
    getUserList();
    if (roles.includes(100)) {
      getSubscriptionList();
    }
  }, [pagination.pageNumber]);

  const getList = async (searchQuery) => {
    try {
      setLoading((prev) => ({ ...prev, listLoading: true }));
      const query = `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}&${searchQuery}`;
      const list = await companyApi.list(query);
      if (list.data.data.length > 0) {
        setCompanyList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
        dispatch(setCompany(list.data.data));
      } else {
        setCompanyList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const getUserList = async () => {
    try {
      const list = await userApi.list(`?pageSize=1000&status=10&status=0`);
      if (list.data.data.length > 0) {
        setUserList(list.data.data.filter((x) => x.status !== 20));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getSubscriptionList = async () => {
    try {
      const list = await subscriptionApi.list(`?pageSize=1000`);
      if (list.data.data.length > 0) {
        setSubscriptionList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onSearchChange = (val, key) => {
    setSearch((prev) => ({ ...prev, [key]: val }));
  };

  const onClickAddCompany = () => {
    setForm({
      id: '',
      logo: '',
      emaildomain: 'deneme',
      subdomain: '',
      name: '',
      ownername:
        !roles.includes(100) && selected.ownername ? selected.ownername : '',
      ownerphone:
        !roles.includes(100) && selected.ownerphone ? selected.ownerphone : '',
      owneremail:
        !roles.includes(100) && selected.owneremail ? selected.owneremail : '',
      isactive: true,
      users: roles.includes(100) ? [] : [user.id],
      creditcardfeeper: 0,
      creditcardfeerate: 0,
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onClickEdit = (val) => {
    setForm({
      ...val,
      imagepath: val.logo,
    });

    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    if (!form.name) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };

      const formData = new FormData();

      for (const key of Object.keys(body)) {
        if (key === 'image' && body['image'] !== '') {
          if (body.imageid) {
            await companyApi.deleteLogoById(body.id);
          }
          formData.append('image', body.image);
        } else if (key === 'users') {
          body.users.forEach((element) => {
            formData.append('users', element);
          });
        } else {
          if (body[key] && body[key] !== 'null') {
            formData.append(key, body[key]);
          }
        }
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const res = await companyApi.upsert(formData, config);
      if (res.data.data) {
        if (selected.id === res.data.data.id) {
          dispatch(setGlobalCompany(res.data.data));
        }
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getList();
    }
  };
  const onCloseUpsert = () => {
    setErrors((prev) => ({ ...prev, name: false }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onClickDelete = () => {
    setDialogs((prev) => ({ ...prev, upsert: false, delete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await companyApi.deleteById(form.id);
      await getList();
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false, upsert: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const rolePermissions = (permissions) => {
    let rolepermissions = [];
    permissions.forEach((permission) => {
      rolepermissions.push(permission.permission.code);
    });
    return rolepermissions;
  };

  const signAsCompany = async (comp) => {
    try {
      setLoading((prev) => ({ ...prev, loginAsCompany: true }));
      const res = await companyApi.signAsCompanyById(comp.id);
      if (res.data.data) {
        if (res.data.data.roles.includes('Owner')) {
          tokenService.updateLocalAccessToken(res.data.data.token);
          const userDetail = await userApi.getUserDetail();

          if (userDetail.status === 200) {
            tokenService.setRole([150]);
            dispatch(loginSuccess(userDetail.data.data));
            dispatch(setGlobalCompany(comp));
            dispatch(setRole([150]));
            dispatch(
              setRolePermissions(
                rolePermissions(res.data.data.permissions[0].rolepermissions)
              )
            );
            navigate('/dashboard');
          }
        }
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, loginAsCompany: false }));
    }
  };

  const onClickShowStoreModal = async (companyId) => {
    try {
      const res = await storeApi.getByCompanyId(companyId);
      if (res.data.data) {
        setSelectedStoreList(res.data.data);
        setDialogs((prev) => ({
          ...prev,
          storeList: true,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const uploadButton = (
    <div>
      {loading.upsertLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-200"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const users = () => {
    if (
      !companyList[selectedCompany] ||
      !companyList[selectedCompany].companyusers
    ) {
      return [];
    }

    const { companyusers } = companyList[selectedCompany];

    const activeCompanyUsers = companyusers.filter(
      ({ user }) => user.status == 10
    );

    if (roles.includes(100)) {
      return activeCompanyUsers;
    }

    if (roles.includes(150)) {
      return activeCompanyUsers.filter(({ user }) => {
        if (!user || !user.userroles[0] || !user.userroles[0].role)
          return false;
        const roleName = user.userroles[0].role.name;

        return !['Admin', 'Limited Admin', 'Menu'].includes(roleName);
      });
    }

    return activeCompanyUsers.filter(({ user }) => {
      if (!user || !user.userroles[0] || !user.userroles[0].role) return false;
      const roleName = user.userroles[0].role.name;
      return !['Admin', 'Limited Admin', 'Menu', 'Owner'].includes(roleName);
    });
  };

  useEffect(() => {
    transRef.start();
  }, [showFilters]);

  const transRef = useSpringRef();
  const transitions = useTransition(showFilters, {
    ref: transRef,
    keys: null,
    from: { maxHeight: '0px', opacity: 0 },
    enter: { maxHeight: '1000px', opacity: 1, overflowY: 'none' },
    leave: { maxHeight: '0px', opacity: 0 },
  });

  const searchQuery = () => {
    const query = Object.keys(search)
      .map((key) => {
        return `${key}=${search[key]}`;
      })
      .filter(Boolean)
      .join('&');

    return query;
  };

  const onClickSearch = async () => {
    const query = searchQuery();
    await getList(query);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  };

  const onClickClearSearch = () => {
    setSearch({
      name: '',
      isdeleted: 'false',
    });
    getList();
  };

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Company
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                Create new and manage all your companies here.
              </p>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col-reverse mt-5 sm:mt-0 gap-2 justify-end">
            <Button
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              type="ghost"
              className={classNames(
                'inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
              )}
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Filters
            </Button>
            <Button
              onClick={() => onClickAddCompany()}
              disabled={loading.listLoading}
              type="ghost"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200 dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
            >
              Add company
            </Button>
          </div>
          {transitions(
            (style, item) =>
              item && (
                <animated.div style={{ ...style }}>
                  <div className="sm:mt-0 mt-2">
                    <div className=" justify-between pb-2 grid grid-cols-6 gap-4">
                      <div className="col-span-3 sm:col-span-2 w-full ">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            value={search.name}
                            onChange={(e) =>
                              onSearchChange(e.target.value, 'name')
                            }
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      {roles.includes(100) && (
                        <div className="col-span-3 sm:col-span-2  w-full ">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Show Deleted
                          </label>

                          <div className="mt-1">
                            <Select
                              size="large"
                              style={{
                                width: '100%',
                              }}
                              placeholder="Show Deleted"
                              value={search.isdeleted}
                              onChange={(e) => {
                                setSearch((prev) => ({
                                  ...prev,
                                  isdeleted: e,
                                }));
                              }}
                            >
                              <Select.Option value={'false'}>No</Select.Option>
                              <Select.Option value={'true'}>Yes</Select.Option>
                            </Select>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="justify-end pb-2 grid grid-cols-3 gap-4 ">
                      <div className="flex sm:col-start-3 items-center col-start-2 gap-x-5 ">
                        <Button
                          onClick={() => {
                            onClickClearSearch();
                          }}
                          type="ghost"
                          className={classNames(
                            'col-span-6',
                            'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                          )}
                        >
                          <TrashIcon className="text-gray-700 w-5 h-5 mr-2" />
                          Clear
                        </Button>
                        <Button
                          type="ghost"
                          onClick={() => {
                            onClickSearch();
                          }}
                          className={classNames(
                            'col-span-6',
                            'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                          )}
                        >
                          <MagnifyingGlassIcon className="text-white w-5 h-5 mr-2" />
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )
          )}
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        {headers.map((head, headIdx) => (
                          <th
                            key={headIdx}
                            scope="col"
                            className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-200 ${
                              headIdx === 0 ? 'md:pl-0 sm:pl-6' : ''
                            } `}
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500 dark:divide-slate-500">
                      {companyList.map((comp, compIdx) => (
                        <tr key={compIdx}>
                          <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-200 sm:pl-6 md:pl-0">
                            {comp.logo ? (
                              <img
                                alt="logo"
                                className="w-8 h-8 rounded-full"
                                src={comp.logo}
                              />
                            ) : (
                              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                <span className="text-xs font-medium leading-none text-white">
                                  {comp.name.split(' ').map((x) => x[0])}
                                </span>
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {roles.includes(100) ? (
                              <Link
                                to={comp.id}
                                className="hover:text-blue-600"
                              >
                                {comp.name} {comp.isdeleted && ' (Deleted)'}
                              </Link>
                            ) : (
                              comp.name
                            )}
                          </td>
                          {/* <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                          {comp.emaildomain || '-'}
                        </td> */}
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {comp.ownername || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {comp.ownerphone || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            <Button
                              type="outline"
                              className="text-blue-600 px-0 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-600"
                              onClick={() => onClickShowStoreModal(comp.id)}
                            >
                              Show
                            </Button>
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {comp.companyusers.length > 0 ? (
                              <Button
                                type="outline"
                                className="text-blue-600 px-0 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-600"
                                onClick={() => {
                                  setSelectedCompany(compIdx);
                                  setDialogs((prev) => ({
                                    ...prev,
                                    userList: true,
                                  }));
                                }}
                              >
                                Show
                                <span className="sr-only">, {comp.name}</span>
                              </Button>
                            ) : (
                              '-'
                            )}
                          </td>
                          {roles.includes(100) ? (
                            <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                              <ArrowRightOnRectangleIcon
                                onClick={() => signAsCompany(comp)}
                                className="text-blue-600 w-6 h-6 mr-2 cursor-pointer dark:text-blue-400 dark:hover:text-blue-600"
                              />
                            </td>
                          ) : (
                            <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                              <PencilSquareIcon
                                onClick={() => onClickEdit(comp)}
                                className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer dark:text-blue-400 dark:hover:text-blue-600"
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full text-end mt-4">
                    <Pagination
                      current={pagination.pageNumber}
                      total={pagination.totalRecords}
                      defaultPageSize={pagination.pageSize}
                      onChange={(page) =>
                        setPagination((prev) => ({ ...prev, pageNumber: page }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <Notification
            display={notf.display}
            type={notf.type}
            message={notf.message}
            onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
          />
          <ModalUserList
            title="User List"
            headers={userHeaders}
            keys={userKeys}
            open={dialogs.userList}
            list={users()}
            onCloseRequest={() =>
              setDialogs((prev) => ({ ...prev, userList: false }))
            }
          />
          <ModalList
            title="Store List"
            headers={storeHeaders}
            keys={storeKeys}
            size="lg"
            open={dialogs.storeList}
            list={selectedStoreList || []}
            onCloseRequest={() =>
              setDialogs((prev) => ({ ...prev, storeList: false }))
            }
          />
        </div>
        <Transition.Root show={dialogs.upsert} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div>
                      <label
                        htmlFor="logo"
                        className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-200"
                      >
                        Company Logo
                      </label>
                      <div className="mt-5">
                        <>
                          <Upload
                            multiple={false}
                            maxCount={1}
                            name="avatar"
                            onChange={(e) => onFormChange(e.file, 'image')}
                            listType="picture-circle"
                            className="avatar-uploader"
                            beforeUpload={() => false}
                          >
                            {form.logo ? (
                              <img
                                src={form.logo}
                                alt="avatar"
                                className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                        <div className="h-2 mt-1  text-xs">
                          {errors.imageid && (
                            <p className="text-red-700">
                              Image can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Company Name
                      </label>
                      <div className="mt-1">
                        <input
                          value={form.name}
                          onChange={(e) => onFormChange(e.target.value, 'name')}
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500 dark:placeholder:text-white"
                          placeholder="e.g. 'Abc Pizza'"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.name && (
                            <p className="text-red-700">
                              Name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="w-1/2">
                        <label
                          htmlFor="ownername"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Owner Name
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.ownername}
                            onChange={(e) =>
                              onFormChange(e.target.value, 'ownername')
                            }
                            type="text"
                            name="ownername"
                            id="ownername"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="e.g. 'John Doe'"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.ownername && (
                              <p className="text-red-700">
                                Owner name can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2">
                        <label
                          htmlFor="ownerphone"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Owner Phone
                        </label>
                        <div className="mt-1">
                          <ReactInputMask
                            mask="(999) 999 9999"
                            maskPlaceholder={' '}
                            value={form.ownerphone}
                            onChange={(e) =>
                              onFormChange(e.target.value, 'ownerphone')
                            }
                            type="text"
                            name="ownerphone"
                            id="ownerphone"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.ownerphone && (
                              <p className="text-red-700">
                                Owner phone can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="owneremail"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Owner E-mail
                      </label>
                      <div className="mt-1">
                        <input
                          value={form.owneremail}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'owneremail')
                          }
                          type="text"
                          name="owneremail"
                          id="owneremail"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500 dark:placeholder:text-white"
                          placeholder="e.g. 'owner@abc.com'"
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                    </div>
                    {roles.includes(100) && (
                      <div className="">
                        <label
                          htmlFor="users"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Users
                        </label>
                        <Select
                          mode="multiple"
                          allowClear
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          placeholder="Please select"
                          defaultValue={form.users}
                          onChange={(val) => onFormChange(val, 'users')}
                          options={userList.map((x) => ({
                            label: x.fullname,
                            value: x.id,
                          }))}
                        />
                      </div>
                    )}

                    {roles.includes(100) && (
                      <div className="">
                        <label
                          htmlFor="subscription"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Subscription
                        </label>
                        <Select
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          placeholder="Please select"
                          defaultValue={form?.subscriptionid}
                          onChange={(val) =>
                            onFormChange(val, 'subscriptionid')
                          }
                          options={subscriptionList.map((x) => ({
                            label: x.name,
                            value: x.id,
                          }))}
                        />
                      </div>
                    )}
                    {isAdmin && (
                      <div>
                        <label
                          htmlFor="subdomain"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Subdomain
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.subdomain}
                            onChange={(e) =>
                              onFormChange(e.target.value, 'subdomain')
                            }
                            type="text"
                            name="subdomain"
                            id="subdomain"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500 dark:placeholder:text-white"
                            placeholder="e.g. 'abcpizza'"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.subdomain && (
                              <p className="text-red-700">
                                Subdomain can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      {form.id && (
                        <Button
                          type="ghost"
                          disabled={loading.deleteLoading}
                          loading={loading.deleteLoading}
                          onClick={() => onClickDelete()}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-0 sm:px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 col-span-2 sm:text-sm"
                        >
                          <TrashIcon className="text-white w-5 h-5" />
                        </Button>
                      )}
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        className={classNames(
                          form.id ? 'col-span-5' : 'col-span-6',
                          'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400'
                        )}
                        onClick={() => onCloseUpsert()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        loading={loading.upsertLoading}
                        className={classNames(
                          form.id ? 'col-span-5' : 'col-span-6',
                          'inline-flexw-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmit()}
                      >
                        {form.id ? 'Save' : 'Add Company'}
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={loading.loginAsCompany} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-slate-900 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-32 h-32 flex justify-center items-center sm:w-48 sm:h-48 sm:p-6">
                    <div className="w-full flex flex-col items-center space-y-4 justify-center py-4">
                      <h1>Logging in</h1>
                      <Spin />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <ModalDelete
          title="Company"
          loading={loading.deleteLoading}
          open={dialogs.delete}
          onCloseRequest={() =>
            setDialogs((prev) => ({ ...prev, delete: false }))
          }
          onOk={onConfirmDelete}
          item={form}
          shouldConfirm={true}
        />
      </div>
    </div>
  );
};

export default Company;
