import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Switch, Dialog, Transition, Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Spin, Button, Checkbox } from 'antd';
import { Tooltip } from 'react-tooltip';
import ModalDelete from '../../../components/modal-delete/modal-delete';
import Notification from '../../../components/notification/notification';

import roleApi from '../../../services/roleApi';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Permission = () => {
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState({
    initialLoading: true,
    roleLoading: false,
    upsertLoading: false,
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [errors, setErrors] = useState({
    emaildomain: false,
    name: false,
  });
  const [form, setForm] = useState({
    id: '',
    logo: '',
    emaildomain: '',
    name: '',
    users: [],
    isAdmin: false,
  });
  const [query, setQuery] = useState('');
  const [deletedItem, setDeletedItem] = useState({
    name: '',
    id: '',
  });
  const [dialogs, setDialogs] = useState({
    userList: false,
    upsert: false,
    delete: false,
  });
  const [search, setSearch] = useState('');

  const getRoleList = async (isPermissionSetting = false) => {
    try {
      if (!isPermissionSetting) {
        setLoading((prev) => ({ ...prev, initialLoading: true }));
      }
      const list = await roleApi.roleList();
      if (list.data.data.length > 0) {
        setRoleList(list.data.data);
      } else {
        setRoleList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getPermissionList = async () => {
    try {
      const list = await roleApi.permissionList();
      if (list.data.data.length > 0) {
        setPermissionList(list.data.data);
      } else {
        setPermissionList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const changeValue = async (roleId, permissionId, checked) => {
    try {
      setLoading((prev) => ({ ...prev, roleLoading: true }));
      if (checked) {
        await roleApi.addPermissionToRole(roleId, permissionId);
      } else {
        await roleApi.removePermissionToRole(roleId, permissionId);
      }
      await getRoleList(true);
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, roleLoading: false }));
    }
  };

  const filteredPermissions =
    query === ''
      ? permissionList
      : permissionList.filter((permission) => {
          return permission.name.toLowerCase().includes(query.toLowerCase());
        });

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickAddRole = () => {
    setForm({
      id: '',
      name: '',
      permissions: [],
      code: roleList[roleList.length - 1].code + 50,
    });

    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onClickDelete = (item) => {
    setDeletedItem(item);
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await roleApi.deleteRoleById(deletedItem.id);
      getRoleList(true);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const onFormSubmit = async () => {
    if (!form.name) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };

      if (form.isAdmin) {
        body.code = 100;
      }

      const res = await roleApi.upsertRole(body);
      if (res.data.data) {
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getRoleList(true);
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({ ...prev, name: false }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const isEnabled = (roleId, permissionId) => {
    const found = roleList?.find((role) => role.id === roleId);
    if (found) {
      const foundPermission = found?.rolepermissions?.find(
        (permission) => permission.permissionid === permissionId
      );
      if (foundPermission) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const cancelButtonRef = useRef(null);
  useEffect(() => {
    getRoleList();
    getPermissionList();
  }, []);

  return (
    <div className="px-6 py-6 lg:px-8">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Roles
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              A list of all the roles and permissions in the system.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() => onClickAddRole()}
              disabled={loading.listLoading}
              type="ghost"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
            >
              Add Role
            </Button>
          </div>
        </div>

        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
        <div className="mt-4">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
            placeholder="Search permission..."
          />
        </div>
        {loading.initialLoading ? (
          <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
            <Spin />
          </div>
        ) : (
          <div className="mt-4 flow-root">
            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                  <thead>
                    <tr className="divide-x divide-gray-200 dark:divide-slate-500">
                      <th
                        scope="col"
                        className="whitespace-nowrap py-4 pl-6 pr-4 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200"
                      >
                        Role / Permission
                      </th>
                      {roleList.map((role, Idx) => (
                        <th
                          key={Idx}
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          {role.name}
                          {role.code !== 150 && (
                            <Button
                              type="outline"
                              className="text-red-600 hover:text-red-900"
                              onClick={() => onClickDelete(role)}
                            >
                              X
                            </Button>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-slate-500 bg-white dark:bg-slate-800">
                    {permissionList
                      .filter((a) =>
                        a.name?.toLowerCase().includes(search.toLowerCase())
                      )
                      .map((permission, permissionIdx) => {
                        return (
                          <tr
                            key={permissionIdx}
                            className="divide-x divide-gray-200 dark:divide-slate-500"
                          >
                            <td className="whitespace-nowrap py-4 pl-6 pr-4 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                              {permission.name}
                            </td>
                            {roleList.map((role, roleIdx) => {
                              return (
                                <td
                                  key={roleIdx}
                                  className="whitespace-nowrap p-4 text-sm text-gray-500"
                                >
                                  <Switch
                                    key={role.id}
                                    checked={isEnabled(role.id, permission.id)}
                                    onChange={(e) => {
                                      changeValue(role.id, permission.id, e);
                                    }}
                                    className={classNames(
                                      isEnabled(role.id, permission.id)
                                        ? 'bg-blue-600'
                                        : 'bg-gray-200',
                                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        isEnabled(role.id, permission.id)
                                          ? 'translate-x-5'
                                          : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                      )}
                                    />
                                  </Switch>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
      <ModalDelete
        title="Delete Role"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedItem}
      />
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Role Name*
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="rolename"
                        id="rolename"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="Store Manager"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Role Name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <Combobox
                    as="div"
                    multiple
                    value={form.permissions}
                    onChange={(val) => onFormChange(val, 'permissions')}
                  >
                    <Combobox.Label className="block z-40 text-sm font-medium text-gray-700 dark:text-slate-500">
                      Permissons
                    </Combobox.Label>
                    <div className="relative mt-1">
                      <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white text-gray-700 py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 dark:focus:border-sky-400 focus:outline-none focus:ring-1 focus:ring-blue-500  sm:text-sm dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(permission) =>
                          filteredPermissions
                            .filter((x) => permission.includes(x.id))
                            .map((x) => x.name)
                        }
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400 dark:text-slate-200"
                          aria-hidden="true"
                        />
                      </Combobox.Button>
                      {filteredPermissions.length > 0 && (
                        <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-slate-900">
                          {filteredPermissions.map((permission) => (
                            <Combobox.Option
                              key={permission.id}
                              value={permission.id}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-default select-none py-2 pl-3 pr-9',
                                  active
                                    ? 'bg-blue-600 text-white dark:text-slate-300 dark:bg-slate-700'
                                    : 'text-gray-900 dark:text-slate-200'
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      'block truncate',
                                      selected && 'font-semibold'
                                    )}
                                  >
                                    {permission.name}
                                  </span>

                                  {selected && (
                                    <span
                                      className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active
                                          ? 'text-white '
                                          : 'text-blue-600 dark:text-slate-200'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                  </Combobox>
                  <div>
                    <div className="flex items-center sm:pt-5">
                      <Tooltip id="isAdmin" />
                      <Checkbox
                        data-tooltip-id="isAdminPanel"
                        data-tooltip-html=" When customers are checking out, they will be presented<br />
                      with add-on item suggestions. You can select up to four<br />
                      add-on items."
                        className="flex"
                        checked={form.isAdmin}
                        onChange={(e) =>
                          onFormChange(e.target.checked, 'isAdmin')
                        }
                      ></Checkbox>
                      <div className="flex pl-2 items-center">
                        <p className="text-sm font-medium text-gray-700 dark:text-slate-300">
                          Admin
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => onFormSubmit()}
                    >
                      Add Role
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={loading.roleLoading} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-[50]"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-[50]"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-[50]"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-[50]"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-32 h-32 flex justify-center items-center sm:w-48 sm:h-48 sm:p-6">
                  <div className="w-full flex flex-col items-center space-y-4 justify-center py-4">
                    <h1>Permission Setting</h1>
                    <Spin />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Permission;
