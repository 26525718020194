export const UserRoles = {
  ADMIN: 100,
  STORE_OWNER: 150,
  STORE_MANAGER: 200,
  STORE_OPERATOR: 250,
};

export const OrderStatus = {
  0: 'Pending',
  10: 'Accepted',
  20: 'Ready',
  30: 'Rejected',
  40: 'Refund',
  100: 'Completed',
};

export const PaymentStatus = {
  0: 'Not Paid',
  20: 'Paid',
};

export const OrderTypes = {
  10: 'Dine-In',
  20: 'TO GO',
};

export const PlatformTypes = {
  0: 'Kiosk',
  10: 'Online/QR',
  20: 'Mobile',
};
