import { Dialog, Transition } from "@headlessui/react";
import { Button } from "antd";
import React, { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Notification from "../../components/notification/notification";
import userApi from "../../services/userApi";
import User from "../../assets/user.svg";

const Profile = () => {
  const user = useSelector((state) => state.authReducer.user);
  const [form, setForm] = useState({
    fullname: user?.fullname,
    email: user?.email,
    password: "",
    confirmpassword: "",
  });
  const [loading, setLoading] = useState({
    upsertLoading: false,
  });
  const [errors, setErrors] = useState({
    fullname: false,
    email: false,
    password: false,
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
  });

  const cancelButtonRef = useRef(null);

  const [notf, setNotf] = useState({
    display: false,
    type: "error",
    message: "",
  });

  const onFormSubmit = async () => {
    if (
      !form.fullname ||
      !form.email ||
      form.password !== form.confirmpassword
    ) {
      setErrors((prev) => ({
        ...prev,
        fullname: !form.fullname ? true : false,
        email: !form.email ? true : false,
        password: form.password !== form.confirmpassword ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        fullname: false,
        email: false,
        password: false,
      }));
    }
    setLoading((prev) => ({ ...prev, upsertLoading: true }));
    try {
      const res = await userApi.updateProfile(form);

      if (res.status === 200) {
        setNotf({
          display: true,
          type: "success",
          message: "Profile updated successfully",
        });
      }
    } catch (err) {
      setNotf({
        display: true,
        type: "error",
        message:
          err.response.data.errors[Object.keys(err.response.data.errors)[0]][0],
      });
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickEdit = () => {
    setForm({
      fullname: user.fullname,
      email: user.email,
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      fullname: false,
      email: false,
      password: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-3xl">
        <div className="grid pb-16 pt-4 justify-center sm:justify-start">
          <img
            src={User}
            className="w-36 h-36 sm:w-48 sm:h-48 "
            alt="profile_img"
          />
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Profile
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              Update and manage your personal profile information.
            </p>
          </div>
        </div>
        <div>
          <div className="mt-5 border-t border-gray-200 dark:border-slate-700">
            <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                  Full Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-200">
                  {user?.fullname}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                  Email
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-200">
                  {user?.email}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 dark:text-slate-400">
                  Password
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-slate-200">
                  ******
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 mt-4">
          <Button
            type="ghost"
            onClick={() => onClickEdit()}
            className="inline-flex w-full sm:col-start-2 justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
          >
            Edit
          </Button>
        </div>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="grid grid-cols-8 gap-6">
                    <div className="col-span-8 sm:col-span-8">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Full Name
                      </label>
                      <input
                        value={form.fullname}
                        onChange={(e) =>
                          onFormChange(e.target.value, "fullname")
                        }
                        type="text"
                        name="fullname"
                        id="fullname"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'John Doe'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.fullname && (
                          <p className="text-red-700">
                            Full name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-8">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Email
                      </label>
                      <input
                        value={form.email}
                        onChange={(e) => onFormChange(e.target.value, "email")}
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'example@acme.com'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.email && (
                          <p className="text-red-700">Email can not be empty</p>
                        )}
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                          Change Password
                        </h1>
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Password
                      </label>
                      <input
                        value={form.password}
                        onChange={(e) =>
                          onFormChange(e.target.value, "password")
                        }
                        type="password"
                        name="password"
                        id="password"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder=""
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.password && (
                          <p className="text-red-700">
                            Passwords does not match
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="confirmpassword"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Confirm Password
                      </label>
                      <input
                        value={form.confirmpassword}
                        onChange={(e) =>
                          onFormChange(e.target.value, "confirmpassword")
                        }
                        type="password"
                        name="confirmpassword"
                        id="confirmpassword"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder=""
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.password && (
                          <p className="text-red-700">
                            Passwords does not match
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => onFormSubmit()}
                    >
                      {"Update"}
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm dark:bg-slate-800 dark:text-white dark:hover:text-sky-400"
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default Profile;
