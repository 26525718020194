import { Spin, Input, Button } from 'antd';
import React, { useState } from 'react';
import companyApi from '../../../../services/companyApi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Notes = ({ companyDetail, updateCompany, setNotf }) => {
  const [loading, setLoading] = useState({
    noteLoading: false,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [form, setForm] = useState({ note: companyDetail.note || '' });

  const onClickSave = async () => {
    try {
      setLoading((prev) => ({ ...prev, itemFee: true }));
      const body = {
        ...companyDetail,
        ...form,
      };
      const formData = new FormData();

      Object.keys(body).forEach((key) => {
        if (body[key]) {
          formData.append(key, body[key]);
        }
      });

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      await companyApi.upsert(formData, config);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      checkUpdate(true);
      updateCompany();
      setLoading((prev) => ({ ...prev, itemFee: false }));
    }
  };

  const checkUpdate = (val, message) => {
    if (val) {
      setNotf((prev) => ({
        ...prev,
        message: 'Note Updated Successfully',
        display: true,
        type: 'success',
      }));
    } else {
      setNotf((prev) => ({
        ...prev,
        message,
        display: true,
        type: 'error',
      }));
    }
  };

  return (
    <>
      {loading.noteLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <>
          <div className="sm:flex sm:items-center pb-2">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                Company Notes
              </h1>
            </div>
          </div>
          <div className="max-w-md p-4 border rounded shadow-sm">
            {isEditing ? (
              <div>
                <div className="mt-2 h-96 pb-16 ">
                  <ReactQuill
                    className="h-full"
                    theme="snow"
                    value={form.note}
                    onChange={(e) => setForm({ ...form, note: e })}
                  />
                </div>
                <div className="flex justify-end mt-2">
                  <Button
                    type="button"
                    className="mr-2 inline-flex  justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:text-slate-200 dark:bg-slate-900 dark:hover:text-sky-400"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="inline-flex  justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
                    onClick={onClickSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: companyDetail?.note || '<p>Click to add a note</p>',
                }}
                onClick={() => setIsEditing(true)}
                className="cursor-pointer"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Notes;
