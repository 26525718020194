import React, { useState, useEffect } from 'react';
import { Spin, TimePicker, Button } from 'antd';
import { Switch } from '@headlessui/react';
import dayjs from 'dayjs';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import storeApi from '../../../../services/storeApi';
import { weekDaysEnum } from '../../../../data/constants';
import { PlusIcon } from '@heroicons/react/24/solid';

const format = 'hh:mm a';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WorkingHoursTab = ({ storeDetail, checkUpdate }) => {
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [daysLoading, setDaysLoading] = useState(true);
  const [switches, setSwitches] = useState({
    websiteclose: storeDetail.websiteclose,
  });

  useEffect(() => {
    if (storeDetail.storadays) {
      setDays(gruppedByDays(storeDetail.storadays || []));
      setDaysLoading(false);
    }
  }, [storeDetail.storadays]);

  const gruppedByDays = (days) => {
    const gruppedByDays = days
      .sort((a, b) => a.day - b.day)
      .reduce((acc, curr) => {
        const found = acc.find((a) => a.day === curr.day);
        if (!found) {
          acc.push({ day: curr.day, hours: [curr] });
        } else {
          found.hours.push(curr);
        }
        return acc;
      }, []);

    return gruppedByDays;
  };

  const onClickDeleteDay = (day, dayIndex) => {
    setDaysLoading(true);

    let tempArray = [...days];
    let dayIndexToDelete = tempArray.findIndex((a) => a.day === day);

    if (tempArray[dayIndexToDelete].hours.length === 1) {
      tempArray.splice(dayIndexToDelete, 1);
    } else {
      tempArray[dayIndexToDelete].hours.splice(dayIndex, 1);
    }

    setDays(tempArray);

    setTimeout(() => {
      setDaysLoading(false);
    }, 150);
  };

  const handleHourChange = (value, day, type, hourIndex) => {
    setDaysLoading(true);

    let tempArray = [...days];
    let dayIndex = tempArray.findIndex((a) => a.day === day);

    tempArray[dayIndex].hours[hourIndex][`${type}hour`] = value.hour();
    tempArray[dayIndex].hours[hourIndex][`${type}min`] = value.minute();

    setDays(tempArray);

    setTimeout(() => {
      setDaysLoading(false);
    }, 100);
  };

  const onClickSubmit = async () => {
    try {
      setLoading(true);

      const body = {
        storeid: storeDetail.id,
        days,
      };
      await storeApi.upsertHours({ storeid: storeDetail.id, days: [] });
      const res = await storeApi.upsertHours(body);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false);
    } finally {
      setLoading(false);
    }
  };

  const onClickAddHour = (day) => {
    setDaysLoading(true);

    setDays((prevDays) => {
      const dayIndex = prevDays.findIndex((a) => a.day === day);
      if (dayIndex === -1) {
        return [
          ...prevDays,
          {
            day,
            hours: [{ starthour: 10, startmin: 0, endhour: 22, endmin: 0 }],
          },
        ];
      } else {
        const updatedDays = [...prevDays];
        updatedDays[dayIndex].hours.push({
          starthour: 10,
          startmin: 0,
          endhour: 22,
          endmin: 0,
        });
        return updatedDays;
      }
    });

    setTimeout(() => {
      setDaysLoading(false);
    }, 150);
  };

  const onChangeWebsiteStatus = async (val) => {
    const form = {
      ...storeDetail,
      websiteclose: val,
    };
    try {
      setLoading(true);
      const formData = new FormData();

      for (const key of Object.keys(form)) {
        formData.append(key, form[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-2 sm:gap-x-8 p-4">
      <div className="col-span-4 sm:col-span-3">
        <div className="flex flex-col gap-2">
          {daysLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-5 gap-4 pb-10">
                <div className="col-span-4 sm:col-span-3">
                  <h1 className="text-lg font-semibold">Website On/Off</h1>
                  <p className="text-sm text-gray-500">
                    You can Turn On/Off your online store for the orders
                  </p>
                </div>

                <div className="flex justify-start col-span-1 md:col-span-1 md:col-start-5 lg:col-start-4 gap-2">
                  <Switch
                    checked={switches.websiteclose}
                    onChange={(e) => {
                      setSwitches({
                        ...switches,
                        websiteclose: !switches.websiteclose,
                      });
                      onChangeWebsiteStatus(!switches.websiteclose);
                    }}
                    className={classNames(
                      switches.websiteclose
                        ? 'bg-red-600 focus:ring-red-500'
                        : 'bg-green-600 focus:ring-green-500',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2  focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        switches.websiteclose
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>

                  {switches.websiteclose ? (
                    <p className="text-red-600">Store Closed</p>
                  ) : (
                    <p className="text-green-600">Open</p>
                  )}
                </div>
              </div>
              <h1 className="text-lg font-semibold mb-4 border-b">
                Working Hours
              </h1>
              {weekDaysEnum.map((weekDay, dayIndex) => {
                return (
                  <div
                    key={weekDay.value}
                    className="sm:flex flex-row items-center gap-5 border-b border-gray-200 dark:border-slate-600 py-2"
                  >
                    <div className="flex flex-col  w-[250px] self-start">
                      <label
                        htmlFor="image"
                        className="block font-bold text-gray-700 dark:text-slate-400 justify-center"
                      >
                        {weekDay.label}
                      </label>
                    </div>
                    <div className="flex flex-col ">
                      {days
                        .find((a) => a.day === weekDay.value)
                        ?.hours.map((hour, hourIndex) => (
                          <div key={hourIndex} className="flex flex-col gap-5">
                            <div className="flex gap-5 pb-4">
                              <div className="flex flex-col gap-1">
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                >
                                  Start Time
                                </label>
                                <TimePicker
                                  needConfirm={false}
                                  use12Hours
                                  size="large"
                                  allowClear={false}
                                  defaultValue={dayjs(
                                    `${hour.starthour}:${hour.startmin}`,
                                    'hh:mm'
                                  )}
                                  format={format}
                                  onChange={(val) =>
                                    handleHourChange(
                                      val,
                                      weekDay.value,
                                      'start',
                                      hourIndex
                                    )
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                >
                                  End Time
                                </label>
                                <TimePicker
                                  needConfirm={false}
                                  use12Hours
                                  size="large"
                                  allowClear={false}
                                  defaultValue={dayjs(
                                    `${hour.endhour}:${hour.endmin}`,
                                    'hh:mm'
                                  )}
                                  format={format}
                                  onChange={(val) =>
                                    handleHourChange(
                                      val,
                                      weekDay.value,
                                      'end',
                                      hourIndex
                                    )
                                  }
                                />
                              </div>
                              <div className="self-center">
                                <MinusCircleIcon
                                  onClick={() =>
                                    onClickDeleteDay(weekDay.value, hourIndex)
                                  }
                                  className="text-red-500 w-5 h-5 mt-5 hover:text-red-900"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="flex flex-col gap-1 self-start">
                      <div>
                        <button
                          type="button"
                          onClick={() => onClickAddHour(weekDay.value)}
                          className="rounded-full bg-blue-600 p-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer "
                        >
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 mt-4">
          <Button
            type="ghost"
            disabled={loading}
            loading={loading}
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 dark:hover:text-sky-400 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            onClick={() => onClickSubmit()}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkingHoursTab;
