import api from './api';

const resource = 'BankAccount/';

export const list = async (query) => {
  return await api.get(resource + 'List' + query);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const connectStipeBankAccount = async (id) => {
  return await api.post(
    resource + 'ConnectStripeBankAccount?bankaccountid=' + id
  );
};

const bankAccountApi = {
  list,
  getById,
  upsert,
  deleteById,
  connectStipeBankAccount,
};

export default bankAccountApi;
