import { Select } from 'antd';
import React from 'react';
import AddressAutoComplete from '../../../components/address-autocomplete/address-autocomplete';
const Restaurant = ({
  companyname,
  storeaddress,
  errors,
  onChange,
  apartment,
  city,
  state,
  postalcode,
}) => {
  const handleChange = (value, key) => {
    onChange(value, key);
  };

  const onAddressSelect = (obj) => {
    handleChange(obj, 'storeaddress');
  };

  return (
    <div className="absolute w-full mt-4 grid sm:gap-6 gap-4 grid-cols-8 ">
      <div className="col-span-8">
        <label
          htmlFor="companyname"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Restaurant Name*
        </label>
        <div className="mt- 1">
          <input
            onChange={(e) => handleChange(e.target.value, 'companyname')}
            value={companyname}
            id="companyname"
            name="companyname"
            type="companyname"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
          />
        </div>
        <div className="h-2 mt-1  text-xs">
          {errors.companyname && (
            <p className="text-red-700">Enter your restaurant name</p>
          )}
        </div>
      </div>
      <div className="col-span-8 sm:col-span-6">
        <label
          htmlFor="address"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Street Name
        </label>
        <AddressAutoComplete
          value={storeaddress}
          onChange={(e) => onAddressSelect(e)}
        />

        <div className="h-2 mt-1  text-xs">
          {errors.storeaddress ? (
            <p className="text-red-700">Address can not be empty</p>
          ) : (
            <p className="text-gray-500 dark:text-slate-500">
              Please type your street name here e.g. '100 Main St'
            </p>
          )}
        </div>
      </div>
      <div className="col-span-8 sm:col-span-2">
        <label
          htmlFor="apartment"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Apt / Suite
        </label>
        <input
          value={apartment}
          onChange={(e) => handleChange(e.target.value, 'apartment')}
          type="text"
          name="apartment"
          id="apartment"
          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
          placeholder=""
        />
        <div className="h-2 mt-1  text-xs"></div>
      </div>
      <div className="col-span-8 grid grid-cols-8 sm:gap-6 gap-4">
        <div className="col-span-8 sm:col-span-4">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
          >
            City
          </label>
          <input
            value={city}
            onChange={(e) => handleChange(e.target.value, 'city')}
            type="text"
            name="city"
            id="city"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
            placeholder=""
          />
          <div className="h-2 mt-1  text-xs"></div>
        </div>
        <div className="col-span-4 sm:col-span-2">
          <label
            htmlFor="state"
            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
          >
            State
          </label>
          <input
            value={state}
            onChange={(e) => handleChange(e.target.value, 'state')}
            type="text"
            name="state"
            id="state"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
            placeholder=""
          />
          <div className="h-2 mt-1  text-xs"></div>
        </div>
        <div className="col-span-4 sm:col-span-2">
          <label
            htmlFor="postalcode"
            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
          >
            Zip Code
          </label>
          <input
            value={postalcode}
            onChange={(e) => handleChange(e.target.value, 'postalcode')}
            type="text"
            name="postalcode"
            id="postalcode"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
            placeholder=""
          />
          <div className="h-2 mt-1  text-xs"></div>
        </div>
      </div>
    </div>
  );
};

export default Restaurant;
