import api from './api';

const resource = 'Product/';

export const list = async (query) => {
  return await api.get(resource + 'List?PageSize=1000' + query);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body, config) => {
  return await api.post(resource + 'Upsert', body, config);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const deleteImageById = async (id) => {
  return await api.delete(resource + 'DeleteImage?productId=' + id);
};

const productApi = { list, getById, upsert, deleteById, deleteImageById };

export default productApi;
