import { useState, useEffect } from 'react';
import update from 'immutability-helper';
import Notification from '../../notification/notification';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import categoryApi from '../../../services/categoryApi';
import CategoryUpsertModal from './category-upsert-modal';
import ModalDelete from '../../modal-delete/modal-delete';
import CategoryCard from './category-card';
import { Spin } from 'antd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CategoryList({ selected, setSelected }) {
  const [deletedItem, setDeletedItem] = useState({ name: '', id: '' });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    displayorder: false,
  });
  const [categoryForm, setCategoryForm] = useState({
    id: '',
    name: '',
    displayorder: 0,
    menuid: selected.menuid,
    description: '',
  });
  const [categoryDialogs, setCategoryDialogs] = useState({
    categoryUpsert: false,
    categoryDelete: false,
  });
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState({
    categoryLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const getCategoryList = async (method) => {
    try {
      setLoading((prev) => ({ ...prev, categoryLoading: true }));
      const list = await categoryApi.list(selected.menuid);
      if (list.data.data.length > 0) {
        setCategoryList(list.data.data);
        setSelected((prev) => ({
          ...prev,
          category: method?.method === 'update' && prev?.category.id,
        }));
      } else {
        setCategoryList([]);
        setSelected((prev) => ({
          ...prev,
          category: {},
          itemid: '',
          product: {},
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, categoryLoading: false }));
    }
  };

  const onClickAddCategory = () => {
    setCategoryForm({
      id: '',
      name: '',
      description: '',
      displayorder:
        categoryList.length > 0 ? (categoryList.length + 1) * 1024 : 1024,
      menuid: selected.menuid,
    });

    setCategoryDialogs((prev) => ({ ...prev, categoryUpsert: true }));
  };

  const onFormChange = (val, key) => {
    setCategoryForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickDeleteCategory = (category) => {
    setDeletedItem(category);
    setCategoryDialogs((prev) => ({ ...prev, categoryDelete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await categoryApi.deleteById(deletedItem.id);
      getCategoryList();
    } catch (error) {
    } finally {
      setCategoryDialogs((prev) => ({
        ...prev,
        categoryDelete: false,
        categoryUpsert: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const onClickEditCategory = (val) => {
    setCategoryForm({
      id: val.id,
      name: val.name,
      displayorder: val.displayorder,
      menuid: val.menuid,
      description: val.description,
    });

    setCategoryDialogs((prev) => ({ ...prev, categoryUpsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({ ...prev, displayorder: false, name: false }));
    setCategoryDialogs((prev) => ({ ...prev, categoryUpsert: false }));
  };

  const onFormSubmit = async () => {
    if (!categoryForm.name || categoryForm.displayorder === '') {
      setErrors((prev) => ({
        ...prev,
        name: !categoryForm.name ? true : false,
        displayorder: categoryForm.displayorder === '' ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, name: false, displayorder: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...categoryForm };
      const res = await categoryApi.upsert(body);
      if (res.data.data) {
        setCategoryDialogs((prev) => ({ ...prev, categoryUpsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getCategoryList({ method: categoryForm.id ? 'update' : 'create' });
    }
  };

  const moveCategoryItem = async (dragIndex, hoverIndex) => {
    setCategoryList((prevCategories) =>
      update(prevCategories, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCategories[dragIndex]],
        ],
      })
    );
  };

  const dropCategoryItem = async (index, category) => {
    if (index === categoryList.length - 1) {
      category.displayorder = Math.round(
        categoryList[index - 1].displayorder + 1024
      );
    } else if (index === 0) {
      category.displayorder = Math.round(categoryList[1].displayorder / 2);
    } else {
      category.displayorder = Math.round(
        (categoryList[index - 1].displayorder +
          categoryList[index + 1].displayorder) /
          2
      );
    }

    try {
      setLoading((prev) => ({ ...prev, categoryLoading: true }));
      await categoryApi.upsert(category);
      getCategoryList();
    } catch (error) {}
  };

  useEffect(() => {
    getCategoryList();
  }, [selected.menuid, selected.isCategoryFetch]);
  return (
    <>
      {loading.categoryLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <nav className="">
          {selected.category.id !== '' && (
            <div>
              {categoryList.map((category, i) => (
                <CategoryCard
                  key={category.id}
                  index={i}
                  id={category.id}
                  selected={selected}
                  setSelected={setSelected}
                  onClickEditCategory={onClickEditCategory}
                  category={category}
                  moveCategoryItem={moveCategoryItem}
                  dropCategoryItem={dropCategoryItem}
                />
              ))}
            </div>
          )}
          <div
            onClick={() => onClickAddCategory()}
            className="flex-col justify-center hover:bg-slate-50 
          flex items-center cursor-pointer mt-0 p-5 text-sm font-medium  text-blue-600 dark:text-slate-200 dark:hover:bg-slate-700 h-[6rem]"
          >
            <button
              type="button"
              className="rounded-full bg-blue-600 p-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <span className="truncate font-semibold text-[0.925rem]">
              Add Category
            </span>
          </div>
        </nav>
      )}
      <CategoryUpsertModal
        show={categoryDialogs.categoryUpsert}
        loading={loading.upsertLoading}
        categoryForm={categoryForm}
        errors={errors}
        onCloseUpsert={onCloseUpsert}
        onFormChange={onFormChange}
        onFormSubmit={onFormSubmit}
        onClickDeleteCategory={onClickDeleteCategory}
      />

      <ModalDelete
        title="Delete Confirmation"
        text={`Are you sure you want to delete "${deletedItem.name}"? All associated data will be permanently removed.`}
        loading={loading.deleteLoading}
        open={categoryDialogs.categoryDelete}
        onCloseRequest={() => {
          setCategoryDialogs((prev) => ({ ...prev, categoryDelete: false }));
        }}
        onOk={onConfirmDelete}
        item={deletedItem}
      />
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </>
  );
}
