import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spin, Upload } from 'antd';
import { Dialog, Switch, Transition } from '@headlessui/react';
import ModalDelete from '../../../components/modal-delete/modal-delete';
import companyApi from '../../../services/companyApi';
import { Select } from 'antd';
import {
  ArrowTrendingUpIcon,
  InformationCircleIcon,
  CreditCardIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import userApi from '../../../services/userApi';
import Notification from '../../../components/notification/notification';
import ReactInputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useSpringRef, useTransition, animated } from '@react-spring/web';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import AccountActivity from './tabs/account-activity-tab';
import AccountInformation from './tabs/account-information-tab';
import Fees from './tabs/fees-tab';
import Settings from './tabs/settings-tab';
import Notes from './tabs/notes-tab';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CompanyDetail = () => {
  const userRoles = useSelector((state) => state.authReducer.roles);
  const isAdmin = userRoles.includes(100);
  const { id } = useParams();
  const navigate = useNavigate();
  const roles = useSelector((state) => state.authReducer.roles);

  const tabs = roles.includes(100)
    ? [
        {
          name: 'Account Activity',
          id: 0,
          icon: ArrowTrendingUpIcon,
        },
        {
          name: 'Account Information',
          id: 1,
          icon: InformationCircleIcon,
        },
        { name: 'Fees', id: 2, icon: CreditCardIcon },
        { name: 'Settings', id: 3, icon: Cog6ToothIcon },
        { name: 'Notes', id: 4, icon: DocumentTextIcon },
      ]
    : [];

  const [currentTab, setCurrentTab] = useState(0);

  const cancelButtonRef = useRef(null);
  const [companyDetail, setCompantDetail] = useState({});
  const [userList, setUserList] = useState([]);

  const [form, setForm] = useState({
    id: '',
    ownername: '',
    ownerphone: '',
    owneremail: '',
    logo: '',
    emaildomain: '',
    name: '',
    subdomain: '',
    itemfee: 0,
    users: [],
    isactive: false,
    subscriptionid: '',
    creditcardfeeper: null,
    creditcardfeerate: null,
  });
  const [loading, setLoading] = useState({
    initialLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });

  const [errors, setErrors] = useState({
    code: false,
    name: false,
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [isNext, setIsNext] = useState(false);
  const transRef = useSpringRef();

  const transitions = useTransition(currentTab, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: isNext ? 'translate3d(10%,0,0)' : 'translate3d(-10%,0,0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: isNext ? 'translate3d(-10%,0,0)' : 'translate3d(10%,0,0)',
    },
  });

  const tabComponents = [
    <AccountActivity
      companyDetail={companyDetail}
      notf={notf}
      setNotf={setNotf}
    />,
    <AccountInformation
      companyDetail={companyDetail}
      notf={notf}
      setNotf={setNotf}
    />,
    <Fees
      companyDetail={companyDetail}
      setNotf={setNotf}
      updateCompany={() => getCompanyDetail()}
    />,
    <Settings
      companyDetail={companyDetail}
      setNotf={setNotf}
      updateCompany={() => getCompanyDetail()}
    />,
    <Notes
      companyDetail={companyDetail}
      setNotf={setNotf}
      updateCompany={() => getCompanyDetail()}
    />,
  ];
  useEffect(() => {
    transRef.start();
  }, [currentTab]);

  const onClickTab = (tab) => {
    if (tab.id > currentTab) {
      setIsNext(true);
    } else {
      setIsNext(false);
    }
    setCurrentTab(tab.id);
  };

  const onClickTabMobile = (id) => {
    if (id > currentTab) {
      setIsNext(true);
    } else {
      setIsNext(false);
    }
    setCurrentTab(id);
  };
  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onFormSubmit = async () => {
    if (!form.name) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      const formData = new FormData();

      Object.keys(body).forEach((key) => {
        if (body[key]) {
          formData.append(key, body[key]);
        }
      });

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await companyApi.upsert(body, config);
      if (res.data.data) {
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getCompanyDetail();
    }
  };

  const onClickEdit = () => {
    setForm({
      id: companyDetail.id,
      logo: companyDetail.logo,
      imagepath: companyDetail.logo,
      emaildomain: companyDetail.emaildomain,
      subdomain: companyDetail.subdomain,
      name: companyDetail.name,
      users: companyDetail.companyusers.map((x) => x.userid),
      isactive: companyDetail.isactive,
      ownerphone: companyDetail.ownerphone,
      ownername: companyDetail.ownername,
      owneremail: companyDetail.owneremail,
      subscriptionid: companyDetail.subscriptionid,
      creditcardfeeper: companyDetail.creditcardfeeper,
      creditcardfeerate: companyDetail.creditcardfeerate,
      itemfee: companyDetail.itemfee,
    });

    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({ ...prev, code: false, name: false }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  useEffect(() => {
    getCompanyDetail();
    getUserList();
  }, []);

  const getCompanyDetail = async () => {
    try {
      setLoading((prev) => ({ ...prev, initialLoading: true }));
      const res = await companyApi.getById(id);
      if (res.data) {
        setCompantDetail(res.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const getUserList = async () => {
    try {
      const list = await userApi.list(`?pageSize=1000&status=10&status=0`);
      if (list.data.data.length > 0) {
        setUserList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const onClickDelete = () => {
    setDialogs((prev) => ({ ...prev, upsert: false, delete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await companyApi.deleteById(companyDetail.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      navigate(-1);
    }
  };

  const uploadButton = (
    <div>
      {loading.upsertLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-200"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onClickConnectStripe = async () => {
    setLoading((prev) => ({ ...prev, upserLoading: true }));
    try {
      await companyApi.connectStripeAccount(companyDetail.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        display: true,
        message: error?.response?.data?.Message || 'Error',
        type: 'error',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upserLoading: false }));
      await getCompanyDetail();
    }
  };

  const onClickDisconnectStripe = async () => {
    setLoading((prev) => ({ ...prev, upserLoading: true }));
    try {
      await companyApi.disconnectStripeAccount(companyDetail.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        display: true,
        message: error?.response?.data?.Message || 'Error',
        type: 'error',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upserLoading: false }));
      await getCompanyDetail();
    }
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      {loading.initialLoading ? (
        <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
          <Spin />
        </div>
      ) : (
        <div className="space-y-10  mx-auto max-w-5xl">
          <div className=" md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                {companyDetail.logo ? (
                  <img
                    className="hidden h-16 w-16 rounded-full sm:block"
                    src={companyDetail.logo}
                    alt=""
                  />
                ) : (
                  <span className="hidden h-16 w-16 items-center justify-center rounded-full bg-gray-500 sm:inline-flex">
                    <span className="text-xs font-medium leading-none text-white">
                      {companyDetail.name
                        ? companyDetail.name.split(' ').map((x) => x[0])
                        : ''}
                    </span>
                  </span>
                )}
                <div>
                  <div className="flex items-center">
                    {companyDetail.logo ? (
                      <img
                        className="h-16 w-16 rounded-full sm:hidden"
                        src={companyDetail.logo}
                        alt=""
                      />
                    ) : (
                      <span className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-gray-500 sm:hidden">
                        <span className="text-xs font-medium leading-none text-white">
                          {companyDetail.name
                            ? companyDetail.name.split(' ').map((x) => x[0])
                            : ''}
                        </span>
                      </span>
                    )}
                    <h1 className="ml-3 text-2xl font-bold leading-7 flex space-x-3 text-gray-900 sm:truncate sm:leading-9">
                      <span className="dark:text-slate-200">
                        {companyDetail.name}
                      </span>
                      {companyDetail.isactive ? (
                        <span className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0 dark:text-slate-200">
                          <CheckCircleIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                            aria-hidden="true"
                          />
                          Active company
                        </span>
                      ) : (
                        <span className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0 dark:text-slate-200">
                          <XCircleIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          Inactive company
                        </span>
                      )}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Terminal</dt>
                    <dd className="flex items-center text-sm font-medium capitalize space-x-2 text-gray-500">
                      {!companyDetail.ownername &&
                      !companyDetail.ownerphone ? null : (
                        <UserIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      <span>
                        {companyDetail.ownername
                          ? companyDetail.ownername
                          : null}
                      </span>
                      <span>
                        {companyDetail.ownerphone
                          ? companyDetail.ownerphone
                          : ''}
                      </span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-2 md:mt-0 md:ml-4">
              {!companyDetail?.stripeaccountid ? (
                <Button
                  onClick={onClickConnectStripe}
                  type="button"
                  className="h-10 inline-flex items-center rounded-md bg-white dark:bg-slate-900 dark:hover:text-sky-400 dark:text-slate-200 dark:ring-0 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Connect Stripe
                </Button>
              ) : (
                <Button
                  onClick={onClickDisconnectStripe}
                  type="button"
                  className="h-10 inline-flex items-center rounded-md bg-white dark:bg-slate-900 dark:hover:text-sky-400 dark:text-slate-200 dark:ring-0 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Disconnect Stripe
                </Button>
              )}
              <Button
                type="button"
                className="block w-full rounded-md bg-blue-600 h-10  px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 dark:hover:text-sky-400 dark:bg-slate-900 "
                onClick={() => onClickEdit()}
              >
                Edit
              </Button>
            </div>
          </div>
          <div>
            <div className="sm:hidden">
              <Select
                size="large"
                style={{
                  width: '100%',
                }}
                placeholder="Please select"
                value={currentTab}
                onChange={(val) => onClickTabMobile(val)}
                options={tabs.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
              />
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200 dark:border-slate-700">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      onClick={() => onClickTab(tab)}
                      key={tab.id}
                      href={'#'}
                      className={classNames(
                        currentTab === tab.id
                          ? 'border-blue-500 text-blue-600 dark:border-sky-400 dark:text-sky-500'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-slate-300',
                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      aria-current={currentTab === tab.id ? 'page' : undefined}
                    >
                      <tab.icon
                        className={classNames(
                          currentTab === tab.id
                            ? 'text-blue-500 dark:text-sky-400'
                            : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {roles.includes(100) && (
            <div>
              {transitions((style, item) => (
                <animated.div
                  style={{
                    ...style,
                  }}
                >
                  {tabComponents[item]}
                </animated.div>
              ))}
            </div>
          )}
        </div>
      )}
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="logo"
                      className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Company Logo
                    </label>
                    <div className="mt-1">
                      <>
                        <Upload
                          multiple={false}
                          maxCount={1}
                          name="avatar"
                          onChange={(e) => onFormChange(e.file, 'image')}
                          listType="picture-circle"
                          className="avatar-uploader"
                          beforeUpload={() => false}
                        >
                          {form.logo ? (
                            <img
                              src={form.logo}
                              alt="avatar"
                              className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full"
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </>
                      <div className="h-2 mt-1  text-xs">
                        {errors.image && (
                          <p className="text-red-700">Image can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Company Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'Abc Pizza'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">Name can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label
                        htmlFor="ownername"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Owner Name
                      </label>
                      <div className="mt-1">
                        <input
                          value={form.ownername}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'ownername')
                          }
                          type="text"
                          name="ownername"
                          id="ownername"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="e.g. 'John Doe'"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.ownername && (
                            <p className="text-red-700">
                              Owner name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label
                        htmlFor="ownerphone"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Owner Phone
                      </label>
                      <div className="mt-1">
                        <ReactInputMask
                          mask="(999) 999 9999"
                          maskPlaceholder={' '}
                          value={form.ownerphone}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'ownerphone')
                          }
                          type="text"
                          name="ownerphone"
                          id="ownerphone"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.ownerphone && (
                            <p className="text-red-700">
                              Owner phone can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="owneremail"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Owner E-mail
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.owneremail}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'owneremail')
                        }
                        type="text"
                        name="owneremail"
                        id="owneremail"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="e.g. 'owner@abc.com'"
                      />
                      <div className="h-2 mt-1  text-xs"></div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="logo"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Users
                    </label>
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Please select"
                      value={form.users}
                      onChange={(val) => onFormChange(val, 'users')}
                      options={userList.map((x) => ({
                        label: x.fullname,
                        value: x.id,
                      }))}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="image"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Active
                    </label>
                    <div className="mt-1">
                      <Switch
                        checked={form.isactive}
                        onChange={(e) => {
                          onFormChange(e, 'isactive');
                        }}
                        className={classNames(
                          form.isactive ? 'bg-blue-600' : 'bg-gray-200',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            form.isactive ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.deleteLoading}
                      loading={loading.deleteLoading}
                      onClick={() => onClickDelete()}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                    >
                      <TrashIcon className="text-white w-5 h-5" />
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        'sm:col-span-5 col-span-12',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        'sm:col-span-5 col-span-12',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      Edit Company
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ModalDelete
        title="Company"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={companyDetail}
        shouldConfirm={true}
      />
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default CompanyDetail;
