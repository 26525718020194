import React, { useState, useEffect } from 'react';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import update from 'immutability-helper';
import itemApi from '../../../services/itemApi';
import ItemUpsertModal from './item-upsert-modal';
import ModalDelete from '../../modal-delete/modal-delete';
import ItemCard from './item-card';
import { Spin, Button } from 'antd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ItemList({ selected, setSelected }) {
  const [deletedItem, setDeletedItem] = useState({ name: '', id: '' });
  const [isSingleItem, setIsSingleItem] = useState(true);
  const [items, setItems] = useState([{ name: '', price: '' }]);
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    price: false,
  });
  const [itemForm, setItemForm] = useState({
    id: '',
    name: '',
    price: null,
    displayorder: 0,
    productid: selected.product?.id,
  });
  const [itemDialogs, setItemDialogs] = useState({
    itemUpsert: false,
    itemDelete: false,
  });
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState({
    itemLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });

  const headers = ['Name', 'Price'];
  const getItemList = async (method) => {
    try {
      setLoading((prev) => ({ ...prev, itemLoading: true }));
      const list = await itemApi.list(selected.product.id);
      if (list.data.data.length > 0) {
        setItemList(list.data.data);
      } else {
        setItemList([]);
        setSelected((prev) => ({
          ...prev,
          itemid: '',
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, itemLoading: false }));
    }
  };

  const onClickAddItem = () => {
    setItemForm({
      id: '',
      name: itemList.length > 0 ? '' : selected.product.name,
      productid: selected.product.id,
      price: null,
      displayorder: itemList.length > 0 ? (itemList.length + 1) * 1024 : 1024,
    });

    setItemDialogs((prev) => ({ ...prev, itemUpsert: true }));
  };

  const onFormChange = (val, key) => {
    setItemForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickDeleteItem = (item) => {
    setDeletedItem(item);
    setItemDialogs((prev) => ({ ...prev, itemDelete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await itemApi.deleteById(deletedItem.id);
      getItemList();
    } catch (error) {
    } finally {
      setItemDialogs((prev) => ({
        ...prev,
        itemDelete: false,
        itemUpsert: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const onClickEditItem = (val) => {
    setItemForm({
      id: val.id,
      productid: selected.product.id,
      price: val.price,
      name: val.name,
      displayorder: val.displayorder,
    });

    setItemDialogs((prev) => ({ ...prev, itemUpsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      price: false,
      displayorder: false,
    }));
    setItemDialogs((prev) => ({ ...prev, itemUpsert: false }));
    setItems([{ name: '', price: '' }]);
    setIsSingleItem(true);
  };

  const onFormSubmit = async () => {
    if (!itemForm.name || itemForm.price === '') {
      setErrors((prev) => ({
        ...prev,
        name: !itemForm.name ? true : false,
        price: itemForm.price === '' ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...itemForm };
      const res = await itemApi.upsert(body);
      if (res.data.data) {
        setItemDialogs((prev) => ({ ...prev, itemUpsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getItemList({ method: itemForm.id ? 'update' : 'create' });
    }
  };

  const moveItem = async (dragIndex, hoverIndex) => {
    setItemList((prevProducts) =>
      update(prevProducts, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevProducts[dragIndex]],
        ],
      })
    );
  };

  const dropItem = async (index, item) => {
    if (index === itemList.length - 1) {
      item.displayorder = Math.round(itemList[index - 1].displayorder + 1024);
    } else if (index === 0) {
      item.displayorder = Math.round(itemList[1].displayorder / 2);
    } else {
      item.displayorder = Math.round(
        (itemList[index - 1].displayorder + itemList[index + 1].displayorder) /
          2
      );
    }
    try {
      setLoading((prev) => ({ ...prev, itemLoading: true }));
      await itemApi.upsert(item);
      getItemList();
    } catch (error) {}
  };

  const onFormSubmitMultiple = async () => {
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      for (const [index, item] of items.entries()) {
        if (!item.name || item.price === '') {
          setErrors((prev) => ({
            ...prev,
            name: !item.name ? true : false,
            price: item.price === '' ? true : false,
          }));
          return;
        } else {
          setErrors((prev) => ({ ...prev, name: false }));
        }
      }

      for (const [index, item] of items.entries()) {
        const body = {
          name: item.name,
          productid: selected.product.id,
          price: item.price,
          displayorder: 1024 * (index + 1),
        };
        await itemApi.upsert(body);
      }
      setIsSingleItem(true);
      setItemDialogs((prev) => ({ ...prev, itemUpsert: false }));
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      getItemList();
    } catch (error) {}
  };

  useEffect(() => {
    getItemList();
  }, [selected.product.id]);
  return (
    <>
      {loading.itemLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <div className="mt-2 flex flex-col">
          <div>
            <h1 className="my-2  mx-4 text-xl font-semibold text-slate-900 dark:text-slate-200">
              Main Option
            </h1>
          </div>
          <div className="-my-2 -mx-4 overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 px-4">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                <thead>
                  <tr>
                    {headers.map((head, headIdx) => (
                      <th
                        key={headIdx}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-slate-200 ${
                          headIdx === 0 ? 'sm:pl-6' : ''
                        } `}
                      >
                        {head}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-right text-sm font-semibold text-zinc-900 dark:text-slate-200"
                    >
                      <Button
                        onClick={() => onClickAddItem()}
                        type="button"
                        className="inline-flex max-h-7 self-center items-center gap-x-1.5 rounded-md bg-blue-600 dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        <PlusIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        Item
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-slate-500 dark:divide-slate-500">
                  {itemList.map((item, i) => (
                    <ItemCard
                      key={item.id}
                      index={i}
                      id={item.id}
                      onClickEditItem={onClickEditItem}
                      productItem={item}
                      moveItem={moveItem}
                      dropItem={dropItem}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <ItemUpsertModal
        show={itemDialogs.itemUpsert}
        loading={loading.upsertLoading}
        itemForm={itemForm}
        errors={errors}
        onClickDeleteItem={onClickDeleteItem}
        onCloseUpsert={onCloseUpsert}
        onFormChange={onFormChange}
        onFormSubmit={onFormSubmit}
        setIsSingleItem={setIsSingleItem}
        isSingleItem={isSingleItem}
        items={items}
        itemList={itemList}
        setItems={setItems}
        onFormSubmitMultiple={onFormSubmitMultiple}
      />

      <ModalDelete
        title="Delete Confirmation"
        text={`Are you sure you want to delete "${deletedItem.name}"? All associated data will be permanently removed.`}
        loading={loading.deleteLoading}
        open={itemDialogs.itemDelete}
        onCloseRequest={() =>
          setItemDialogs((prev) => ({ ...prev, itemDelete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedItem}
      />
    </>
  );
}
