//Modules
import { createSlice } from "@reduxjs/toolkit";
import tokenService from "../../utils/tokenService";

const initialState = {
  isDark: tokenService.getTheme() === "dark" ? true : false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDarkMode: (state, { payload }) => {
      state.isDark = payload;
    },
  },
});

export const { setDarkMode } = commonSlice.actions;

export default commonSlice.reducer;
