import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Select } from 'antd';

import { TrashIcon } from '@heroicons/react/24/outline';
import { MinusCircleIcon } from '@heroicons/react/20/solid';
import optionApi from '../../services/optionApi';
import { useSelector } from 'react-redux';
import subscriptionApi from '../../services/subscriptionApi';
import companyApi from '../../services/companyApi';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SubscriptionUpsertModal({
  show,
  onCloseUpsert,
  companyDetail,
  updateCompany,
}) {
  const cancelButtonRef = useRef(null);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [loading, setLoading] = useState({
    initialLoading: true,
    upsert: false,
    create: false,
  });

  const [companyForm, setCompanyForm] = useState({
    id: companyDetail.id,
    logo: companyDetail.logo,
    emaildomain: companyDetail.emaildomain,
    name: companyDetail.name,
    users: companyDetail.companyusers.map((x) => x.userid),
    isactive: companyDetail.isactive,
    ownerphone: companyDetail.ownerphone,
    ownername: companyDetail.ownername,
    subscriptionid: companyDetail.subscriptionid,
    creditcardfeeper: companyDetail.creditcardfeeper,
    creditcardfeerate: companyDetail.creditcardfeerate,
  });

  const [subscriptionForm, setSubscriptionForm] = useState({
    id: '',
    name: '',
    orderfee: undefined,
    monthlyfee: undefined,
    orderfeerate: undefined,
  });

  const [errors, setErrors] = useState({
    name: false,
    orderfee: false,
    monthlyfee: false,
    orderfeerate: false,
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const onFormChange = (val, key) => {
    setSubscriptionForm((prev) => ({ ...prev, [key]: val }));
  };

  const getSubscriptionList = async () => {
    try {
      setLoading({ ...loading, initialLoading: true });
      const query = `?pageSize=100`;
      const list = await subscriptionApi.list(query);
      if (list.data.data.length > 0) {
        setSubscriptionList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading({ ...loading, initialLoading: false });
    }
  };

  const onClickSaveSubscription = async () => {
    try {
      setLoading((prev) => ({ ...prev, upsert: true }));

      const formData = new FormData();

      for (const key of Object.keys(companyForm)) {
        if (key === 'users') {
          companyForm.users.forEach((element) => {
            formData.append('users', element);
          });
        } else {
          formData.append(key, companyForm[key]);
        }
      }
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await companyApi.upsert(formData, config);
      if (res.data.data) {
        onCloseUpsert(true);
      }
    } catch (error) {
    } finally {
      updateCompany();
      setLoading((prev) => ({ ...prev, upsert: false }));
    }
  };

  const onClickCreate = async () => {
    if (
      !subscriptionForm.name ||
      !subscriptionForm.orderfee ||
      !subscriptionForm.monthlyfee ||
      !subscriptionForm.orderfeerate
    ) {
      setErrors((prev) => ({
        ...prev,
        name: !subscriptionForm.name ? true : false,
        orderfee: !subscriptionForm.orderfee ? true : false,
        monthlyfee: !subscriptionForm.monthlyfee ? true : false,
        orderfeerate: !subscriptionForm.orderfeerate ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: false,
        orderfee: false,
        monthlyfee: false,
        orderfeerate: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, create: true }));
      const res = await subscriptionApi.upsert(subscriptionForm);

      if (res.data.data) {
        const formData = new FormData();

        formData.append('subscriptionid', res.data.data.id);

        for (const key of Object.keys(companyForm)) {
          if (key === 'users') {
            companyForm.users.forEach((element) => {
              formData.append('users', element);
            });
          } else {
            formData.append(key, companyForm[key]);
          }
        }

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };

        const response = await companyApi.upsert(formData, config);

        if (response.data.data) {
          onCloseUpsert(true);
        }
      }
    } catch (error) {
    } finally {
      updateCompany();
      setLoading((prev) => ({ ...prev, create: false }));
    }
  };

  const onClickCancel = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      orderfee: false,
      monthlyfee: false,
      orderfeerate: false,
    }));
    onCloseUpsert(false);
  };

  useEffect(() => {
    getSubscriptionList();
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div className="border-b-2 pb-5 border-gray-300">
                      <h1 className="font-semibold pb-5">
                        You can select existing subscriptions
                      </h1>
                      {show && (
                        <div>
                          <label
                            htmlFor="tax"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Subscriptions
                          </label>
                          <div>
                            {!loading.initialLoading && (
                              <Select
                                size="large"
                                style={{
                                  width: '100%',
                                }}
                                placeholder="Please select Option Groups"
                                defaultValue={companyForm.subscriptionid}
                                onChange={(val) =>
                                  setCompanyForm({
                                    ...companyForm,
                                    subscriptionid: val,
                                  })
                                }
                                options={subscriptionList?.map((x) => ({
                                  label: x.name,
                                  value: x.id,
                                }))}
                              />
                            )}
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-4 sm:gap-3">
                            <Button
                              type="ghost"
                              disabled={loading.upsert}
                              loading={loading.upsert}
                              className={classNames(
                                'col-span-1 col-start-4',
                                'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                              )}
                              onClick={() => onClickSaveSubscription()}
                            >
                              Save Subscription
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <h1 className="font-semibold pb-5">
                        Or you can create a new subscription
                      </h1>
                      <div className="pb-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Name*
                        </label>
                        <div className="mt-1">
                          <input
                            value={subscriptionForm.name}
                            onChange={(e) =>
                              onFormChange(e.target.value, 'name')
                            }
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Starter Subscription"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.name && (
                              <p className="text-red-700">
                                Name can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-2">
                        <div>
                          <label
                            htmlFor="monthlyfee"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Per Order Charge*
                          </label>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">
                                $
                              </span>
                            </div>
                            <input
                              value={subscriptionForm.orderfee}
                              onChange={(e) =>
                                onFormChange(e.target.value, 'orderfee')
                              }
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                              placeholder="0.00"
                              aria-describedby="price-currency"
                              type="number"
                              name="orderfee"
                              id="orderfee"
                            />
                            {errors.orderfee && (
                              <div className="h-2 mt-1  text-xs">
                                <p className="text-red-700">
                                  Per order charge can not be empty
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="monthlyfee"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Monthly Fee*
                          </label>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">
                                $
                              </span>
                            </div>
                            <input
                              value={subscriptionForm.monthlyfee}
                              onChange={(e) =>
                                onFormChange(e.target.value, 'monthlyfee')
                              }
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                              placeholder="0.00"
                              aria-describedby="price-currency"
                              type="number"
                              name="monthlyfee"
                              id="monthlyfee"
                            />
                            {errors.orderfee && (
                              <div className="h-2 mt-1  text-xs">
                                <p className="text-red-700">
                                  Monthly Fee can not be empty
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="monthlyfee"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Order Fee Rate*
                          </label>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">
                                %
                              </span>
                            </div>
                            <input
                              value={subscriptionForm.orderfeerate}
                              onChange={(e) =>
                                onFormChange(e.target.value, 'orderfeerate')
                              }
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                              placeholder="0.00"
                              aria-describedby="price-currency"
                              type="number"
                              name="orderfeerate"
                              id="orderfeerate"
                            />
                            {errors.orderfee && (
                              <div className="h-2 mt-1  text-xs">
                                <p className="text-red-700">
                                  Order Fee Rate can not be empty
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 grid-flow-row-dense grid-cols-12 gap-3">
                      <Button
                        type="ghost"
                        disabled={loading.create}
                        className={classNames(
                          'col-span-6',
                          'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                        )}
                        onClick={() => onClickCancel()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.create}
                        loading={loading.create}
                        className={classNames(
                          'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => {
                          onClickCreate();
                        }}
                      >
                        Create
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
