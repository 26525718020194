import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Spin, Button, Select } from 'antd';
import { Link } from 'react-router-dom';
import customerApi from '../../services/customerApi';
import Notification from '../../components/notification/notification';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useTransition, animated, useSpringRef } from '@react-spring/web';
import NoData2 from '../../assets/no-data-2.svg';
import { formatPhoneNumber } from '../../utils/baseUtils';
dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Customers = () => {
  const { roles } = useSelector((state) => state.authReducer);
  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState({
    fullname: '',
    email: '',
    phone: '',
    isdeleted: 'false',
  });
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState({
    listLoading: true,
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const getCustomerList = async (searchQuery) => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setCustomerList([]);

    let query = `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}&${searchQuery}`;

    try {
      const customer = await customerApi.list(query);
      if (customer.data.data) {
        setCustomerList(customer.data.data);
        setPagination((prev) => ({
          ...prev,
          totalRecords: customer.data.totalRecords,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  useEffect(() => {
    transRef.start();
  }, [showFilters]);

  const transRef = useSpringRef();
  const transitions = useTransition(showFilters, {
    ref: transRef,
    keys: null,
    from: { maxHeight: '0px', opacity: 0 },
    enter: { maxHeight: '1000px', opacity: 1, overflowY: 'none' },
    leave: { maxHeight: '0px', opacity: 0 },
  });

  const searchQuery = () => {
    const query = Object.keys(search)
      .map((key) => {
        return `${key}=${search[key]}`;
      })
      .filter(Boolean)
      .join('&');

    return query;
  };

  const onSearchChange = (val, key) => {
    setSearch((prev) => ({ ...prev, [key]: val }));
  };

  const onClickClearSearch = () => {
    setSearch({
      search: '',
      fullname: '',
      email: '',
      phone: '',
      isdeleted: 'false',
    });
    getCustomerList();
  };

  const onClickSearch = async () => {
    const query = searchQuery();
    await getCustomerList(query);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  };

  useEffect(() => {
    getCustomerList();
  }, [pagination.pageNumber, pagination.pageSize]);

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Customers
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                You can see all your customers here.
              </p>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col-reverse mt-5 sm:mt-0 gap-2 justify-end">
            <Button
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              type="ghost"
              className={classNames(
                'inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
              )}
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Filters
            </Button>
          </div>
          {transitions(
            (style, item) =>
              item && (
                <animated.div style={{ ...style }}>
                  <div className="sm:mt-0 mt-2">
                    <div className=" justify-between pb-2 grid grid-cols-6 gap-4">
                      <div className="col-span-3 sm:col-span-2 w-full ">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Full Name
                        </label>
                        <div className="mt-1">
                          <input
                            value={search.fullname}
                            onChange={(e) =>
                              onSearchChange(e.target.value, 'fullname')
                            }
                            type="text"
                            name="fullname"
                            id="fullname"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-2 w-full ">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          E-mail
                        </label>
                        <div className="mt-1">
                          <input
                            value={search.email}
                            onChange={(e) =>
                              onSearchChange(e.target.value, 'email')
                            }
                            type="text"
                            name="email"
                            id="email"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="E-mail"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-2 w-full ">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Phone
                        </label>
                        <div className="mt-1">
                          <input
                            value={search.phone}
                            onChange={(e) =>
                              onSearchChange(e.target.value, 'phone')
                            }
                            type="text"
                            name="phone"
                            id="phone"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Phone"
                          />
                        </div>
                      </div>
                      {roles.includes(100) && (
                        <div className="col-span-3 sm:col-span-2  w-full ">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Show Deleted
                          </label>

                          <div className="mt-1">
                            <Select
                              size="large"
                              style={{
                                width: '100%',
                              }}
                              placeholder="Show Deleted"
                              value={search.isdeleted}
                              onChange={(e) => {
                                setSearch((prev) => ({
                                  ...prev,
                                  isdeleted: e,
                                }));
                              }}
                            >
                              <Select.Option value={'false'}>No</Select.Option>
                              <Select.Option value={'true'}>Yes</Select.Option>
                            </Select>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="justify-end pb-2 grid grid-cols-3 gap-4 ">
                      <div className="flex sm:col-start-3 items-center col-start-2 gap-x-5 ">
                        <Button
                          onClick={() => {
                            onClickClearSearch();
                          }}
                          type="ghost"
                          className={classNames(
                            'col-span-6',
                            'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                          )}
                        >
                          <TrashIcon className="text-gray-700 w-5 h-5 mr-2" />
                          Clear
                        </Button>
                        <Button
                          type="ghost"
                          onClick={() => {
                            onClickSearch();
                          }}
                          className={classNames(
                            'col-span-6',
                            'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                          )}
                        >
                          <MagnifyingGlassIcon className="text-white w-5 h-5 mr-2" />
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )
          )}
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              {customerList.length > 0 ? (
                <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Phone
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            E-mail
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Orders
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                        {customerList.map((customer) => (
                          <tr key={customer.id}>
                            <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                              {customer.fullname}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {formatPhoneNumber(customer.phone) || '-'}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {customer.email || '-'}
                            </td>
                            {customer.orders.length > 0 ? (
                              <td className="cursor-pointer whitespace-nowrap py-4 px-3 text-sm text-blue-700 dark:text-slate-200">
                                <Link
                                  to={`${customer.id}/orders`}
                                  className="text-blue-600 dark:text-sky-400"
                                >
                                  Orders ({customer.orders.length})
                                </Link>
                              </td>
                            ) : (
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                -
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="w-full justify-between flex mt-4">
                      <div>
                        <p className="text-sm text-gray-700 dark:text-slate-200">
                          Showing{' '}
                          <span className="font-medium">
                            {pagination.pageNumber}
                          </span>{' '}
                          to{' '}
                          <span className="font-medium">
                            {pagination.pageSize}
                          </span>{' '}
                          of{' '}
                          <span className="font-medium">
                            {pagination.totalRecords}
                          </span>{' '}
                          results
                        </p>
                      </div>
                      <Pagination
                        showSizeChanger
                        onChange={(page) =>
                          setPagination((prev) => ({
                            ...prev,
                            pageNumber: page,
                          }))
                        }
                        onShowSizeChange={(current, pageSize) => {
                          setPagination((prev) => ({
                            ...prev,
                            pageNumber: current,
                            pageSize,
                          }));
                        }}
                        pageSize={pagination.pageSize}
                        current={pagination.pageNumber}
                        total={pagination.totalRecords}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid justify-center pt-20">
                  <img src={NoData2} className="h-24 pb-2" alt="" />
                  <p className=" text-slate-400 font-light text-center">
                    No Customers
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default Customers;
