import api from './api';

const resource = 'Company/';

export const list = async (query = '') => {
  return await api.get(resource + 'List' + query);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body, config) => {
  return await api.post(resource + 'Upsert', body, config);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const deleteLogoById = async (companyid) => {
  return await api.delete(resource + 'DeleteLogo?companyId=' + companyid);
};

export const signAsCompanyById = async (id) => {
  return await api.get(resource + 'LoginAsCompany?id=' + id);
};

export const connectStripeAccount = async (id) => {
  return await api.post(resource + 'ConnectStripeAccount?companyid=' + id);
};

export const disconnectStripeAccount = async (id) => {
  return await api.post(resource + 'DisconnectStripeAccount?companyid=' + id);
};

const companyApi = {
  list,
  getById,
  upsert,
  deleteById,
  signAsCompanyById,
  deleteLogoById,
  connectStripeAccount,
  disconnectStripeAccount,
};

export default companyApi;
