export const formatCurrency = (value) => {
  const options = {
    style: 'currency',
    currency: 'USD',
  };

  return Math.abs(value) < 0.01
    ? (0).toLocaleString('en-US', options)
    : value.toLocaleString('en-US', options);
};

export const formatPhoneNumber = (phone) => {
  if (phone) {
    if (phone.includes('+1')) {
      return `(${phone.slice(2, 5)}) ${phone.slice(5, 8)} ${phone.slice(8)}`;
    }

    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} ${phone.slice(6)}`;
  }
};
