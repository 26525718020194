import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import moment from 'moment';
import orderApi from '../../services/orderApi';
import { PaymentStatus } from '../../data/enums';
import LogoBlack from '../../assets/logo-black.png';
import LogoWhite from '../../assets/logo-white-brand.svg';
import { formatPhoneNumber } from '../../utils/baseUtils';
import NoImageCircle from '../../assets/no-product-image.svg';
import {
  PhoneIcon,
  HomeIcon,
  UserIcon,
  EnvelopeIcon,
} from '@heroicons/react/20/solid';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { Dialog, Transition } from '@headlessui/react';
import tokenService from '../../utils/tokenService';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Receipt = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    tokenService.getTheme() === 'dark' ? true : false
  );
  const navigate = useNavigate();
  const params = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState({
    listLoading: true,
  });
  const cancelButtonRef = useRef(null);
  const paymentStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-red-100 text-red-800';
      case 20:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getOrderDetail = async () => {
    setLoading({ listLoading: true });
    const res = await orderApi.getInvoiceById(params.id);
    if (res.data.data) {
      setOrderDetail(res.data.data);
    }
    setLoading({ listLoading: false });
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <div className="flex h-screen flex-col relative  py-12 px-6 lg:px-8 bg-white dark:bg-slate-900   mx-auto sm:max-w-[50%]">
      {loading.listLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <div className="space-y-10">
          <div className=" flex items-center justify-between">
            <div>
              <Link
                to={`${process.env.REACT_APP_ORDER_URL}/${orderDetail.store?.slug}`}
                className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500"
              >
                <img
                  className="h-8 w-auto  block"
                  src={LogoBlack}
                  alt="Your Company"
                />
              </Link>
            </div>
            <div className="cursor-pointer">
              <span className="font-bold">
                <Link
                  to={`${process.env.REACT_APP_ORDER_URL}/${orderDetail.store?.slug}/login`}
                >
                  Login
                </Link>
              </span>
            </div>
          </div>
          <div className=" md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                {orderDetail.store?.storelogo ? (
                  <img
                    className="h-20 w-20 rounded-full"
                    src={orderDetail.store.storelogo}
                    alt=""
                  />
                ) : (
                  <span className="hidden h-16 w-16 items-center justify-center rounded-full bg-gray-500 sm:inline-flex">
                    <span className="text-xs font-medium leading-none text-white">
                      {orderDetail.store.name
                        ? orderDetail.store.name.split(' ').map((x) => x[0])
                        : ''}
                    </span>
                  </span>
                )}
                <div>
                  <div className="ml-3">
                    <div className="flex items-center">
                      <h1 className="ml-0 text-2xl font-bold leading-7 flex space-x-3 text-gray-900 sm:truncate sm:leading-9 dark:text-slate-200">
                        <span>{orderDetail.store.name}</span>
                      </h1>
                    </div>
                    <dl className="flex flex-col sm:mt-1 ">
                      <dd className="flex items-center text-sm font-medium capitalize space-x-2 text-gray-500 dark:text-slate-400">
                        {!orderDetail.store.address &&
                        !orderDetail.store.address ? null : (
                          <HomeIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                        <span>
                          {orderDetail.store.address || null}{' '}
                          {orderDetail.store.apartment &&
                          orderDetail.store.apartment !== 'null'
                            ? orderDetail.store.apartment
                            : ''}
                          {'  '}
                          {orderDetail.store.city}
                          {'  '}
                          {orderDetail.store.state}
                          {'  '}
                          {orderDetail.store.postalcode}
                        </span>
                      </dd>
                    </dl>
                    <dl className="flex flex-col  sm:mt-1 ">
                      <dd className="flex items-center text-sm font-medium capitalize space-x-2 text-gray-500 dark:text-slate-400">
                        {!orderDetail.store.phone &&
                        !orderDetail.store.phone ? null : (
                          <PhoneIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                        <span>{orderDetail?.store?.phone}</span>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4">
            <div className="sm:col-span-3 col-span-6 overflow-hidden shadow sm:rounded-lg bg-[#f9fafb] dark:bg-slate-700">
              <div className="flex justify-between px-4 py-2 sm:px-6 dark:bg-slate-700">
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Status
                </h3>
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Receipt Number
                </h3>
              </div>
              <div className="border-t border-gray-200 dark:border-slate-700 px-4 py-2 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
                  <div className="py-2 flex justify-between sm:grid sm:grid-cols-2  sm:gap-4 sm:py-2 sm:px-6 dark:bg-slate-700">
                    <div>
                      <dt className="font-bold text-2xl text-gray-900 dark:text-slate-200">
                        {orderDetail.ordertype === 10 ? 'Dine-In' : 'To Go'}
                      </dt>
                      <dd className="mt-1  font-bold text-xl text-gray-900 col-span-1  sm:col-span-1 sm:mt-0 dark:text-slate-200">
                        {orderDetail.table
                          ? `(Table ${orderDetail.table})`
                          : ''}
                      </dd>
                    </div>
                    <div>
                      <dd className="mt-1 font-bold text-4xl text-gray-900 text-end sm:col-span-1 sm:mt-0 dark:text-slate-200">
                        {orderDetail.ordernumberstr || ''}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <div className="sm:col-span-3 col-span-6 overflow-hidden shadow sm:rounded-lg bg-[#f9fafb] dark:bg-slate-700">
              <div className="px-4 py-2 sm:px-6">
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Order Details
                </h3>
              </div>
              <div className="border-t border-gray-200 dark:border-slate-700 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
                  <div className="py-2 flex justify-between sm:grid sm:grid-cols-2 sm:gap-4 sm:py-2 sm:px-6 dark:bg-slate-700">
                    <div>
                      <dt className=" text-lg text-gray-900 dark:text-slate-200">
                        {`Order#${orderDetail.orderid}`}
                      </dt>
                      <dd className="mt-1  text-lg text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-200">
                        {moment(orderDetail.createdate)
                          .utcOffset(orderDetail?.timezone * 60)
                          .format('MM/DD/YYYY hh:mm A') || ''}
                      </dd>
                    </div>
                    <div className="flex justify-self-center">
                      <dd className="mt-1 self-center font-bold text-6xl text-gray-900 text-end sm:col-span-1 sm:mt-0">
                        <span
                          className={classNames(
                            paymentStatusColor(orderDetail.paymentstatus),
                            'flex items-center rounded-full px-3 py-0.5 text-xl font-medium'
                          )}
                        >
                          {PaymentStatus[orderDetail.paymentstatus]}
                        </span>
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <div className="sm:col-span-6 col-span-6 overflow-hidden shadow sm:rounded-lg bg-[#f9fafb] dark:bg-slate-700">
              <div className="flex justify-between px-4 py-2 sm:px-6 dark:bg-slate-700">
                <h3 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-400">
                  Customer
                </h3>
              </div>
              <div className="border-t border-gray-200 dark:border-slate-700 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:divide-slate-500">
                  <div className="py-2 sm:grid sm:grid-cols-1 sm:gap-4 sm:py-5 sm:px-6">
                    <div
                      className={classNames(
                        orderDetail.email || orderDetail.customer?.email
                          ? 'grid lg:flex'
                          : 'flex',
                        'gap-x-6'
                      )}
                    >
                      <dt className="flex items-center font-bold text-lg text-gray-900 dark:text-slate-200">
                        <UserIcon className="h-8 w-8 pr-2 text-gray-400" />
                        {orderDetail.name ||
                          orderDetail.customer?.fullname ||
                          '-'}
                      </dt>
                      <dt className="flex items-center font-bold text-lg text-gray-900 dark:text-slate-200">
                        <PhoneIcon className="h-8 w-8 pr-2 text-gray-400" />
                        {formatPhoneNumber(
                          orderDetail?.phone || orderDetail?.customer?.phone
                        )}
                      </dt>
                      {orderDetail.email ||
                        (orderDetail.customer?.email && (
                          <dt className="flex items-center font-bold text-lg text-gray-900">
                            <EnvelopeIcon className="h-8 w-8 pr-2 text-gray-400" />
                            {orderDetail.email || orderDetail.customer?.email}
                          </dt>
                        ))}
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            {orderDetail.orderitems.map((item, i) => (
              <div
                key={i}
                className={classNames(
                  'text-gray-600 dark:text-slate-300',
                  'flex items-center col-span-6 '
                )}
              >
                <div className="px-6 hidden sm:block">
                  <img
                    className=" h-16 w-16 rounded-full object-cover"
                    src={
                      item.item.product?.imagepath
                        ? item.item.product?.imagepath
                        : NoImageCircle
                    }
                    alt=""
                  />
                </div>
                <div className="px-2 font-bold text-black dark:text-slate-200 text-sm mb-auto pt-2">
                  {`${item.quantity} x`}
                </div>

                <div
                  key={item.name}
                  className={classNames(
                    'block items-start px-3 pt-2 text-sm font-medium flex-1 mb-auto'
                  )}
                >
                  <div>
                    <div className="flex justify-between">
                      <h1 className="font-bold text-black dark:text-slate-200">
                        {item.item.product?.name}
                      </h1>
                    </div>
                    <div className="mb-auto">
                      {item.item.product?.name !== item.name && (
                        <div className="px-3 gap-x-5 flex">
                          <p className="truncate">{item.name} </p>
                          <p className="truncate font-bold">
                            {(item.price + orderDetail.itemfee).toLocaleString(
                              'en-US',
                              {
                                style: 'currency',
                                currency: 'USD',
                              }
                            )}
                          </p>
                        </div>
                      )}
                      {item.orderitemoptions.map((option, i) => (
                        <div
                          key={i}
                          className={classNames(
                            'block items-center  px-3 text-sm font-medium'
                          )}
                        >
                          <div className="flex gap-x-5">
                            <p className="truncate">{option.name}</p>
                            {option.price > 0 && (
                              <p className="truncate font-bold">
                                {option.price.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <p className=" font-light italic">{item.note || ''}</p>
                  </div>
                </div>

                <div className="px-6 mb-auto pt-2">
                  <p className="truncate font-bold text-black dark:text-slate-200 text-sm">
                    {(
                      item.quantity *
                      (item.price +
                        orderDetail.itemfee +
                        item.orderitemoptions.reduce((total, currentOption) => {
                          return total + currentOption.price;
                        }, 0))
                    ).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </p>
                </div>
              </div>
            ))}
            <div className="sm:col-span-6 col-span-6 overflow-hidden border-t pb-2" />
            {orderDetail.note && (
              <div className="sm:col-span-4 col-span-6 overflow-hidden">
                <div className="pb-5 px-4">
                  <h1 className="text-base font-semibold leading-2 text-gray-500 dark:text-slate-200">
                    Order Note
                  </h1>
                </div>
                <div className="border border-dashed border-black min-h-[3.5rem]   bg-white  sm:rounded-lg">
                  <p className="p-4 italic text-sm">{orderDetail.note || ''}</p>
                </div>
              </div>
            )}
            <div
              className={classNames(
                !orderDetail.note
                  ? ' lg:col-span-3 lg:col-start-4 xl:col-span-2 xl:col-start-5 '
                  : '',
                'lg:mx-2 min-w-[180px] mx-0 grid lg:col-span-2 col-span-6 overflow-hidden shadow rounded-lg bg-[#f9fafb] dark:bg-slate-700'
              )}
            >
              <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4  sm:px-4 ">
                <dt className="text-sm  font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                  Subtotal
                </dt>
                <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                  {orderDetail.subtotal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </dd>
              </div>
              <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
                {orderDetail.fee !== 0 || orderDetail.crvtax ? (
                  <dt
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-sm font-medium text-gray-500 sm:col-span-2 flex items-center cursor-pointer dark:text-slate-200"
                  >
                    Tax {orderDetail.fee !== 0 && '& Fees'}{' '}
                    <Icon
                      className="text-sm ml-1"
                      component={InfoCircleOutlined}
                    />
                  </dt>
                ) : (
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Tax
                  </dt>
                )}
                {orderDetail.fee !== 0 || orderDetail.crvtax ? (
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {(
                      orderDetail.tax +
                      orderDetail.fee +
                      orderDetail.crvtax
                    ).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || ''}
                  </dd>
                ) : (
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {orderDetail.tax.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || ''}
                  </dd>
                )}
              </div>
              {orderDetail.tip !== 0 && (
                <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4  sm:px-4">
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Tip
                  </dt>
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {orderDetail.tip.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </dd>
                </div>
              )}
              {orderDetail.discount !== 0 && (
                <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4   sm:px-4 ">
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Discount ({orderDetail.discountcode})
                  </dt>
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    -{' '}
                    {orderDetail.discount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </dd>
                </div>
              )}
              {orderDetail.refund !== 0 && (
                <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4  sm:px-4">
                  <dt className="text-sm font-medium text-gray-500 sm:col-span-2 dark:text-slate-200">
                    Refund
                  </dt>
                  <dd className="mt-1 text-sm text-end text-gray-900 sm:col-span-1 sm:mt-0 dark:text-slate-300">
                    {'- ' +
                      orderDetail.refund.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                  </dd>
                </div>
              )}
              <div className="p-2 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-4 border-t dark:border-slate-600">
                <dt className="text-sm font-bold text-gray-500 sm:col-span-2 dark:text-slate-200">
                  Total Amount
                </dt>
                <dd className="mt-1 text-sm text-end text-gray-900 font-bold sm:col-span-1 sm:mt-0 dark:text-slate-300">
                  {(orderDetail.refund
                    ? orderDetail.total - orderDetail.refund
                    : orderDetail.total
                  ).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </dd>
              </div>
            </div>
          </div>
          <div className=" flex items-center justify-center gap-4">
            <div>
              <button
                type="button"
                onClick={() =>
                  window.location.replace(
                    `${process.env.REACT_APP_ORDER_URL}/${orderDetail.store?.slug}`
                  )
                }
                className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Order Again
              </button>
            </div>
            <div className="cursor-pointer">
              <button
                onClick={() =>
                  window.location.replace(
                    `${process.env.REACT_APP_ORDER_URL}/${orderDetail.store?.slug}/login`
                  )
                }
                type="button"
                className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                My Account
              </button>
            </div>
          </div>
          <div className=" items-center text-center pb-4">
            <span className="pt-4 font-bold text-3xl pb-4 dark:text-slate-200">
              Thank You
            </span>
            <div className="flex justify-center pb-1 pt-4">
              <Link to="https://thisway.co">
                <span className="text-[10px] dark:text-slate-200">
                  Powered by{' '}
                </span>
                <img
                  className="h-4 w-auto pl-[4px]"
                  src={isDarkMode ? LogoWhite : LogoBlack}
                  alt="Company Logo"
                />
              </Link>
            </div>
          </div>
          <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-20"
              initialFocus={cancelButtonRef}
              onClose={() => {
                setIsOpen(!isOpen);
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div
                        ref={cancelButtonRef}
                        className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
                      >
                        <div className="sm:flex sm:items-start">
                          <div className="space-y-4 w-full text-start">
                            <h1 className="font-bold text-xl">
                              Sales Tax & Fees
                            </h1>
                            <div className="">
                              <p className="font-medium text-lg mb-1">
                                Sales Tax{' '}
                                {orderDetail.store.customerfeeisrate &&
                                  '(' + orderDetail.store.tax + '%)'}
                                : ${orderDetail.tax.toFixed(2)}
                              </p>
                              <p className="text-gray-500 text-sm mb-1">
                                Sales tax is added to your order as required by
                                local regulations.
                              </p>
                              {orderDetail.crvtax !== 0 && (
                                <p className="font-medium text-lg">
                                  CRV Tax: ${orderDetail.crvtax.toFixed(2)}
                                </p>
                              )}
                              {orderDetail.crvtax !== 0 && (
                                <p className="text-gray-500 text-sm mb-1">
                                  The CRV fee, included in the price of
                                  beverages in California
                                </p>
                              )}
                              <p className="font-medium text-lg">
                                Fee: ${orderDetail.fee.toFixed(2)}
                              </p>
                              <p className="text-gray-500 text-sm mb-1">
                                A small Service Charge is applied to each order.
                                This fee helps us invest in developing and
                                maintaining our platform, providing you with a
                                user-friendly ordering experience.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <Button
                          type="ghost"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4  text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent  focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          Ok
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
};

export default Receipt;
