import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Button, Pagination, Spin, Select } from 'antd';
import moment from 'moment';
import ModalDelete from '../../../../components/modal-delete/modal-delete';
import subscriptionApi from '../../../../services/subscriptionApi';
import financeApi from '../../../../services/financeApi';
import { formatCurrency } from '../../../../utils/baseUtils';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AccountActivity = ({ companyDetail, notf, setNotf }) => {
  const { id } = useParams();
  const [filter, setFilter] = useState({
    storeid: null,
    dateRange: null,
    month: null,
  });
  const [financeList, setFinanceList] = useState([]);
  const [financeTypeList, setFinanceTypeList] = useState([]);
  const [balance, setBalance] = useState(0);
  const [storeList, setStoreList] = useState([]);
  const [deletedSubscription, setDeletedSubscription] = useState({});
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [form, setForm] = useState({
    companyid: id,
    financetypeid: '',
    storeid: '',
    description: '',
    selectedMonth: '',
    selectedYear: '',
    selectedWeek: '',
    code: '',
    amount: 0,
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
  });

  const [errors, setErrors] = useState({
    financetypeid: false,
    amount: false,
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });

  const cancelButtonRef = useRef(null);

  const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];

  const getFinanceList = async () => {
    try {
      const query = `?companyid=${id}&pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`;

      const list = await financeApi.list(query);
      if (list.data.data.length > 0) {
        setFinanceList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      } else {
        setFinanceList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickAdd = () => {
    setForm({
      companyid: id,
      financetypeid:
        financeTypeList.find((x) => x.name === 'Payment')?.id || '',
      description: '',
      code: '',
      selectedYear: moment().year(),
      selectedMonth: moment().month() + 1,
      selectedWeek: moment().isoWeek(),
      amount: 0,
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    if (!form.financetypeid || !form.amount) {
      setErrors((prev) => ({
        ...prev,
        financetypeid: !form.financetypeid ? true : false,
        amount: !form.amount ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        financetypeid: false,
        amount: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      body.code = createCode(body.financetypeid);

      await financeApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      await getFinanceList();
      await getBalance();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };
  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      financetypeid: false,
      amount: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onClickDelete = (sub) => {
    setDeletedSubscription(sub);
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await subscriptionApi.deleteById(deletedSubscription.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false, upsert: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const getFinanceTypeList = async () => {
    try {
      setLoading((prev) => ({ ...prev, listLoading: true }));
      const list = await financeApi.getTypeList();
      setFinanceTypeList(list.data.data);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getBalance = async () => {
    try {
      const list = await financeApi.getBalanceById(id);
      setBalance(list.data.data);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getWeekOptions = () => {
    const options = [];
    for (let i = 1; i <= 52; i++) {
      options.push({
        label: `Week ${i}`,
        value: i,
      });
    }
    return options;
  };

  const getBackAndNextYear = () => {
    const options = [];
    for (let i = -1; i <= 1; i++) {
      options.push({
        label: `${moment().year() + i}`,
        value: moment().year() + i,
      });
    }
    return options;
  };

  const createCode = (financetypeid) => {
    if (
      financetypeid ===
      financeTypeList.find((x) => x.name === 'Subscription Fee').id
    ) {
      return `${form.selectedYear}:${form.selectedMonth}`;
    } else if (
      financetypeid === financeTypeList.find((x) => x.name === 'Invoice').id
    ) {
      return `${form.selectedYear}:${form.selectedWeek}`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    getFinanceTypeList();
    getBalance();
    getFinanceList();
  }, [pagination.pageNumber]);

  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-6 sm:gap-x-8">
      <div>
        {loading.listLoading ? (
          <div className="w-full flex justify-center py-4">
            <Spin />
          </div>
        ) : (
          <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
            <div className="">
              <div className="sm:flex sm:items-end space-y-1 space-x-4">
                <div className="sm:flex-auto ">
                  <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                    {companyDetail.name}
                  </h1>
                </div>
                <div className="min-w-[15%]">
                  <Select
                    allowClear
                    size="large"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Months"
                    onChange={(e) => {
                      setFilter((prev) => ({ ...prev, month: e }));
                    }}
                    options={months}
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none grid">
                  <Button
                    type="button"
                    className="block rounded-md bg-blue-600 h-10  px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => onClickAdd()}
                  >
                    New
                  </Button>
                </div>
                <div className="flex items-center min-w-[25%] justify-between overflow-hidden rounded-lg bg-white px-4 py-1 shadow sm:px-4 sm:py-4 dark:bg-slate-700">
                  <dt className="truncate text-sm font-medium text-gray-500 dark:text-slate-200">
                    Balance
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-slate-200">
                    {formatCurrency(balance)}
                  </dd>
                </div>
              </div>

              <div className="mt-2 flow-root">
                <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Explanation
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          ></th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Create Date
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                        {financeList.map((finance) => (
                          <tr key={finance.id}>
                            <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                              {finance.financetype.name}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {finance.details.length > 0 ? (
                                <Link
                                  to={`/dashboard/companies/${id}/orders?financeid=${finance.id}`}
                                  className="text-blue-600 dark:text-sky-400"
                                >
                                  {finance.description}
                                </Link>
                              ) : (
                                finance.description
                              )}
                            </td>
                            <td className="whitespace-nowrap font-bold py-4 pl-6 pr-3 text-sm text-gray-900 sm:pl-0 dark:text-slate-200">
                              {finance.code}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {moment
                                .utc(finance.createdate)
                                .local()
                                .format('MM/DD/YYYY')}
                            </td>
                            <td className="whitespace-nowrap font-bold py-4 pl-6 pr-3 text-sm text-gray-900 sm:pl-0 dark:text-slate-200">
                              {finance.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="w-full text-end">
                      <Pagination
                        current={pagination.pageNumber}
                        total={pagination.totalRecords}
                        defaultPageSize={pagination.pageSize}
                        onChange={(page) =>
                          setPagination((prev) => ({
                            ...prev,
                            pageNumber: page,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Transition.Root show={dialogs.upsert} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div className="grid grid-cols-2 gap-x-5">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Finance Type*
                        </label>
                        <div className="mt-1">
                          <Select
                            allowClear
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder="Finance Type"
                            onChange={(val) =>
                              onFormChange(val, 'financetypeid')
                            }
                            options={financeTypeList
                              .filter(
                                (financeType) =>
                                  ![20, 30].includes(financeType.code)
                              )
                              .map(({ name, id }) => ({
                                label: name,
                                value: id,
                              }))}
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.name && (
                              <p className="text-red-700">
                                Finance Type can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="monthlyfee"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Amount*
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            value={form.amount}
                            onChange={(e) =>
                              onFormChange(e.target.value, 'amount')
                            }
                            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                            type="number"
                            name="amount"
                            id="amount"
                          />
                        </div>
                        <div className="mt-1">
                          <div className="h-2 mt-1  text-xs">
                            {errors.amount && (
                              <p className="text-red-700">
                                Amount can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="grid grid-cols-2 gap-x-5">
                        {(form.financetypeid ===
                          financeTypeList.find((x) => x.name === 'Invoice')
                            ?.id ||
                          form.financetypeid ===
                            financeTypeList.find(
                              (x) => x.name === 'Subscription Fee'
                            )?.id) && (
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Year*
                            </label>
                            <div className="mt-1">
                              <Select
                                allowClear
                                defaultValue={moment().year()}
                                size="large"
                                style={{
                                  width: '100%',
                                }}
                                placeholder="Year"
                                onChange={(val) =>
                                  onFormChange(val, 'selectedYear')
                                }
                                options={getBackAndNextYear()}
                              />
                              <div className="h-2 mt-1  text-xs">
                                {errors.name && (
                                  <p className="text-red-700">
                                    Year can not be empty
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {form.financetypeid ===
                          financeTypeList.find(
                            (x) => x.name === 'Subscription Fee'
                          )?.id && (
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Month*
                            </label>
                            <div className="mt-1">
                              <Select
                                allowClear
                                size="large"
                                defaultValue={moment().month() + 1}
                                style={{
                                  width: '100%',
                                }}
                                placeholder="Month"
                                onChange={(val) =>
                                  onFormChange(val, 'selectedMonth')
                                }
                                options={months}
                              />
                              <div className="h-2 mt-1  text-xs">
                                {errors.name && (
                                  <p className="text-red-700">
                                    Month can not be empty
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {form.financetypeid ===
                          financeTypeList.find((x) => x.name === 'Invoice')
                            ?.id && (
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Week*
                            </label>
                            <div className="mt-1">
                              <Select
                                allowClear
                                defaultValue={moment().isoWeek()}
                                size="large"
                                style={{
                                  width: '100%',
                                }}
                                placeholder="Month"
                                onChange={(val) =>
                                  onFormChange(val, 'selectedWeek')
                                }
                                options={getWeekOptions()}
                              />
                              <div className="h-2 mt-1  text-xs">
                                {errors.name && (
                                  <p className="text-red-700">
                                    Week can not be empty
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="Description"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          onChange={(e) =>
                            onFormChange(e.target.value, 'description')
                          }
                          value={form.description}
                          rows={3}
                          type="text"
                          name="description"
                          id="description"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="e.g ''Subscription payment for May 2023''"
                        />
                      </div>
                    </div>

                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      {form.id && (
                        <Button
                          type="ghost"
                          disabled={loading.deleteLoading}
                          loading={loading.deleteLoading}
                          onClick={() => onClickDelete(form)}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                        >
                          <TrashIcon className="text-white w-5 h-5" />
                        </Button>
                      )}
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        className={classNames(
                          form.id ? 'sm:col-span-5 col-span-12' : 'col-span-6',
                          'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                        )}
                        onClick={() => onCloseUpsert()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        loading={loading.upsertLoading}
                        className={classNames(
                          form.id ? 'sm:col-span-5 col-span-12' : 'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmit()}
                      >
                        {form.id ? 'Edit Finance' : 'Add Finance'}
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <ModalDelete
          title="Delete Subscription"
          loading={loading.deleteLoading}
          open={dialogs.delete}
          onCloseRequest={() =>
            setDialogs((prev) => ({ ...prev, delete: false }))
          }
          onOk={onConfirmDelete}
          item={deletedSubscription}
        />
      </div>
    </div>
  );
};

export default AccountActivity;
