import api from './api';

const resource = 'City/';

export const list = async (query) => {
  return await api.get(resource + 'List' + query);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

const cityApi = { list, upsert, deleteById };

export default cityApi;
