import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

const WeekSlider = ({
  onWeekChange,
  initialDate,
  weekOfStartMonday = true,
}) => {
  const [currentDate, setCurrentDate] = useState(dayjs(initialDate || dayjs()));
  const today = dayjs();

  const getWeekStart = (date) =>
    weekOfStartMonday
      ? date.startOf('week')
      : date.startOf('week').subtract(1, 'day');
  const getWeekEnd = (date) =>
    weekOfStartMonday
      ? date.endOf('week')
      : date.endOf('week').subtract(1, 'day');

  const currentWeekStart = getWeekStart(today);
  const currentWeekEnd = getWeekEnd(today);

  const startOfWeek = getWeekStart(currentDate);
  const endOfWeek = getWeekEnd(currentDate);

  useEffect(() => {
    onWeekChange(startOfWeek, endOfWeek);
  }, [currentDate]);

  const prevWeek = () => {
    const newDate = currentDate.subtract(1, 'week');
    setCurrentDate(newDate);
    onWeekChange(getWeekStart(newDate), getWeekEnd(newDate));
  };

  const nextWeek = () => {
    const newDate = currentDate.add(1, 'week');
    if (newDate.isSameOrBefore(currentWeekEnd, 'week')) {
      setCurrentDate(newDate);
      onWeekChange(getWeekStart(newDate), getWeekEnd(newDate));
    }
  };

  return (
    <div className="flex items-center justify-between bg-white">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              onClick={prevWeek}
              className="cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            <a className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">
              {startOfWeek.format('MMM D')} - {endOfWeek.format('MMM D')}
            </a>
            <a
              onClick={nextWeek}
              className="cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default WeekSlider;
