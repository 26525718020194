import api from './api';

const resource = 'Dashboard/';

export const getOrderTotal = async (body) => {
  return await api.post(resource + 'GetOrderTotal', body);
};
export const getOrderCount = async (body) => {
  return await api.post(resource + 'GetOrderCount', body);
};
export const getOrdersDaily = async (body) => {
  return await api.post(resource + 'GetOrdersDaily', body);
};

export const getOrderTop10 = async (body) => {
  return await api.post(resource + 'GetOrderItemTop10', body);
};

export const getOrderRefundTotal = async (body) => {
  return await api.post(resource + 'GetOrderRefundTotal', body);
};

export const getOrderCountByTerminal = async (body) => {
  return await api.post(resource + 'GetOrderCountByTerminal', body);
};

export const getReports = async (body) => {
  return await api.post(resource + 'GetReport', body);
};

export const getTopOrderItems = async (body) => {
  return await api.post(resource + 'GetTopOrderItems', body);
};

export const getUpsellOrderItems = async (body) => {
  return await api.post(resource + 'GetUpsellOrderItems', body);
};

const dashboardApi = {
  getOrderTotal,
  getOrderCount,
  getOrdersDaily,
  getOrderTop10,
  getOrderCountByTerminal,
  getOrderRefundTotal,
  getReports,
  getTopOrderItems,
  getUpsellOrderItems,
};

export default dashboardApi;
