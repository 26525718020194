import { Button } from 'antd';
import React, { useState } from 'react';
import LogoDark from '../../../assets/logo-dark.svg';
import LogoWhite from '../../../assets/logo-white.svg';
import userApi from '../../../services/userApi';
import tokenService from '../../../utils/tokenService';
import Notification from '../../../components/notification/notification';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ThemeChanger from '../../../components/theme-changer/theme-changer';

const NewPassword = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [state, setState] = useState({
    email: searchParams.get('email') || '',
    code: '',
    newpassword: '',
    newpasswordagain: '',
    loading: false,
  });
  const [errors, setErrors] = useState({
    code: false,
    newpassword: false,
  });

  const [isDarkMode, setIsDarkMode] = useState(
    tokenService.getTheme() === 'dark' ? true : false
  );

  const onClickSendEmail = async () => {
    if (state.code === '' || state.newpassword === '') {
      setErrors((prev) => ({
        ...prev,
        code: state.code === '' ? true : false,
        newpassword: state.newpassword === '' ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        code: false,
        newpassword: false,
      }));
    }

    setState((prev) => ({ ...prev, loading: true }));

    const body = {
      email: state.email,
      code: state.code,
      newpassword: state.newpassword,
      newpasswordagain: state.newpassword,
    };

    try {
      const res = await userApi.resetPassword(body);
      if (res.data) {
        navigate('/');
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <div className="flex h-screen flex-col relative justify-center py-12 px-6 lg:px-8 bg-gray-50 dark:bg-slate-900">
      <ThemeChanger
        position="right-5 top-5 absolute"
        onChange={setIsDarkMode}
      />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          onClick={() => navigate('/')}
          className="mx-auto h-16 w-auto cursor-pointer"
          src={isDarkMode ? LogoWhite : LogoDark}
          alt="Your Company"
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-200">
          New Password
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white dark:bg-slate-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="code"
                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
              >
                Code
              </label>
              <div className="mt-1">
                <input
                  id="code"
                  name="code"
                  type="number"
                  onChange={(val) =>
                    setState((prev) => ({ ...prev, code: val.target.value }))
                  }
                  value={state.code}
                  required
                  className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                />
                <div className="h-2 mt-1  text-xs">
                  {errors.code && (
                    <p className="text-red-700">Enter your code</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
              >
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(val) =>
                    setState((prev) => ({
                      ...prev,
                      newpassword: val.target.value,
                    }))
                  }
                  value={state.newpassword}
                  autoComplete="email"
                  required
                  className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                />
                <div className="h-2 mt-1  text-xs">
                  {errors.newpassword && (
                    <p className="text-red-700">Enter your new password</p>
                  )}
                </div>
              </div>
            </div>
            <div className="space-y-2 text-center">
              <Button
                type="ghost"
                onClick={onClickSendEmail}
                disabled={state.loading}
                loading={state.loading}
                className="flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
              >
                Reset Password
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default NewPassword;
