import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button } from 'antd';
const ModalDelete = ({
  open,
  onCloseRequest,
  title,
  onOk,
  item,
  loading,
  text,
  shouldConfirm = false,
}) => {
  const [value, setValue] = useState('');
  const cancelButtonRef = useRef(null);

  const onClickCancel = () => {
    setValue('');
    onCloseRequest();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={() => {
          onClickCancel();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div
                  ref={cancelButtonRef}
                  className="bg-white dark:bg-slate-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
                >
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 dark:text-slate-200"
                      >
                        Delete {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-slate-400">
                          {text
                            ? text
                            : `You're about to remove your ${title.toLowerCase()} and
                          all data belong to this ${title.toLowerCase()}. Are you
                          sure you want to remove "${item.name}"?`}
                        </p>
                      </div>
                      {shouldConfirm && (
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 mb-1">
                            Please type <strong>{item.name}</strong> below to
                            confirm deletion.
                          </p>
                          <input
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            type="text"
                            name="value"
                            id="value"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-2 py-1 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400"
                            placeholder=""
                          />
                          <div className="h-2 mt-1  text-xs">
                            <p className="text-gray-500">
                              This action can not be undo.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-slate-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Button
                    loading={loading}
                    disabled={loading || (shouldConfirm && value !== item.name)}
                    type="ghost"
                    className="inline-flex w-full cursor-pointer justify-center disabled:bg-[#d9d9d9] disabled:hover:border-[#d9d9d9] disabled:text-zinc-400 disabled:cursor-not-allowed rounded-md border border-transparent bg-red-600 px-4  text-base font-medium text-white hover:text-white shadow-sm hover:bg-red-700 hover:border-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      onOk(item.id);
                      setValue('');
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    disabled={loading}
                    type="ghost"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4  text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent  focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-800"
                    onClick={() => onClickCancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalDelete;
