import react, { useState, useEffect } from 'react';
import optionApi from '../../../services/optionApi';
import productApi from '../../../services/productApi';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import update from 'immutability-helper';
import OptionGroupCard from './option-group-card';
import OptionGroupUpsertModal from './option-group-upsert-modal';
import OptionGroupExistModal from './option-group-exist-modal';
import { useSelector } from 'react-redux';
import Notification from '../../notification/notification';
import { Spin, Button } from 'antd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function OptionGroupList({ selected, setSelected }) {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    min: false,
    max: false,
  });
  const [loading, setLoading] = useState({
    optionGroupLoading: false,
    optionsLoading: false,
    upsertLoading: false,
    deleteLoading: false,
    visibleLoading: false,
  });

  const [dialogs, setDialogs] = useState({
    optionGroupUpsert: false,
    optionGroupDelete: false,
    optionGroupExist: false,
    optionVisible: false,
    hidedateDelete: false,
  });

  const [optionGroupForm, setOptionGroupForm] = useState({
    name: '',
    displayorder: '',
    description: '',
    min: '',
    max: '',
    companyid: globalCompany.id,
    options: [],
    productoptiongroups: [],
  });

  const getOptionGroupList = async () => {
    if (!selected.product?.id) return;
    try {
      setLoading((prev) => ({ ...prev, optionGroupLoading: true }));
      const list = await productApi.getById(selected.product.id);
      setOptionGroupForm((prev) => ({
        ...prev,
        productoptiongroups: list.data.data.productoptiongroups,
      }));
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, optionGroupLoading: false }));
    }
  };

  const onClickOpenOptionGroupUpsertModal = (optionGroup) => {
    setOptionGroupForm({
      ...optionGroup,
      options: [],
      productoptiongroups: optionGroupForm.productoptiongroups,
    });
    setDialogs((prev) => ({ ...prev, optionGroupUpsert: true }));
  };

  const onClickOptionGroupFormSubmit = async () => {
    if (
      !optionGroupForm.name ||
      optionGroupForm.min === '' ||
      optionGroupForm.max === ''
    ) {
      setErrors((prev) => ({
        ...prev,
        name: !optionGroupForm.name ? true : false,
        min: optionGroupForm.min === '' ? true : false,
        max: optionGroupForm.max === '' ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: false,
        min: false,
        max: false,
      }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...optionGroupForm };
      const optionGroup = await optionApi.upsertGroup(body);

      optionGroupForm.options.forEach(async (item) => {
        const body = { ...item, optiongroupid: optionGroup.data.data.id };
        await optionApi.upsert(body);
      });

      if (!optionGroupForm.id) {
        await linkProductToOptionGroup(optionGroup.data.data);
      }
    } catch (error) {
    } finally {
      setOptionGroupForm({
        options: [],
        productoptiongroups: optionGroupForm.productoptiongroups,
      });
      getOptionGroupList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, optionGroupUpsert: false }));
    }
  };

  const linkProductToOptionGroup = async (optionGroup) => {
    let optiongroupids = [];

    optionGroupForm.productoptiongroups.map((optionGroup) =>
      optiongroupids.push(optionGroup.optiongroupid)
    );
    optiongroupids.push(optionGroup.id);

    const formData = new FormData();

    for (const key of Object.keys(selected.product)) {
      if (
        (key === 'description' || key === 'hidedate') &&
        (selected.product[key] === '' || selected.product[key] === null)
      ) {
        formData.append(key, '');
      } else {
        formData.append(key, selected.product[key]);
      }
    }

    optiongroupids.forEach((id) => {
      formData.append('optiongroupids', id);
    });

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    await productApi.upsert(formData, config);
  };

  const onClickOpenOptionGroupExistModal = () => {
    setDialogs((prev) => ({ ...prev, optionGroupExist: true }));
  };

  const onClickOptionGroupExistSubmit = async () => {
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const optiongroupids = optionGroupForm.optiongroupids;

      const formData = new FormData();

      for (const key of Object.keys(selected.product)) {
        if (
          (key === 'description' || key === 'hidedate') &&
          (selected.product[key] === '' || selected.product[key] === null)
        ) {
          formData.append(key, '');
        } else {
          formData.append(key, selected.product[key]);
        }
      }

      if (optiongroupids.length > 0) {
        optiongroupids.forEach((id) => {
          formData.append('optiongroupids', id);
        });
      } else {
        formData.append('optiongroupids', '');
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const optionGroup = await productApi.upsert(formData, config);

      if (optionGroup.data.data) {
        setDialogs((prev) => ({
          ...prev,
          optionGroupExist: false,
        }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getOptionGroupList();
    }
  };

  const dropOptionGroupItem = async (index, optionGroup) => {
    if (index === optionGroupForm.productoptiongroups?.length - 1) {
      optionGroup.displayorder = Math.round(
        optionGroupForm.productoptiongroups[index - 1].optiongroup
          .displayorder + 1024
      );
    } else if (index === 0) {
      optionGroup.displayorder = Math.round(
        optionGroupForm.productoptiongroups[1].optiongroup.displayorder / 2
      );
    } else {
      optionGroup.displayorder = Math.round(
        (optionGroupForm.productoptiongroups[index - 1].optiongroup
          .displayorder +
          optionGroupForm.productoptiongroups[index + 1].optiongroup
            .displayorder) /
          2
      );
    }

    try {
      await optionApi.upsertGroup(optionGroup);
    } catch (error) {}
  };

  const moveOptionGroupItem = async (dragIndex, hoverIndex) => {
    setOptionGroupForm((prevOptionGroups) =>
      update(prevOptionGroups, {
        productoptiongroups: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevOptionGroups.productoptiongroups[dragIndex]],
          ],
        },
      })
    );
  };

  useEffect(() => {
    getOptionGroupList();
  }, [selected.product.id]);
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <div className="flex justify-between my-4  mx-4">
        <h1 className=" text-xl font-semibold text-slate-900 dark:text-slate-200">
          Item Modifiers
        </h1>
        <div className="flex sm:items-center ">
          <div>
            <Button
              type="button"
              onClick={onClickOpenOptionGroupExistModal}
              className="inline-flex max-h-7 self-center  border border-gray-300  items-center gap-x-1.5 rounded-md bg-white dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              + Modifiers
            </Button>
          </div>
          <div className="sm:mt-0 ml-4 sm:flex-none">
            <Button
              onClick={() =>
                onClickOpenOptionGroupUpsertModal({
                  name: '',
                  displayorder:
                    optionGroupForm.productoptiongroups?.length > 0
                      ? (optionGroupForm.productoptiongroups.length + 1) * 1024
                      : 1024,
                  min: '',
                  max: '',
                  companyid: globalCompany.id,
                })
              }
              type="button"
              className="inline-flex max-h-7 self-center items-center gap-x-1.5 rounded-md bg-blue-600 dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              + Add New
            </Button>
          </div>
        </div>
      </div>
      {loading.optionGroupLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        optionGroupForm &&
        optionGroupForm.productoptiongroups
          .filter((x) => x.optiongroup.isdeleted === false)
          .map((optionGroup, i) => (
            <OptionGroupCard
              key={optionGroup.id}
              index={i}
              id={optionGroup.id}
              optionGroup={optionGroup.optiongroup}
              onClickOpenOptionGroupUpsertModal={
                onClickOpenOptionGroupUpsertModal
              }
              moveOptionGroupItem={moveOptionGroupItem}
              dropOptionGroupItem={dropOptionGroupItem}
              selected={selected}
              setSelected={setSelected}
            />
          ))
      )}
      {dialogs.optionGroupUpsert && (
        <OptionGroupUpsertModal
          show={dialogs.optionGroupUpsert}
          optionGroupForm={optionGroupForm}
          setOptionGroupForm={setOptionGroupForm}
          getOptionGroupList={getOptionGroupList}
          dialogs={dialogs}
          setDialogs={setDialogs}
          loading={loading}
          errors={errors}
          setErrors={setErrors}
          setLoading={setLoading}
          onClickOptionGroupFormSubmit={onClickOptionGroupFormSubmit}
        />
      )}
      {dialogs.optionGroupExist && (
        <OptionGroupExistModal
          show={dialogs.optionGroupExist}
          optionGroupForm={optionGroupForm}
          setOptionGroupForm={setOptionGroupForm}
          onClickOptionGroupExistSubmit={onClickOptionGroupExistSubmit}
          notf={notf}
          setNotf={setNotf}
          setDialogs={setDialogs}
        />
      )}
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </DndProvider>
  );
}
