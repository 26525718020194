import { Button, Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import customerApi from '../../../services/customerApi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Notification from '../../../components/notification/notification';
import moment from 'moment-timezone';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { OrderStatus, PaymentStatus } from '../../../data/enums';
import NoData2 from '../../../assets/no-data-2.svg';
import { formatPhoneNumber } from '../../../utils/baseUtils';
import { PlatformTypes } from '../../../data/enums';
dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CustomerOrders = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState({});
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState({
    customerLoading: true,
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const orderStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-yellow-100 text-yellow-800';
      case 10:
        return 'bg-blue-100 text-blue-800';
      case 20:
        return 'bg-purple-100 text-purple-800';
      case 30:
        return 'bg-gray-100 text-gray-800';
      case 40:
        return 'bg-pink-100 text-pink-800';
      case 100:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const paymentStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-red-100 text-red-800';
      case 20:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getCustomer = async () => {
    setLoading((prev) => ({ ...prev, customerLoading: true }));
    setCustomer({});
    try {
      const query = `?id=${id}`;
      const list = await customerApi.getById(query);
      if (list.data.data) {
        setCustomer(list.data.data);
        setFilteredList(
          list.data.data.orders
            .sort((a, b) => {
              return new Date(b.createdate) - new Date(a.createdate);
            })
            .slice(0, pagination.pageSize)
        );
        setPagination({
          ...pagination,
          totalRecords: Math.ceil(list.data.data.orders.length),
        });
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, customerLoading: false }));
    }
  };

  const onClickRow = (id) => {
    navigate(`/dashboard/orders/${id}`);
  };

  const handleChangePage = (pageNumber, pageSize) => {
    setPagination((prev) => ({ ...prev, pageNumber, pageSize }));

    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredList = customer.orders
      .sort((a, b) => {
        return new Date(b.createdate) - new Date(a.createdate);
      })
      .slice(startIndex, endIndex);

    setFilteredList(filteredList);
  };

  const orderTypeColor = (platform) => {
    if (platform === 0) {
      return 'bg-gray-100 text-gray-800';
    } else if (platform === 10) {
      return 'bg-purple-100 text-purple-800';
    } else if (platform === 20) {
      return 'bg-blue-100 text-blue-800';
    } else {
      return 'bg-green-100 text-green-800';
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="flex sm:flex-row flex-col sm:items-start gap-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200 mb-2">
                {formatPhoneNumber(customer.phone)}
              </h1>
              <p className="cursor-pointer text-sm text-gray-500 dark:text-slate-400">
                {customer?.fullname} - {customer?.email}
              </p>
            </div>
            <Button
              onClick={(e) => {
                if (!customer?.customerid) {
                  e.preventDefault();
                  alert(
                    'There is no customerid for this customer. Please report this.'
                  );
                } else {
                  window.open(
                    process.env.REACT_APP_BASE_STRIPE_CUSTOMER_URL +
                      customer?.customerid,
                    '_blank'
                  );
                }
              }}
              disabled={loading.listLoading}
              type="ghost"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200 dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
            >
              Show on Stripe
            </Button>
          </div>
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.customerLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              {filteredList.length > 0 ? (
                <div className="inline-block min-w-full py-2 align-middle ">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 md:pl-0 dark:text-slate-200"
                        >
                          Receipt#
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 md:pl-0 dark:text-slate-200"
                        >
                          Details
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Payment Status
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Time
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Order Type
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      {filteredList.map((order, orderIdx) => (
                        <tr
                          className="cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-700"
                          key={orderIdx}
                          onClick={() => {
                            onClickRow(order.id);
                          }}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0 ">
                            {order.ordernumberstr}
                            <div className="text-slate-500 font-medium">
                              #{order.orderid}
                            </div>
                          </td>

                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0 ">
                            <div className="text-slate-900 dark:text-slate-200">
                              {order.ordertype === 10 ? 'Dine-In' : 'To Go'}
                            </div>
                            <div className="text-slate-500 font-medium">
                              {order.store?.name}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 dark:text-slate-200">
                            <span
                              className={classNames(
                                paymentStatusColor(order.paymentstatus),
                                'items-center rounded-full px-3 py-0.5 text-sm font-medium'
                              )}
                            >
                              {PaymentStatus[order.paymentstatus]}
                            </span>
                          </td>

                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            <div className="text-slate-500 font-medium">
                              <div className="text-slate-900 dark:text-slate-200">
                                {moment(order.createdate)
                                  .utcOffset(order?.timezone * 60)
                                  .format('hh:mm A')}
                              </div>
                              <div className="text-slate-500 font-medium">
                                {moment(order.createdate)
                                  .utcOffset(order?.timezone * 60)
                                  .format('MMMM Do')}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 dark:text-slate-200">
                            <span
                              className={classNames(
                                orderTypeColor(order.platform),
                                'items-center rounded-full px-3 py-0.5 text-sm font-medium'
                              )}
                            >
                              {PlatformTypes[order.platform]}
                            </span>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-bold text-gray-500 dark:text-slate-200">
                            {(order.total - order.refund).toLocaleString(
                              'en-US',
                              {
                                style: 'currency',
                                currency: 'USD',
                              }
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 dark:text-slate-200">
                            <span
                              className={classNames(
                                orderStatusColor(order.orderstatus),
                                'items-center rounded-full px-3 py-0.5 text-sm font-medium'
                              )}
                            >
                              {order.orderstatus === 40 &&
                              order.refund !== order.total
                                ? 'Partial Refund'
                                : OrderStatus[order.orderstatus]}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full justify-between flex mt-4">
                    <div>
                      <p className="text-sm text-gray-700 dark:text-slate-200">
                        Showing{' '}
                        <span className="font-medium">
                          {pagination.pageNumber}
                        </span>{' '}
                        to{' '}
                        <span className="font-medium">
                          {pagination.pageSize}
                        </span>{' '}
                        of{' '}
                        <span className="font-medium">
                          {pagination.totalRecords}
                        </span>{' '}
                        results
                      </p>
                    </div>
                    <div className="w-full text-end mt-4">
                      <Pagination
                        current={pagination.pageNumber}
                        total={pagination.totalRecords}
                        defaultPageSize={pagination.pageSize}
                        onChange={(pageNumber, pageSize) =>
                          handleChangePage(pageNumber, pageSize)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid justify-center py-20">
                  <img src={NoData2} alt="no-data-2" className="h-24 pb-2" />
                  <p className=" text-slate-400 font-light text-center">
                    No Orders
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default CustomerOrders;
