import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  XMarkIcon,
  PrinterIcon,
  LinkIcon,
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon as EnvelopeIconOutline,
} from '@heroicons/react/24/outline';
import { Button } from 'antd';
import Receipt from './receipt';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SlideOver({
  showSlide,
  setShowSlide,
  orderDetail,
  onClickSendEmail,
  onClickSendSms,
  onClickOpenReceiptNewTab,
}) {
  const [email, setEmail] = useState(null);
  const [dialogs, setDialogs] = useState({
    email: false,
  });
  const [loading, setLoading] = useState({
    email: false,
  });
  const cancelButtonRef = useRef(null);

  const onCloseUpsert = () => {
    setDialogs((prev) => ({ ...prev, email: false }));
  };

  const sendEmail = async () => {
    if (orderDetail.email || orderDetail.customer.email) {
      await onClickSendEmail();
    } else {
      onClickOpenEmailModal();
    }
  };

  const onClickOpenEmailModal = () => {
    setEmail(null);
    setDialogs((prev) => ({ ...prev, email: true }));
  };

  const submitEmail = async () => {
    setLoading((prev) => ({ ...prev, email: true }));
    await onClickSendEmail(email);
    setLoading((prev) => ({ ...prev, email: false }));
    onCloseUpsert();
    setShowSlide(false);
  };

  return (
    <>
      <Transition.Root show={showSlide} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowSlide}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-96 ">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setShowSlide(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="h-full overflow-y-auto bg-white dark:bg-slate-900  p-10">
                      <Receipt orderDetail={orderDetail} />
                      <div className="py-5">
                        <h1>Share this receipt with Customer:</h1>
                      </div>
                      <div className="flex justify-start gap-2">
                        <Button
                          type="button"
                          className="inline-flex  self-center h-10 items-center gap-x-1.5 rounded-md border-gray-300 bg-white dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:mb-0 sm:mt-0 mb-3 sm:text-sm"
                          onClick={() => sendEmail()}
                        >
                          <EnvelopeIconOutline
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          E-mail
                        </Button>
                        <Button
                          type="button"
                          className="inline-flex  self-center h-10 items-center gap-x-1.5 rounded-md border-gray-300 bg-white dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:mb-0 sm:mt-0 mb-3 sm:text-sm"
                          onClick={() => onClickSendSms()}
                        >
                          <ChatBubbleBottomCenterTextIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          Sms
                        </Button>
                        <Button
                          type="button"
                          className="inline-flex  self-center h-10 items-center gap-x-1.5 rounded-md border-gray-300 bg-white dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:mb-0 sm:mt-0 mb-3 sm:text-sm"
                          onClick={() => onClickOpenReceiptNewTab()}
                        >
                          <LinkIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          Public Link
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={dialogs.email} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="ownername"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>
                  <div className="mt-5 pt-2 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-12 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.email}
                      className={classNames(
                        'sm:col-span-6',
                        'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.email}
                      loading={loading.email}
                      className={classNames(
                        'sm:col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => submitEmail()}
                    >
                      Send E-Mail
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
