import React from "react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Alert = ({ title, description, type, buttonText, navigate }) => {
  const alertType = {
    info: {
      icon: (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-blue-400 dark:text-slate-200"
          aria-hidden="true"
        />
      ),
      color: "bg-blue-50 dark:bg-slate-500",
      textColor: "text-blue-700 dark:text-slate-200",
      hoverColor: "hover:text-blue-600",
    },
    warning: {
      icon: (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      ),
      color: "bg-yellow-50",
      textColor: "text-yellow-700",
      hoverColor: "hover:text-yellow-600",
    },
    error: {
      icon: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
      color: "bg-red-50",
      textColor: "text-red-700",
      hoverColor: "hover:text-red-600",
    },
    success: {
      icon: (
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      ),
      color: "bg-green-50",
      textColor: "text-green-700",
      hoverColor: "hover:text-green-600",
    },
  };

  return (
    <div className={classNames(alertType[type].color, " rounded-md p-4 my-5")}>
      <div className="flex">
        <div className="flex-shrink-0">{alertType[type].icon}</div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={classNames(alertType[type].textColor, "text-sm")}>
            {description}
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Link
              to={navigate}
              className={classNames(
                alertType[type].textColor,
                alertType[type].hoverColor,
                " whitespace-nowrap  text-blue-700 hover:text-blue-600 font-bold"
              )}
            >
              {buttonText}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
