import api from './api';

const resource = 'Terms/';

export const list = async () => {
  return await api.get(resource + `List`);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

const termsApi = { list, getById, upsert, deleteById };

export default termsApi;
