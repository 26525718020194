import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Spin, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import subscriptionApi from '../../services/subscriptionApi';
import companyApi from '../../services/companyApi';
import cardApi from '../../services/cardApi';
import { setGlobalCompany } from '../../store/slices/companySlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [dialogs, setDialogs] = useState({
    hasCreditCard: false,
  });
  const [hasCreditCard, setHasCreditCard] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const cancelButtonRef = useRef(null);

  const getDefaultSubscriptionList = async () => {
    setSubscriptionList([]);
    try {
      const list = await subscriptionApi.listDefaults();
      if (list.data.data.length > 0) {
        setSubscriptionList(list.data.data);
      }
    } catch (error) {}
  };

  const getCompany = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    try {
      const list = await companyApi.getById(globalCompany.id);
      if (list.data.data) {
        setSelectedCompany(list.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const onClickSelectSubscription = async (sub) => {
    if (!hasCreditCard) {
      setDialogs((prev) => ({ ...prev, hasCreditCard: true }));
    } else {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      try {
        const formData = new FormData();

        for (const key of Object.keys(selectedCompany)) {
          if (key === 'subscriptionid') {
            formData.append(key, sub.id);
          } else {
            formData.append(key, selectedCompany[key]);
          }
        }

        formData.append('trialend', null);

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };

        const res = await companyApi.upsert(formData, config);
        if (res.data.data) {
          getCompany();
          dispatch(setGlobalCompany(res.data.data));
        }
      } catch (error) {
      } finally {
        setLoading((prev) => ({ ...prev, upsertLoading: false }));
      }
    }
  };

  const getCreditCard = async () => {
    const query = `?companyid=${globalCompany.id}`;
    try {
      const list = await cardApi.list(query);
      if (list.data.data.length > 0) {
        setHasCreditCard(true);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const onCloseHasCreditCardDialogs = () => {
    setDialogs((prev) => ({ ...prev, hasCreditCard: false }));
  };

  const onClickGoToCreditCard = () => {
    setDialogs((prev) => ({ ...prev, hasCreditCard: false }));
    navigate('/dashboard/settings/bank-accounts');
  };

  useEffect(() => {
    getCompany();
    getCreditCard();
    getDefaultSubscriptionList();
  }, [globalCompany.id]);

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Subscription
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                Learn about the pricing and features of our subscription plans.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              {selectedCompany.subscription !== null ? (
                <div>
                  <div className="relative mt-8 flex flex-col rounded-2xl border border-gray-200 dark:border-slate-700 bg-white p-8 shadow-sm dark:bg-slate-900 dark:border-slate-600">
                    <div className="flex-1">
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                        {selectedCompany.subscription?.name}
                      </h3>
                      {selectedCompany.subscription?.name ===
                      'Ultimate Solution' ? (
                        <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-blue-500 px-4 py-1.5 text-sm font-semibold text-white">
                          Most popular
                        </p>
                      ) : null}
                      <p className="mt-4 flex items-baseline text-gray-900 dark:text-slate-200">
                        <span className="text-5xl font-bold tracking-tight">
                          {selectedCompany.subscription?.orderfeerate}%
                        </span>
                        <span className="ml-1 text-xl font-semibold">
                          /per order
                        </span>
                      </p>
                      <p className="mt-6 text-gray-500 dark:text-slate-400">
                        {selectedCompany.subscription?.name ===
                        'Ultimate Solution'
                          ? 'Unlock a complimentary kiosk with your subscription.'
                          : 'Access to every tool, feature, and capability our platform offers'}
                      </p>

                      {/* Feature list */}
                      <ul role="list" className="mt-6 space-y-6">
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Unlimited Orders
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Unlimited Kiosks
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Unlimited Software Upgrades
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            White Label Branding
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Multi-Store Management
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Enterprise Cloud Hosted
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Free Implementation
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            Remote Support and Assistance
                          </span>
                        </li>
                        <li className="flex">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-blue-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500 dark:text-slate-400">
                            {selectedCompany.subscription?.monthlyfee !== 0
                              ? `$ ${selectedCompany.subscription?.monthlyfee} / Monthly Fee`
                              : 'No Monthly Fees'}
                          </span>
                        </li>
                        {selectedCompany.subscription?.name ===
                          'Ultimate Solution' && (
                          <li className="flex ">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500 "
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-blue-500 font-semibold">
                              Hardware Included
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="text-gray-400 text-xs mt-2">
                    Credit card processing fees are not included in the
                    subscriptions, All transactions will apply regular credit
                    card processing fees. There is an $75 Inactive Account Fee
                    if no orders are made within a 60-day timeframe.
                  </div>
                </div>
              ) : (
                <div className="mx-auto max-w-2xl mt-4 space-y-6 px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:space-y-0 lg:px-8">
                  {subscriptionList.map((subscription, subscriptionIdx) => (
                    <div
                      key={subscriptionIdx}
                      className="relative flex flex-col rounded-2xl border border-gray-200 dark:border-slate-700 bg-white p-8 shadow-sm dark:bg-slate-900 dark:border-slate-600"
                    >
                      <div className="flex-1">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                          {subscription?.name}
                        </h3>
                        {subscription?.name === 'Ultimate Solution' ? (
                          <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-blue-500 px-4 py-1.5 text-sm font-semibold text-white">
                            Most popular
                          </p>
                        ) : null}
                        <p className="mt-4 flex items-baseline text-gray-900 dark:text-slate-200">
                          <span className="text-5xl font-bold tracking-tight">
                            {subscription?.orderfeerate}%
                          </span>
                          <span className="ml-1 text-xl font-semibold">
                            /per order
                          </span>
                        </p>
                        <p className="mt-6 text-gray-500 dark:text-slate-400">
                          {subscription?.name === 'Ultimate Solution'
                            ? 'Unlock a complimentary kiosk with your subscription.'
                            : 'Access to every tool, feature, and capability our platform offers'}
                        </p>

                        {/* Feature list */}
                        <ul role="list" className="mt-6 space-y-6">
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Unlimited Orders
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Unlimited Kiosks
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Unlimited Software Upgrades
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              White Label Branding
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Multi-Store Management
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Enterprise Cloud Hosted
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Free Implementation
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              Remote Support and Assistance
                            </span>
                          </li>
                          <li className="flex">
                            <CheckIcon
                              className="h-6 w-6 flex-shrink-0 text-blue-500"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 dark:text-slate-400">
                              {subscription?.monthlyfee !== 0
                                ? `$ ${subscription?.monthlyfee} / Monthly Fee`
                                : 'No Monthly Fees'}
                            </span>
                          </li>
                          {subscription?.name === 'Ultimate Solution' && (
                            <li className="flex ">
                              <CheckIcon
                                className="h-6 w-6 flex-shrink-0 text-blue-500 "
                                aria-hidden="true"
                              />
                              <span className="ml-3 text-blue-500 font-semibold">
                                Hardware Included
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>

                      <a
                        onClick={() => onClickSelectSubscription(subscription)}
                        href="#"
                        className={classNames(
                          'bg-blue-50 text-blue-700 hover:bg-blue-100',
                          'mt-8 block w-full rounded-md border border-transparent py-3 px-6 text-center font-medium dark:bg-slate-700 dark:text-white dark:hover:text-sky-400 dark:hover:bg-slate-800'
                        )}
                      >
                        Select
                      </a>
                    </div>
                  ))}
                  <ul
                    role="list"
                    className="lg:pt-4 space-y-1 text-xs col-span-2"
                  >
                    <li className="flex">
                      <span className="text-gray-400">
                        *Ultimate Solution subscription includes a free kiosk
                        only for select restaurants.
                      </span>
                    </li>
                    <li className="flex">
                      <span className="text-gray-400">
                        Credit card processing fees are not included in the
                        subscriptions, All transactions will apply regular
                        credit card processing fees.{' '}
                      </span>
                    </li>
                    <li className="flex">
                      <span className="text-gray-400">
                        There is an $75 Inactive Account Fee if no orders are
                        made within a 60-day timeframe.
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={dialogs.hasCreditCard} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add a Credit Card
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      We couldn't find any saved credit card information
                      associated with your account. To subscribe, please save a
                      credit card. Click the button below to go to the credit
                      card page and add a new card.
                    </p>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 pt-2 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-12 sm:gap-3">
                    <Button
                      type="ghost"
                      className={classNames(
                        'col-span-6 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() => onCloseHasCreditCardDialogs()}
                    >
                      Later
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        'col-span-6 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onClickGoToCreditCard()}
                    >
                      Add Credit Card
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Subscription;
