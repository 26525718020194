import { Button } from 'antd';
import React, { useState } from 'react';
import LogoDark from '../../assets/logo-dark.svg';
import LogoWhite from '../../assets/logo-white.svg';
import userApi from '../../services/userApi';
import {
  setIsAuthenticated,
  setRole,
  setRolePermissions,
} from '../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import tokenService from '../../utils/tokenService';
import Notification from '../../components/notification/notification';
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom';

const Login = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const auth = useSelector((state) => state.authReducer.isAuthenticated);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
    password: '',
    loading: false,
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    showNotf: false,
    message: '',
  });

  const [isDarkMode, setIsDarkMode] = useState(
    tokenService.getTheme() === 'dark' ? true : false
  );

  const onClickLogin = async () => {
    if (state.email === '' || state.password === '') {
      setErrors((prev) => ({
        ...prev,
        email: state.email === '' ? true : false,
        password: state.password === '' ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, email: false, password: false }));
    }

    setState((prev) => ({ ...prev, loading: true }));

    try {
      const body = {
        email: state.email,
        password: state.password,
      };

      const res = await userApi.login(body);
      if (res.data) {
        let roles = res.data.data.rolecodes;

        let rolepermissions = rolePermissions(
          res.data.data.permissions[0].rolepermissions
        );

        tokenService.updateLocalAccessToken(res.data.data.token);
        tokenService.setRole(roles);
        tokenService.setRolePermissions(rolepermissions);

        dispatch(setIsAuthenticated());
        dispatch(setRole(roles));
        dispatch(setRolePermissions(rolepermissions));
        navigate('/dashboard');
      }
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        showNotf: true,
        message: error.response.data,
      }));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const rolePermissions = (permissions) => {
    let rolepermissions = [];
    permissions.forEach((permission) => {
      rolepermissions.push(permission.permission.code);
    });
    return rolepermissions;
  };

  if (auth) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return (
    <div className="flex h-screen flex-col relative justify-center py-12 px-6 lg:px-8 bg-gray-50 dark:bg-slate-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src={isDarkMode ? LogoWhite : LogoDark}
          alt="Your Company"
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-slate-200">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white dark:bg-slate-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(val) =>
                    setState((prev) => ({ ...prev, email: val.target.value }))
                  }
                  value={state.email}
                  autoComplete="email"
                  required
                  className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500 "
                />
                <div className="h-2 mt-1  text-xs">
                  {errors.email && (
                    <p className="text-red-700">Enter your email</p>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(val) =>
                    setState((prev) => ({
                      ...prev,
                      password: val.target.value,
                    }))
                  }
                  value={state.password}
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                />
                <div className="h-2 mt-1 text-xs">
                  {errors.password && (
                    <p className="text-red-700 ">Enter your password</p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 dark:text-slate-500 focus:ring-blue-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900 dark:text-slate-500"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="forgot-password"
                  className="font-medium text-blue-600 dark:text-slate-500 hover:text-blue-500 dark:hover:text-sky-400"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div className="space-y-2 text-center">
              <Button
                type="ghost"
                onClick={onClickLogin}
                disabled={state.loading}
                loading={state.loading}
                className="flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
              >
                Sign In
              </Button>
              <div className="text-sm">
                <Link
                  to="register"
                  className="font-medium text-blue-600 dark:text-slate-500 dark:hover:text-sky-400 hover:text-blue-500"
                >
                  Don't have an account yet ? Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification
        display={errors.showNotf}
        type="error"
        message={errors.message}
        onClose={() => setErrors((prev) => ({ ...prev, showNotf: false }))}
      />
    </div>
  );
};

export default Login;
