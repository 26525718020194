import queryString from "query-string";
import api from "./api";

const resource = "User/";

export const login = async (body) => {
  return await api.post(
    resource +
      `Login?${queryString.stringify(body, {
        skipNull: true,
      })}`
  );
};

export const register = async (body) => {
  return await api.post(resource + "Register", body);
};

export const getUserDetail = async () => {
  return await api.get(resource + "WhoAmI");
};

export const list = async (query = "") => {
  return await api.get(resource + "List" + query);
};

export const upsert = async (body) => {
  return await api.post(resource + "Upsert", body);
};

export const validateCaptcha = async (response) => {
  return await api.get(resource + "ValidateCaptcha?response=" + response);
};

export const updateProfile = async (body) => {
  return await api.post(resource + "UpdateProfile", body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + "Delete?id=" + id);
};

export const sendEmailForgotPassword = async (email) => {
  return await api.get(resource + "ForgotPassword?email=" + email);
};

export const resetPassword = async (body) => {
  return await api.post(resource + "ForgotPassword", body);
};

const userApi = {
  login,
  getUserDetail,
  register,
  list,
  validateCaptcha,
  updateProfile,
  upsert,
  deleteById,
  sendEmailForgotPassword,
  resetPassword,
};

export default userApi;
