import api from './api';
import queryString from 'query-string';

const resource = 'Order/';

export const list = async (query) => {
  return await api.get(
    resource +
      `List?${queryString.stringify(query, {
        skipNull: true,
      })}`
  );
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const getInvoice = async (query) => {
  return await api.get(resource + 'GetInvoice' + query);
};

export const getInvoiceById = async (id) => {
  return await api.get(resource + 'GetInvoiceById?id=' + id);
};

export const sendInvoiceBySms = async (id, phone) => {
  return await api.get(
    resource + 'SendInvoiceBySms?id=' + id + '&phone=' + phone
  );
};

export const sendInvoiceByEmail = async (id, email) => {
  return await api.get(
    resource + 'SendInvoiceByEmail?id=' + id + '&email=' + email
  );
};

export const changeOrderStatus = async (body) => {
  return await api.post(resource + 'ChangeStatus', body);
};

export const exportCsv = async (query) => {
  return await api.get(resource + 'Export' + query);
};

export const customerOrderById = async (id) => {
  return await api.get(resource + 'Get/CustomerOrder?id=' + id);
};

const orderApi = {
  list,
  upsert,
  deleteById,
  getById,
  getInvoice,
  getInvoiceById,
  sendInvoiceBySms,
  sendInvoiceByEmail,
  changeOrderStatus,
  exportCsv,
  customerOrderById,
};

export default orderApi;
