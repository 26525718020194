import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Spin, Button, Radio, Select, DatePicker } from 'antd';
import MasterCard from '../../../../assets/mastercard.svg';
import Visa from '../../../../assets/visa.svg';
import AmericanExpress from '../../../../assets/american-express.svg';
import Discover from '../../../../assets/discover.svg';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ModalDelete from '../../../../components/modal-delete/modal-delete';
import bankAccountApi from '../../../../services/bankAccountApi';
import companyApi from '../../../../services/companyApi';
import cardApi from '../../../../services/cardApi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AccountInformation = ({ companyDetail, notf, setNotf }) => {
  const [bankAccountList, setBankAccountList] = useState([]);
  const [creditCardList, setCreditCardList] = useState([]);
  const [deletedBankAccount, setDeletedBankAccount] = useState({});
  const [deletedCreditCard, setDeletedCreditCard] = useState({});
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [bankAccountForm, setBankAccountForm] = useState({
    id: '',
    accounttype: 10,
    accountholdername: '',
    routingnumber: '',
    accountnumber: '',
    isdefault: false,
    companyid: companyDetail.id,
  });
  const [creditCardForm, setCreditCardForm] = useState({
    id: '',
    isdefault: false,
    createcardmodel: {
      expyear: 0,
      expmonth: 0,
      number: '',
      cvc: '',
    },
    companyid: companyDetail.id,
  });

  const [dialogs, setDialogs] = useState({
    upsertBankAccount: false,
    viewBankAccount: false,
    upsertCreditCard: false,
    deleteBankAccount: false,
    deleteCreditCard: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    state: false,
    statecode: false,
    displayorder: false,
  });

  const cancelButtonRef = useRef(null);

  const getBankAccountList = async () => {
    setBankAccountList([]);
    try {
      const query = `?companyid=${companyDetail.id}`;
      const list = await bankAccountApi.list(query);
      if (list.data.data.length > 0) {
        setBankAccountList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const getCreditCardList = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setCreditCardList([]);
    try {
      const query = `?companyid=${companyDetail.id}`;
      const list = await cardApi.list(query);
      if (list.data.data.length > 0) {
        setCreditCardList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const onFormChangeCreditCard = (val, key) => {
    setCreditCardForm((prev) => ({
      ...prev,
      createcardmodel: { ...prev.createcardmodel, [key]: val },
    }));
  };

  const onClickAddBankAccount = () => {
    setBankAccountForm({
      id: '',
      accounttype: 10,
      accountholdername: '',
      routingnumber: '',
      accountnumber: '',
      companyid: companyDetail.id,
      reprensentativefullname: '',
      taxid: '',
      last4ssn: '',
      reprensentativebirthday: dayjs(),
    });
    setDialogs((prev) => ({ ...prev, upsertBankAccount: true }));
  };

  const onClickAddCreditCard = () => {
    setCreditCardForm({
      id: '',
      isdefault: false,
      createcardmodel: {
        expyear: 0,
        expmonth: 0,
        number: '',
        cvc: '',
      },
      companyid: companyDetail.id,
    });
    setDialogs((prev) => ({ ...prev, upsertCreditCard: true }));
  };

  const onClickEditBankAccount = (val) => {
    setBankAccountForm({
      id: val.id,
      accounttype: val.accounttype,
      isdefault: val.isdefault,
      accountholdername: val.accountholdername,
      routingnumber: val.routingnumber,
      accountnumber: val.accountnumber,
      companyid: companyDetail.id,
      reprensentativefullname: val.reprensentativefullname,
      taxid: val.taxid,
      last4ssn: val.last4ssn,
      reprensentativebirthday: dayjs(val.reprensentativebirthday),
      confirmaccountnumber: val.accountnumber,
    });
    setDialogs((prev) => ({ ...prev, upsertBankAccount: true }));
  };

  const onClickViewBankAccount = (val) => {
    setBankAccountForm({
      id: val.id,
      accounttype: val.accounttype,
      isdefault: val.isdefault,
      accountholdername: val.accountholdername,
      routingnumber: val.routingnumber,
      accountnumber: val.accountnumber,
      companyid: companyDetail.id,
      reprensentativefullname: val.reprensentativefullname,
      taxid: val.taxid,
      last4ssn: val.last4ssn,
      reprensentativebirthday: dayjs(val.reprensentativebirthday),
      confirmaccountnumber: val.accountnumber,
    });
    setDialogs((prev) => ({ ...prev, viewBankAccount: true }));
  };

  const onFormSubmitBankAccount = async () => {
    if (
      !bankAccountForm.companyid ||
      !bankAccountForm.accountholdername ||
      !bankAccountForm.routingnumber ||
      !bankAccountForm.accountnumber ||
      !bankAccountForm.taxid ||
      !bankAccountForm.last4ssn ||
      !bankAccountForm.reprensentativefullname ||
      !bankAccountForm.reprensentativebirthday ||
      bankAccountForm.accountnumber !== bankAccountForm.confirmaccountnumber
    ) {
      setErrors((prev) => ({
        ...prev,
        companyid: !bankAccountForm.companyid ? true : false,
        accountholdername: !bankAccountForm.accountholdername ? true : false,
        routingnumber: !bankAccountForm.routingnumber ? true : false,
        accountnumber: !bankAccountForm.accountnumber ? true : false,
        taxid: !bankAccountForm.taxid,
        last4ssn: !bankAccountForm.last4ssn,
        reprensentativefullname: !bankAccountForm.reprensentativefullname,
        reprensentativebirthday: !bankAccountForm.reprensentativebirthday,
        confirmaccountnumber:
          bankAccountForm.accountnumber !==
          bankAccountForm.confirmaccountnumber,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        companyid: false,
        accountholdername: false,
        routingnumber: false,
        accountnumber: false,
        taxid: false,
        last4ssn: false,
        reprensentativefullname: false,
        reprensentativebirthday: false,
        confirmaccountnumber: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...bankAccountForm };
      if (bankAccountList.length === 0) {
        body.isdefault = true;
      }
      await bankAccountApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      await getBankAccountList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsertBankAccount: false }));
    }
  };
  const onFormSubmitCreditCard = async () => {
    if (
      !creditCardForm.companyid ||
      !creditCardForm.createcardmodel.expyear ||
      !creditCardForm.createcardmodel.expmonth ||
      !creditCardForm.createcardmodel.number ||
      !creditCardForm.createcardmodel.cvc ||
      creditCardForm.createcardmodel.number.length < 15 ||
      creditCardForm.createcardmodel.cvc.length < 3 ||
      creditCardForm.createcardmodel.zipcode.length < 5
    ) {
      setErrors((prev) => ({
        ...prev,
        companyid: !creditCardForm.companyid ? true : false,
        expyear: !creditCardForm.createcardmodel.expyear ? true : false,
        expmonth: !creditCardForm.createcardmodel.expmonth ? true : false,
        zipcode:
          !creditCardForm.createcardmodel.zipcode ||
          creditCardForm.createcardmodel.zipcode.length < 5
            ? true
            : false,
        number:
          !creditCardForm.createcardmodel.number ||
          creditCardForm.createcardmodel.number.length < 15
            ? true
            : false,
        cvc:
          !creditCardForm.createcardmodel.cvc ||
          creditCardForm.createcardmodel.cvc.length < 3
            ? true
            : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        companyid: false,
        expyear: false,
        expmonth: false,
        number: false,
        cvc: false,
        zipcode: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...creditCardForm };
      if (creditCardList.length === 0) {
        body.isdefault = true;
      }
      await cardApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      await getCreditCardList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsertCreditCard: false }));
    }
  };

  const onFormChangeBankAccount = (val, key) => {
    if (key === 'taxid') {
      const numbersOnly = val.replace(/\D/g, '');

      let formattedValue = '';
      for (let i = 0; i < numbersOnly.length; i++) {
        if (i === 2) {
          formattedValue += '-';
        }
        formattedValue += numbersOnly[i];
      }
      val = formattedValue;
    }

    setBankAccountForm((prev) => ({ ...prev, [key]: val }));
  };

  const onCloseUpsertBankAccount = () => {
    setErrors((prev) => ({
      ...prev,
      companyid: false,
      accountholdername: false,
      routingnumber: false,
      accountnumber: false,
    }));
    setDialogs((prev) => ({ ...prev, upsertBankAccount: false }));
  };

  const onCloseUpsertCreditCard = () => {
    setErrors((prev) => ({
      ...prev,
      companyid: false,
      expyear: false,
      expmonth: false,
      number: false,
      cvc: false,
      zipcode: false,
    }));
    setDialogs((prev) => ({ ...prev, upsertCreditCard: false }));
  };

  const onClickDeleteBankAccount = (bankAccount) => {
    if (bankAccount.isdefault) {
      setNotf({
        display: true,
        type: 'error',
        message: 'Cannot delete default bank account',
      });
      return;
    }
    setDeletedBankAccount(bankAccount);
    setDialogs((prev) => ({ ...prev, deleteBankAccount: true }));
  };

  const onClickDeleteCreditCard = (creditCard) => {
    if (creditCard.isdefault) {
      setNotf({
        display: true,
        type: 'error',
        message: 'Cannot delete default credit card',
      });
      return;
    }
    setDeletedCreditCard(creditCard);
    setDialogs((prev) => ({ ...prev, deleteCreditCard: true }));
  };

  const onConfirmDeleteBankAccount = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await bankAccountApi.deleteById(deletedBankAccount.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setDialogs((prev) => ({
        ...prev,
        deleteBankAccount: false,
        upsertBankAccount: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getBankAccountList();
    }
  };

  const onConfirmDeleteCreditCard = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await cardApi.deleteById(deletedCreditCard.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setDialogs((prev) => ({
        ...prev,
        deleteCreditCard: false,
        upsertCreditCard: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getCreditCardList();
    }
  };

  const onChangeDefaultBankAccount = async (bankAccount, val, index) => {
    if (val === false) {
      setNotf({
        display: true,
        type: 'error',
        message: 'Cannot uncheck default bank account',
      });
      return;
    }
    setLoading((prev) => ({ ...prev, upsertLoading: true }));
    const defaultAccount = bankAccountList.find((x) => x.isdefault === true);
    try {
      setBankAccountList((prev) => {
        const newList = [...prev];
        newList[index].isdefault = val;
        return newList;
      });
      if (defaultAccount) {
        setBankAccountList((prev) => {
          const newList = [...prev];
          const defaultIndex = newList.findIndex(
            (x) => x.id === defaultAccount.id
          );
          newList[defaultIndex].isdefault = false;
          return newList;
        });
        const body = { ...defaultAccount, isdefault: false };
        await bankAccountApi.upsert(body);
      }
      const body = { ...bankAccount, isdefault: val };
      await bankAccountApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getBankAccountList();
    }
  };

  const onChangeDefaultCreditCard = async (creditCard, val, index) => {
    if (val === false) {
      setNotf({
        display: true,
        type: 'error',
        message: 'Cannot uncheck default credit card',
      });
      return;
    }
    setLoading((prev) => ({ ...prev, upsertLoading: true }));
    const defaultCreditCard = creditCardList.find((x) => x.isdefault === true);
    try {
      setCreditCardList((prev) => {
        const newList = [...prev];
        newList[index].isdefault = val;
        return newList;
      });
      if (defaultCreditCard) {
        setCreditCardList((prev) => {
          const newList = [...prev];
          const defaultIndex = newList.findIndex(
            (x) => x.id === defaultCreditCard.id
          );
          newList[defaultIndex].isdefault = false;
          return newList;
        });
        const body = { ...defaultCreditCard, isdefault: false };
        await cardApi.upsert(body);
      }
      const body = { ...creditCard, isdefault: val };
      await cardApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getCreditCardList();
    }
  };

  const cardImage = (brand) => {
    switch (brand) {
      case 'Visa':
        return Visa;
      case 'MasterCard':
        return MasterCard;
      case 'American Express':
        return AmericanExpress;
      case 'Discover':
        return Discover;
      default:
        return Visa;
    }
  };

  const getThisYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const expYearOptions = () => {
    const year = getThisYear();
    const options = [];
    for (let i = 0; i <= 10; i++) {
      options.push({
        label: year + i,
        value: year + i,
      });
    }
    return options;
  };

  const expMonthOptions = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        label: i,
        value: i,
      });
    }
    return options;
  };

  const onClickConnectStripe = async (account) => {
    setLoading((prev) => ({ ...prev, upserLoading: true }));
    try {
      await bankAccountApi.connectStipeBankAccount(account.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        display: true,
        message: error?.response?.data?.Message || 'Error',
        type: 'error',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, upserLoading: false }));
      await getBankAccountList();
    }
  };

  useEffect(() => {
    getCreditCardList();
    getBankAccountList();
  }, [companyDetail.id]);
  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-6 sm:gap-x-8">
      <div>
        <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
          <div className="mx-auto max-w-5xl dark:bg-slate-800">
            <div className="sm:flex sm:items-center">
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            {loading.listLoading ? (
              <div className="w-full flex justify-center py-4">
                <Spin />
              </div>
            ) : (
              <div className="mt-2 flow-root">
                <div className="bg-white  sm:rounded-lg">
                  <div className="dark:bg-slate-800">
                    <div className="flex">
                      <div className="sm:flex-auto dark:bg-slate-800">
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                          Credit Cards
                        </h1>
                        <p className="mt-2 text-sm text-gray-700 dark:text-slate-200">
                          View and manage all your credit cards in one place.
                        </p>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <Button
                          disabled={loading.listLoading}
                          onClick={() => onClickAddCreditCard()}
                          type="ghost"
                          className="inline-flex justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
                        >
                          Add Credit Card
                        </Button>
                      </div>
                    </div>
                    {creditCardList.map((card, cardIdx) => (
                      <div key={cardIdx} className="mt-5 shadow">
                        <div className=" sm:items-center rounded-md bg-white dark:bg-slate-700 px-6 py-5 sm:flex  sm:justify-between">
                          <div className=" sm:flex sm:items-center">
                            <XMarkIcon
                              onClick={() => onClickDeleteCreditCard(card)}
                              className="text-red-400 w-5 h-5 cursor-pointer"
                            />
                            <img
                              className="pl-5"
                              src={cardImage(card.brand)}
                              alt="Card Brand"
                            />
                            <div className="mt-3 sm:mt-0 sm:ml-4">
                              <div className="text-sm font-medium text-gray-900 dark:text-slate-400">
                                Ending with {card.last4}
                              </div>
                            </div>
                          </div>
                          <div className="flex space-x-5 mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                            <Switch.Group
                              as="div"
                              className="flex items-center"
                            >
                              <Switch
                                disabled={loading.upsertLoading}
                                checked={card.isdefault}
                                onChange={(val) => {
                                  onChangeDefaultCreditCard(card, val, cardIdx);
                                }}
                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full dark:bg-transparent"
                              >
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute h-full w-full rounded-md bg-transparent"
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    card.isdefault
                                      ? 'bg-blue-600'
                                      : 'bg-red-400',
                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                  )}
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    card.isdefault
                                      ? 'translate-x-5'
                                      : 'translate-x-0',
                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pt-20 dark:bg-slate-800">
                    <div className="flex">
                      <div className="sm:flex-auto dark:bg-slate-800">
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                          Bank Account
                        </h1>
                        <p className="mt-2 text-sm text-gray-700 dark:text-slate-200">
                          This is the bank account will use for payouts
                        </p>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <Button
                          disabled={loading.listLoading}
                          onClick={() => onClickAddBankAccount()}
                          type="ghost"
                          className="inline-flex justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
                        >
                          Add Bank Account
                        </Button>
                      </div>
                    </div>

                    {bankAccountList.map((account, accountIdx) => (
                      <div key={accountIdx} className="mt-5 shadow">
                        <div className=" sm:items-center rounded-md bg-white dark:bg-slate-700 px-6 py-5 sm:flex  sm:justify-between">
                          <div className=" sm:flex sm:items-center">
                            <div className="mt-3 sm:mt-0 sm:ml-4">
                              <div className="text-sm font-bold text-gray-900 dark:text-slate-200">
                                {account.accountholdername}
                              </div>
                              <div className="text-xs  text-gray-400 dark:text-slate-200">
                                {account.accounttype === 10
                                  ? 'Company'
                                  : 'Individual'}
                              </div>
                              <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center dark:text-slate-400">
                                <div>Routing #{account.routingnumber}</div>
                                <div className="pl-5">
                                  Account #{account.accountnumber}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex space-x-5 mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                            <Switch.Group
                              as="div"
                              className="flex items-center"
                            >
                              <Switch
                                disabled={loading.upsertLoading}
                                checked={account.isdefault}
                                onChange={(val) => {
                                  onChangeDefaultBankAccount(
                                    account,
                                    val,
                                    accountIdx
                                  );
                                }}
                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
                              >
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute h-full w-full rounded-md  bg-transparent"
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    account.isdefault
                                      ? 'bg-blue-600'
                                      : 'bg-red-400',
                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                  )}
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    account.isdefault
                                      ? 'translate-x-5'
                                      : 'translate-x-0',
                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                            <Button
                              onClick={() => onClickConnectStripe(account)}
                              type="button"
                              className="inline-flex items-center rounded-md bg-white dark:bg-slate-900 dark:hover:text-sky-400 dark:text-slate-200 dark:ring-0 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Connect Stripe
                            </Button>
                            <Button
                              onClick={() => onClickViewBankAccount(account)}
                              type="button"
                              className="inline-flex items-center rounded-md bg-white dark:bg-slate-900 dark:hover:text-sky-400 dark:text-slate-200 dark:ring-0 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              View
                            </Button>
                            <Button
                              onClick={() => onClickEditBankAccount(account)}
                              type="button"
                              className="inline-flex items-center rounded-md bg-white dark:bg-slate-900 dark:hover:text-sky-400 dark:text-slate-200 dark:ring-0 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Transition.Root show={dialogs.upsertBankAccount} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div className="flex">
                      <div className="grid pr-2 pb-1">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Account Type*
                          </label>
                        </div>
                        <div className="flex">
                          <Radio.Group
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'accounttype'
                              )
                            }
                            value={bankAccountForm.accounttype}
                          >
                            <Radio value={10}>Company</Radio>
                            <Radio value={20} disabled>
                              Individual
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-x-5">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Legal Name*
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.accountholdername}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'accountholdername'
                              )
                            }
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Acme Inc"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.accountholdername && (
                              <p className="text-red-700">
                                Legal Name can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Tax ID*
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.taxid}
                            onChange={(e) =>
                              onFormChangeBankAccount(e.target.value, 'taxid')
                            }
                            maxLength={10}
                            type="text"
                            name="taxid"
                            id="taxid"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="12-3456789"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.taxid && (
                              <p className="text-red-700">
                                Tax ID can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Routing Number*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.routingnumber}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'routingnumber'
                            )
                          }
                          type="number"
                          name="routingnumber"
                          id="routingnumber"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="1234567890"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.routingnumber && (
                            <p className="text-red-700">
                              Routing Number can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-x-5">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Account Number*
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.accountnumber}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'accountnumber'
                              )
                            }
                            type="number"
                            name="accountnumber"
                            id="accountnumber"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="1234567890"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.accountnumber && (
                              <p className="text-red-700">
                                Account Number can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Confirm Account Number*
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.confirmaccountnumber}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'confirmaccountnumber'
                              )
                            }
                            type="number"
                            name="confirmaccountnumber"
                            id="confirmaccountnumber"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="1234567890"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.confirmaccountnumber && (
                              <p className="text-red-700">
                                Account Number does not match
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-bold text-gray-700 dark:text-slate-200"
                      >
                        Please add Account Representative Details
                      </label>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Representative Full Name*
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.reprensentativefullname}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'reprensentativefullname'
                            )
                          }
                          type="text"
                          name="reprensentativefullname"
                          id="reprensentativefullname"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="John Doe"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.reprensentativefullname && (
                            <p className="text-red-700">
                              Representative Full Name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-x-5">
                      {' '}
                      <div>
                        <label
                          htmlFor="code"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Representative Birthday*
                        </label>
                        <div className="mt-1">
                          <DatePicker
                            value={dayjs(
                              bankAccountForm.reprensentativebirthday,
                              'YYYY-MM-DD'
                            )}
                            format={'MM/DD/YYYY'}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                dayjs(e, 'YYYY-MM-DD'),
                                'reprensentativebirthday'
                              )
                            }
                            style={{ width: '100%' }}
                            size="large"
                            placeholder="Select a Representative Birthday"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.reprensentativebirthday && (
                              <p className="text-red-700">
                                Select a Representative Birthday
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Last 4 Digit of SSN*
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.last4ssn}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'last4ssn'
                              )
                            }
                            maxLength={4}
                            type="text"
                            name="last4ssn"
                            id="last4ssn"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="1234"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.last4ssn && (
                              <p className="text-red-700">
                                Last 4 Digit of SSN can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      {bankAccountForm.id && (
                        <Button
                          type="ghost"
                          disabled={loading.deleteLoading}
                          loading={loading.deleteLoading}
                          onClick={() =>
                            onClickDeleteBankAccount(bankAccountForm)
                          }
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                        >
                          <TrashIcon className="text-white w-5 h-5" />
                        </Button>
                      )}
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        className={classNames(
                          bankAccountForm.id
                            ? 'sm:col-span-5 col-span-12'
                            : 'col-span-6',
                          'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-slate-200'
                        )}
                        onClick={() => onCloseUpsertBankAccount()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        loading={loading.upsertLoading}
                        className={classNames(
                          bankAccountForm.id
                            ? 'sm:col-span-5 col-span-12'
                            : 'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmitBankAccount()}
                      >
                        {bankAccountForm.id
                          ? 'Edit Bank Account'
                          : 'Add Bank Account'}
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={dialogs.viewBankAccount} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div className="flex">
                      <div className="grid pr-2 pb-1">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Account Type
                          </label>
                        </div>
                        <div className="flex">
                          <Radio.Group value={bankAccountForm.accounttype}>
                            <Radio value={10}>Company</Radio>
                            <Radio value={20} disabled>
                              Individual
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-5">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Legal Name
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.accountholdername}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'accountholdername'
                              )
                            }
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Acme Inc"
                            readOnly
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.accountholdername && (
                              <p className="text-red-700">
                                Legal Name can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Tax ID
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.taxid}
                            onChange={(e) =>
                              onFormChangeBankAccount(e.target.value, 'taxid')
                            }
                            maxLength={10}
                            type="text"
                            name="taxid"
                            id="taxid"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="12-3456789"
                            readOnly
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.taxid && (
                              <p className="text-red-700">
                                Tax ID can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Routing Number
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.routingnumber}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'routingnumber'
                            )
                          }
                          type="number"
                          name="routingnumber"
                          id="routingnumber"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="1234567890"
                          readOnly
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.routingnumber && (
                            <p className="text-red-700">
                              Routing Number can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-5">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Account Number
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.accountnumber}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'accountnumber'
                              )
                            }
                            type="number"
                            name="accountnumber"
                            id="accountnumber"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="1234567890"
                            readOnly
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.accountnumber && (
                              <p className="text-red-700">
                                Account Number can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Confirm Account Number
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.confirmaccountnumber}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'confirmaccountnumber'
                              )
                            }
                            type="number"
                            name="confirmaccountnumber"
                            id="confirmaccountnumber"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="1234567890"
                            readOnly
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.confirmaccountnumber && (
                              <p className="text-red-700">
                                Account Number does not match
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-bold text-gray-700 dark:text-slate-200"
                      >
                        Account Representative Details
                      </label>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Representative Full Name
                      </label>
                      <div className="mt-1">
                        <input
                          value={bankAccountForm.reprensentativefullname}
                          onChange={(e) =>
                            onFormChangeBankAccount(
                              e.target.value,
                              'reprensentativefullname'
                            )
                          }
                          type="text"
                          name="reprensentativefullname"
                          id="reprensentativefullname"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="John Doe"
                          readOnly
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.reprensentativefullname && (
                            <p className="text-red-700">
                              Representative Full Name can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-5">
                      {' '}
                      <div>
                        <label
                          htmlFor="code"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Representative Birthday
                        </label>
                        <div className="mt-1">
                          <DatePicker
                            value={dayjs(
                              bankAccountForm.reprensentativebirthday,
                              'YYYY-MM-DD'
                            )}
                            format={'MM/DD/YYYY'}
                            style={{ width: '100%' }}
                            size="large"
                            allowClear={false}
                            inputReadOnly
                            open={false}
                            placeholder="Select a Representative Birthday"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.reprensentativebirthday && (
                              <p className="text-red-700">
                                Select a Representative Birthday
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Last 4 Digit of SSN
                        </label>
                        <div className="mt-1">
                          <input
                            value={bankAccountForm.last4ssn}
                            onChange={(e) =>
                              onFormChangeBankAccount(
                                e.target.value,
                                'last4ssn'
                              )
                            }
                            readOnly
                            maxLength={4}
                            type="text"
                            name="last4ssn"
                            id="last4ssn"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="1234"
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.last4ssn && (
                              <p className="text-red-700">
                                Last 4 Digit of SSN can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-2 mt-1  text-xs"></div>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        bankAccountForm.id
                          ? 'sm:col-span-5 col-span-12'
                          : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() =>
                        setDialogs((prev) => ({
                          ...prev,
                          viewBankAccount: false,
                        }))
                      }
                    >
                      Close
                    </Button>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <ModalDelete
          title="Delete Bank Account"
          loading={loading.deleteLoading}
          open={dialogs.deleteBankAccount}
          onCloseRequest={() =>
            setDialogs((prev) => ({ ...prev, deleteBankAccount: false }))
          }
          onOk={onConfirmDeleteBankAccount}
          item={{
            name: deletedBankAccount.accountnumber,
            id: deletedBankAccount.id,
          }}
        />
        <Transition.Root show={dialogs.upsertCreditCard} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Credit Card Number*
                      </label>
                      <div className="mt-1">
                        <input
                          value={creditCardForm.createcardmodel?.number}
                          onChange={(e) =>
                            onFormChangeCreditCard(e.target.value, 'number')
                          }
                          type="text"
                          maxLength={16}
                          minLength={15}
                          name="accountnumber"
                          id="accountnumber"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="1234567890"
                        />
                        <div className="mt-1  text-xs">
                          {errors.number && (
                            <p className="text-red-700">
                              Please enter a valid credit card number
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-4 gap-x-2">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Expiration Year*
                        </label>
                        <div className="mt-1">
                          <Select
                            allowClear
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder="Year"
                            onChange={(val) =>
                              onFormChangeCreditCard(val, 'expyear')
                            }
                            options={expYearOptions()}
                          />
                        </div>

                        <div className="mt-1  text-xs">
                          {errors.expyear && (
                            <p className="text-red-700">
                              Expyear can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Expiration Month*
                        </label>
                        <div className="mt-1">
                          <Select
                            allowClear
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder="Month"
                            onChange={(val) =>
                              onFormChangeCreditCard(val, 'expmonth')
                            }
                            options={expMonthOptions()}
                          />
                          <div className="mt-1  text-xs">
                            {errors.expmonth && (
                              <p className="text-red-700">
                                Expmonth can not be empty
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Cvc*
                        </label>
                        <div className="mt-1">
                          <input
                            value={creditCardForm.createcardmodel?.cvc}
                            onChange={(e) =>
                              onFormChangeCreditCard(e.target.value, 'cvc')
                            }
                            type="text"
                            name="cvc"
                            id="cvc"
                            maxLength={4}
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="123"
                          />
                          <div className="mt-1  text-xs">
                            {errors.cvc && (
                              <p className="text-red-700">
                                Please enter a valid CVC
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Zip Code*
                        </label>
                        <div className="mt-1">
                          <input
                            value={creditCardForm.createcardmodel?.zipcode}
                            onChange={(e) =>
                              onFormChangeCreditCard(e.target.value, 'zipcode')
                            }
                            type="text"
                            name="cvc"
                            id="cvc"
                            maxLength={5}
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="123"
                          />
                          <div className="mt-1  text-xs">
                            {errors.zipcode && (
                              <p className="text-red-700">
                                Please enter a valid zip code
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        className={classNames(
                          'sm:col-span-5 col-span-12',
                          'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-slate-200'
                        )}
                        onClick={() => onCloseUpsertCreditCard()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        loading={loading.upsertLoading}
                        className={classNames(
                          bankAccountForm.id
                            ? 'sm:col-span-5 col-span-12'
                            : 'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmitCreditCard()}
                      >
                        Add Credit Card
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <ModalDelete
          title="Delete Credit Card"
          loading={loading.deleteLoading}
          open={dialogs.deleteCreditCard}
          onCloseRequest={() =>
            setDialogs((prev) => ({ ...prev, deleteCreditCard: false }))
          }
          onOk={onConfirmDeleteCreditCard}
          item={{
            name: deletedCreditCard.last4,
            id: deletedCreditCard.id,
          }}
        />
      </div>
    </div>
  );
};

export default AccountInformation;
