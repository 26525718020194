import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import tokenService from "../../utils/tokenService";
import { Switch } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { setDarkMode } from "../../store/slices/commonSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ThemeChanger = ({ position, onChange, switchMode = false }) => {
  const dispatch = useDispatch();
  const [isDarkMode, setIsDarkMode] = useState(
    tokenService.getTheme() === "dark" ? true : false
  );
  const changeTheme = () => {
    if (isDarkMode) {
      tokenService.setTheme("light");
      dispatch(setDarkMode(false));
      document.documentElement.classList.remove("dark");
      document.body.classList.remove("bg-slate-900");
    } else {
      tokenService.setTheme("dark");
      dispatch(setDarkMode(true));
      document.documentElement.classList.add("dark");
      document.body.classList.add("bg-slate-900");
    }
    setIsDarkMode(!isDarkMode);
    onChange(!isDarkMode);
  };
  return switchMode ? (
    <Switch.Group as="div" className=" w-full">
      <Switch.Label
        as="h3"
        className="text-base font-semibold leading-6 text-gray-900 dark:text-slate-200"
        passive
      >
        Dark Mode{" "}
        {/* <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
          Coming Soon
        </span> */}
      </Switch.Label>
      <div className="mt-2 flex items-start justify-between">
        <div className="max-w-xl text-sm text-gray-500 dark:text-slate-400">
          <Switch.Description>
            Customize your dashboard with the dark mode feature.
          </Switch.Description>
        </div>
        <div className=" sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
          <Switch
            checked={isDarkMode}
            onChange={() => {
              changeTheme();
            }}
            className={classNames(
              isDarkMode ? "bg-blue-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                isDarkMode ? "translate-x-5" : "translate-x-0",
                "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </div>
      </div>
    </Switch.Group>
  ) : (
    <button
      type="button"
      className={`rounded-full shadow bg-white p-1 text-zinc-900 dark:bg-slate-800 hover:text-gray-500 ${position}`}
      onClick={changeTheme}
    >
      <span className="sr-only">View notifications</span>
      {isDarkMode ? (
        <SunIcon className="h-6 w-6 dark:text-slate-200" aria-hidden="true" />
      ) : (
        <MoonIcon className="h-6 w-6 dark:text-slate-200" aria-hidden="true" />
      )}
    </button>
  );
};

export default ThemeChanger;
