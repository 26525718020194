import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function OptionGroupCard({
  id,
  index,
  optionGroup,
  selected,
  setSelected,
  moveOptionGroupItem,
  dropOptionGroupItem,
  onClickOpenOptionGroupUpsertModal,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'optionGroup',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropOptionGroupItem(item.index, optionGroup);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveOptionGroupItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'optionGroup',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={classNames(
        optionGroup.id === selected.optiongroupid
          ? 'text-gray-800 bg-slate-50 border-r-2 hover:bg-slate-50  border-blue-600 dark:text-sky-400 dark:bg-slate-700 dark:border-sky-400'
          : 'text-slate-500  cursor-pointer dark:text-slate-200 hover:bg-white dark:hover:bg-slate-700 hover:text-gray-900',
        isDragging ? 'shadow-lg' : '',
        'flex items-center px-5 py-1 space-x-5 h-[4rem]'
      )}
    >
      <div className="flex-1">
        <div
          key={optionGroup.name}
          className={classNames(
            'flex items-center cursor-pointer  py-2 text-sm font-medium'
          )}
          aria-current={
            optionGroup.id === selected.optiongroupid ? 'page' : undefined
          }
        >
          <div className="overflow-hidden">
            <span className="truncate font-bold whitespace-normal max-h-[2.5em]">
              {optionGroup.name}
            </span>
            <br />
            <span className="text-xs font-light whitespace-normal">
              {optionGroup.description}
            </span>
            <p
              className={classNames(
                optionGroup.id === selected.optiongroupid
                  ? 'text-slate-500'
                  : 'text-slate-400',
                'text-xs font-light whitespace-normal max-h-[2.5em]'
              )}
            >
              Min: {optionGroup.min} - Max: {optionGroup.max}
            </p>
          </div>
        </div>
      </div>

      <div className="flex">
        <PencilSquareIcon
          onClick={() => onClickOpenOptionGroupUpsertModal(optionGroup)}
          className={classNames(
            optionGroup.id === selected.optiongroupid
              ? 'text-gray-800'
              : 'text-slate-500',
            'w-5 h-5 hover:text-gray-800 cursor-pointer dark:text-sky-400 dark:hover:text-sky-900'
          )}
        />
      </div>
    </div>
  );
}

export default OptionGroupCard;
