import React from 'react';
import moment from 'moment';
import { OrderTypes, PaymentStatus } from '../../../data/enums';
import { formatPhoneNumber } from '../../../utils/baseUtils';
import LogoBlack from '../../../assets/logo-black.png';

const Receipt = ({ orderDetail }) => {
  return (
    <div
      className="border border-black py-8 px-3 text-center grid dark:border-slate-200 dark:text-slate-200"
      style={{ width: '300px' }}
    >
      <span className="text-sm font-extrabold">{orderDetail.store.name}</span>
      <span className="text-[8px] font-normal">
        {orderDetail.store.address}
      </span>
      <span className="text-[8px] font-normal text-[#444444] dark:text-slate-300">
        {orderDetail.store.city} {orderDetail.store.postalcode}
      </span>
      <span className="text-[8px] font-normal">{orderDetail.store.phone}</span>
      <span className="pt-1 text-[50px] font-[800]">
        {orderDetail.ordernumberstr}
      </span>
      <span className="text-[14px] font-[800]">
        {OrderTypes[orderDetail.ordertype]}{' '}
        <span className="text-[14px] font-[600] italic">
          {orderDetail.table ? `(Table ${orderDetail.table})` : ''}
        </span>
      </span>
      <div className="pt-6 flex justify-between items-center">
        <span className="text-[20px] font-[800] leading-6">
          {PaymentStatus[orderDetail.paymentstatus]?.toUpperCase()}
        </span>
        <span className="text-[8px] font-[400]">
          {moment(orderDetail.createdate)
            .utcOffset(orderDetail?.timezone * 60)
            .format('MM/DD/YYYY hh:mm A') || ''}
        </span>
      </div>
      <div className="w-full border-t-[0.5px] border-black dark:border-slate-200"></div>
      <div className="flex justify-between items-center">
        <span className="text-[8px] font-normal">
          Name: {orderDetail.name || orderDetail.customer?.fullname || '-'}
        </span>
        <span className="text-[8px] font-normal text-[#444444] dark:text-slate-300">
          Kiosk: {orderDetail.terminal?.name}
        </span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-[8px] font-normal">
          Phone:{' '}
          {formatPhoneNumber(orderDetail.phone || orderDetail.customer.phone)}
        </span>
        <span className="text-[8px] font-normal text-[#444444] dark:text-slate-300">
          Order # {orderDetail.orderid}
        </span>
      </div>
      <div className="w-full border-t-[0.5px] border-black dark:border-slate-200"></div>
      <div className="pt-1 block justify-between items-center">
        {orderDetail.orderitems.map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-12 justify-between items-center"
          >
            <div className="col-span-1 whitespace-nowrap self-start">
              <span className="text-[12px] font-[700]">{item.quantity} x </span>
            </div>
            <div className="col-span-9 flex">
              <div key={index} className="grid pb-3 pl-4 text-left">
                <span className="text-[12px] font-[600] leading-3 pb-1">
                  {item.item.product.name}
                </span>
                {item.item.product.name !== item.item.name && (
                  <span className="text-[8px] font-[400] text-[#444444] dark:text-slate-300">
                    {item.item.name}{' '}
                    {item.item.price.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </span>
                )}
                {item.orderitemoptions.map((option, index) => (
                  <div key={index} className="grid pl-2">
                    <span className="text-[8px] font-[800] text-[#444444] dark:text-slate-300">
                      {option.name}{' '}
                      <span className="text-[8px] italic font-[800]">
                        {option.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </span>
                    </span>
                    {item.note && (
                      <span className="text-[8px] italic font-[400]">
                        "{item.note}"
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-2 self-start">
              <span className="self-start text-[14px] font-[700]">
                {(
                  item.quantity *
                  (item.price +
                    item.orderitemoptions.reduce((total, currentOption) => {
                      return total + currentOption.price;
                    }, 0))
                ).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full border-t-[0.5px] border-black dark:border-slate-200"></div>
      <div className="grid grid-cols-2 justify-between">
        <div></div>
        <div className="py-2">
          <div className="flex justify-between text-[10px] font-[600]">
            <span>Subtotal</span>
            <span>
              {orderDetail.subtotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          </div>
          <div className="flex justify-between text-[10px] font-[600]">
            <span>Tax & Fees</span>
            <span>
              {(
                orderDetail.tax +
                orderDetail.fee +
                orderDetail.crvtax
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          </div>
          {orderDetail.tip !== 0 && (
            <div className="flex justify-between text-[10px] font-[600]">
              <span>Tip</span>
              <span>
                {orderDetail.tip.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </span>
            </div>
          )}
          {orderDetail.discount !== 0 && (
            <div className="flex justify-between text-[10px] font-[600]">
              <span>Discount</span>
              <span>
                {'- ' +
                  orderDetail.discount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
              </span>
            </div>
          )}
          {orderDetail.refund !== 0 && (
            <div className="flex justify-between text-[10px] font-[600]">
              <span>Refund</span>
              <span>
                {'- ' +
                  orderDetail.refund.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="w-full border-t-[0.5px] border-black dark:border-slate-200"></div>
      <div className="grid grid-cols-2 justify-between">
        <div className="flex items-center">
          {/*    <span className="text-[8px] font-[400]">Visa - xx1234</span> */}
        </div>
        <div className="py-2">
          <div className="flex justify-between text-[14px] font-[800]">
            <span>Total</span>
            <span>
              {(orderDetail.refund
                ? orderDetail.total - orderDetail.refund
                : orderDetail.total
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          </div>
        </div>
      </div>
      {orderDetail.note && (
        <div className=" border-dashed border rounded-md border-black flex min-h-[2rem] dark:border-slate-200">
          <span
            className="text-[10px] font-[400] italic"
            style={{ color: '#444444' }}
          >
            {orderDetail.note}
          </span>
        </div>
      )}
      <span className="pt-4 text-[14] font-[700] pb-4">Thank You</span>
      <div className="flex justify-center pb-1">
        <span className="text-[5px] font-[300]">Powered by</span>
        <img
          className="h-2 w-auto pl-[1px]"
          src={LogoBlack}
          alt="Company Logo"
        />
      </div>
    </div>
  );
};

export default Receipt;
