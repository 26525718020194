import React, { useState, useEffect } from 'react';
import { BuildingOfficeIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useParams, Link } from 'react-router-dom';
import { Select, Spin } from 'antd';
import storeApi from '../../../services/storeApi';
import {
  BuildingStorefrontIcon,
  Cog8ToothIcon,
  TagIcon,
  ClockIcon,
  BriefcaseIcon,
  ArrowTopRightOnSquareIcon,
  ReceiptPercentIcon,
} from '@heroicons/react/24/outline';
import { useSpringRef, useTransition, animated } from '@react-spring/web';
import StoreTab from './tabs/store-tab';
import OrderTab from './tabs/order-tab';
import BrandingTab from './tabs/branding-tab';
import TimezoneTab from './tabs/timezone-tab';
import DangerZoneTab from './tabs/danger-zone-tab';
import TaxesTab from './tabs/taxes-tab';
import WorkingHoursTab from './tabs/working-hours-tab';
import StoreSlugUpsertModal from './components/store-slug-upsert-modal';
import Notification from '../../../components/notification/notification';
import NoStoreLogo from '../../../assets/no-store-logo.svg';

const tabs = [
  {
    name: 'Store',
    icon: BuildingStorefrontIcon,
    id: 0,
  },
  {
    name: 'Order Settings',
    icon: Cog8ToothIcon,
    id: 1,
  },
  { name: 'Branding', icon: TagIcon, id: 2 },
  { name: 'Taxes', icon: ReceiptPercentIcon, id: 3 },
  { name: 'Timezone', icon: ClockIcon, id: 4 },
  { name: 'Working Hours', icon: BriefcaseIcon, id: 5 },
  { name: 'Danger Zone', icon: TrashIcon, id: 6 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StoreSettings = () => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [storeDetail, setStoreDetail] = useState({});
  const [dialogs, setDialogs] = useState({
    slugUpsert: false,
  });
  const [loading, setLoading] = useState({
    initialLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    loginAsCompany: false,
  });
  const [errors, setErrors] = useState({
    slug: false,
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [isNext, setIsNext] = useState(false);

  const transRef = useSpringRef();
  const transitions = useTransition(currentTab, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: isNext ? 'translate3d(10%,0,0)' : 'translate3d(-10%,0,0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: isNext ? 'translate3d(-10%,0,0)' : 'translate3d(10%,0,0)',
    },
  });

  const tabComponents = [
    <StoreTab
      storeDetail={storeDetail}
      checkUpdate={(val, msg) => checkUpdate(val, msg)}
    />,
    <OrderTab
      storeDetail={storeDetail}
      checkUpdate={(val) => checkUpdate(val)}
    />,
    <BrandingTab
      storeDetail={storeDetail}
      checkUpdate={(val) => checkUpdate(val)}
    />,
    <TaxesTab
      storeDetail={storeDetail}
      checkUpdate={(val) => checkUpdate(val)}
    />,
    <TimezoneTab
      storeDetail={storeDetail}
      checkUpdate={(val) => checkUpdate(val)}
    />,
    <WorkingHoursTab
      storeDetail={storeDetail}
      checkUpdate={(val) => checkUpdate(val)}
    />,
    <DangerZoneTab
      storeDetail={storeDetail}
      checkUpdate={(val) => checkUpdate(val)}
    />,
  ];

  const checkUpdate = (val, msg) => {
    if (val) {
      getStoreDetail(true);
      setNotf((prev) => ({
        ...prev,
        message: 'Store Updated Successfully',
        display: true,
        type: 'success',
      }));
    } else {
      setNotf((prev) => ({
        ...prev,
        message: msg || 'Store Update Failed',
        display: true,
        type: 'error',
      }));
    }
  };

  useEffect(() => {
    transRef.start();
  }, [currentTab]);

  useEffect(() => {
    getStoreDetail();
  }, []);

  const getStoreDetail = async (withoutLoader = false) => {
    try {
      setLoading((prev) => ({
        ...prev,
        initialLoading: withoutLoader ? false : true,
      }));
      const res = await storeApi.getById(id);
      if (res.data) {
        setStoreDetail(res.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const onClickTab = (tab) => {
    if (tab.id > currentTab) {
      setIsNext(true);
    } else {
      setIsNext(false);
    }
    setCurrentTab(tab.id);
  };

  const onClickTabMobile = (id) => {
    if (id > currentTab) {
      setIsNext(true);
    } else {
      setIsNext(false);
    }
    setCurrentTab(id);
  };

  const onCloseStoreSlugModal = () => {
    setErrors((prev) => ({ ...prev, slug: false }));

    setDialogs((prev) => ({ ...prev, slugUpsert: false }));
  };

  const onClickOpenStoreSlugModal = () => {
    setDialogs((prev) => ({ ...prev, slugUpsert: true }));
  };

  const onSubmitStoreSlugModal = async (slug) => {
    if (slug === '' || !slug) {
      setErrors((prev) => ({ ...prev, slug: true }));
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...storeDetail, slug };
      const formData = new FormData();

      for (const key of Object.keys(body)) {
        formData.append(key, body[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);

      if (res.data) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false, error);
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      onCloseStoreSlugModal();
    }
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      {loading.initialLoading ? (
        <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
          <Spin />
        </div>
      ) : (
        <div className="space-y-5 mx-auto max-w-5xl">
          <div className=" flex items-center justify-between pb-8 sm:pb-0 ">
            <div className="min-w-0 flex-1 justify-between grid sm:flex">
              <div className="flex items-center">
                <img
                  className="inline-flex h-14 w-14 items-center justify-center rounded-full"
                  src={storeDetail.storelogo || NoStoreLogo}
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 dark:text-slate-200 sm:truncate sm:leading-9">
                      {storeDetail.name}
                    </h1>
                  </div>
                  <dl className="flex pl-2 sm:pl-0 flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Terminal</dt>
                    <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                      <BuildingOfficeIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {storeDetail.address}
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="flex items-center cursor-pointer sm:pt-0 pt-2">
                {storeDetail.slug && storeDetail.slug !== '' ? (
                  <Link
                    to={`${process.env.REACT_APP_ORDER_URL}/${storeDetail.slug}`}
                    target="_blank"
                  >
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-3 py-2 text-sm font-medium text-green-700">
                      <svg
                        className="h-1.5 w-1.5 fill-green-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {process.env.REACT_APP_ORDER_URL.replace('https://', '')}/
                      {storeDetail.slug}
                      <ArrowTopRightOnSquareIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-700"
                        aria-hidden="true"
                      />
                    </span>
                  </Link>
                ) : (
                  <span
                    onClick={() => onClickOpenStoreSlugModal()}
                    className="cursor-pointer inline-flex items-center rounded-md bg-yellow-100 px-3 py-2 text-sm font-medium text-yellow-800"
                  >
                    Create Order Link
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="sm:hidden">
              <Select
                size="large"
                style={{
                  width: '100%',
                }}
                placeholder="Please select"
                value={currentTab}
                onChange={(val) => onClickTabMobile(val)}
                options={tabs.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
              />
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200 dark:border-slate-700">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      onClick={() => onClickTab(tab)}
                      key={tab.id}
                      href={tab.href}
                      className={classNames(
                        currentTab === tab.id
                          ? 'border-blue-500 text-blue-600 dark:border-sky-400 dark:text-sky-500'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-slate-300',
                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                      )}
                      aria-current={
                        currentTab === tab.name ? 'page' : undefined
                      }
                    >
                      <tab.icon
                        className={classNames(
                          currentTab === tab.id
                            ? 'text-blue-500  dark:text-sky-400'
                            : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div>
            {transitions((style, item) => (
              <animated.div
                style={{
                  ...style,
                }}
              >
                {tabComponents[item]}
              </animated.div>
            ))}
          </div>
        </div>
      )}

      <StoreSlugUpsertModal
        onSubmit={onSubmitStoreSlugModal}
        show={dialogs.slugUpsert}
        loading={loading.upsertLoading}
        storeDetail={storeDetail}
        setStoreDetail={setStoreDetail}
        onClose={onCloseStoreSlugModal}
        errors={errors}
      />

      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default StoreSettings;
