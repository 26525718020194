import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

const libraries = ['places'];

const AddressAutocomplete = ({ onChange, value }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries,
  });

  return !isLoaded ? (
    <div>
      <input
        disabled={true}
        type="text"
        name="name"
        id="name"
        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
        placeholder=""
      />
    </div>
  ) : (
    <PlacesAutoComplete initValue={value} onChange={(e) => onChange(e)} />
  );
};

const PlacesAutoComplete = ({ initValue, onChange }) => {
  const autoCompleteRef = useRef(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us'] },
    },
    debounce: 600,
  });
  const handleInput = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setValue(initValue, false);
  }, [initValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        autoCompleteRef.current &&
        !autoCompleteRef.current.contains(event.target)
      ) {
        clearSuggestions();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autoCompleteRef]);

  const handleSelect = async (place_id) => {
    let street = '',
      city = '',
      state = '',
      zipCode = '',
      latitude = null,
      longitude = null;

    const parameter = {
      placeId: place_id,
      fields: ['address_component', 'geometry'],
    };

    try {
      const res = await getDetails(parameter);
      const keys = {
        streetNumber: 'street_number',
        streetName: 'route',
        city: 'locality',
        state: 'administrative_area_level_1',
        zipCode: 'postal_code',
      };

      res.address_components.forEach((element) => {
        if (element.types.includes(keys.streetNumber))
          street = element.long_name;
        else if (element.types.includes(keys.streetName))
          street += ' ' + element.long_name;
        else if (element.types.includes(keys.city)) city = element.long_name;
        else if (element.types.includes(keys.state)) state = element.long_name;
        else if (element.types.includes(keys.zipCode))
          zipCode = element.long_name;
      });

      if (res.geometry) {
        latitude = res.geometry.location.lat();
        longitude = res.geometry.location.lng();
      }

      onChange({ street, city, state, zipCode, latitude, longitude });
      clearSuggestions();
    } catch (error) {
      console.error(error);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="p-2 text-sm text-black border-b cursor-pointer hover:bg-gray-50"
          key={place_id}
          onClick={() => handleSelect(place_id)}
        >
          {main_text} {secondary_text}
        </li>
      );
    });

  return (
    <div ref={autoCompleteRef} className="relative z-[999]">
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        type="text"
        name="name"
        id="name"
        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
        placeholder=""
      />
      {status === 'OK' && (
        <ul className="shadow-md border rounded-lg bg-white absolute z-[999]">
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default AddressAutocomplete;
