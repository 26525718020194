import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  BuildingOfficeIcon,
  EllipsisHorizontalIcon,
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/20/solid';
import { Link, useParams } from 'react-router-dom';
import { Button, Spin } from 'antd';
import storeApi from '../../../services/storeApi';
import terminalApi from '../../../services/terminalApi';
import { Dialog, Menu, Transition } from '@headlessui/react';
import ModalDelete from '../../../components/modal-delete/modal-delete';
import StoreSlugUpsertModal from '../store-settings/components/store-slug-upsert-modal';
import NoStoreLogo from '../../../assets/no-store-logo.svg';
import kitchenterminalApi from '../../../services/kitchenterminalApi';
import KioskLogo from '../../../assets/kiosk-logo.png';
import KitchenTerminalLogo from '../../../assets/kitchen-terminal-logo.png';
import Notification from '../../../components/notification/notification';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { QRCodeCanvas } from 'qrcode.react';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.Ls.en.weekStart = 1;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StoreDetail = () => {
  const { id } = useParams();

  const cancelButtonRef = useRef(null);
  const [storeDetail, setStoreDetail] = useState({});
  const [terminalList, setTerminalList] = useState([]);
  const [kitchenterminalList, setKitchenTerminalList] = useState([]);

  const [isKitchenTerminal, setIsKitchenTerminal] = useState(false);

  const [form, setForm] = useState({
    id: '',
    code: '',
    name: '',
    serialnumber: '',
    storeid: id,
  });
  const [loading, setLoading] = useState({
    initialLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    loginAsCompany: false,
  });

  const [errors, setErrors] = useState({
    code: false,
    name: false,
    slug: false,
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
    slugUpsert: false,
  });

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onFormSubmit = async () => {
    if (!form.code || !form.name) {
      setErrors((prev) => ({
        ...prev,
        code: !form.code ? true : false,
        name: !form.name ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, code: false, name: false }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = {
        ...form,
        serialnumber: isKitchenTerminal ? '' : form.serialnumber,
      };
      const res = isKitchenTerminal
        ? await kitchenterminalApi.upsert(body)
        : await terminalApi.upsert(body);
      if (res.data.data) {
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      if (isKitchenTerminal) await getKitchenTerminalList();
      else await getTerminalList();
    }
  };

  const onClickAddTerminal = (isKitchen) => {
    setIsKitchenTerminal(isKitchen);
    setForm({ id: '', code: '', serialnumber: '', name: '', storeid: id });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onClickEdit = (val, isKitchen) => {
    setIsKitchenTerminal(isKitchen);
    setForm({
      id: val.id,
      code: val.code,
      name: val.name,
      serialnumber: val.serialnumber,
      storeid: val.storeid,
    });

    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({ ...prev, code: false, name: false }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onClickOpenStoreSlugModal = () => {
    setDialogs((prev) => ({ ...prev, slugUpsert: true }));
  };

  const onCloseStoreSlugModal = () => {
    setErrors((prev) => ({ ...prev, slug: false }));

    setDialogs((prev) => ({ ...prev, slugUpsert: false }));
  };

  const onSubmitStoreSlugModal = async (slug) => {
    if (slug === '' || !slug) {
      setErrors((prev) => ({ ...prev, slug: true }));
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...storeDetail, slug };
      const formData = new FormData();

      for (const key of Object.keys(body)) {
        formData.append(key, body[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);

      if (res.data) {
        await getStoreDetail();
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        upsertLoading: false,
        initialLoading: false,
      }));
      onCloseStoreSlugModal();
    }
  };

  useEffect(() => {
    getStoreDetail();
    getTerminalList();
    getKitchenTerminalList();
  }, []);

  const getStoreDetail = async () => {
    try {
      setLoading((prev) => ({ ...prev, initialLoading: true }));
      const res = await storeApi.getById(id);
      if (res.data) {
        setStoreDetail(res.data.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const getTerminalList = async () => {
    try {
      const query = `?storeid=${id}&isdeleted=false`;
      const res = await terminalApi.list(query);
      if (res.data) {
        setTerminalList(res.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const getKitchenTerminalList = async () => {
    try {
      const res = await kitchenterminalApi.list(id);
      if (res.data) {
        setKitchenTerminalList(res.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const onClickDeleteKiosk = () => {
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      if (isKitchenTerminal) {
        await kitchenterminalApi.deleteById(form.id);
        getKitchenTerminalList();
      } else {
        await terminalApi.deleteById(form.id);
        getTerminalList();
      }
    } catch (error) {
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false, upsert: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const downloadQR = () => {
    const canvas = document.getElementById('qrCodeEl');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${storeDetail.slug}-QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      {loading.initialLoading ? (
        <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
          <Spin />
        </div>
      ) : (
        <div className="space-y-10 mx-auto max-w-5xl">
          <div className=" md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <img
                  className="inline-flex h-14 w-14 items-center justify-center rounded-full"
                  src={storeDetail.storelogo || NoStoreLogo}
                  alt=""
                />

                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9 dark:text-slate-200">
                      {storeDetail.name}
                    </h1>
                  </div>
                  <dl className="flex pl-2 sm:pl-0 flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Terminal</dt>
                    <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                      <BuildingOfficeIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {storeDetail.address}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="flex items-center cursor-pointer">
              {storeDetail.slug && storeDetail.slug !== '' ? (
                <Link
                  to={`${process.env.REACT_APP_ORDER_URL}/${storeDetail.slug}`}
                  target="_blank"
                >
                  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-3 py-2 text-sm font-medium text-green-700">
                    <svg
                      className="h-1.5 w-1.5 fill-green-500"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    {process.env.REACT_APP_ORDER_URL.replace('https://', '')}/
                    {storeDetail.slug}
                    <ArrowTopRightOnSquareIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-700"
                      aria-hidden="true"
                    />
                  </span>
                </Link>
              ) : (
                <span
                  onClick={() => onClickOpenStoreSlugModal()}
                  className="cursor-pointer inline-flex items-center rounded-md bg-yellow-100 px-3 py-2 text-sm font-medium text-yellow-800"
                >
                  Create Order Link
                </span>
              )}
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <Link to={'settings'}>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 bg-white dark:bg-slate-900 dark:text-slate-200 dark:border-slate-900 px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm"
                >
                  Store Settings
                </button>
              </Link>
            </div>
          </div>

          <div className="min-h-[200px]">
            <div className="sm:flex sm:items-center border-b border-gray-300 dark:border-slate-600 pb-4">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                  Kiosks
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-slate-400">
                  Create new and manage all your kiosks
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Button
                  type="button"
                  onClick={() => onClickAddTerminal(false)}
                  className="block rounded-md bg-blue-600 h-10 px-3 text-center text-sm font-semibold leading-6 text-white dark:bg-slate-900 dark:text-slate-200 shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="mt-2 flow-root">
              <ul
                role="list"
                className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
              >
                {terminalList.map((terminal, index) => (
                  <li
                    key={index}
                    className="overflow-hidden rounded-xl border border-gray-200 dark:border-slate-700"
                  >
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 dark:bg-slate-700 p-6">
                      <img
                        src={KioskLogo}
                        alt={terminal.name}
                        className="h-12 w-12 flex-none rounded-lg bg-white  object-cover ring-1 ring-gray-900/10"
                      />
                      <div className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">
                        {terminal.name}
                      </div>
                      <Menu as="div" className="relative ml-auto">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => onClickEdit(terminal, false)}
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  Edit
                                  <span className="sr-only">
                                    , {terminal.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 dark:divide-slate-500 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Status</dt>
                        <dd className="text-gray-700">
                          {terminal.isconnected ? (
                            <span className="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                              <svg
                                className="h-1.5 w-1.5 fill-green-500"
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              Active
                            </span>
                          ) : (
                            <span className="inline-flex items-center gap-x-1.5 rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                              <svg
                                className="h-1.5 w-1.5 fill-red-500"
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              Disconnected
                            </span>
                          )}
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Payment SN</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900 dark:text-slate-200">
                            {terminal.serialnumber
                              ? terminal.serialnumber
                              : '-'}
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="pb-4 min-h-[200px]">
            <div className="sm:flex sm:items-center border-b border-gray-300 dark:border-slate-600 pb-4">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                  Kitchen Terminals
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-slate-400">
                  Create new and manage all your Kitchen Terminals
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Button
                  onClick={() => onClickAddTerminal(true)}
                  type="button"
                  className="block rounded-md bg-blue-600 h-10  px-3 text-center text-sm font-semibold leading-6 text-white dark:bg-slate-900 dark:text-slate-200 shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="mt-2 flow-root">
              <ul
                role="list"
                className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
              >
                {kitchenterminalList.map((terminal, index) => (
                  <li
                    key={index}
                    className="overflow-hidden rounded-xl border border-gray-200 dark:border-slate-700"
                  >
                    <div className="flex items-center gap-x-4 border-b  border-gray-900/5 bg-gray-50 dark:bg-slate-700 p-6">
                      <img
                        src={KitchenTerminalLogo}
                        alt={terminal.name}
                        className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                      />
                      <div className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">
                        {terminal.name}
                      </div>
                      <Menu as="div" className="relative ml-auto">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => onClickEdit(terminal, true)}
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  Edit
                                  <span className="sr-only">
                                    , {terminal.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 dark:divide-slate-500 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Status</dt>
                        <dd className="text-gray-700">
                          <span className="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                            <svg
                              className="h-1.5 w-1.5 fill-green-500"
                              viewBox="0 0 6 6"
                              aria-hidden="true"
                            >
                              <circle cx={3} cy={3} r={3} />
                            </svg>
                            Active
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pb-4 min-h-[200px]">
            <div className="sm:flex sm:items-center border-b border-gray-300 dark:border-slate-600 pb-4">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                  QR Code
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-slate-400">
                  Scan this QR code to open the store
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            <div className="mt-2 flow-root">
              <ul
                role="list"
                className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8"
              >
                <>
                  <li className="overflow-hidden rounded-xl border border-gray-200 dark:border-slate-700">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 dark:bg-slate-700 p-6">
                      <QRCodeCanvas
                        id="qrCodeEl"
                        value={`${process.env.REACT_APP_ORDER_URL}/${storeDetail.slug}`}
                        size={256}
                        level={'H'}
                        includeMargin={true}
                      />
                      <div className="text-sm font-bold leading-6 text-gray-900 dark:text-slate-200">
                        {storeDetail.name}
                      </div>

                      <ArrowDownTrayIcon
                        onClick={downloadQR}
                        className="h-5 w-5 mr-1 cursor-pointer flex-shrink-0 text-gray-400 hover:text-gray-500 dark:text-slate-200 dark:hover:text-sky-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                </>
              </ul>
            </div>
          </div>
        </div>
      )}
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      {isKitchenTerminal
                        ? 'Kitchen Terminal Name*'
                        : 'Kiosk Name*'}
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder={
                          isKitchenTerminal
                            ? 'e.g “Kitchen 1, etc.”'
                            : 'e.g “Kiosk 1, etc.”'
                        }
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">Name can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="verificationcode"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Verification Code*
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.code}
                        onChange={(e) => onFormChange(e.target.value, 'code')}
                        type="text"
                        name="code"
                        id="code"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.code && (
                          <p className="text-red-700">Code can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {!isKitchenTerminal && (
                    <div>
                      <label
                        htmlFor="serialnumber"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Serial Number
                      </label>
                      <div className="mt-1">
                        <input
                          value={form.serialnumber}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'serialnumber')
                          }
                          type="text"
                          name="serialnumber"
                          id="serialnumber"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        />
                      </div>
                    </div>
                  )}
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 pt-2 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-12 sm:gap-3">
                    {form.id && (
                      <Button
                        type="ghost"
                        disabled={loading.deleteLoading}
                        loading={loading.deleteLoading}
                        onClick={() => onClickDeleteKiosk()}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        form.id ? 'sm:col-span-5' : 'col-span-6',
                        'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        form.id ? 'sm:col-span-5' : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      {isKitchenTerminal
                        ? form.id
                          ? 'Edit Kitchen Terminal'
                          : 'Add Kitchen Terminal'
                        : form.id
                        ? 'Edit Terminal'
                        : 'Add Terminal'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <StoreSlugUpsertModal
        onSubmit={onSubmitStoreSlugModal}
        show={dialogs.slugUpsert}
        loading={loading.upsertLoading}
        storeDetail={storeDetail}
        setStoreDetail={setStoreDetail}
        onClose={onCloseStoreSlugModal}
        errors={errors}
      />
      <ModalDelete
        title={
          isKitchenTerminal ? 'Delete Kitchen Terminal' : 'Delete Terminal'
        }
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={form}
      />
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default StoreDetail;
