import { configureStore } from "@reduxjs/toolkit";
//Store Reducers
import authReducer from "./slices/authSlice";
import companyReducer from "./slices/companySlice";
import commonReducer from "./slices/commonSlice";
const store = configureStore({
  reducer: { authReducer, companyReducer, commonReducer },
});

export default store;
