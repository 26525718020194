import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Pusher from 'pusher-js';
import { useParams } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/20/solid';
import ToGo from '../../../assets/togo.png';
import DineIn from '../../../assets/dine-in.png';
import NoOrder from '../../../assets/no-orders.svg';
import {
  CheckIcon,
  ReceiptRefundIcon,
  PrinterIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline';
import { Button, Spin, Pagination } from 'antd';
import orderApi from '../../../services/orderApi';
import moment from 'moment';
import NotificationSound from '../../../assets/notification-sound.mp3';
import { OrderStatus, OrderTypes, PaymentStatus } from '../../../data/enums';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function LiveOrders() {
  const { storeid } = useParams();
  const [selectedExtraTimes, setSelectedExtraTimes] = useState(null);
  const [dialogs, setDialogs] = useState({
    refund: false,
  });
  const [form, setForm] = useState({
    refund: 0,
  });
  const [errors, setErrors] = useState({
    refund: false,
  });
  const [loading, setLoading] = useState({
    orderList: true,
  });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });
  const [orderList, setOrderList] = useState([]);
  const [tabs, setTabs] = useState([
    { name: 'Active', href: '#', current: true },
    { name: 'Completed', href: '#', current: false },
  ]);

  const extraTimes = [
    {
      name: '10 min',
      value: 10,
    },
    {
      name: '15 min',
      value: 15,
    },
    {
      name: '20 min',
      value: 20,
    },
    {
      name: '25 min',
      value: 25,
    },
    {
      name: '30+ min',
      value: 30,
    },
  ];

  const audioPlayer = useRef(null);
  const cancelButtonRef = useRef(null);

  const orderStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-yellow-100 text-yellow-800';
      case 10:
        return 'bg-blue-100 text-blue-800';
      case 20:
        return 'bg-purple-100 text-purple-800';
      case 30:
        return 'bg-gray-100 text-gray-800';
      case 40:
        return 'bg-pink-100 text-pink-800';
      case 100:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const onClickSelectOrder = (order) => {
    setSelectedOrder(order);
    setSelectedExtraTimes(null);
  };

  let orderstatuses;

  const currentTab = tabs.find((tab) => tab.current).name;

  if (currentTab === 'Active') {
    orderstatuses = [0, 10, 20];
  } else {
    orderstatuses = [30, 40, 100];
  }

  const getOrderList = async () => {
    setLoading((prev) => ({ ...prev, orderList: true }));

    const query = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      deleted: false,
      storeid: storeid,
      orderstatuses,
      paymentstatus: 20,
    };

    try {
      const list = await orderApi.list(query);
      if (list.data.data.length > 0) {
        setOrderList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      } else {
        setOrderList([]);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, orderList: false }));
    }
  };

  const onChangeOrderStatus = async (orderstatus, acceptplus) => {
    try {
      const body = {
        orderid: selectedOrder.id,
        orderstatus,
        acceptplus: acceptplus || 0,
      };
      const changedStatusOrder = await orderApi.changeOrderStatus(body);
      setSelectedOrder(changedStatusOrder.data.data);
    } catch (error) {
    } finally {
      setSelectedExtraTimes(null);
    }
  };

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickRefund = () => {
    setForm({
      refund: (selectedOrder.total - selectedOrder.refund).toFixed(2),
      orderstatus: 40,
      orderid: selectedOrder.id,
    });
    setDialogs((prev) => ({ ...prev, refund: true }));
  };

  const onFormRefundSubmit = async () => {
    if (!form.refund || form.refund > selectedOrder.total || form.refund < 0) {
      setErrors((prev) => ({
        ...prev,
        refund:
          !form.refund || form.refund > selectedOrder.total || form.refund < 0
            ? true
            : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        refund: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, orderList: true }));
      const body = { ...form };
      await orderApi.changeOrderStatus(body);
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, orderList: false }));
      setDialogs((prev) => ({ ...prev, refund: false }));
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      refund: false,
    }));
    setDialogs((prev) => ({ ...prev, refund: false }));
  };

  const calculateItemsCount = (items) => {
    const itemsCount = items.reduce((total, item) => {
      return total + item.quantity;
    }, 0);

    return itemsCount > 1 ? `${itemsCount} items` : `${itemsCount} item`;
  };

  useEffect(() => {
    getOrderList();
  }, [tabs, pagination.pageNumber]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_TOKEN, {
      cluster: 'us3',
    });
    const channel = pusher.subscribe(storeid);

    const callback = (eventName, data) => {
      if (eventName === 'new_order') {
        audioPlayer.current.play();
        getOrderList();
      } else if (eventName === 'update_order') {
        getOrderList();
      }
    };
    channel.bind_global(callback);

    return () => {
      pusher.unsubscribe(storeid);
    };
  }, []);

  return (
    <div className="flex flex-1 flex-col  min-h-[92vh]  relative z-0 overflow-hidden  bg-white dark:bg-slate-800">
      <div className="flex-1 grid grid-cols-4 flex-row ">
        <div
          className={classNames(
            selectedOrder ? 'hidden lg:block' : 'block',
            'col-span-4 min-w-[20rem] border border-t-0 border-l-0  border-r-1  sm:col-span-4 md:col-span-4 lg:col-span-1  h-screen overflow-y-auto hide-scrollbar dark:border-slate-700'
          )}
        >
          <div className="border-b border-gray-200 dark:border-slate-700 bg-white dark:bg-slate-900 dark:border-slate-700  px-4 py-10 sm:px-6 ">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-slate-200">
                  Live Orders
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                {tabs.map((item, i) => (
                  <a
                    onClick={() => {
                      setTabs(
                        tabs.map((tab) =>
                          tab.name === item.name
                            ? { ...tab, current: true }
                            : { ...tab, current: false }
                        )
                      );
                    }}
                    key={i}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'border-blue-500 text-gray-900 dark:text-slate-200'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-slate-400',
                      'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-md bg-white dark:bg-slate-900 shadow  cursor-pointer">
            {loading.orderList ? (
              <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
                <Spin />
              </div>
            ) : (
              <>
                <ul role="list" className="min-h-screen">
                  {orderList.length > 0 ? (
                    orderList.map((order) => (
                      <li
                        key={order.id}
                        onClick={() => onClickSelectOrder(order)}
                        className={classNames(
                          order.id === selectedOrder?.id
                            ? 'bg-gray-100 dark:bg-slate-600'
                            : '',
                          'px-4 py-4 hover:bg-gray-50 border-b border-gray-200 dark:border-slate-700  dark:hover:bg-slate-500'
                        )}
                      >
                        <a className="block">
                          <div className="flex items-center ">
                            <div className="flex min-w-0 flex-1 items-center">
                              <div className="flex-shrink-0 pr-4">
                                <img
                                  className="h-12 w-12 rounded-full"
                                  src={order.ordertype === 10 ? DineIn : ToGo}
                                  alt="ordertype"
                                />
                              </div>
                              <div className="min-w-0 grid grid-cols-2 w-full">
                                <div>
                                  <p className="truncate text-2xl font-extrabold text-black dark:text-slate-200">
                                    {order.ordernumberstr}
                                  </p>
                                  <p className=" flex items-center text-sm text-gray-500 dark:text-slate-200">
                                    <UserIcon
                                      className=" h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="truncate">
                                      {order.name ||
                                        order.customer?.fullname ||
                                        '-'}
                                    </span>
                                  </p>
                                </div>
                                <div className="md:block justify-self-end text-right">
                                  <div>
                                    <span
                                      className={classNames(
                                        orderStatusColor(order.orderstatus),
                                        'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium'
                                      )}
                                    >
                                      {order.orderstatus === 40 &&
                                      order.refund !== order.total
                                        ? 'Partial Refund'
                                        : OrderStatus[order.orderstatus]}
                                    </span>
                                    <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-slate-200">
                                      {order.total?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                      {` (${calculateItemsCount(
                                        order.orderitems
                                      )})`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))
                  ) : (
                    <div className="flex w-full justify-center pt-12">
                      <img src={NoOrder} className="h-24 pb-2" alt="" />
                    </div>
                  )}
                </ul>

                <div className="px-5 text-center cursor-pointer text-blue-600  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 border-b border-t-2 font-semibold text-sm">
                  <Pagination
                    onChange={(page) =>
                      setPagination((prev) => ({
                        ...prev,
                        pageNumber: page,
                      }))
                    }
                    simple
                    current={pagination.pageNumber}
                    defaultPageSize={pagination.pageSize}
                    total={pagination.totalRecords}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {selectedOrder && (
          <div className="col-span-4  sm:col-span-4 md:col-span-4 lg:col-span-3  h-screen overflow-y-auto hide-scrollbar">
            <div className=" py-8 px-3 text-center grid max-w-xl mx-auto">
              <div className="lg:hidden  pb-5 flex justify-between border-b border-gray-200 dark:border-slate-700">
                <ChevronLeftIcon
                  onClick={() => {
                    setSelectedOrder(null);
                  }}
                  className=" h-8 w-8 flex-shrink-0 text-black"
                  aria-hidden="true"
                />
                <span className="text-[40px] font-[800] leading-6">
                  {selectedOrder.ordernumberstr}
                </span>
              </div>
              {selectedOrder.orderstatus === 0 && (
                <div className="border-b border-gray-200 dark:border-slate-700 bg-white dark:bg-slate-800 py-5 ">
                  <div className="py-5 text-left font-medium text-[12px]">
                    <p>Select a time to Confirm</p>
                  </div>
                  <div className="justify-between flex flex-wrap items-center  sm:flex-nowrap">
                    <div>
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        {extraTimes.map((time, i) => (
                          <button
                            key={i}
                            onClick={() => {
                              onChangeOrderStatus(10, time.value);
                            }}
                            type="button"
                            className={classNames(
                              time.value === selectedExtraTimes
                                ? 'bg-green-500'
                                : 'bg-white dark:bg-slate-900',
                              i === 0 ? 'rounded-l-md' : '',
                              i === extraTimes.length - 1 ? 'rounded-r-md' : '',
                              'relative -ml-px inline-flex items-center  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-green-200 dark:hover:bg-green-600 focus:z-10 dark:text-slate-200'
                            )}
                          >
                            {time.name}
                          </button>
                        ))}
                      </span>
                    </div>
                    <div>
                      <Button
                        type="ghost"
                        onClick={() => {
                          onChangeOrderStatus(30);
                        }}
                        className={classNames(
                          'mt-3 ml-3 inline-flex justify-center rounded-md border border-red-400 bg-red-400 px-4 h-9 items-center   text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm'
                        )}
                      >
                        <p className="font-bold  text-[16px] mr-1">X</p>
                        Reject
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {selectedOrder.orderstatus &&
              ![0].includes(selectedOrder.orderstatus) ? (
                <div className="py-6 border-b border-gray-200 dark:border-slate-700 bg-white dark:bg-slate-800">
                  <div className="text-end">
                    {selectedOrder.orderstatus === 20 && (
                      <Button
                        type="ghost"
                        onClick={() => {
                          onChangeOrderStatus(100);
                        }}
                        className={classNames(
                          'mt-3 mr-3 inline-flex justify-center rounded-md border border-blue-300 bg-white px-4 h-10 items-center   text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm'
                        )}
                      >
                        <CheckIcon
                          className="h-5 w-5 mr-1"
                          aria-hidden="true"
                        />
                        Complete
                      </Button>
                    )}
                    {selectedOrder.orderstatus === 10 && (
                      <Button
                        type="ghost"
                        onClick={() => {
                          onChangeOrderStatus(20);
                        }}
                        className={classNames(
                          'mt-3 mr-3 inline-flex justify-center rounded-md border border-green-300 bg-white px-4 h-10 items-center   text-base font-medium text-green-700 shadow-sm hover:bg-green-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm'
                        )}
                      >
                        <CheckIcon
                          className="h-5 w-5 mr-1"
                          aria-hidden="true"
                        />
                        Ready
                      </Button>
                    )}
                    {![30, 40].includes(selectedOrder.orderstatus) && (
                      <Button
                        type="ghost"
                        onClick={() => {
                          onClickRefund();
                        }}
                        className={classNames(
                          'mt-3 mr-3 inline-flex justify-center rounded-md border border-red-300 bg-white px-4 h-10 items-center   text-base font-medium text-red-700 shadow-sm hover:bg-red-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm'
                        )}
                      >
                        <ReceiptRefundIcon
                          className="h-5 w-5 mr-1"
                          aria-hidden="true"
                        />
                        Refund
                      </Button>
                    )}

                    {/*                     <Button
                      type="ghost"
                      className={classNames(
                        'mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                      )}
                    >
                      <PrinterIcon
                        className="h-5 w-5 mr-1"
                        aria-hidden="true"
                      />
                      Print
                    </Button> */}
                  </div>
                </div>
              ) : null}

              <div className="pt-6  grid grid-cols-2 justify-between items-center">
                <div className="col-span-1">
                  <div className="flex-shrink-0">
                    {
                      <img
                        className="h-12 w-12 rounded-full"
                        src={selectedOrder.ordertype === 10 ? DineIn : ToGo}
                        alt="ordertype"
                      />
                    }
                  </div>
                </div>
                <div className="col-span-1 text-end pl-8">
                  <span className="pt-1 text-[18px] font-[800] dark:text-slate-200">
                    {PaymentStatus[selectedOrder.paymentstatus]?.toUpperCase()}
                  </span>
                </div>
              </div>

              <div className="pt-6 flex justify-between items-center">
                <span className="text-[20px] font-[800] leading-6 dark:text-slate-200">
                  {selectedOrder.ordernumberstr}
                </span>

                <span className="text-[20px] font-[800] leading-6 dark:text-slate-200">
                  {selectedOrder.name}

                  {/*  {moment(selectedOrder.createdate)
                    .utcOffset(3 * 60)
                    .format('MM/DD/YYYY hh:mm A') || ''} */}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[14px] font-[400] text-[#7A7A7A] dark:text-slate-200">
                  {OrderTypes[selectedOrder.ordertype]}{' '}
                  <span className="text-[14px] font-[400] italic">
                    {selectedOrder.table
                      ? `(Table ${selectedOrder.table})`
                      : ''}
                  </span>
                </span>
                <span className="text-[14px] font-[400] text-[#7A7A7A] dark:text-slate-200">
                  {selectedOrder.phone}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[10px] font-normal text-[#7A7A7A] dark:text-slate-200">
                  {selectedOrder.terminal?.name}
                </span>
                <span className="text-[10px] font-normal text-[#7A7A7A] dark:text-slate-200">
                  {moment(selectedOrder.createdate)
                    .utcOffset(selectedOrder?.timezone * 60)
                    .format('MM/DD/YYYY hh:mm A') || ''}
                </span>
              </div>

              <div className="w-full border-t-[0.5px] mt-4 border-gray-300"></div>
              <div className="flex justify-between items-center">
                <span className="text-[12px] font-normal text-[#9C9C9C] dark:text-slate-200">
                  {selectedOrder.store.name}
                </span>
                <span className="text-[12px] font-normal text-[#9C9C9C] dark:text-slate-200">
                  Order # {selectedOrder.orderid}
                </span>
              </div>
              <div className="grid grid-cols-12 justify-between items-center mt-6">
                <div className="col-span-1">
                  <span className="text-[10px] font-bold">QTY</span>
                </div>
                <div className="col-span-9 pl-4 text-left">
                  <span className="text-[10px] font-bold">ITEM</span>
                </div>
                <div className="col-span-2 text-end">
                  <span className="text-[10px] font-bold">PRICE</span>
                </div>
              </div>
              <div className="w-full border-t-[0.5px] border-black dark:border-slate-300 mb-4"></div>
              <div className="pt-1 block justify-between items-center">
                {selectedOrder.orderitems.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-12 justify-between items-center"
                  >
                    <div className="col-span-1 whitespace-nowrap self-start leading-3 pb-1">
                      <span className="text-[12px] font-[700]">
                        {item.quantity} x{' '}
                      </span>
                    </div>
                    <div className="col-span-9 flex">
                      <div key={index} className="grid pb-3 pl-4 text-left">
                        <span className="text-[12px] font-[600] leading-3 pb-1">
                          {item.item.product.name}
                        </span>
                        {item.item.product.name !== item.item.name && (
                          <span className="text-[10px] font-[400] text-[#444444] dark:text-slate-300">
                            {item.item.name}{' '}
                            {(
                              item.item.price + selectedOrder?.itemfee
                            ).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </span>
                        )}
                        {item.orderitemoptions.map((option, index) => (
                          <div key={index} className="grid">
                            <span className="text-[10px] font-[400] text-[#444444] dark:text-slate-300">
                              {option.name}{' '}
                              <span className="text-[10px] italic font-[400]">
                                {option.price.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </span>
                            </span>
                            {item.note && (
                              <span className="text-[10px] italic font-[400]">
                                "{item.note}"
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-span-2 self-start leading-3 pb-1 text-end">
                      <span className="self-start text-[20px] font-[700]  ">
                        {(
                          item.quantity *
                          (item.price +
                            selectedOrder?.itemfee +
                            item.orderitemoptions.reduce(
                              (total, currentOption) => {
                                return total + currentOption.price;
                              },
                              0
                            ))
                        ).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full border-t-[0.5px] border-black dark:border-slate-300"></div>
              <div className="grid grid-cols-3 py-4 gap-x-4 justify-between">
                <div className="col-span-2">
                  {selectedOrder.note && (
                    <div className=" border-dashed border rounded-md border-black dark:border-slate-300 flex min-h-[2rem]">
                      <span
                        className="text-[10px] font-[400] italic text-left"
                        style={{ color: '#444444' }}
                      >
                        "{selectedOrder.note}"
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-span-1">
                  <div className="flex justify-between text-[12px] font-[600] text-[#7A7A7A] dark:text-slate-300">
                    <span>Subtotal</span>
                    <span>
                      {selectedOrder.subtotal.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between text-[12px] font-[600] text-[#7A7A7A] dark:text-slate-300">
                    <span>Tax & Fees</span>
                    <span>
                      {(
                        selectedOrder.tax +
                        selectedOrder.fee +
                        selectedOrder.crvtax
                      ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </span>
                  </div>
                  {selectedOrder.tip !== 0 && (
                    <div className="flex justify-between text-[12px] font-[600] text-[#7A7A7A] dark:text-slate-300">
                      <span>Tip</span>
                      <span>
                        {selectedOrder.tip.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </span>
                    </div>
                  )}
                  {selectedOrder.discount !== 0 && (
                    <div className="flex justify-between text-[12px] font-[600] text-[#7A7A7A] dark:text-slate-300">
                      <span>Discount</span>
                      <span>
                        {'- ' +
                          selectedOrder.discount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                      </span>
                    </div>
                  )}
                  {selectedOrder.refund !== 0 && (
                    <div className="flex justify-between text-[12px] font-[600] text-[#7A7A7A] dark:text-slate-300">
                      <span>Refund</span>
                      <span>
                        {'- ' +
                          selectedOrder.refund.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full border-t-[0.5px] border-black dark:border-slate-300"></div>
              <div className="grid grid-cols-3 justify-between gap-x-4">
                <div className="flex items-center">
                  {/*  <span
                    style={{ color: '#7A7A7A' }}
                    className="text-[10px] font-[400]"
                  >
                    Visa - xx1234
                  </span> */}
                </div>
                <div className="py-2 col-start-3">
                  <div className="flex justify-between text-[20px] font-[700]">
                    <span>Total</span>
                    <span>
                      {(selectedOrder.refund
                        ? selectedOrder.total - selectedOrder.refund
                        : selectedOrder.total
                      ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </span>
                  </div>
                </div>
              </div>

              {/*   <span className="pt-4 text-[14] font-[700] pb-4">Thank You</span>
              <div className="flex justify-center pb-1">
                <span className="text-[5px] font-[300]">Powered by</span>
                <img
                  className="h-2 w-auto pl-[1px]"
                  src={LogoBlack}
                  alt="Company Logo"
                />
              </div> */}
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={dialogs.refund} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="pb-2 block text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Refund Amount
                    </label>
                    <div className="relative  rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        value={form.refund}
                        onChange={(e) => onFormChange(e.target.value, 'refund')}
                        type="number"
                        name="refund"
                        id="refund"
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                        placeholder="0.00"
                        aria-describedby="refund-currency"
                      />
                    </div>
                    <p className="text-xs pt-2">
                      Available balance is{' '}
                      {(
                        selectedOrder?.total - selectedOrder?.refund
                      ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </p>
                    <div className="h-2 mt-1  text-xs">
                      {errors.refund && (
                        <p className="text-red-700">
                          Please enter a valid refund amount
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.orderList}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.orderList}
                      loading={loading.orderList}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormRefundSubmit()}
                    >
                      Refund
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <audio ref={audioPlayer} src={NotificationSound} />
    </div>
  );
}

export default LiveOrders;
