import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Select, Spin } from 'antd';
import optionApi from '../../../services/optionApi';
import { useSelector } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function OptionGroupExistModal({
  show,
  onClickOptionGroupExistSubmit,
  optionGroupForm,
  setOptionGroupForm,
  loading,
  notf,
  setNotf,
  setDialogs,
}) {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const cancelButtonRef = useRef(null);
  const [optionGroupIds, setOptionGroupIds] = useState([]);
  const [loadingOptionGroupList, setLoadingOptionGroupList] = useState(true);

  const getOptionGroupsList = async () => {
    try {
      setLoadingOptionGroupList(true);
      const query = `?companyid=${globalCompany.id}&pageSize=100`;
      const list = await optionApi.groupList(query);

      if (list.data.data.length > 0) {
        setOptionGroupIds(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoadingOptionGroupList(false);
    }
  };

  const onFormChange = (val, key) => {
    setOptionGroupForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClose = () => {
    setDialogs((prev) => ({ ...prev, optionGroupExist: false }));
  };

  useEffect(() => {
    getOptionGroupsList();
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={onClose}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                    <div className="pb-5 border-gray-300 dark:border-slate-600">
                      <h1 className="font-semibold pb-5 dark:text-slate-300">
                        Add Existing Modifier Groups
                      </h1>
                      {show && (
                        <div>
                          <label
                            htmlFor="tax"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Modifiers
                          </label>
                          <div>
                            {loadingOptionGroupList ? (
                              <div className="w-full flex justify-center py-4">
                                <Spin />
                              </div>
                            ) : (
                              <Select
                                mode="multiple"
                                allowClear
                                size="large"
                                style={{
                                  width: '100%',
                                }}
                                placeholder="Please select Option Groups"
                                defaultValue={optionGroupForm?.productoptiongroups
                                  .filter(
                                    (x) => x.optiongroup.isdeleted === false
                                  )
                                  .map((x) => ({
                                    value: x.optiongroup.id,
                                  }))}
                                onChange={(val) =>
                                  onFormChange(val, 'optiongroupids')
                                }
                                options={optionGroupIds?.map((x) => ({
                                  label: x.name,
                                  value: x.id,
                                }))}
                              />
                            )}
                          </div>
                          <div className="mt-5 sm:mt-6  z-10  sm:gap-3 flex justify-center">
                            <Button
                              type="ghost"
                              disabled={loading}
                              loading={loading}
                              className={classNames(
                                ' max-w-xs inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                              )}
                              onClick={onClickOptionGroupExistSubmit}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
