import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import termsApi from "../../services/termsApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TermsAndConditions = () => {
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    upsertPrivacyLoading: false,
  });
  const [termsForm, setTermsForm] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [privacyForm, setPrivacyForm] = useState({
    id: "",
    name: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    privacyName: false,
    privacyDescription: false,
  });

  const getTermsList = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));

    try {
      const list = await termsApi.list();
      if (list.data.data.length > 0) {
        const termsData = list.data.data.find((x) => x.name === "terms");
        const privacyData = list.data.data.find((x) => x.name === "privacy");
        if (termsData) {
          setTermsForm({
            id: termsData.id,
            name: termsData.name,
            description: termsData.description,
          });
        } else {
          setTermsForm({
            id: "",
            name: "terms",
            description: "",
          });
        }
        if (privacyData) {
          setPrivacyForm({
            id: privacyData.id,
            name: privacyData.name,
            description: privacyData.description,
          });
        } else {
          setPrivacyForm({
            id: "",
            name: "privacy",
            description: "",
          });
        }
      } else {
        setTermsForm({
          id: "",
          name: "terms",
          description: "",
        });
        setPrivacyForm({
          id: "",
          name: "privacy",
          description: "",
        });
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  useEffect(() => {
    getTermsList();
  }, []);

  const onFormChange = (val, key, formName) => {
    if (formName === "terms") {
      setTermsForm((prev) => ({ ...prev, [key]: val }));
    } else {
      setPrivacyForm((prev) => ({ ...prev, [key]: val }));
    }
  };

  const onFormSubmit = async (form, key) => {
    if (!form.name || !form.description) {
      setErrors((prev) => ({
        ...prev,
        name: key === "terms" && !form.name ? true : false,
        description: !form.description ? true : false,
        privacyName: key === "privacy" && !form.name ? true : false,
        privacyDescription:
          key === "privacy" && !form.description ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: false,
        description: false,
        privacyName: false,
        privacyDescription: false,
      }));
    }

    try {
      setLoading((prev) => ({
        ...prev,
        upsertLoading: key === "terms" ? true : false,
        upsertPrivacyLoading: key === "privacy" ? true : false,
      }));

      const body = { ...form };
      await termsApi.upsert(body);
    } catch (error) {
    } finally {
      await getTermsList();
      setLoading((prev) => ({
        ...prev,
        upsertLoading: false,
        upsertPrivacyLoading: false,
      }));
    }
  };

  return (
    <div>
      {loading.listLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
          <div className="mx-auto max-w-5xl">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                  Terms & Conditions
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
            <div className="mt-2 flow-root">
              <div className="flex items-center gap-2">
                <label
                  htmlFor="fullname"
                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                >
                  Name* :
                </label>
                <div className="w-1/2">
                  <input
                    value={termsForm.name}
                    onChange={(e) =>
                      onFormChange(e.target.value, "name", "terms")
                    }
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                    placeholder="e.g. 'Terms & Conditions'"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-xs">
                    {errors.name && (
                      <p className="text-red-700">Name can not be empty</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-8 h-96 pb-16 ">
                <ReactQuill
                  className="h-full"
                  theme="snow"
                  value={termsForm.description}
                  onChange={(e) => onFormChange(e, "description", "terms")}
                />
              </div>
              <div className="w-full flex justify-center">
                <Button
                  disabled={loading.upsertLoading}
                  loading={loading.upsertLoading}
                  type="button"
                  className="inline-flex  justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:text-slate-200 dark:bg-slate-900 dark:hover:text-sky-400"
                  onClick={() => onFormSubmit(termsForm, "terms")}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-5xl">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                  Privacy Policy
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

            <div className="mt-2 flow-root">
              <div className="flex items-center gap-2">
                <label
                  htmlFor="fullname"
                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                >
                  Name* :
                </label>
                <div className="w-1/2">
                  <input
                    value={privacyForm.name}
                    onChange={(e) =>
                      onFormChange(e.target.value, "name", "privacy")
                    }
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                    placeholder="e.g. 'Privacy Policy'"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-xs">
                    {errors.privacyName && (
                      <p className="text-red-700">Name can not be empty</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-8 h-96 pb-16 ">
                <ReactQuill
                  className="h-full"
                  theme="snow"
                  value={privacyForm.description}
                  onChange={(e) => onFormChange(e, "description", "privacy")}
                />
              </div>
              <div className="w-full flex justify-center">
                <Button
                  disabled={loading.upsertPrivacyLoading}
                  loading={loading.upsertPrivacyLoading}
                  type="button"
                  className="inline-flex  justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:text-slate-200 dark:bg-slate-900 dark:hover:text-sky-400"
                  onClick={() => onFormSubmit(privacyForm, "privacy")}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
