import { Button } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalDelete from '../../../../components/modal-delete/modal-delete';
import { TrashIcon } from '@heroicons/react/24/outline';
import storeApi from '../../../../services/storeApi';

const DangerZoneTab = ({ storeDetail, checkUpdate }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
  });

  const onClickDelete = () => {
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      await storeApi.deleteById(storeDetail.id);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false }));
      setLoading(false);
      navigate('/dashboard/stores');
    }
  };
  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-2 sm:gap-x-8 p-4">
      <div className="col-span-4 sm:col-span-3">
        <h1 className="text-lg font-semibold">Danger Zone</h1>
      </div>
      <div className="mt-3">
        <Button
          type="ghost"
          disabled={loading.deleteLoading}
          loading={loading.deleteLoading}
          onClick={() => onClickDelete()}
          className="min-w-[15rem] inline-flex  justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 sm:text-sm"
        >
          <TrashIcon className="text-white w-5 h-5" /> Delete Store
        </Button>
        <h5 className="text-sm text-gray-500 mt-2">
          This action can not be undo.
        </h5>
      </div>
      <ModalDelete
        title="Store"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={storeDetail}
        shouldConfirm={true}
      />
    </div>
  );
};

export default DangerZoneTab;
