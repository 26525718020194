import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import Notification from '../../notification/notification';
import { PlusIcon } from '@heroicons/react/20/solid';
import productApi from '../../../services/productApi';
import itemApi from '../../../services/itemApi';
import ProductUpsertModal from './product-upsert-modal';
import ProductVisibleModal from './components/product-visible-modal';
import ProductCard from './product-card';
import ModalDelete from '../../modal-delete/modal-delete';
import DeleteHidedateModal from './components/delete-hidedate-modal';
import { Spin } from 'antd';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const timezone = new Date().getTimezoneOffset() / -60;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductList({ selected, setSelected }) {
  const [selectedProductVisible, setSelectedProductVisible] = useState({});
  const [isSingleItem, setIsSingleItem] = useState(true);
  const [deletedItem, setDeletedItem] = useState({ name: '', id: '' });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    displayorder: false,
    tax: false,
    imagepath: false,
    items: [{ name: false, price: false }],
  });
  const [productForm, setProductForm] = useState({
    id: '',
    name: '',
    description: '',
    categoryid: selected.category.id,
    visible: false,
    isupsell: false,
    usetax: false,
    usecrvtax: false,
    image: '',
    imageid: '',
    imagepath: '',
    tax: 0,
    optiongroupids: [],
    items: [{ name: '', price: '', displayorder: 1024 }],
    displayorder: '',
    hidedate: '',
    crvtaxrate: 0,
  });
  const [productDialogs, setProductDialogs] = useState({
    productUpsert: false,
    productDelete: false,
    productVisible: false,
    hidedateDelete: false,
  });
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState({
    productLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    visibleLoading: false,
  });

  const getProductList = async (method) => {
    try {
      setLoading((prev) => ({ ...prev, productLoading: true }));
      const query = `&categoryid=${selected.category.id}${
        selected.onlyDisabled ? '&isvisible=false' : ''
      }`;

      const list = await productApi.list(query);

      if (list.data.data.length > 0) {
        setProductList(list.data.data);
      } else {
        setProductList([]);
        setSelected((prev) => ({
          ...prev,
          product: { productoptiongroups: [] },
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, productLoading: false }));
    }
  };

  const onClickAddProduct = () => {
    setProductForm({
      id: '',
      name: '',
      description: '',
      categoryid: selected.category.id,
      visible: true,
      isupsell: false,
      image: '',
      imageid: '',
      imagepath: '',
      tax: 0,
      optiongroupids: [],
      items: [{ name: '', price: '', displayorder: 1024 }],
      displayorder:
        productList.length > 0 ? (productList.length + 1) * 1024 : 1024,
      hidedate: '',
      usecrvtax: false,
      usetax: false,
      crvtaxrate: 0,
    });

    setProductDialogs((prev) => ({ ...prev, productUpsert: true }));
  };

  const onFormChange = (val, key) => {
    setProductForm((prev) => ({ ...prev, [key]: val }));

    if (key === 'name' && isSingleItem && !productForm.id) {
      setProductForm((prev) => ({
        ...prev,
        items: [{ ...prev.items[0], name: val }],
      }));
    }
  };

  const onClickDeleteProduct = (product) => {
    setDeletedItem(product);
    setProductDialogs((prev) => ({ ...prev, productDelete: true }));
  };

  const onConfirmDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await productApi.deleteById(deletedItem.id);
      getProductList();
    } catch (error) {
    } finally {
      setProductDialogs((prev) => ({
        ...prev,
        productDelete: false,
        productUpsert: false,
      }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const onClickEditProduct = (val) => {
    setProductForm({
      id: val.id,
      name: val.name,
      description: val.description,
      categoryid: val.categoryid,
      imageid: val.imageid,
      imagepath: val.imagepath,
      visible: val.visible,
      isupsell: val.isupsell,
      tax: val.tax,
      displayorder: val.displayorder,
      hidedate: val.hidedate,
      usetax: val.usetax,
      usecrvtax: val.usecrvtax,
      crvtaxrate: val.crvtaxrate,
    });

    setProductDialogs((prev) => ({ ...prev, productUpsert: true }));
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      displayorder: false,
      description: false,
      tax: false,
      imagepath: false,
      name: false,
      items: prev.items.map((item) => ({
        name: false,
        price: false,
      })),
    }));
    setProductDialogs((prev) => ({ ...prev, productUpsert: false }));
  };

  const onCloseVisible = () => {
    setErrors((prev) => ({
      ...prev,
      displayorder: false,
      description: false,
      tax: false,
      imagepath: false,
      name: false,
    }));
    setProductDialogs((prev) => ({ ...prev, productVisible: false }));
  };

  const onChangeProductVisible = async (product, val, index) => {
    if (!val) {
      onClickOpenVisibleModal(product, val, index);
    } else {
      await onChangeSubmitProductVisible(product, val, index);
    }
  };

  const onClickOpenVisibleModal = (product, val, index) => {
    setSelectedProductVisible({ product, val, index });

    setProductDialogs((prev) => ({ ...prev, productVisible: true }));
  };

  const onClickOpenHidedateModal = (product, val, index) => {
    setSelectedProductVisible({ product, val, index });

    setProductDialogs((prev) => ({ ...prev, hidedateDelete: true }));
  };

  const onSubmitDeleteHideDate = async () => {
    let { product, val, index } = selectedProductVisible;

    product.hidedate = '';

    await onChangeSubmitProductVisible(product, val, index);

    setProductDialogs((prev) => ({ ...prev, hidedateDelete: false }));

    setSelectedProductVisible({});
  };

  const onCloseHidedateModal = () => {
    setProductDialogs((prev) => ({ ...prev, hidedateDelete: false }));
    setSelectedProductVisible({});
  };

  const onSelectVisible = async (time) => {
    let { product, val, index } = selectedProductVisible;

    val = true;

    let hidedate;

    if (time === 'tomorrow') {
      hidedate = dayjs().add(1, 'day').startOf('day').add(timezone, 'hour');
      product.hidedate = dayjs(hidedate, 'YYYY-MM-DD HH:mm:ss');
    } else if (time === 'forever') {
      val = false;
    } else {
      hidedate = dayjs().add(time + timezone, 'hour');
      product.hidedate = dayjs(hidedate, 'YYYY-MM-DD HH:mm:ss');
    }

    await onChangeSubmitProductVisible(product, val, index);

    setProductDialogs((prev) => ({ ...prev, productVisible: false }));

    setSelectedProductVisible({});
  };

  const onChangeSubmitProductVisible = async (product, val, index) => {
    console.log(product);

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const formData = new FormData();

      for (const key of Object.keys(product)) {
        if (
          (key === 'description' || key === 'hidedate') &&
          (product[key] === '' || product[key] === null)
        ) {
          formData.append(key, '');
        } else if (key === 'visible') {
          formData.append(key, val);
        } else {
          formData.append(key, product[key]);
        }
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      setProductList((prev) => {
        const newList = [...prev];
        newList[index].visible = val;
        return newList;
      });

      await productApi.upsert(formData, config);
    } catch (error) {
    } finally {
      await getProductList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
    }
  };

  const moveProductItem = async (dragIndex, hoverIndex) => {
    setProductList((prevProducts) =>
      update(prevProducts, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevProducts[dragIndex]],
        ],
      })
    );
  };

  const dropProductItem = async (index, product) => {
    if (index === productList.length - 1) {
      product.displayorder = Math.round(
        productList[index - 1].displayorder + 1024
      );
    } else if (index === 0) {
      product.displayorder = Math.round(productList[1].displayorder / 2);
    } else {
      product.displayorder = Math.round(
        (productList[index - 1].displayorder +
          productList[index + 1].displayorder) /
          2
      );
    }

    try {
      const formData = new FormData();

      for (const key of Object.keys(product)) {
        if (
          (key === 'description' || key === 'hidedate') &&
          (product[key] === '' || product[key] === null)
        ) {
          formData.append(key, '');
        } else {
          formData.append(key, product[key]);
        }
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      setLoading((prev) => ({ ...prev, productLoading: true }));
      await productApi.upsert(formData, config);
      getProductList();
    } catch (error) {}
  };

  const onFormMultipleItemSubmit = async (productid) => {
    try {
      for (const [index, item] of productForm.items.entries()) {
        const body = {
          name: item.name,
          productid,
          price: item.price,
          displayorder: 1024 * (index + 1),
        };
        await itemApi.upsert(body);
      }
      setErrors((prev) => ({
        ...prev,
        name: false,
        price: false,
      }));
      setIsSingleItem(true);
    } catch (error) {}
  };

  const onFormSingleItemSubmit = async (productid) => {
    try {
      const body = {
        ...productForm.items[0],
        productid,
        name: productForm.name,
      };
      await itemApi.upsert(body);
    } catch (error) {
    } finally {
    }
  };

  const validateForm = () => {
    if (!productForm.name) {
      setErrors((prev) => ({ ...prev, name: true }));
      return false;
    }
    if (!productForm.id) {
      const errorItems = productForm.items.map((item) => ({
        name: !item.name,
        price: item.price === '',
      }));

      setErrors((prev) => ({ ...prev, items: errorItems }));
      return !errorItems.some((error) => error.name || error.price);
    }

    if (productForm.tax < 0 || productForm.tax > 100) {
      setErrors((prev) => ({ ...prev, tax: true }));
      return false;
    }

    return true;
  };

  const handleFormData = async () => {
    const formData = new FormData();
    for (const key of Object.keys(productForm)) {
      if (key === 'image' && productForm['image'] !== '') {
        if (productForm.imageid) {
          await productApi.deleteImageById(productForm.id);
        }
        formData.append('image', productForm.image);
      } else if (key === 'description' || key === 'hidedate') {
        formData.append(key, productForm[key] ? productForm[key] : '');
      } else {
        formData.append(key, productForm[key]);
      }
    }
    return formData;
  };

  const onSubmitSuccess = (productid) => {
    const message = productForm.id
      ? 'Product has been successfully updated.'
      : 'Product has been successfully created.';
    setNotf((prev) => ({ ...prev, message, display: true, type: 'success' }));
    const submitAction = isSingleItem
      ? onFormSingleItemSubmit
      : onFormMultipleItemSubmit;
    return submitAction(productid);
  };

  const onFormSubmit = async () => {
    if (!validateForm()) return;
    setLoading((prev) => ({ ...prev, upsertLoading: true }));

    try {
      const formData = await handleFormData();
      const res = await productApi.upsert(formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (res.data.data) await onSubmitSuccess(res.data.data.id);
    } catch (error) {
      const message =
        error.response.data === 'Upsell count max 4'
          ? 'You have already selected 4 add-ons. Delete one to add more.'
          : error.response.data;
      setNotf((prev) => ({ ...prev, message, display: true }));
    } finally {
      setErrors((prev) => ({
        ...prev,
        name: false,
        price: false,
        tax: false,
      }));

      setProductDialogs((prev) => ({ ...prev, productUpsert: false }));
      await getProductList({ method: productForm.id ? 'update' : 'create' });
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
    }
  };

  useEffect(() => {
    getProductList();
  }, [selected.category.id, selected.onlyDisabled]);
  return (
    <>
      {loading.productLoading ? (
        <div className="w-full flex justify-center py-4 ">
          <Spin />
        </div>
      ) : (
        <nav className="">
          {productList.map((product, i) => (
            <ProductCard
              key={product.id}
              index={i}
              id={product.id}
              selected={selected}
              setSelected={setSelected}
              onClickEditProduct={onClickEditProduct}
              onChangeProductVisible={onChangeProductVisible}
              onClickOpenHidedateModal={onClickOpenHidedateModal}
              product={product}
              moveProductItem={moveProductItem}
              dropProductItem={dropProductItem}
            />
          ))}
          <div
            onClick={() => onClickAddProduct()}
            className="flex-col hover:bg-slate-50 justify-center dark:hover:bg-slate-700
          flex items-center cursor-pointer h-[6rem] p-5 text-sm font-medium rounded-md text-blue-500 dark:text-slate-200"
          >
            <button
              type="button"
              className="rounded-full bg-blue-600 p-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <span className="truncate font-semibold text-[0.925rem]">
              Add Product
            </span>
          </div>
        </nav>
      )}

      <ProductUpsertModal
        show={productDialogs.productUpsert}
        loading={loading.upsertLoading}
        productForm={productForm}
        setProductForm={setProductForm}
        setIsSingleItem={setIsSingleItem}
        isSingleItem={isSingleItem}
        errors={errors}
        onClickDeleteProduct={onClickDeleteProduct}
        onCloseUpsert={onCloseUpsert}
        onFormChange={onFormChange}
        onFormSubmit={onFormSubmit}
      />
      <ProductVisibleModal
        onSelectVisible={onSelectVisible}
        show={productDialogs.productVisible}
        loading={loading.visibleLoading}
        productForm={productForm}
        errors={errors}
        onCloseVisible={onCloseVisible}
      />

      <ModalDelete
        title="Delete Confirmation"
        text={`Are you sure you want to delete "${deletedItem.name}"? All associated data will be permanently removed.`}
        loading={loading.deleteLoading}
        open={productDialogs.productDelete}
        onCloseRequest={() =>
          setProductDialogs((prev) => ({ ...prev, productDelete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedItem}
      />
      <DeleteHidedateModal
        show={productDialogs.hidedateDelete}
        loading={loading.upsertLoading}
        onClose={onCloseHidedateModal}
        onSubmit={onSubmitDeleteHideDate}
      />
      <Notification
        classAttributes="pt-20"
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </>
  );
}
