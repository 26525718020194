import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { TrashIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Button, Select } from 'antd';
import menuApi from '../../../services/menuApi';
import { useSelector } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CategoryUpsertModal({
  show,
  onFormChange,
  onFormSubmit,
  onClickDeleteCategory,
  onCloseUpsert,
  categoryForm,
  errors,
  loading,
}) {
  const cancelButtonRef = useRef(null);
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [menuList, setMenuList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const getMenuList = async () => {
    try {
      setInitialLoading(true);
      const query = `?companyid=${globalCompany.id}&pageSize=100&isdeleted=false`;
      const list = await menuApi.list(query);
      if (list.data.data.length > 0) {
        setMenuList(list.data.data);
      }
    } catch (error) {
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    getMenuList();
  }, []);

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-3 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Category Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={categoryForm.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-md dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-slate-500"
                        placeholder="e.g ''Salads, Pizzas, Drinks''"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Category name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Description
                    </label>
                    <div className="mt-1">
                      <input
                        value={categoryForm.description}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'description')
                        }
                        type="text"
                        name="description"
                        id="description"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-md dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-slate-500"
                        placeholder="e.g ''Fresh and Healthy Salads, Delicious Pizzas, Refreshing Drinks''"
                      />
                      <div className="h-2 mt-1  text-xs"></div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400 mb-1"
                    >
                      Menu
                    </label>
                    <Select
                      loading={initialLoading}
                      size="large"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Please select Option Groups"
                      defaultValue={categoryForm.menuid}
                      onChange={(val) => onFormChange(val, 'menuid')}
                      options={menuList?.map((x) => ({
                        label: x.name,
                        value: x.id,
                      }))}
                    />
                  </div>
                  {/*    <div className="w-full">
                    <div className="w-3/6 sm:w-1/4  bg-white">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white py-2 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                              <div className="grid leading-6">
                                <span className=" font-semibold text-md">
                                  Advanced Settings
                                </span>
                              </div>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-black`}
                              />
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel className="pt-4 text-sm">
                                <div>
                                  <label
                                    htmlFor="displayorder"
                                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                  >
                                    Display Order
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      value={categoryForm.displayorder}
                                      onChange={(e) =>
                                        onFormChange(
                                          e.target.value,
                                          'displayorder'
                                        )
                                      }
                                      type="number"
                                      name="displayorder"
                                      id="displayorder"
                                      className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                      placeholder="0"
                                    />
                                    <div className="h-2 mt-1  text-xs">
                                      {errors.displayorder && (
                                        <p className="text-red-700">
                                          Display Order can not be empty
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div> */}
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {categoryForm.id && (
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        onClick={() => onClickDeleteCategory(categoryForm)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-0 sm:px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 col-span-2 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading}
                      className={classNames(
                        categoryForm.id ? 'col-span-5' : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading}
                      loading={loading}
                      className={classNames(
                        categoryForm.id ? 'col-span-5 ' : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      {categoryForm.id ? 'Edit Category' : 'Add Category'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
