import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { Button, DatePicker, Pagination, Spin, Radio } from 'antd';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import ModalDelete from '../../components/modal-delete/modal-delete';
import Notification from '../../components/notification/notification';
import discountApi from '../../services/discountApi';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import NoCoupon from '../../assets/no-coupon.svg';
dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Coupons = () => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [couponList, setCouponList] = useState([]);
  const [deletedCoupon, setDeletedCoupon] = useState({});
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [form, setForm] = useState({
    id: '',
    name: '',
    code: '',
    companyid: globalCompany.id,
    isactive: true,
    minprice: 0,
    rate: 0,
    fixrate: 0,
    rateOrFixRate: true,
    expiredate: dayjs(),
  });

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    code: false,
    companyid: false,
    minprice: false,
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });

  const cancelButtonRef = useRef(null);

  const getCouponList = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setCouponList([]);
    try {
      const query = `?companyid=${globalCompany.id}&isactive=true&pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`;
      const list = await discountApi.list(query);
      if (list.data.data.length > 0) {
        setCouponList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  useEffect(() => {
    getCouponList();
  }, [globalCompany.id, pagination.pageNumber]);

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onClickAdd = () => {
    setForm({
      id: '',
      name: '',
      code: '',
      companyid: globalCompany.id,
      isactive: true,
      minprice: 0,
      rate: 0,
      fixrate: 0,
      rateOrFixRate: true,
      expiredate: dayjs(),
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onClickEdit = (val) => {
    setForm({
      id: val.id,
      name: val.name,
      code: val.code,
      companyid: val.companyid,
      isactive: val.isactive,
      minprice: val.minprice,
      rate: val.rate,
      fixrate: val.fixrate,
      rateOrFixRate: val.rate ? true : false,
      expiredate: val.expiredate,
    });
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    if (!form.name || !form.code || !form.companyid) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
        code: !form.code ? true : false,
        companies: !form.companyid ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: false,
        code: false,
        companies: false,
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      body.rateOrFixRate ? (body.fixrate = 0) : (body.rate = 0);
      await discountApi.upsert(body);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      await getCouponList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };
  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      name: false,
      code: false,
      companies: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onClickDelete = (coupon) => {
    setDeletedCoupon(coupon);
    setDialogs((prev) => ({ ...prev, delete: true }));
  };

  const onConfirmDelete = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await discountApi.deleteById(deletedCoupon.id);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false, upsert: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getCouponList();
    }
  };

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Coupons
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                Create and manage coupons for your company promotions and
                discounts.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                type="button"
                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
                onClick={() => onClickAdd()}
              >
                Add Coupon
              </Button>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              {couponList.length > 0 ? (
                <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Code
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Min Price
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Rate
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Is Active?
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-6 sm:pr-0"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                        {couponList
                          .filter((x) => x.isactive)
                          .map((coupon) => (
                            <tr key={coupon.id}>
                              <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                                {coupon.name}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                {coupon.code}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                ${coupon.minprice}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                {coupon.rate
                                  ? coupon.rate + `%`
                                  : `$` + coupon.fixrate}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                {coupon.isactive ? (
                                  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                    Active
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
                                    Expired
                                  </span>
                                )}
                              </td>
                              <td className="relative flex justify-between whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                <div className="flex w-1/2 text-right justify-center  pr-1 items-center">
                                  <PencilSquareIcon
                                    onClick={() => onClickEdit(coupon)}
                                    className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="w-full text-end">
                      <Pagination
                        current={pagination.pageNumber}
                        total={pagination.totalRecords}
                        defaultPageSize={pagination.pageSize}
                        onChange={(page) =>
                          setPagination((prev) => ({
                            ...prev,
                            pageNumber: page,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid justify-center pt-20">
                  <img src={NoCoupon} className="h-24 pb-2" alt="" />
                  <p className=" text-slate-400 font-light text-center">
                    No Coupons
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                        placeholder="Discount %5"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">Name can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-5">
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Coupon Code
                      </label>
                      <div className="mt-1">
                        <input
                          value={form.code}
                          onChange={(e) => onFormChange(e.target.value, 'code')}
                          type="text"
                          name="code"
                          id="code"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                          placeholder="disc05"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.code && (
                            <p className="text-red-700">
                              Code can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-self-end">
                      <div className="grid pr-2 pb-1">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Discount Type
                          </label>
                        </div>
                        <div className="flex items-start">
                          <Radio.Group
                            className="flex"
                            onChange={(e) =>
                              onFormChange(e.target.value, 'rateOrFixRate')
                            }
                            value={form.rateOrFixRate}
                          >
                            <Radio className=" items-center" value={true}>
                              %
                            </Radio>
                            <Radio className=" items-center" value={false}>
                              $
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>

                      <div className="self-end">
                        <div>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">
                                {form.rateOrFixRate ? '%' : '$'}
                              </span>
                            </div>
                            <input
                              value={
                                form.rateOrFixRate ? form.rate : form.fixrate
                              }
                              onChange={(e) =>
                                onFormChange(
                                  e.target.value,
                                  form.rateOrFixRate ? 'rate' : 'fixrate'
                                )
                              }
                              type="text"
                              name={form.rateOrFixRate ? 'rate' : 'fixrate'}
                              id={form.rateOrFixRate ? 'rate' : 'fixrate'}
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-slate-500 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                              placeholder="0.00"
                              aria-describedby="price-currency"
                            />
                          </div>
                          <div className="mt-1">
                            <div className="h-2 mt-1  text-xs">
                              {errors.rateOrFixRate && (
                                <p className="text-red-700">
                                  Rate or Fix Rate can not be empty
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-5">
                    <div>
                      <label
                        htmlFor="price"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
                      >
                        Minimum Order Value
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 dark:text-slate-200 sm:text-sm">
                            $
                          </span>
                        </div>
                        <input
                          value={form.minprice}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'minprice')
                          }
                          type="number"
                          name="price"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-slate-500 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200 dark:border-slate-500"
                          placeholder="0.00"
                          aria-describedby="price-currency"
                        />
                      </div>
                      <div className="h-2 mt-1  text-xs">
                        {errors.minprice && (
                          <p className="text-red-700">
                            Min price can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Expiration Date
                      </label>
                      <div className="mt-1">
                        <DatePicker
                          value={dayjs(form.expiredate, 'YYYY-MM-DD HH:mm')}
                          format={'MM/DD/YYYY'}
                          onChange={(e) =>
                            onFormChange(
                              dayjs(e, 'YYYY-MM-DD HH:mm'),
                              'expiredate'
                            )
                          }
                          style={{ width: '100%' }}
                          size="large"
                          placeholder="Select a expire date"
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.expiredate && (
                            <p className="text-red-700">Select a expire date</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {form.id && (
                      <Button
                        type="ghost"
                        disabled={loading.deleteLoading}
                        loading={loading.deleteLoading}
                        onClick={() => onClickDelete(form)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        form.id ? 'sm:col-span-5 col-span-12' : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className={classNames(
                        form.id ? 'sm:col-span-5 col-span-12' : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      {form.id ? 'Edit Coupon' : 'Add Coupon'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ModalDelete
        title="Delete Coupon"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedCoupon}
      />
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default Coupons;
