import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ItemCard({
  id,
  index,
  productItem,
  onClickEditItem,
  moveItem,
  dropItem,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropItem(item.index, productItem);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <tr
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={classNames(isDragging ? 'shadow-lg' : '', 'cursor-pointer')}
    >
      <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
        {productItem.name || '-'}
      </td>
      <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
        {productItem.price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) || '-'}
      </td>
      <td className="relative flex justify-end whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
        <div className="flex w-1/2 text-right justify-center  pr-1 items-center">
          <PencilSquareIcon
            onClick={() => onClickEditItem(productItem)}
            className="text-slate-800 w-5 h-5 hover:text-green-900 cursor-pointer dark:text-sky-400"
          />
        </div>
      </td>
    </tr>
  );
}

export default ItemCard;
