import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { Button } from 'antd';
import { TrashIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { MinusCircleIcon } from '@heroicons/react/20/solid';
import CurrencyInput from 'react-currency-input-field';
import ItemList from './item-list';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ItemUpsertModal({
  show,
  onFormChange,
  onFormSubmit,
  onFormSubmitMultiple,
  onClickDeleteItem,
  isSingleItem,
  setIsSingleItem,
  onCloseUpsert,
  items,
  itemList,
  setItems,
  itemForm,
  errors,
  loading,
}) {
  const cancelButtonRef = useRef(null);
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const onClickAddItem = () => {
    setItems((prev) => [...prev, { name: '', price: '' }]);
  };

  const onItemsChange = (val, key, index) => {
    const newItemList = [...items];
    newItemList[index][key] = val;
    setItems(newItemList);
  };

  const onClickDeleteItemInMultiple = (index) => {
    const newItemList = [...items];
    newItemList.splice(index, 1);
    setItems(newItemList);
  };

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  {itemList.length === 0 && (
                    <div className="flex space-x-5">
                      <h1
                        onClick={() => setIsSingleItem(true)}
                        className={classNames(
                          isSingleItem ? 'border-blue-500' : '',
                          'cursor-pointer border-b-2 inline-block'
                        )}
                      >
                        Single Item
                      </h1>

                      <h1
                        onClick={() => setIsSingleItem(false)}
                        className={classNames(
                          isSingleItem ? '' : 'border-blue-500',
                          'cursor-pointer border-b-2 inline-block'
                        )}
                      >
                        Multiple Item
                      </h1>
                    </div>
                  )}
                  {isSingleItem ? (
                    <>
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Item Name
                        </label>
                        <div className="mt-1">
                          <input
                            value={itemForm.name}
                            onChange={(e) =>
                              onFormChange(e.target.value, 'name')
                            }
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="e.g ''Chicken,beef'' or ''Small, Medium''"
                          />
                          {errors.name && (
                            <div className="h-2 mt-1  text-xs">
                              <p className="text-red-700">
                                Item name can not be empty
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-7">
                        <p className="font-normal text-sm dark:text-slate-400 text-gray-500">
                          Customize your items with options like "chicken, beef,
                          lamb" or "small, medium, and large." For items without
                          customizations, simply enter the item name.
                        </p>
                      </div>
                      <div className="sm:w-1/4 w-full ">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
                        >
                          Price
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <CurrencyInput
                            id="input-price"
                            name="price"
                            placeholder="0.00"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            prefix="$"
                            value={itemForm.price}
                            decimalSeparator="."
                            groupSeparator=","
                            decimalScale={2}
                            step={1}
                            defaultValue={0}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) =>
                              onFormChange(value, name)
                            }
                          />
                        </div>
                        {errors.price && (
                          <div className="h-2 mt-1  text-xs">
                            <p className="text-red-700">
                              Price name can not be empty
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {items.map((item, index) => {
                        return (
                          <div
                            className="grid grid-cols-12 space-x-5
                      "
                          >
                            <div className="col-span-1  self-center justify-self-center">
                              <MinusCircleIcon
                                onClick={() =>
                                  onClickDeleteItemInMultiple(index)
                                }
                                className="text-blue-500 mr-3 w-5 h-5 hover:text-blue-900"
                              />
                            </div>
                            <div className="col-span-7">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                              >
                                Item Name
                              </label>
                              <div className="mt-1">
                                <input
                                  value={item.name}
                                  onChange={(e) =>
                                    onItemsChange(e.target.value, 'name', index)
                                  }
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                  placeholder="e.g ''Chicken,beef'' or ''Small, Medium''"
                                />
                                <div className="h-2 mt-1  text-xs">
                                  {errors.name && (
                                    <p className="text-red-700">
                                      Item name can not be empty
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-span-4">
                              <label
                                htmlFor="price"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Price
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <CurrencyInput
                                  id="input-price"
                                  name="price"
                                  placeholder="0.00"
                                  className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                  prefix="$"
                                  value={item.price}
                                  decimalSeparator="."
                                  groupSeparator=","
                                  decimalScale={2}
                                  step={1}
                                  defaultValue={0}
                                  decimalsLimit={2}
                                  onValueChange={(value, name, values) =>
                                    onItemsChange(value, name, index)
                                  }
                                />
                              </div>
                              {errors.price && (
                                <div className="h-2 mt-1  text-xs">
                                  <p className="text-red-700">
                                    Price can not be empty
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="col-span-12">
                        <p className="font-normal text-sm dark:text-slate-400">
                          Customize your items with options like "chicken, beef,
                          lamb" or "small, medium, and large." For items without
                          customizations, simply enter the item name.
                        </p>
                      </div>

                      <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-4 sm:gap-3">
                        <Button
                          type="ghost"
                          disabled={loading.upsertLoading}
                          loading={loading.upsertLoading}
                          className={classNames(
                            'col-span-1 col-start-1',
                            'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                          )}
                          onClick={() => onClickAddItem()}
                        >
                          + Add Item
                        </Button>
                      </div>
                    </>
                  )}

                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {itemForm.id && (
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        onClick={() => onClickDeleteItem(itemForm)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-0 sm:px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 col-span-2  sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading}
                      className={classNames(
                        itemForm.id ? 'col-span-5 ' : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    {isSingleItem ? (
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        className={classNames(
                          itemForm.id ? 'col-span-5' : 'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmit()}
                      >
                        {itemForm.id ? 'Edit Item' : 'Add Item'}
                      </Button>
                    ) : (
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        className={classNames(
                          itemForm.id ? 'col-span-5' : 'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmitMultiple()}
                      >
                        Add Items
                      </Button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
