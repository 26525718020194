import { Dialog, Transition, Switch } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { Button, Spin, Radio, DatePicker } from 'antd';
import React, { Fragment, useState, useRef, useEffect } from 'react';
import SubscriptionUpsertModal from '../../../../components/subscription/subscription-upsert-modal';
import companyApi from '../../../../services/companyApi';
import storeApi from '../../../../services/storeApi';
import dayjs from 'dayjs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Settings = ({ companyDetail, updateCompany, setNotf }) => {
  const [loading, setLoading] = useState({
    storeListLoading: false,
    slugUpdateLoading: false,
  });
  const [storeList, setStoreList] = useState([]);

  const getStoreList = async () => {
    try {
      setLoading((prev) => ({ ...prev, storeListLoading: true }));
      const query = `companyid=${companyDetail.id}`;
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, storeListLoading: false }));
    }
  };

  const onClickUpdateStoreSlug = async (val, key, index) => {
    const form = {
      ...storeList[index],
    };

    form[key] = val;

    try {
      setLoading((prev) => ({ ...prev, slugUpdateLoading: true }));
      const formData = new FormData();

      for (const key of Object.keys(form)) {
        formData.append(key, form[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      await storeApi.upsert(formData, config);
      checkUpdate(true);
    } catch (error) {
      checkUpdate(false, error);
    } finally {
      getStoreList();
      setLoading((prev) => ({ ...prev, slugUpdateLoading: false }));
    }
  };

  const onChangeStoreSlug = async (val, key, index) => {
    setStoreList((prev) => {
      const list = [...prev];
      list[index][key] = val;
      return list;
    });
  };

  const checkUpdate = (val, message) => {
    if (val) {
      setNotf((prev) => ({
        ...prev,
        message: 'Store Updated Successfully',
        display: true,
        type: 'success',
      }));
    } else {
      setNotf((prev) => ({
        ...prev,
        message,
        display: true,
        type: 'error',
      }));
    }
  };

  useEffect(() => {
    getStoreList();
  }, []);
  return (
    <>
      {loading.storeListLoading ? (
        <div className="w-full flex justify-center py-4">
          <Spin />
        </div>
      ) : (
        <div className="max-w-md">
          {storeList.map((store, index) => (
            <div key={index} className="py-4">
              <label
                htmlFor="company-website"
                className="pb-1 block text-md font-bold leading-6 text-gray-900"
              >
                {store.name}
              </label>
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Site Slug
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                  {process.env.REACT_APP_ORDER_URL}/
                </span>
                <input
                  value={store.slug}
                  type="text"
                  name="company-website"
                  id="company-website"
                  className="block pl-2 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="thisway"
                  onBlur={(e) =>
                    onClickUpdateStoreSlug(e.target.value, 'slug', index)
                  }
                  onChange={(e) =>
                    onChangeStoreSlug(e.target.value, 'slug', index)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Settings;
