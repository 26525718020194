import api from './api';

const resource = 'Subscription/';

export const list = async (query = '') => {
  return await api.get(resource + 'List' + query);
};

export const listDefaults = async () => {
  return await api.get(resource + 'ListDefaults');
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  return await api.post(resource + 'Upsert', body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

const subscriptionApi = { list, getById, upsert, deleteById, listDefaults };

export default subscriptionApi;
