import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'antd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function StoreSlugUpsertModal({
  show,
  onClose,
  loading,
  errors,
  storeDetail,
  onSubmit,
}) {
  const [slug, setSlug] = useState(storeDetail.slug);
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => onClose()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-1 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => onClose()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="col-span-8 sm:col-span-8 pb-4">
                    <h1 className="text-base font-extrabold text-gray-900 dark:text-slate-500 ">
                      Store Website
                    </h1>
                    <p className=" text-sm text-gray-500 dark:text-slate-500 dark:placeholder:text-slate-500">
                      This information <strong>will</strong> be displayed on
                      your website.
                    </p>
                  </div>
                  <div className="col-span-8">
                    <label
                      htmlFor="slug"
                      className=" pb-1 block text-sm font-medium text-gray-700 dark:text-slate-400 "
                    >
                      Site Slug
                    </label>
                    <div className="flex">
                      <span className="w-full inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                        {process.env.REACT_APP_ORDER_URL}/
                      </span>
                      <input
                        value={storeDetail.slug}
                        onChange={(e) => {
                          const result = e.target.value
                            .replace(/[^a-z0-9-]/gi, '')
                            .slice(0, 30);

                          setSlug(result);
                        }}
                        type="text"
                        name="slug"
                        maxLength={30}
                        id="slug"
                        className="block w-full appearance-none rounded-r-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                        placeholder="e.g. 'mystorename'"
                      />
                    </div>
                    <div className="h-2 mt-1  text-xs">
                      {errors.slug && (
                        <p className="text-red-700">Please enter store slug.</p>
                      )}
                    </div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      <Button
                        type="ghost"
                        disabled={loading}
                        className={
                          'col-span-6 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                        }
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        className={
                          'col-span-6 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        }
                        onClick={() => onSubmit(slug)}
                      >
                        Create Order Link
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
