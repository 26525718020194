import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import NoImage from "../../../assets/no-image.svg";
import { Switch } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MenuCard({
  id,
  index,
  menu,
  selected,
  setSelected,
  onClickEditMenu,
  moveMenuItem,
  dropMenuItem,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "menu",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropMenuItem(item.index, menu);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientX = clientOffset.x - hoverBoundingRect.right;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      moveMenuItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "menu",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      onClick={() => {
        setSelected((prev) => ({
          ...prev,
          menuid: menu.id,
          category: {},
          product: {},
          productid: "",
          isCategoryFetch: !prev.isCategoryFetch,
        }));
      }}
      key={id}
      className={classNames(
        menu.id === selected.menuid
          ? "border-blue-600 text-gray-800 dark:border-sky-400 dark:text-sky-500 bg-slate-50 dark:bg-slate-700"
          : " text-slate-500 hover:text-gray-900 hover:border-gray-300 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-600",
        "flex justify-between whitespace-nowrap cursor-pointer py-3 px-4 border-b-2 font-medium text-sm max-w-[10rem] w-[10rem] dark:border-slate-500"
      )}
    >
      <div
        className={classNames(!menu.description ? "self-center px-2" : "")}
        aria-current={selected.menuid ? "page" : undefined}
      >
        <p className="font-semibold">
          {menu.name.slice(0, 15) + (menu.name.length > 15 ? "..." : "")}
        </p>
        <p
          className={classNames(
            menu.id === selected.menuid ? "text-gray-500" : "text-gray-400",
            "text-xs font-light"
          )}
        >
          {menu.description.slice(0, 15) +
            (menu.description.length > 15 ? "..." : "")}
        </p>
      </div>
      <div className="flex items-center">
        <PencilSquareIcon
          onClick={() => onClickEditMenu(menu)}
          className={classNames(
            menu.id === selected.menuid ? "text-gray-800" : "text-slate-500",
            "w-5 h-5 hover:text-gray-900 dark:text-sky-400"
          )}
        />
      </div>
    </div>
  );
}

export default MenuCard;
