import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTransition, animated, useSpringRef } from '@react-spring/web';
import { useDispatch } from 'react-redux';
import {
  loginSuccess,
  setRole,
  setRolePermissions,
} from '../../store/slices/authSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import userApi from '../../services/userApi';
import tokenService from '../../utils/tokenService';
import LogoBlack from '../../assets/logo-black.png';
import LogoWhite from '../../assets/white-logo-brand.svg';
import RegisterImage from '../../assets/register-image.png';
import Notification from '../../components/notification/notification';
import Account from './steps/account';
import Restaurant from './steps/restaurant';
import Owner from './steps/owner';
import moment from 'moment';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Register = () => {
  let navigate = useNavigate();

  /*   const [subscriptionList, setSubscriptionList] = useState([]); */
  const dispatch = useDispatch();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    tokenService.getTheme() === 'dark' ? true : false
  );
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    passwordlength: false,
    terms: false,
    companyname: false,
    storeaddress: false,
    subscriptionid: false,
    responsiblename: false,
    responsiblephone: false,
    recaptcha: false,
    showNotf: false,
    message: '',
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [isNext, setIsNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    terms: false,
    companyname: '',
    storeaddress: '',
    // suite: "",
    postalcode: '',
    timezone: moment().utcOffset() / 60,
    state: '',
    city: '',
    apartment: '',
    subscriptionid: null,
    responsiblename: '',
    responsiblephone: '',
    longitude: 0,
    latitude: 0,
  });
  const [steps, setSteps] = useState([
    { id: 1, name: 'Account', status: 'current' },
    { id: 2, name: 'Restaurant', status: 'upcoming' },
    { id: 3, name: 'Owner', status: 'upcoming' },
  ]);
  const captchaRef = useRef(null);
  const transRef = useSpringRef();
  const transitions = useTransition(currentStep, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: isNext ? 'translate3d(10%,0,0)' : 'translate3d(-10%,0,0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: isNext ? 'translate3d(-10%,0,0)' : 'translate3d(10%,0,0)',
    },
  });

  useEffect(() => {
    transRef.start();
  }, [currentStep]);

  const onChange = (value, key) => {
    if (typeof value === 'object') {
      setUserData({
        ...userData,
        storeaddress: value.street,
        city: value.city,
        state: value.state,
        postalcode: value.zipCode,
        apartment: value.apartment,
        longitude: value.longitude,
        latitude: value.latitude,
      });
    } else {
      setUserData({ ...userData, [key]: value });
    }
  };

  const stepComponents = [
    <Account
      errors={errors}
      onChange={onChange}
      email={userData.email}
      password={userData.password}
      terms={userData.terms}
    />,
    <Restaurant
      errors={errors}
      onChange={onChange}
      companyname={userData.companyname}
      storeaddress={userData.storeaddress}
      city={userData.city}
      state={userData.state}
      apartment={userData.apartment}
      postalcode={userData.postalcode}
      subscriptionid={userData.subscriptionid}
    />,
    <Owner
      errors={errors}
      onChange={onChange}
      responsiblename={userData.responsiblename}
      responsiblephone={userData.responsiblephone}
    />,
  ];

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const stepValidation = (step) => {
    if (step === 1) {
      const { email, password, terms } = userData;
      if (
        !email ||
        !password ||
        !terms ||
        password.length < 8 ||
        !isValidEmail(email)
      ) {
        setErrors({
          ...errors,
          email: !email || !isValidEmail(email),
          password: !password,
          terms: !terms,
          passwordlength: password.length < 8,
        });
        return false;
      }
    } else if (step === 2) {
      const { companyname, storeaddress } = userData;
      if (!companyname || !storeaddress) {
        setErrors({
          ...errors,
          companyname: !companyname,
          storeaddress: !storeaddress,
        });
        return false;
      }
    } else if (step === 3) {
      const { responsiblename, responsiblephone } = userData;
      if (
        !responsiblename ||
        !responsiblephone ||
        !isCaptchaVerified ||
        responsiblephone.replace(/[\(\)\s-]/g, '').length < 10
      ) {
        setErrors({
          ...errors,
          responsiblename: !responsiblename,
          responsiblephone:
            !responsiblephone ||
            responsiblephone.replace(/[\(\)\s-]/g, '').length < 10,
          recaptcha: !isCaptchaVerified,
        });
        return false;
      }
    }
    return true;
  };

  const nextStep = (newStep) => {
    setSteps((prev) => {
      return prev.map((step) => {
        if (step.id === newStep) {
          return { ...step, status: 'current' };
        } else if (step.id === newStep - 1) {
          return { ...step, status: 'complete' };
        } else {
          return step;
        }
      });
    });
  };

  const backStep = (newStep) => {
    setSteps((prev) => {
      return prev.map((step) => {
        if (step.id === newStep) {
          return { ...step, status: 'current' };
        } else if (step.id === newStep + 1) {
          return { ...step, status: 'upcoming' };
        } else {
          return step;
        }
      });
    });
  };

  const handleClick = async (direction) => {
    let newStep = currentStep;

    if (direction === 'next') {
      setIsNext(true);
      if (stepValidation(currentStep)) {
        if (newStep === 3) {
          setLoading(true);

          try {
            userData.fullname = userData.responsiblename;

            const token = captchaRef.current.getValue();
            captchaRef.current.reset();

            const res = await userApi.validateCaptcha(token);

            if (!res.data.success) {
              setErrors({
                ...errors,
                showNotf: true,
                message: 'Please verify that you are not a robot',
              });
              return;
            }

            const registeredUser = await userApi.register(userData);
            if (registeredUser.data.data) {
              if (registeredUser.data.data) {
                tokenService.updateLocalAccessToken(registeredUser.data.data);
                const userDetail = await userApi.getUserDetail();
                if (userDetail.status === 200) {
                  let rolepermissions = rolePermissions(
                    userDetail.data.data.userroles[0]?.role?.rolepermissions
                  );
                  tokenService.setRole([150]);
                  dispatch(loginSuccess(userDetail.data.data));
                  dispatch(setRole([150]));
                  dispatch(setRolePermissions(rolepermissions));
                  navigate('/dashboard');
                }
              }
            }
          } catch (error) {
            setErrors({
              ...errors,
              showNotf: true,
              message: error.response.data,
            });
          } finally {
            setLoading(false);
          }
        }
        newStep++;
        nextStep(newStep);
      }
    } else {
      setIsNext(false);
      setErrors({
        email: false,
        password: false,
        passwordlength: false,
        terms: false,
        companyname: false,
        storeaddress: false,
        responsiblename: false,
        responsiblephone: false,
        recaptcha: false,
        showNotf: false,
        message: '',
      });
      newStep--;
      backStep(newStep);
    }

    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleCaptchaChange = (value) => {
    if (value !== null) setIsCaptchaVerified(true);
    else setIsCaptchaVerified(false);
  };

  const rolePermissions = (permissions) => {
    let rolepermissions = [];
    permissions.forEach((permission) => {
      rolepermissions.push(permission.permission.code);
    });
    return rolepermissions;
  };

  return (
    <div className="relative bg-white dark:bg-slate-800 h-screen">
      <div className="lg:absolute lg:inset-0">
        <div className="z-10 lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 sm:block hidden">
          <img
            className="z-10 h-48 sm:h-48 md:h-56 w-full object-cover lg:absolute lg:h-full"
            src={RegisterImage}
            alt=""
          />
        </div>
        <div className="pl-4 pt-4">
          <Link
            to="https://thisway.co"
            className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500"
          >
            <img
              className="h-12 w-auto sm:hidden block"
              src={LogoBlack}
              alt="Your Company"
            />
          </Link>
        </div>
      </div>
      <div className="h-full sm:h-screen flex flex-col sm:justify-between justify-center">
        <div>
          <div className="lg:absolute relative sm:right-0 lg:mx-auto lg:my-auto lg:left-0 z-20 lg:grid lg:max-w-full lg:grid-cols-2 lg:px-6 py-4 inline-flex">
            <div className="relative px-4 lg:pl-6 flex w-full items-center space-x-2 justify-center lg:justify-start">
              <Link
                to="https://thisway.co"
                className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500"
              >
                <img
                  className="h-12 w-auto sm:block hidden"
                  src={LogoWhite}
                  alt="Your Company"
                />
              </Link>
            </div>
            <div></div>
          </div>
          <div className="relative pb-16 px-4 overflow-x-hidden lg:mx-auto lg:grid lg:max-w-full lg:grid-cols-2 lg:px-8 h-full lg:h-screen">
            <div></div>
            <div className="pt-5 sm:pt-0 lg:pr-4 lg:pl-9 mx-auto max-w-5xl md:w-[30rem] self-center">
              <div className="pb-5 max-w-xl mx-auto">
                <h2 className="text-3xl font-bold dark:text-gray-200 tracking-tight sm:text-4xl">
                  Sign Up
                </h2>
                <h1 className="dark:text-gray-200">
                  Start your 14-day free trial today!
                </h1>
              </div>
              {currentStep !== 1 && (
                <nav
                  className="flex items-center justify-center "
                  aria-label="Progress"
                >
                  <ol role="list" className="ml-8 flex items-center space-x-5 ">
                    {steps.map((step, stepIdx) => (
                      <li key={step.name}>
                        {step.status === 'complete' ? (
                          <a
                            href={step.href}
                            className="block h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-900"
                          >
                            <span className="sr-only">{step.name}</span>
                          </a>
                        ) : step.status === 'current' ? (
                          <a
                            href={step.href}
                            className="relative flex items-center justify-center"
                            aria-current="step"
                          >
                            <span
                              className="absolute flex h-5 w-5 p-px"
                              aria-hidden="true"
                            >
                              <span className="h-full w-full rounded-full bg-blue-200" />
                            </span>
                            <span
                              className="relative block h-2.5 w-2.5 rounded-full bg-blue-600"
                              aria-hidden="true"
                            />
                            <span className="sr-only">{step.name}</span>
                          </a>
                        ) : (
                          <a
                            href={step.href}
                            className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                          >
                            <span className="sr-only">{step.name}</span>
                          </a>
                        )}
                      </li>
                    ))}
                  </ol>
                </nav>
              )}
              <div className="mx-auto max-w-xl lg:max-w-full lg:mx-0 space-y-4 pb-1">
                <div
                  className="z-20 relative"
                  style={{
                    height:
                      currentStep === 3 || currentStep === 2
                        ? '13rem'
                        : '15rem',
                  }}
                >
                  {transitions((style, item) => (
                    <animated.div
                      style={{
                        ...style,
                      }}
                    >
                      {stepComponents[item - 1]}
                    </animated.div>
                  ))}
                </div>
                <div
                  className={classNames(
                    currentStep === 3 ? 'justify-stretch' : 'justify-end',
                    'z-10'
                  )}
                  style={{
                    marginTop: '0px',
                  }}
                >
                  {currentStep === 3 && (
                    <div>
                      <ReCAPTCHA
                        style={{ paddingBottom: '5px' }}
                        ref={captchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                      />
                      <div className="h-2 mt-1 text-xs">
                        {errors.recaptcha && (
                          <p className="text-red-700">
                            Please verify that you are not a robot.
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames(
                      currentStep === 2 ? 'pt-60' : 'pt-12',
                      'text-right md:pt-20'
                    )}
                  >
                    {currentStep !== 1 && (
                      <span
                        onClick={() => handleClick('back')}
                        className="mx-4 pr-4 inline-flex justify-center h-10 items-center rounded-md text-black font-bold cursor-pointer dark:text-slate-200 dark:hover:text-sky-400"
                      >
                        Back
                      </span>
                    )}
                    <Button
                      type="ghost"
                      onClick={() => handleClick('next')}
                      disabled={loading}
                      loading={loading}
                      className="inline-flex min-w-[6rem] justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0"
                    >
                      {currentStep === steps.length ? 'Finish' : 'Continue'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 pb-4 flex justify-center">
          <div className="relative flex sm:hidden dark:text-zinc-200 text-end">
            <div className="ml-3 text-sm">
              <Link
                to="/"
                className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500 dark:hover:text-sky-400"
              >
                Do you have an account? Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Notification
        display={errors.showNotf}
        type="error"
        message={errors.message}
        onClose={() => setErrors((prev) => ({ ...prev, showNotf: false }))}
      />
    </div>
  );
};

export default Register;
