//Modules
import { createSlice } from '@reduxjs/toolkit';
import tokenService from '../../utils/tokenService';

const initialState = {
  list: [],
  selected: {},
};

export const companySlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCompany: (state, { payload }) => {
      state.list = payload;
    },
    setGlobalCompany: (state, { payload }) => {
      state.selected = payload;
      tokenService.setComp(JSON.stringify(payload));
    },
  },
});

export const { setCompany, setGlobalCompany } = companySlice.actions;

export default companySlice.reducer;
