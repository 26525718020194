import { Dialog, Switch, Transition } from '@headlessui/react';
import { Button, Pagination, Select, Spin } from 'antd';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { PencilSquareIcon, UserIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import roleApi from '../../services/roleApi';
import userApi from '../../services/userApi';
import companyApi from '../../services/companyApi';
import ModalDelete from '../../components/modal-delete/modal-delete';
import { useSelector } from 'react-redux';
import { useTransition, animated, useSpringRef } from '@react-spring/web';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Users = () => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const userRoles = useSelector((state) => state.authReducer.roles);
  const [isAccountEdit, setIsAccountEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [deletedUser, setDeletedUser] = useState({});
  //Filter Management Start//
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState({
    email: '',
    roleid: '',
  });
  //Filter Management End//

  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [form, setForm] = useState({
    id: '',
    email: '',
    fullname: '',
    roles: [],
    status: null,
    password: '',
  });

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [dialogs, setDialogs] = useState({
    upsert: false,
    delete: false,
  });

  const [errors, setErrors] = useState({
    fullname: false,
    email: false,
    status: false,
    roles: false,
    companies: false,
    password: false,
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });

  const cancelButtonRef = useRef(null);

  const getUserList = async (searchQuery) => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setUserList([]);

    const createQueryParams = () => {
      const baseParams = `pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}&status=10&status=0`;
      if (userRoles.includes(100)) {
        return `?${baseParams}`;
      } else if (globalCompany?.id) {
        const restaurantRoles = [
          { id: 'e1928053-a880-417a-a723-3094d5a3f8b1', name: 'Owner' },
          { id: '3b6a8649-cc56-4a62-a8e6-e553223c4a7e', name: 'Store Manager' },
          {
            id: 'e9bfc27b-3e57-4f5b-b3f7-287f61b47ac8',
            name: 'Store Operator',
          },
        ];
        const roleParams = restaurantRoles
          .map((role) => `roleids=${role.id}`)
          .join('&');
        return `?companyid=${globalCompany.id}&${baseParams}&${roleParams}`;
      }
      return `?${baseParams}`;
    };

    const query = createQueryParams() + `&${searchQuery}`;

    try {
      const list = await userApi.list(query);
      if (list.data.data.length > 0) {
        setUserList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      }
    } catch (error) {
      console.error('An error occurred while fetching user list:', error);
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const getRoleList = async () => {
    try {
      const list = await roleApi.roleList();
      if (list.data.data.length > 0) {
        const roles = list.data.data;
        let filteredRoles = userRoles.includes(100)
          ? roles
          : userRoles.includes(150)
          ? roles.filter(
              (x) =>
                x.name === 'Store Manager' ||
                x.name === 'Store Operator' ||
                x.name === 'Owner'
            )
          : roles.filter((x) => x.name !== 'Admin' && x.name !== 'Owner');

        filteredRoles = filteredRoles.map((role) => ({
          label: role.name,
          value: role.id,
        }));
        setRoleList(filteredRoles);
      }
    } catch (error) {
    } finally {
    }
  };

  const getCompanyList = async () => {
    try {
      const query = `?PageNumber=1&PageSize=100`;
      const list = await companyApi.list(query);
      if (list.data.data.length > 0) {
        setCompanyList(list.data.data);
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  useEffect(() => {
    const query = searchQuery();
    getUserList(query);
    getRoleList();
    getCompanyList();
  }, [globalCompany.id, pagination.pageNumber]);

  const onFormChange = (val, key) => {
    if (key === 'companies') {
      setSelectedCompanies(val);
    } else if (key === 'status') {
      setForm((prev) => ({ ...prev, [key]: val ? 10 : 20 }));
    } else if (key === 'roles') {
      setForm((prev) => ({ ...prev, [key]: [val] }));
    } else {
      setForm((prev) => ({ ...prev, [key]: val }));
    }
  };

  const onClickAdd = () => {
    setForm({
      id: '',
      email: '',
      fullname: '',
      roles: [],
      status: 10,
    });
    setSelectedCompanies([globalCompany.id]);
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onClickEdit = (val) => {
    setForm({
      id: val.id,
      email: val.email,
      fullname: val.fullname,
      roles: val.userroles.map((x) => x.role.id),
      status: val.status,
    });

    const companies = companyList
      .map((x) => {
        const users = x.companyusers.find((a) => a.userid === val.id);
        if (users) {
          return x;
        }
      })
      .filter((x) => x !== undefined)
      .map((x) => x.id);

    setSelectedCompanies(companies);
    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormSubmit = async () => {
    const errors = {};

    if (!isCreate && isAccountEdit) {
      if (!form.email || !form.fullname || !form.password) {
        errors.email = !form.email;
        errors.fullname = !form.fullname;
        errors.password = !form.password;
      }
    } else if (!isCreate && !isAccountEdit) {
      if (
        !form.status ||
        form.roles.length === 0 ||
        selectedCompanies.length === 0
      ) {
        errors.status = !form.status;
        errors.roles = form.roles.length === 0;
        errors.companies = selectedCompanies.length === 0;
      }
    } else {
      if (
        !form.email ||
        !form.fullname ||
        !form.status ||
        form.roles.length === 0 ||
        selectedCompanies.length === 0 ||
        !form.password
      ) {
        errors.email = !form.email;
        errors.fullname = !form.fullname;
        errors.status = !form.status;
        errors.roles = form.roles.length === 0;
        errors.companies = selectedCompanies.length === 0;
        errors.password = !form.password;
      }
    }

    setErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));

      const body = { ...form, companies: selectedCompanies };
      await userApi.upsert(body);
    } catch (error) {
    } finally {
      await getUserList();
      await getRoleList();
      await getCompanyList();
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      setDialogs((prev) => ({ ...prev, upsert: false }));
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      email: false,
      fullname: false,
      status: false,
      roles: false,
      companies: false,
      password: false,
    }));
    setSelectedCompanies([]);
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onClickDelete = (user) => {
    setDeletedUser({ name: user.fullname, id: user.id });
    setDialogs((prev) => ({ ...prev, upsert: false, delete: true }));
  };

  const onConfirmDelete = async () => {
    setLoading((prev) => ({ ...prev, deleteLoading: true }));
    try {
      await userApi.deleteById(deletedUser.id);
    } catch (error) {
    } finally {
      setDialogs((prev) => ({ ...prev, delete: false, upsert: false }));
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
      await getUserList();
      await getRoleList();
      await getCompanyList();
    }
  };

  useEffect(() => {
    transRef.start();
  }, [showFilters]);

  const transRef = useSpringRef();
  const transitions = useTransition(showFilters, {
    ref: transRef,
    keys: null,
    from: { maxHeight: '0px', opacity: 0 },
    enter: { maxHeight: '1000px', opacity: 1, overflowY: 'none' },
    leave: { maxHeight: '0px', opacity: 0 },
  });

  const searchQuery = () => {
    const query = Object.keys(search)
      .map((key) => {
        return `${key}=${search[key]}`;
      })
      .filter(Boolean)
      .join('&');
    return query;
  };

  const onClickSearch = async () => {
    const query = searchQuery();
    await getUserList(query);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  };

  const onClickClearSearch = () => {
    setSearch({
      email: '',
      roleid: '',
    });
    getUserList();
  };

  const onSearchChange = (val, key) => {
    setSearch((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Users
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                Control user access and permissions across your platform.
              </p>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col-reverse mt-5 sm:mt-0 gap-2 justify-end">
            {userRoles.includes(100) && (
              <Button
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
                type="ghost"
                className={classNames(
                  'inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                )}
              >
                <MagnifyingGlassIcon
                  className="h-5 w-5 mr-1"
                  aria-hidden="true"
                />
                Filters
              </Button>
            )}
            <Button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
              onClick={() => {
                setIsCreate(true);
                onClickAdd();
              }}
            >
              Add User
            </Button>
          </div>
          {transitions(
            (style, item) =>
              item && (
                <animated.div style={{ ...style }}>
                  <div className="sm:mt-0 mt-2">
                    <div className="justify-between pb-2 grid grid-cols-6 gap-4">
                      <div className="col-span-3 sm:col-span-2 w-full ">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            value={search.email}
                            onChange={(e) =>
                              onSearchChange(e.target.value, 'email')
                            }
                            type="text"
                            name="email"
                            id="email"
                            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-2 w-full ">
                        <label
                          htmlFor="role"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                        >
                          Role
                        </label>
                        <div className="mt-1">
                          <Select
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder="Role"
                            value={search.roleid}
                            onChange={(e) => {
                              setSearch((prev) => ({
                                ...prev,
                                roleid: e,
                              }));
                            }}
                          >
                            <Select.Option value="">All Roles</Select.Option>
                            {roleList.map((item) => (
                              <Select.Option
                                key={item.value}
                                value={item.value}
                              >
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="justify-end pb-2 grid grid-cols-3 gap-4 ">
                      <div className="flex sm:col-start-3 items-center col-start-2 gap-x-5 ">
                        <Button
                          onClick={() => {
                            onClickClearSearch();
                          }}
                          type="ghost"
                          className={classNames(
                            'col-span-6',
                            'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                          )}
                        >
                          <TrashIcon className="text-gray-700 w-5 h-5 mr-2" />
                          Clear
                        </Button>
                        <Button
                          type="ghost"
                          onClick={() => {
                            onClickSearch();
                          }}
                          className={classNames(
                            'col-span-6',
                            'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                          )}
                        >
                          <MagnifyingGlassIcon className="text-white w-5 h-5 mr-2" />
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )
          )}
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-6 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      {userList.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                            {person?.fullname}
                          </td>
                          <td className="whitespace-nowrap py-2 px-3 text-sm text-gray-500 dark:text-slate-400">
                            {person?.email}
                          </td>
                          <td className="whitespace-nowrap py-2 px-3 text-sm text-gray-500 dark:text-slate-400">
                            {person?.userroles.map((x, roleIdx) =>
                              roleIdx === person.userroles.length - 1
                                ? x.role.name
                                : x.role.name + ', '
                            )}
                          </td>
                          <td className="whitespace-nowrap py-2 px-3 text-sm text-gray-500 dark:text-slate-400">
                            {person.status === 10
                              ? 'Active'
                              : person.status === 0
                              ? 'Pending'
                              : 'Passive'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {userRoles.some((role) =>
                              [100, 150].includes(role)
                            ) ? (
                              <div className="flex">
                                <PencilSquareIcon
                                  onClick={() => {
                                    setIsCreate(false);
                                    setIsAccountEdit(false);
                                    onClickEdit(person);
                                  }}
                                  className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer mr-2"
                                />
                                <UserIcon
                                  onClick={() => {
                                    setIsCreate(false);
                                    setIsAccountEdit(true);
                                    onClickEdit(person);
                                  }}
                                  className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                                />
                              </div>
                            ) : !person.userroles.some((role) =>
                                [150].includes(role.role.code)
                              ) ? (
                              <div className="flex">
                                <PencilSquareIcon
                                  onClick={() => {
                                    setIsAccountEdit(false);
                                    onClickEdit(person);
                                  }}
                                  className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer mr-2"
                                />
                                <UserIcon
                                  onClick={() => {
                                    setIsAccountEdit(true);
                                    onClickEdit(person);
                                  }}
                                  className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full text-end">
                    <Pagination
                      current={pagination.pageNumber}
                      total={pagination.totalRecords}
                      defaultPageSize={pagination.pageSize}
                      onChange={(page) =>
                        setPagination((prev) => ({ ...prev, pageNumber: page }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    {(isAccountEdit || isCreate) && (
                      <>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="fullname"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Full Name*
                          </label>
                          <div className="mt-1">
                            <input
                              value={form.fullname}
                              onChange={(e) =>
                                onFormChange(e.target.value, 'fullname')
                              }
                              type="text"
                              name="fullname"
                              id="fullname"
                              className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                              placeholder="e.g. 'John Doe'"
                            />
                            <div className="h-2 mt-1  text-xs">
                              {errors.fullname && (
                                <p className="text-red-700">
                                  Full name can not be empty
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-5">
                          <div className="">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              User Email*
                            </label>
                            <div className="mt-1">
                              <input
                                disabled={form.id}
                                value={form.email}
                                onChange={(e) =>
                                  onFormChange(e.target.value, 'email')
                                }
                                type="text"
                                name="email"
                                id="email"
                                className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                placeholder="e.g. 'example@acme.com'"
                              />
                              <div className="h-2 mt-1  text-xs">
                                {errors.email && (
                                  <p className="text-red-700">
                                    User email can not be empty
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Create a Password*
                            </label>
                            <div className="mt-1">
                              <input
                                value={form.password}
                                onChange={(e) =>
                                  onFormChange(e.target.value, 'password')
                                }
                                type="password"
                                name="password"
                                id="password"
                                className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                placeholder=""
                              />
                              <div className="h-2 mt-1  text-xs">
                                {errors.password && (
                                  <p className="text-red-700">
                                    Password can not be empty
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {(!isAccountEdit || isCreate) && (
                      <>
                        <div className="col-span-2">
                          <label
                            htmlFor="roles"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Select Company*
                          </label>
                          <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder=""
                            defaultValue={selectedCompanies}
                            onChange={(val) => onFormChange(val, 'companies')}
                            options={companyList.map((company) => ({
                              label: company.name,
                              value: company.id,
                            }))}
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.companies ? (
                              <p className="text-red-700">Select a company</p>
                            ) : (
                              <p className="text-gray-500">
                                You can add same user to multiple companies
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-span-2 mt-5">
                          <label
                            htmlFor="roles"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                          >
                            Select Role*
                          </label>
                          <Select
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder=""
                            defaultValue={form?.roles}
                            onChange={(val) => onFormChange(val, 'roles')}
                            options={roleList}
                          />
                          <div className="h-2 mt-1  text-xs">
                            {errors.roles && (
                              <p className="text-red-700">Select a role</p>
                            )}
                          </div>
                        </div>
                        {form.id && (
                          <div>
                            <label
                              htmlFor="image"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Active
                            </label>
                            <div className="mt-1">
                              <Switch
                                checked={form.status === 10 ? true : false}
                                onChange={(e) => {
                                  onFormChange(e, 'status');
                                }}
                                className={classNames(
                                  form.status === 10
                                    ? 'bg-blue-600'
                                    : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    form.status === 10
                                      ? 'translate-x-5'
                                      : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                  )}
                                />
                              </Switch>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="h-2 mt-1  text-xs"></div>
                    <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                      {form.id && (
                        <Button
                          type="ghost"
                          disabled={loading.deleteLoading}
                          loading={loading.deleteLoading}
                          onClick={() => onClickDelete(form)}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-0 sm:px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 col-span-2 sm:text-sm"
                        >
                          <TrashIcon className="text-white w-5 h-5" />
                        </Button>
                      )}
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        className={classNames(
                          form.id ? 'col-span-5' : 'col-span-6',
                          'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                        )}
                        onClick={() => onCloseUpsert()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="ghost"
                        disabled={loading.upsertLoading}
                        loading={loading.upsertLoading}
                        className={classNames(
                          form.id ? 'col-span-5 ' : 'col-span-6',
                          'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                        )}
                        onClick={() => onFormSubmit()}
                      >
                        {form.id ? 'Edit User' : 'Add User'}
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ModalDelete
        title="Delete User"
        loading={loading.deleteLoading}
        open={dialogs.delete}
        onCloseRequest={() =>
          setDialogs((prev) => ({ ...prev, delete: false }))
        }
        onOk={onConfirmDelete}
        item={deletedUser}
        shouldConfirm={true}
      />
    </div>
  );
};

export default Users;
