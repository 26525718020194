import { Pagination, Spin, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import terminalApi from '../../services/terminalApi';
import { Tooltip } from 'react-tooltip';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowTopRightOnSquareIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import KioskLinkUpsertModal from './components/kiosk-link-upsert-modal';
import NoData2 from '../../assets/no-data-2.svg';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { setStartAndEndDate } from '../../utils/setStartAndEndDate';
const utc = require('dayjs/plugin/utc');
const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.Ls.en.weekStart = 1;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Kiosks = () => {
  const [kioskList, setKioskList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedKiosk, setSelectedKiosk] = useState(null);
  const [dialogs, setDialogs] = useState({
    showKioskLinkUpsertModal: false,
  });
  const [loading, setLoading] = useState({
    listLoading: true,
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });

  const [sortConfig, setSortConfig] = useState({
    key: null,
    orderby: 0,
  });

  const kioskHeaders = [
    { key: 'store.company.name', label: 'Company Name', sortable: true },
    { key: 'name', label: 'Kiosk Name', sortable: true },
    { key: 'clickcount', label: 'Interaction', sortable: true },
    { key: 'hours', label: 'Hours', sortable: false },
    { key: 'isconnected', label: 'Reader', sortable: true },
    { key: 'lastup', label: 'Tablet Check', sortable: true },
    {
      key: 'lastconnectionupdate',
      label: 'Reader Check',
      sortable: true,
    },
    { key: 'actions', sortable: false },
  ];

  const getKioksList = async (searchQuery = '') => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setKioskList([]);

    const startdate = setStartAndEndDate(dayjs(), 'startdate', '0');

    const enddate = setStartAndEndDate(dayjs(), 'enddate', '0');

    try {
      const query = `?addclickcount=true&startdate=${startdate}&enddate=${enddate}&pageNumber=1&pageSize=100&isdeleted=false&${searchQuery}`;
      const list = await terminalApi.list(query);
      if (list.data.data.length > 0) {
        setKioskList(list.data.data);
        setFilteredList(
          list.data.data
            .sort((a, b) => {
              const statusA = checkStoreStatus(a.store);
              const statusB = checkStoreStatus(b.store);
              return (
                getStatusPriority(statusA.status) -
                getStatusPriority(statusB.status)
              );
            })
            .slice(0, pagination.pageSize)
        );
        setPagination({
          ...pagination,
          totalRecords: Math.ceil(list.data.data.length),
        });
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const requestSort = (key) => {
    let orderby = 1;
    if (sortConfig.key === key && sortConfig.orderby === 1) {
      orderby = 0;
    }
    setSortConfig({ key, orderby });
    getKioksList(`ordercolumn=${key}&orderby=${sortConfig.orderby}`);
  };

  const onClickEditKioskLink = (kiosk) => {
    setSelectedKiosk(kiosk);
    setDialogs((prev) => ({
      ...prev,
      showKioskLinkUpsertModal: true,
    }));
  };

  const onClickOpenReaderLinkNewTab = (link) => {
    window.open(link, '_blank');
  };

  const onSubmitReaderLink = async (link) => {
    try {
      const body = {
        ...selectedKiosk,
        link,
      };

      await terminalApi.upsert(body);
    } catch (e) {
    } finally {
      getKioksList();
      setDialogs((prev) => ({
        ...prev,
        showKioskLinkUpsertModal: false,
      }));
    }
  };

  const checkConnection = (date) => dayjs().diff(dayjs(date), 'minutes') <= 7;

  const getStatusPriority = (status) => {
    if (status === 'opensSoon') return 1;
    if (status === 'open') return 2;
    if (status === 'closed') return 3;
    return 4;
  };

  const checkStoreStatus = (store) => {
    if (!store || store.storadays.length === 0) {
      return { status: 'closed', label: 'Closed' };
    }

    const timezoneOffset = store.timezone * 60;
    const now = dayjs().utc().utcOffset(timezoneOffset);
    let currentDay = now.day() === 0 ? 6 : now.day() - 1;

    const storeHours = store.storadays.find((x) => x.day === currentDay);
    if (!storeHours) {
      return { status: 'closed', label: 'Closed' };
    }

    const openingHours = now
      .clone()
      .hour(storeHours.starthour)
      .minute(storeHours.startmin)
      .second(0);
    const closingHours = now
      .clone()
      .hour(storeHours.endhour)
      .minute(storeHours.endmin)
      .second(0);
    const oneHourBeforeOpening = openingHours.subtract(1, 'hour');

    if (now.isBetween(oneHourBeforeOpening, openingHours)) {
      return {
        status: 'opensSoon',
        label: `Opens in ${openingHours.diff(now, 'minutes')} min`,
      };
    } else if (now.isBetween(openingHours, closingHours)) {
      return { status: 'open', label: 'Open' };
    } else {
      return { status: 'closed', label: 'Closed' };
    }
  };

  const handleChangePage = (pageNumber, pageSize) => {
    setPagination((prev) => ({ ...prev, pageNumber, pageSize }));

    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredList = kioskList
      .sort((a, b) => {
        const statusA = checkStoreStatus(a.store);
        const statusB = checkStoreStatus(b.store);
        return (
          getStatusPriority(statusA.status) - getStatusPriority(statusB.status)
        );
      })
      .slice(startIndex, endIndex);

    setFilteredList(filteredList);
  };

  const onFormChange = async (value, key, kiosk) => {
    try {
      const body = {
        ...kiosk,
        [key]: value,
      };

      await terminalApi.upsert(body);
    } catch (e) {
    } finally {
      getKioksList();
    }
  };

  useEffect(() => {
    getKioksList();
  }, []);

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Kiosks
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                A list of all the kiosks
              </p>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              {filteredList.length > 0 ? (
                <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                      <thead>
                        <tr>
                          {kioskHeaders.map((header) => (
                            <th
                              scope="col"
                              key={header.key}
                              onClick={() =>
                                header.sortable ? requestSort(header.key) : null
                              }
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <a className="group inline-flex cursor-pointer">
                                {header.label}
                                {sortConfig.key === header.key &&
                                  header.sortable && (
                                    <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                      {sortConfig.orderby === 1 ? (
                                        <ChevronUpIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  )}
                              </a>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                        {filteredList.map((kiosk) => (
                          <tr key={kiosk.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  {kiosk.store?.company?.logo ? (
                                    <img
                                      alt="logo"
                                      className="w-8 h-8 rounded-full"
                                      src={kiosk.store?.company?.logo}
                                    />
                                  ) : (
                                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                      <span className="text-xs font-medium leading-none text-white">
                                        {kiosk.store?.company?.name
                                          .split(' ')
                                          .map((x) => x[0])}
                                      </span>
                                    </span>
                                  )}
                                </div>
                                <div className="ml-4">
                                  <div className="grid font-medium text-gray-900 dark:text-slate-200">
                                    <Link
                                      to={`/dashboard/companies/${kiosk.store?.company?.id}`}
                                      className="hover:text-blue-600"
                                    >
                                      {kiosk.store?.company?.name}
                                    </Link>
                                    <span className="text-sm text-gray-500 dark:text-slate-200">
                                      {kiosk.store.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {kiosk.name}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {kiosk.clickcount}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {
                                <span
                                  className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium ${
                                    checkStoreStatus(kiosk.store).status ===
                                    'open'
                                      ? 'bg-green-100 text-green-800'
                                      : checkStoreStatus(kiosk.store).status ===
                                        'opensSoon'
                                      ? 'bg-yellow-100 text-yellow-800'
                                      : 'bg-blue-100 text-blue-800'
                                  }`}
                                >
                                  {checkStoreStatus(kiosk.store).label}
                                </span>
                              }
                            </td>

                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {kiosk.isconnected ? (
                                <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                  Connected
                                </span>
                              ) : (
                                <span
                                  className={classNames(
                                    checkStoreStatus(kiosk.store)?.status ===
                                      'closed'
                                      ? 'bg-blue-100 text-blue-800'
                                      : 'bg-red-100 text-red-800',
                                    'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium'
                                  )}
                                >
                                  Disconnected
                                </span>
                              )}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {kiosk.lastup ? (
                                checkConnection(kiosk.lastup) ? (
                                  <>
                                    <Tooltip id="checkedLastUp" />
                                    <span
                                      data-tooltip-id="checkedLastUp"
                                      data-tooltip-content={dayjs(
                                        kiosk.lastup
                                      ).format('MM/DD/YYYY hh:mm A')}
                                      className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800"
                                    >
                                      Checked
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip id="checkedLastUp" />
                                    <span
                                      data-tooltip-id="checkedLastUp"
                                      data-tooltip-content={dayjs(
                                        kiosk.lastup
                                      ).format('MM/DD/YYYY hh:mm A')}
                                      className={classNames(
                                        checkStoreStatus(kiosk.store)
                                          ?.status === 'closed'
                                          ? 'bg-blue-100 text-blue-800'
                                          : 'bg-red-100 text-red-800',
                                        'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium '
                                      )}
                                    >
                                      {dayjs(kiosk.lastup).format('hh:mm A')}
                                    </span>
                                  </>
                                )
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {kiosk.lastconnectionupdate ? (
                                checkConnection(kiosk.lastconnectionupdate) ? (
                                  <>
                                    <Tooltip id="checkedConnectionUpdate" />
                                    <span
                                      data-tooltip-id="checkedConnectionUpdate"
                                      data-tooltip-content={dayjs(
                                        kiosk.lastconnectionupdate
                                      ).format('MM/DD/YYYY hh:mm A')}
                                      className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800"
                                    >
                                      Checked
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip id="checkedLastConnectionUpdate" />
                                    <span
                                      data-tooltip-id="checkedLastConnectionUpdate"
                                      data-tooltip-content={dayjs(
                                        kiosk.lastconnectionupdate
                                      ).format('MM/DD/YYYY hh:mm A')}
                                      className={classNames(
                                        checkStoreStatus(kiosk.store)
                                          ?.status === 'closed'
                                          ? 'bg-blue-100 text-blue-800'
                                          : 'bg-red-100 text-red-800',
                                        'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium '
                                      )}
                                    >
                                      {dayjs(kiosk.lastconnectionupdate).format(
                                        'hh:mm A'
                                      )}
                                    </span>
                                  </>
                                )
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="relative flex justify-between whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                              <div className="flex text-right justify-center  items-center gap-x-1">
                                <LinkIcon
                                  onClick={() => onClickEditKioskLink(kiosk)}
                                  className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                                />
                                {kiosk.link && (
                                  <ArrowTopRightOnSquareIcon
                                    onClick={() =>
                                      onClickOpenReaderLinkNewTab(kiosk.link)
                                    }
                                    className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                                  />
                                )}
                                <div className="flex items-center">
                                  <Tooltip id="stopslacknotification" />
                                  <Checkbox
                                    data-tooltip-id="stopslacknotification"
                                    data-tooltip-html="Stop slack notification"
                                    className="flex"
                                    checked={kiosk.stopslacknotification}
                                    onChange={(e) =>
                                      onFormChange(
                                        e.target.checked,
                                        'stopslacknotification',
                                        kiosk
                                      )
                                    }
                                  ></Checkbox>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="w-full text-end">
                      <Pagination
                        current={pagination.pageNumber}
                        total={pagination.totalRecords}
                        defaultPageSize={pagination.pageSize}
                        onChange={(pageNumber, pageSize) =>
                          handleChangePage(pageNumber, pageSize)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid justify-center pt-20">
                  <img src={NoData2} alt="No Data" className="h-24 pb-2" />
                  <p className=" text-slate-400 font-light text-center">
                    No Data
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <KioskLinkUpsertModal
        show={dialogs.showKioskLinkUpsertModal}
        kiosk={selectedKiosk}
        onSubmit={onSubmitReaderLink}
        onClose={() =>
          setDialogs((prev) => ({
            ...prev,
            showKioskLinkUpsertModal: false,
          }))
        }
      />
    </div>
  );
};

export default Kiosks;
