import { Dialog, Transition, Switch } from '@headlessui/react';
import { PencilSquareIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import { Button, Spin, Radio, DatePicker } from 'antd';
import React, { Fragment, useState, useRef, useEffect } from 'react';
import SubscriptionUpsertModal from '../../../../components/subscription/subscription-upsert-modal';
import companyApi from '../../../../services/companyApi';
import storeApi from '../../../../services/storeApi';
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Fees = ({ companyDetail, updateCompany, setNotf }) => {
  const [storeList, setStoreList] = useState([]);
  const cancelButtonRef = useRef(null);
  const [form, setForm] = useState({
    creditcardfeeper: companyDetail.creditcardfeeper,
    creditcardfeerate: companyDetail.creditcardfeerate,
    itemfee: companyDetail.itemfee,
  });
  const [selectedStore, setSelectedStore] = useState({});
  const [loading, setLoading] = useState({
    listLoading: false,
    upsertLoading: false,
    deleteLoading: false,
    creditCardLoading: false,
    storeListLoading: false,
    serviceCharge: false,
    customerFeeLoading: false,
    itemFee: false,
  });

  const [dialogs, setDialogs] = useState({
    subscriptionUpsert: false,
    creditCardUpsert: false,
    delete: false,
    customerFeeUpsert: false,
  });

  const getStoreList = async () => {
    try {
      setLoading((prev) => ({ ...prev, storeListLoading: true }));
      const query = `companyid=${companyDetail.id}`;
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, storeListLoading: false }));
    }
  };

  const onClickEdit = () => {
    setDialogs((prev) => ({ ...prev, subscriptionUpsert: true }));
  };

  const onCloseUpsert = () => {
    setDialogs((prev) => ({ ...prev, subscriptionUpsert: false }));
  };

  const onCloseUpsertCreditCard = () => {
    setDialogs((prev) => ({ ...prev, creditCardUpsert: false }));
  };

  const onClickEditCreditCardFee = () => {
    setDialogs((prev) => ({ ...prev, creditCardUpsert: true }));
  };

  const onFormChange = (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onFormSubmit = async (trialend) => {
    const body = {
      ...companyDetail,
      ...form,
      ...(trialend && { trialend: trialend }),
    };
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      if (body[key]) {
        formData.append(key, body[key]);
      }
    });
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    try {
      setLoading((prev) => ({ ...prev, creditCardLoading: true }));
      await companyApi.upsert(formData, config);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      updateCompany();
      setLoading((prev) => ({ ...prev, creditCardLoading: false }));
      setDialogs((prev) => ({ ...prev, creditCardUpsert: false }));
    }
  };

  const onChangeServiceCharge = async (val, key, index) => {
    try {
      setLoading((prev) => ({ ...prev, serviceCharge: true }));
      const body = {
        ...storeList[index],
        [key]: val,
      };
      const formData = new FormData();

      Object.keys(body).forEach((key) => {
        if (body[key]) {
          formData.append(key, body[key]);
        }
      });

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      await storeApi.upsert(formData, config);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      getStoreList();
      setLoading((prev) => ({ ...prev, serviceCharge: false }));
    }
  };

  const checkUpdate = (val) => {
    if (val) {
      setNotf((prev) => ({
        ...prev,
        message: 'Store Updated Successfully',
        display: true,
        type: 'success',
      }));
    } else {
      setNotf((prev) => ({
        ...prev,
        message: 'Store Update Failed',
        display: true,
        type: 'error',
      }));
    }
  };

  const onChangeItemFee = async (val, key) => {
    setForm((prev) => ({ ...prev, [key]: val }));
  };

  const onBlurSaveItemFee = async (val, key) => {
    val = parseFloat(val.replace('$', ''));
    try {
      setLoading((prev) => ({ ...prev, itemFee: true }));
      const body = {
        ...companyDetail,
        [key]: val,
      };
      const formData = new FormData();

      Object.keys(body).forEach((key) => {
        if (body[key]) {
          formData.append(key, body[key]);
        }
      });

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      await companyApi.upsert(formData, config);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      updateCompany();
      setLoading((prev) => ({ ...prev, itemFee: false }));
    }
  };

  const onClickOpenCustomerFeeUpsertModal = (store) => {
    setSelectedStore(store);
    setDialogs((prev) => ({ ...prev, customerFeeUpsert: true }));
  };

  const onCloseUpsertCustomerFee = () => {
    setDialogs((prev) => ({ ...prev, customerFeeUpsert: false }));
  };

  const onFormCustomerFeeChange = (val, index, key) => {
    setSelectedStore((prev) => ({
      ...prev,
      prices: prev.prices.map((price, i) =>
        i === index ? { ...price, [key]: val } : price
      ),
    }));
  };

  const onSubmitCustomerFee = async () => {
    try {
      setLoading((prev) => ({ ...prev, customerFeeLoading: true }));

      const { id, prices } = selectedStore;
      const body = { storeid: id, prices };

      await storeApi.upsertTierPrice(body);

      const formData = new FormData();

      for (const key of Object.keys(selectedStore)) {
        formData.append(key, selectedStore[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      await storeApi.upsert(formData, config);

      checkUpdate(true);
    } catch (error) {
      checkUpdate(false);
    } finally {
      getStoreList();
      setLoading((prev) => ({ ...prev, customerFeeLoading: false }));
      setDialogs((prev) => ({ ...prev, customerFeeUpsert: false }));
    }
  };

  const onClickAddCustomerFee = () => {
    setSelectedStore((prev) => ({
      ...prev,
      prices: [
        ...prev.prices,
        {
          startprice: 0,
          endprice: 0,
          fee: 0,
        },
      ],
    }));
  };

  const onClickDeletePrice = (index) => {
    setSelectedStore((prev) => ({
      ...prev,
      prices: prev.prices.filter((price, i) => i !== index),
    }));
  };

  useEffect(() => {
    getStoreList();
  }, []);

  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-6 sm:gap-x-8">
      <div className=" bg-white dark:bg-slate-800">
        <div className="">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                Credit Card Fee
              </h1>
            </div>
          </div>
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Percentage
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Per Transaction
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-6 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      <tr key={companyDetail.id}>
                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                          Fee
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          %{companyDetail.creditcardfeerate.toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          ${companyDetail.creditcardfeeper.toFixed(2)}
                        </td>
                        <td className="relative flex justify-between whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                          <div className="flex w-1/2 text-right justify-center  pr-1 items-center">
                            <PencilSquareIcon
                              onClick={() => onClickEditCreditCardFee()}
                              className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className=" bg-white dark:bg-slate-800">
        <div className="">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                Subscription
              </h1>
            </div>
          </div>
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-2 flow-root">
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Order Fee
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Monthly Fee
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Order Fee Rate
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-6 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      <tr key={companyDetail.id}>
                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200">
                          {companyDetail.subscription?.name}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          ${companyDetail.subscription?.orderfee.toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          ${companyDetail.subscription?.monthlyfee.toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          %{companyDetail.subscription?.orderfeerate.toFixed(2)}
                        </td>
                        <td className="relative flex justify-between whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                          <div className="flex w-1/2 text-right justify-center  pr-1 items-center">
                            <PencilSquareIcon
                              onClick={() => onClickEdit()}
                              className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!companyDetail.subscriptionid && (
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                Trial End
              </h1>
            </div>
          </div>
          <div className="mt-2">
            <DatePicker
              value={dayjs(companyDetail.trialend, 'YYYY-MM-DD HH:mm')}
              format={'MM/DD/YYYY'}
              onChange={(e) => {
                onFormSubmit(dayjs(e).endOf('day').format('YYYY-MM-DD HH:mm'));
              }}
              style={{ width: '100%' }}
              size="large"
              placeholder="Select trial end date."
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-2">
        <div>
          <div className="pb-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-lg font-semibold">Service Charge</h1>
                <p className="text-sm text-gray-500">
                  Add service charge for your orders
                </p>
              </div>
            </div>
          </div>
          {storeList.map((store, index) => (
            <div key={index} className="grid grid-cols-5 gap-4">
              <div className="col-span-2">
                <h1 className="text-sm font-bold">{store.name}</h1>
                {store.customerfeeactive && (
                  <>
                    {store.customerfeeisrate ? (
                      <>
                        <span className="text-xs text-gray-500">
                          Fixed Rate: {store.customerfee}%
                        </span>
                      </>
                    ) : (
                      <div className="grid text-xs text-gray-500">
                        <span className="pb-1">
                          Fixed Price: ${store.customerfee}{' '}
                        </span>
                        {store.prices
                          ?.sort((a, b) => a.startprice - b.startprice)

                          .map((price, index) => (
                            <span className="pl-4" key={index}>
                              [${price.startprice} - ${price.endprice}] $
                              {price.fee}
                            </span>
                          ))}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex gap-3">
                <PencilSquareIcon
                  onClick={() => onClickOpenCustomerFeeUpsertModal(store)}
                  className="text-blue-500 w-5 h-5 hover:text-blue-900 cursor-pointer"
                />
                <Switch
                  checked={store.customerfeeactive}
                  disabled={loading.serviceCharge}
                  onChange={(e) => {
                    onChangeServiceCharge(e, 'customerfeeactive', index);
                  }}
                  className={classNames(
                    store.customerfeeactive ? 'bg-blue-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      store.customerfeeactive
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="pb-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-lg font-semibold">Item Fee</h1>
                <p className="text-sm text-gray-500">
                  Add item fee for your orders
                </p>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="price"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Item Fee
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <CurrencyInput
                id="input-price"
                name="itemfee"
                placeholder="0.00"
                className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                prefix="$"
                value={form.itemfee}
                decimalSeparator="."
                groupSeparator=","
                decimalScale={2}
                step={1}
                defaultValue={0}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  onChangeItemFee(value, 'itemfee')
                }
                onBlur={(e) => onBlurSaveItemFee(e.target.value, 'itemfee')}
              />
            </div>
            <div className="h-2 mt-1  text-xs"></div>
          </div>
        </div>
      </div>

      <Transition.Root show={dialogs.creditCardUpsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="creditcardfeerate"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Percentage
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">%</span>
                      </div>
                      <input
                        value={form.creditcardfeerate}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'creditcardfeerate')
                        }
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        type="number"
                        name="creditcardfeerate"
                        id="creditcardfeerate"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="monthlyfee"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Per Transaction
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        value={form.creditcardfeeper}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'creditcardfeeper')
                        }
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        type="number"
                        name="creditcardfeeper"
                        id="creditcardfeeper"
                      />
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.creditCardLoading}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsertCreditCard()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.creditCardLoading}
                      loading={loading.creditCardLoading}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      Edit Credit Card Fees
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={dialogs.customerFeeUpsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="border-b pb-1">
                    <h1 className="text-lg font-semibold ">Customer Fee</h1>
                    <span className="text-sm text-gray-500 mb-3">
                      Add customer fee for your orders
                    </span>
                  </div>
                  <div className="flex">
                    <div className="grid items-center">
                      <div className="">
                        <label
                          htmlFor="name"
                          className="block text-sm font-bold text-gray-700 dark:text-slate-400 pb-1"
                        >
                          Fee Type
                        </label>
                      </div>
                      <Radio.Group
                        className="ml-0 pb-2"
                        disabled={!selectedStore.customerfeeactive}
                        onChange={(e) =>
                          setSelectedStore((prev) => ({
                            ...prev,
                            customerfeeisrate: e.target.value,
                          }))
                        }
                        value={selectedStore.customerfeeisrate}
                      >
                        <Radio value={true}>%</Radio>
                        <Radio value={false}>$</Radio>
                      </Radio.Group>
                    </div>

                    <div>
                      <label
                        htmlFor="creditcardfeerate"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        {selectedStore.customerfeeisrate
                          ? 'Fix Rate'
                          : 'Fix Price'}
                      </label>
                      <div className="relative  rounded-md shadow-sm">
                        <div className=" pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">
                            {selectedStore.customerfeeisrate ? '%' : '$'}
                          </span>
                        </div>
                        <input
                          disabled={!selectedStore.customerfeeactive}
                          value={selectedStore.customerfee}
                          onChange={(e) =>
                            setSelectedStore((prev) => ({
                              ...prev,
                              customerfee: e.target.value,
                            }))
                          }
                          type="number"
                          name="chargeValue"
                          id="chargeValue"
                          className=" block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  {!selectedStore.customerfeeisrate && (
                    <div className="pt-3">
                      {selectedStore.prices?.map((price, index) => (
                        <div className="flex gap-3 pt-5">
                          <div key={index}>
                            <label
                              htmlFor="creditcardfeerate"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Min Price
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">
                                  $
                                </span>
                              </div>
                              <input
                                value={selectedStore.prices[index].startprice}
                                onChange={(e) =>
                                  onFormCustomerFeeChange(
                                    e.target.value,
                                    index,
                                    'startprice'
                                  )
                                }
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                type="number"
                                name="startprice"
                                id="startprice"
                              />
                            </div>
                          </div>
                          <div className="self-center mt-4">
                            <span>-</span>
                          </div>
                          <div>
                            <label
                              htmlFor="creditcardfeerate"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Max Price
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">
                                  $
                                </span>
                              </div>
                              <input
                                value={selectedStore.prices[index].endprice}
                                onChange={(e) =>
                                  onFormCustomerFeeChange(
                                    e.target.value,
                                    index,
                                    'endprice'
                                  )
                                }
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                type="number"
                                name="endprice"
                                id="endprice"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="fee"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Fee
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">
                                  $
                                </span>
                              </div>
                              <input
                                value={selectedStore.prices[index].fee}
                                onChange={(e) =>
                                  onFormCustomerFeeChange(
                                    e.target.value,
                                    index,
                                    'fee'
                                  )
                                }
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500  dark:focus:border-sky-400 dark:focus:ring-sky-400 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:placeholder:text-white dark:text-slate-200"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                type="number"
                                name="fee"
                                id="fee"
                              />
                            </div>
                          </div>
                          <div className="self-center">
                            <MinusCircleIcon
                              onClick={() => onClickDeletePrice(index)}
                              className="text-red-500 w-5 h-5 mt-5 hover:text-red-900"
                            />
                          </div>
                        </div>
                      ))}
                      <div>
                        <Button
                          type="ghost"
                          disabled={loading.customerFeeLoading}
                          className={classNames(
                            'mt-5 inline-flex justify-center rounded-md border border-gray-300 bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                          )}
                          onClick={onClickAddCustomerFee}
                        >
                          + Add Fee Condition
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.customerFeeLoading}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsertCustomerFee()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.customerFeeLoading}
                      loading={loading.customerFeeLoading}
                      onClick={() => onSubmitCustomerFee()}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                    >
                      Edit Customer Fee
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <SubscriptionUpsertModal
        show={dialogs.subscriptionUpsert}
        loading={loading.upsertLoading}
        companyDetail={companyDetail}
        errors={'errors'}
        onClickDeleteOptionGroup={'onClickDeleteOptionGroup'}
        onCloseUpsert={onCloseUpsert}
        updateCompany={updateCompany}
        onClickCreateOptionGroup={'onClickCreateOptionGroup'}
        onClickSaveOptionGroups={'onClickSaveOptionGroups'}
      />
    </div>
  );
};

export default Fees;
