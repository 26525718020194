import moment from 'moment';
import React from 'react';
import { Button } from 'antd';
import {
  CheckIcon,
  ReceiptRefundIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { ClockIcon } from '@heroicons/react/20/solid';

const PaymentLogs = ({ payments, onClickOpenStripeNewTab }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const stripePaymentStatusses = [
    {
      id: 1,
      name: 'Succeeded',
      status: 'succeeded',
      icon: <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />,
      iconBackground: 'bg-green-500',
    },
    {
      id: 2,
      name: 'Requires Capture',
      status: 'requires_capture',
      icon: <ClockIcon className="h-5 w-5 text-white" aria-hidden="true" />,
      iconBackground: 'bg-yellow-500',
    },
    {
      id: 3,
      name: 'Refund',
      status: 'Refund',
      icon: (
        <ReceiptRefundIcon className="h-5 w-5 text-white" aria-hidden="true" />
      ),
      iconBackground: 'bg-red-500',
    },
  ];

  const formatCurrency = (number) => {
    const formattedNumber = (number / 100).toFixed(2);
    return `$${formattedNumber}`;
  };

  return (
    <div className="flow-root">
      <div className="py-5">
        <Button
          type="button"
          className="inline-flex  self-center h-10 items-center gap-x-1.5 rounded-md border-gray-300 bg-white dark:bg-sky-400  py-1.5 px-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:mb-0 sm:mt-0 mb-3 sm:text-sm"
          onClick={() => onClickOpenStripeNewTab()}
        >
          <LinkIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Stripe Link
        </Button>
      </div>
      <ul className="-mb-8">
        {payments &&
          payments
            .sort((a, b) => moment(a.createdate).diff(moment(b.createdate)))
            ?.map((payment, i) => {
              const stripePaymentStatus = stripePaymentStatusses.find(
                (x) => x.status === payment.status
              );

              return (
                <li key={i}>
                  <div className="relative pb-8">
                    {i !== payments?.length - 1 ? (
                      <span
                        className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            stripePaymentStatus.iconBackground,
                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                          )}
                        >
                          {stripePaymentStatus.icon}
                        </span>
                      </div>
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 dark:ring-slate-500">
                          <div className="flex sm:flex-row flex-col justify-between gap-x-4">
                            <div className="py-0.5 text-xs leading-5 text-gray-500 dark:text-slate-400">
                              <h1 className="text-lg font-medium text-black dark:text-slate-200">
                                Status: {stripePaymentStatus.name}
                              </h1>
                              <br />
                              <p className="text-sm leading-6 text-gray-500 dark:text-slate-400">
                                Payment Id: {payment.id}
                                <br />
                                Amount Received:{' '}
                                {formatCurrency(payment.amountreceived)}
                                <br />
                              </p>
                            </div>
                            <time
                              dateTime="2023-01-23T11:24"
                              className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-slate-400 text-right"
                            >
                              {moment
                                .utc(payment.createdate)
                                .local()
                                .format('MM/DD/YYYY hh:mm A')}
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
      </ul>
    </div>
  );
};

export default PaymentLogs;
