import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  Fragment,
  useCallback,
} from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { Pagination, Spin, Select, Button, DatePicker } from 'antd';
import {
  DocumentChartBarIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import WeekSlider from '../../components/week-slider/week-slider';
import NoTransactions from '../../assets/no-transactions.svg';
import companyApi from '../../services/companyApi';
import financeApi from '../../services/financeApi';
import orderApi from '../../services/orderApi';
import storeApi from '../../services/storeApi';
import Notification from '../../components/notification/notification';
import { formatCurrency } from '../../utils/baseUtils';
import { useNavigate, Link } from 'react-router-dom';
import { getSubString } from '../../utils/getSubString';
import { setStartAndEndDate } from '../../utils/setStartAndEndDate';

dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const { RangePicker } = DatePicker;

dayjs.extend(utc);
dayjs.extend(timezone);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Finance = () => {
  let navigate = useNavigate();

  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [dialogs, setDialogs] = useState({
    csv: false,
  });
  const [csvForm, setCsvForm] = useState({
    startdate: '',
    enddate: '',
    storeid: null,
  });
  const cancelButtonRef = useRef(null);
  const roles = useSelector((state) => state.authReducer.roles);
  const [companyList, setCompanyList] = useState([]);
  const [financeTypeList, setFinanceTypeList] = useState([]);
  const [balance, setBalance] = useState(0);
  const [storeList, setStoreList] = useState([]);
  const [search, setSearch] = useState({
    storeid: null,
    startdate: dayjs().startOf('week'),
    enddate: dayjs().endOf('week'),
  });
  const [loading, setLoading] = useState({
    listLoading: false, // true
    storeListLoading: true,
    thisWeekOrdersLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    csvLoading: false,
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [financeList, setFinanceList] = useState([]);
  const [thisWeekOrders, setThisWeekOrders] = useState([]);
  const [thisWeekStats, setThisWeekStats] = useState([]);

  useLayoutEffect(() => {
    if (roles.includes(100)) {
      const isIndeterminate =
        selectedCompany.length > 0 &&
        selectedCompany.length < companyList.length;
      setChecked(
        selectedCompany.length === companyList.length && companyList.length > 0
      );
      setIndeterminate(isIndeterminate);
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedCompany]);

  function toggleAll() {
    setSelectedCompany(checked || indeterminate ? [] : companyList);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const getBalance = async () => {
    try {
      const list = await financeApi.getBalanceById(globalCompany.id);
      setBalance(list.data.data);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getCompanyList = async () => {
    try {
      const query = `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}&hasbalance=true`;
      const list = await companyApi.list(query);
      if (list.data.data.length > 0) {
        setCompanyList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      } else {
        setCompanyList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const getFinanceList = async () => {
    try {
      const query = !roles.includes(100)
        ? `?companyid=${globalCompany.id}&pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`
        : `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`;

      const list = await financeApi.list(query);
      if (list.data.data.length > 0) {
        setFinanceList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      } else {
        setFinanceList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const getFinanceTypeList = async () => {
    try {
      setLoading((prev) => ({ ...prev, listLoading: true }));
      const list = await financeApi.getTypeList();
      setFinanceTypeList(list.data.data);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getStoreList = async () => {
    try {
      const query = globalCompany.id ? `companyid=${globalCompany.id}` : '';
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      } else {
        setStoreList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
      setStoreList([]);
    }
  };

  const onCloseUpsert = () => {
    setDialogs((prev) => ({ ...prev, csv: false }));
  };

  const onClickDownloadCSVModal = () => {
    setDialogs((prev) => ({ ...prev, csv: true }));
  };

  const onClickDownloadCSV = async () => {
    try {
      setLoading((prev) => ({ ...prev, csvLoading: true }));
      const query = `?startdate=${csvForm.startdate}&enddate=${csvForm.enddate}&storeid=${csvForm.storeid}`;
      const csvUrl = await orderApi.exportCsv(query);
      window.open(csvUrl.data, '_blank');

      onCloseUpsert();
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, csvLoading: false }));
    }
  };

  const onChangeCsvForm = (dateString) => {
    setCsvForm((prev) => ({
      ...prev,
      startdate: dateString[0],
      enddate: dateString[1],
    }));
  };

  const getWeekRangeOrders = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, thisWeekOrdersLoading: true }));

      const query = {
        companyid: globalCompany.id,
        paymentstatus: 20,
        startdate: setStartAndEndDate(search.startdate, 'startdate', '0'),
        enddate: setStartAndEndDate(search.enddate, 'enddate', '0'),
        storeid: search.storeid,
        pageSize: 1000,
      };

      const list = await orderApi.list(query);
      if (list.data.data.length > 0) {
        setThisWeekOrders(list.data.data);
      } else {
        setThisWeekOrders([]);
      }

      const totalStats = calculateThisWeekOrdersTotal(list.data.data);

      setThisWeekStats(totalStats);
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, thisWeekOrdersLoading: false }));
    }
  }, [globalCompany.id, search]);

  const calculateThisWeekOrdersTotal = (orderList) => {
    const totalStats = [
      { value: 0, name: 'Total Order' },
      { value: 0, name: 'Total Tax' },
      { value: 0, name: 'Total Tip' },
      { value: 0, name: 'Total Sales' },
    ];

    const totals = orderList.reduce((acc, order) => {
      const updatedTotals = [...acc];

      updatedTotals[0].value += 1;
      updatedTotals[1].value += order.tax;
      updatedTotals[2].value += order.tip;
      updatedTotals[3].value +=
        order.subtotal +
        order.tip +
        order.tax -
        ((order.stripefee > order.cardfee ? order.stripefee : order.cardfee) +
          order.discount +
          order.thiswayfee +
          order.refund);

      return updatedTotals;
    }, totalStats);

    return totals;
  };

  const getLast4AccountNumber = (company) => {
    const defaultBankAccount = company.bankaccounts.find(
      (x) => x.isdefault && !x.isdeleted
    );

    return defaultBankAccount
      ? `····  ${getSubString(defaultBankAccount?.accountnumber, 4)}`
      : '-';
  };

  const handleWeekChange = (startOfWeek, endOfWeek) => {
    setSearch((prev) => ({
      ...prev,
      startdate: startOfWeek,
      enddate: endOfWeek,
    }));
  };

  useEffect(() => {
    getFinanceTypeList();
    if (roles.includes(100)) {
      getCompanyList();
    } else {
      getStoreList();
      getBalance();
      getFinanceList();
    }
  }, [globalCompany.id, pagination.pageNumber, pagination.pageSize]);

  useEffect(() => {
    getWeekRangeOrders();
  }, [getWeekRangeOrders]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Finance
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              View and keep track of all your company financial transactions.
            </p>
          </div>
          {roles.includes(100) && (
            <>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link to="account-activity">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
                  >
                    Account Activities
                  </button>
                </Link>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
                >
                  Pay
                </button>
              </div>
            </>
          )}
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

        {loading.listLoading ? (
          <div className="w-full flex justify-center py-4">
            <Spin />
          </div>
        ) : (
          <div className="mt-8 flex flex-col">
            {roles.includes(100) ? (
              <div className="mt-8 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="relative">
                      <table className="min-w-full table-fixed divide-y divide-gray-300 dark:divide-slate-500">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="relative px-7 sm:w-12 sm:px-6"
                            >
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Company Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Owner Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Owner Phone
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Default Bank Account
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                            >
                              Balance
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-slate-500 bg-white dark:bg-slate-800">
                          {companyList
                            .filter(
                              (company) =>
                                company.balance <= -0.1 ||
                                company.balance >= 0.1
                            )
                            .map((company, companyIdx) => (
                              <tr
                                key={companyIdx}
                                className={
                                  selectedCompany.includes(company)
                                    ? 'bg-gray-50 dark:bg-slate-700'
                                    : undefined
                                }
                              >
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedCompany.includes(company) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                                  )}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                    value={company.id}
                                    checked={selectedCompany.includes(company)}
                                    onChange={(e) =>
                                      setSelectedCompany(
                                        e.target.checked
                                          ? [...selectedCompany, company]
                                          : selectedCompany.filter(
                                              (p) => p !== company
                                            )
                                      )
                                    }
                                  />
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                                  <div className="flex items-center">
                                    <div className="h-10 w-10 flex-shrink-0">
                                      {company.logo ? (
                                        <img
                                          alt="logo"
                                          className="w-8 h-8 rounded-full"
                                          src={company.logo}
                                        />
                                      ) : (
                                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                          <span className="text-xs font-medium leading-none text-white">
                                            {company.name
                                              .split(' ')
                                              .map((x) => x[0])}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                    <div className="ml-4">
                                      <div className="font-medium text-gray-900 dark:text-slate-200">
                                        <Link
                                          to={`/dashboard/companies/${company.id}`}
                                          className="hover:text-blue-600"
                                        >
                                          {company.name}
                                        </Link>
                                      </div>
                                      <div className="text-gray-500">
                                        {company.subscription?.name}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-slate-200">
                                  {company.ownername}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-slate-200">
                                  {company.ownerphone}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-slate-200">
                                  {getLast4AccountNumber(company)}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-slate-200">
                                  <span
                                    className={classNames(
                                      company?.balance > 0
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-red-100 text-red-800',
                                      'items-center rounded-full px-3 py-0.5 text-lg font-medium'
                                    )}
                                  >
                                    {company?.balance.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="pb-10">
                  <dl className=" bg-white dark:bg-slate-800 mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="border-t shadow-md flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white dark:bg-slate-800 px-4 py-10 sm:px-6 xl:px-8 dark:border-slate-600">
                      <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-slate-400">
                        Current Balance
                      </dt>
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-slate-200">
                        {formatCurrency(balance)}
                      </dd>
                    </div>
                    <div></div>
                  </dl>
                </div>

                <div className="">
                  <div className="sm:flex justify-between border-b border-gray-200 dark:border-slate-700  bg-white dark:bg-slate-800  py-5 ">
                    <div>
                      <Select
                        allowClear
                        size="large"
                        className="sm:min-w-[13rem] w-full self-center "
                        placeholder="Stores"
                        onChange={(e) => {
                          setSearch((prev) => ({ ...prev, storeid: e }));
                        }}
                        value={search.storeid === null ? '' : search.storeid}
                      >
                        <Select.Option value={''}>All Stores</Select.Option>
                        {storeList.map((item, i) => (
                          <Select.Option key={i} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="pt-6 sm:pt-0 flex flex-row-reverse sm:flex-row gap-x-2">
                      <div className="text-end">
                        <Button
                          type="ghost"
                          className={classNames(
                            'mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                          )}
                          onClick={onClickDownloadCSVModal}
                        >
                          <ArrowDownTrayIcon
                            className="h-5 w-5 mr-1"
                            aria-hidden="true"
                          />
                          Download
                        </Button>
                      </div>
                      <div className="self-center">
                        <Button
                          type="ghost"
                          className={classNames(
                            'inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0  sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                          )}
                          onClick={() =>
                            navigate(
                              `${
                                globalCompany.id
                              }/orders?thisWeek=true&storeid=${
                                search.storeid || ''
                              }&startdate=${setStartAndEndDate(
                                search.startdate,
                                'startdate',
                                '0'
                              )}&enddate=${setStartAndEndDate(
                                search.enddate,
                                'enddate',
                                '0'
                              )}`
                            )
                          }
                        >
                          <DocumentChartBarIcon
                            className="h-5 w-5 mr-1"
                            aria-hidden="true"
                          />
                          Summary
                        </Button>
                      </div>
                      <WeekSlider onWeekChange={handleWeekChange} />
                    </div>
                  </div>
                  {loading.thisWeekOrdersLoading ? (
                    <div className="w-full flex justify-center py-4">
                      <Spin />
                    </div>
                  ) : (
                    <dl className="mx-auto grid grid-cols-2 gap-px bg-gray-900/5 dark:bg-slate-800 sm:grid-cols-2 lg:grid-cols-4 dark:divide-x-2 dark:divide-slate-600">
                      {thisWeekStats.map((stat) => (
                        <div
                          key={stat.name}
                          className="shadow-md flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white dark:bg-slate-800  px-4 py-10 sm:px-6 xl:px-8"
                        >
                          <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-slate-400">
                            {stat.name}
                          </dt>
                          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-slate-200">
                            {stat.name === 'Total Order'
                              ? stat.value
                              : stat.value.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  )}
                </div>
                <div className="border-b-2 border-gray-200 dark:border-slate-700  border-opacity-50" />
                <div className="pt-5 text-sm text-gray-500 dark:text-slate-400">
                  <p>
                    We automatically process payouts every <b>Monday</b>, which
                    may take up to <b>two business</b> days to arrive. Please
                    note that there may be delays on public holidays.
                  </p>
                </div>
                <div className="pt-10">
                  <div className="sm:flex sm:items-end space-y-1 space-x-4">
                    <div className="sm:flex-auto ">
                      <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                        All Transactions
                      </h1>
                    </div>
                  </div>
                  <div className="mt-2 flow-root">
                    {financeList.length === 0 && (
                      <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5"></div>
                    )}
                    <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                      {financeList.length > 0 ? (
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-0"
                                >
                                  Type
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                                >
                                  Explanation
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                                >
                                  Create Date
                                </th>
                                <th
                                  scope="col"
                                  className="text-end py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                              {financeList.map((finance) => (
                                <tr key={finance.id}>
                                  <td
                                    data-tooltip-id="visibility"
                                    data-tooltip-content={finance.code}
                                    className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0 dark:text-slate-200"
                                  >
                                    <Tooltip id="visibility" />
                                    {finance?.financetype?.name}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                    {finance.details.length > 0 ? (
                                      <Link
                                        to={`${globalCompany.id}/orders?financeid=${finance.id}`}
                                        className="text-blue-600 dark:text-sky-400"
                                      >
                                        {`${moment(finance.startdate)
                                          .utcOffset(0)
                                          .format('MMMM DD')} - ${moment(
                                          finance.enddate
                                        )
                                          .utcOffset(0)
                                          .format('MMMM DD')} (${
                                          finance.store?.name
                                        })`}
                                      </Link>
                                    ) : (
                                      finance.description
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                                    {moment
                                      .utc(finance.createdate)
                                      .local()
                                      .format(
                                        moment().year() ===
                                          moment(finance.createdate).year()
                                          ? 'MMMM Do'
                                          : 'MMMM Do YYYY'
                                      )}
                                  </td>
                                  <td className="text-end whitespace-nowrap font-bold py-4 pl-6 pr-3 text-sm text-gray-900 sm:pl-0 dark:text-slate-200">
                                    {finance.amount.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="grid justify-center py-20">
                          <img
                            src={NoTransactions}
                            alt="no-data"
                            className="h-24 pb-2"
                          />
                          <p className=" text-slate-400 font-light text-center">
                            No Transactions
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(financeList.length > 0 || companyList.length > 0) && (
              <div className="w-full text-end pt-5">
                <Pagination
                  current={pagination.pageNumber}
                  total={pagination.totalRecords}
                  defaultPageSize={pagination.pageSize}
                  onChange={(page) =>
                    setPagination((prev) => ({
                      ...prev,
                      pageNumber: page,
                    }))
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Transition.Root show={dialogs.csv} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="image"
                      className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Stores
                    </label>
                    <Select
                      allowClear
                      size="large"
                      style={{
                        width: '100%',
                        minWidth: '13rem',
                      }}
                      placeholder="Stores"
                      onChange={(e) => {
                        setCsvForm((prev) => ({ ...prev, storeid: e }));
                      }}
                      value={csvForm.storeid}
                    >
                      {storeList.map((item, i) => (
                        <Select.Option key={i} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div className="col-span-12 w-full  ">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Date Range
                    </label>
                    <div className="mt-3 w-full">
                      <RangePicker
                        style={{ width: '100%' }}
                        size="large"
                        format="MM/DD/YYYY"
                        onChange={(e) => onChangeCsvForm(e)}
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-5 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.csvLoading}
                      className={classNames(
                        'sm:col-span-6 col-span-12',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading.csvLoading}
                      type="ghost"
                      className={classNames(
                        'sm:col-span-6 col-span-12',
                        'mr-3 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onClickDownloadCSV()}
                    >
                      <ArrowDownTrayIcon
                        className="h-5 w-5 mr-1"
                        aria-hidden="true"
                      />
                      Download CSV
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default Finance;
