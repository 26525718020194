import React from 'react';
import { Link } from 'react-router-dom';

const Account = ({ email, onChange, password, fullname, terms, errors }) => {
  const handleChange = (value, key) => {
    onChange(value, key);
  };

  return (
    <div
      style={{ rowGap: '0.5rem' }}
      className="absolute w-full mt-4 lg:mt-9 grid grid-cols-1 lg:gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div className="sm:col-span-2">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Email*
        </label>
        <div className="mt-1">
          <input
            onChange={(e) => handleChange(e.target.value, 'email')}
            value={email}
            id="email"
            name="email"
            type="email"
            autoComplete="off"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
          />
        </div>
        <div className="h-2 mt-1  text-xs">
          {errors.email && (
            <p className="text-red-700">Enter your correct email</p>
          )}
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="company"
          className="block text-sm font-medium text-gray-700 dark:text-slate-400"
        >
          Password* (8 characters minimum)
        </label>
        <div className="mt-1">
          <input
            onChange={(e) => handleChange(e.target.value, 'password')}
            value={password}
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
          />
        </div>
        <div className="h-2 mt-1  text-xs">
          {errors.password && (
            <p className="text-red-700">Enter your password</p>
          )}
          {errors.passwordlength && (
            <p className="text-red-700">Please enter at least 8 characters</p>
          )}
        </div>
      </div>
      <div className="pt-4 flex flex-row sm:col-span-2 justify-between ">
        <div
          className="relative flex items-start 
         dark:text-zinc-200"
        >
          <div className="flex h-5 items-center">
            <input
              id="terms"
              aria-describedby="terms-description"
              name="terms"
              onChange={() => handleChange(!terms, 'terms')}
              value={terms}
              checked={terms}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
          </div>
          <div className="ml-3 text-sm">
            <span className="dark:text-slate-500" id="terms-description">
              I agree to{' '}
              <a
                className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500 dark:hover:text-sky-400"
                href="https://www.thisway.co/terms"
                target="_blank"
              >
                Terms and Conditions
              </a>{' '}
              and acknowledge I have read the{' '}
              <a
                className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500 dark:hover:text-sky-400"
                href="https://www.thisway.co/privacy"
                target="_blank"
              >
                Privacy Policy
              </a>
              .
            </span>
            <div className="h-2 mt-1  text-xs">
              {errors.terms && (
                <p className="text-red-700">
                  Please accept the terms and conditions to proceed.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex flex-row justify-self-end sm:col-span-2 justify-between ">
        <div className="relative flex sm:col-span-2 dark:text-zinc-200 text-end">
          <div className="ml-3 text-sm">
            <Link
              to="/"
              className="font-medium text-blue-600 dark:text-zinc-200 hover:text-blue-500 dark:hover:text-sky-400"
            >
              Do you have an account ? Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
