import React, { useState, useEffect } from 'react';
import { Spin, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import orderApi from '../../../services/orderApi';
import NoTransactions from '../../../assets/no-transactions.svg';
import { OrderStatus } from '../../../data/enums';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const OrdersSummary = () => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: 0,
  });
  const [loading, setLoading] = useState({
    listLoading: false,
  });

  const getOrderList = async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));

    try {
      const query = {
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize,
        deleted: false,
        paymentstatus: 20,
      };

      const list = await orderApi.list(query);

      setOrderList(list.data.data);
      setPagination({
        pageNumber: list.data.pageNumber,
        pageSize: list.data.pageSize,
        totalRecords: list.data.totalRecords,
      });
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const calculateNetPayout = (order) => {
    const {
      subtotal,
      tip,
      tax,
      stripefee,
      cardfee,
      discount,
      thiswayfee,
      refund,
      total,
    } = order;

    const netPayout =
      total < 0
        ? `(${(
            subtotal +
            tip +
            tax -
            Math.max(stripefee, cardfee) -
            discount -
            thiswayfee -
            refund
          )
            .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            .replace('-', '')})`
        : (
            subtotal +
            tip +
            tax -
            Math.max(stripefee, cardfee) -
            discount -
            thiswayfee -
            refund
          ).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });

    return netPayout;
  };

  const onClickRow = (id) => {
    navigate(`/dashboard/orders/${id}`);
  };

  useEffect(() => {
    getOrderList();
  }, [pagination.pageNumber, pagination.pageSize]);

  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Orders Summary
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                You can see the summary of orders here.
              </p>
            </div>
          </div>

          <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                {orderList.length > 0 ? (
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                          >
                            Order#
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                          >
                            Company
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Subtotal
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Tax
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Tip
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Card Fees
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Discount
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Fees
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Refund
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Service Charge
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Net Payout
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Stripe Net
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                          >
                            Total Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                        {orderList.map((order, orderIdx) => (
                          <tr
                            className="cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-700"
                            key={orderIdx}
                            onClick={() => {
                              onClickRow(order.id);
                            }}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                              {order.orderid}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                              {dayjs(order.createdate).format(
                                'MM/DD/YYYY hh:mm A'
                              )}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                              {order.store?.company?.name}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                              {OrderStatus[order.orderstatus]}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {order.subtotal.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {order.tax.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {order.tip.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`(${(order.stripefee > order.cardfee
                                ? order.stripefee
                                : order.cardfee
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })})`}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`(${order.discount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })})`}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`(${order.thiswayfee.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })})`}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`(${order.refund.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })})`}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`(${order.fee.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })})`}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {calculateNetPayout(order)}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`${(
                                order.total -
                                (order.stripefee + order.refund)
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}`}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                              {`${order.total.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="w-full justify-between flex mt-4">
                      <div>
                        <p className="text-sm text-gray-700 dark:text-slate-200">
                          Showing{' '}
                          <span className="font-medium">
                            {pagination.pageNumber}
                          </span>{' '}
                          to{' '}
                          <span className="font-medium">
                            {pagination.pageSize}
                          </span>{' '}
                          of{' '}
                          <span className="font-medium">
                            {pagination.totalRecords}
                          </span>{' '}
                          results
                        </p>
                      </div>
                      <div className="w-full text-end mt-4">
                        <Pagination
                          current={pagination.pageNumber}
                          total={pagination.totalRecords}
                          defaultPageSize={pagination.pageSize}
                          onChange={(page) =>
                            setPagination((prev) => ({
                              ...prev,
                              pageNumber: page,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="grid justify-center py-20">
                    <img
                      src={NoTransactions}
                      className="h-24 pb-2"
                      alt="no-data"
                    />
                    <p className=" text-slate-400 font-light text-center">
                      No Orders Summary
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersSummary;
