import api from './api';

const resource = 'Customer/';

export const list = async (query) => {
  return await api.get(resource + 'List' + query);
};

export const getById = async (query) => {
  return await api.get(resource + 'Get' + query);
};

const customerApi = { list, getById };

export default customerApi;
