import React from "react";
import ThemeChanger from "../../components/theme-changer/theme-changer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Preferences = () => {
  return (
    <div>
      <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
        <div className="mx-auto max-w-5xl">
          <div className="sm:flex sm:items-center border-b-2 border-gray-200 dark:border-slate-700 pb-6">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
                Preferences
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                Take control of your dashboard settings and customize your
                experience.
              </p>
            </div>
          </div>
          <div className="sm:flex sm:items-center py-6">
            <ThemeChanger switchMode={true} onChange={() => {}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
