import { Select } from 'antd';
import React, { useState } from 'react';
import { timezones } from '../../../../data/timezones';
import storeApi from '../../../../services/storeApi';
const TimezoneTab = ({ storeDetail, checkUpdate }) => {
  const [loading, setLoading] = useState(false);

  const onChangeTimezone = async (val) => {
    const form = {
      ...storeDetail,
      timezone: val,
    };
    try {
      setLoading(true);
      const formData = new FormData();

      for (const key of Object.keys(form)) {
        formData.append(key, form[key]);
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);
      if (res.status === 200) {
        checkUpdate(true);
      }
    } catch (error) {
      checkUpdate(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="absolute w-full grid grid-cols-1 lg:gap-y-2 sm:gap-x-8 p-4">
      <div className="col-span-4 sm:col-span-3">
        <h1 className="text-lg font-semibold">Timezone</h1>
      </div>
      <Select
        disabled={loading}
        className="w-full sm:w-1/2"
        size="large"
        placeholder="Please select"
        defaultValue={storeDetail.timezone}
        onChange={(e) => onChangeTimezone(e)}
        options={timezones}
      />
    </div>
  );
};

export default TimezoneTab;
