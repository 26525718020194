import React from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import Login from './views/login/login';
import Register from './views/register/register';
import { useDispatch, useSelector } from 'react-redux';
import tokenService from './utils/tokenService';
import AuthProvider from './components/authProvider';
import Layout from './components/layout/layout';
import { setIsAuthenticated } from './store/slices/authSlice';
import Orders from './views/orders/orders';
import Customers from './views/customers/customers';
import CustomerOrders from './views/customers/orders/orders';
import Stores from './views/stores/stores';
import Finance from './views/finance/finance';
import Report from './views/report/report';
import Permission from './views/settings/permission/permission';
import Company from './views/company/company';
import Menus from './views/menus/menus';
import Coupons from './views/coupons/coupons';
import DashboardContent from './views/dashboard/dashboard';
import StoreDetail from './views/stores/store-detail/store-detail';
import CompanyDetail from './views/company/company-detail/company-detail';
import Profile from './views/profile/profile';
import Users from './views/users/users';
import Cities from './views/cities/cities';
import StoreSettings from './views/stores/store-settings/store-settings';
import OrderDetail from './views/orders/order-detail/order-detail';
import LiveOrders from './views/orders/live-orders/live-orders';
import OrderList from './views/company/company-detail/order-list';
import OrdersSummary from './views/orders/orders-summary/orders-summary';
import Subscriptions from './views/subscriptions/subscriptions';
import Templates from './views/templates/templates';
import FinanceTypes from './views/finance-types/finance-types';
import BankAccounts from './views/bank-accounts/bank-accounts';
import Preferences from './views/preferences/preferences';
import Subscription from './views/subscription/subscription';
import Receipt from './views/receipt/receipt';
import ForgotPassword from './views/forgot-password/forgot-password';
import NewPassword from './views/forgot-password/new-password/new-password';
import TermsAndConditions from './views/terms-and-conditions/terms-and-conditions';
import AccountActivity from './views/finance/account-activity/account-activity';
import CompanySales from './views/report/company-sales/company-sales';
import Kiosks from './views/kiosks/kiosks';
import ReportStore from './views/report-store/report-store';

const Router = () => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.authReducer.roles);
  const routes = [
    {
      path: '',
      element: <DashboardContent />,
      index: true,
      roles: [100, 150, 200],
    },
    {
      path: 'companies',
      element: <Outlet />,
      roles: [100, 150, 200],
      children: [
        {
          index: true,
          element: <Company />,
          roles: [100, 150, 200],
        },
        {
          path: '/dashboard/companies/:id',
          element: <CompanyDetail />,
          roles: [100, 150, 200],
        },
        {
          path: '/dashboard/companies/:id/orders',
          element: <OrderList />,
          roles: [100, 150, 200, 250],
        },
      ],
    },
    {
      path: 'orders',
      element: <Outlet />,
      roles: [100, 150, 200, 250],
      children: [
        {
          index: true,
          element: <Orders />,
          roles: [100, 150, 200, 250],
        },
        {
          path: '/dashboard/orders/:id',
          element: <OrderDetail />,
          roles: [100, 150, 200, 250],
        },
        {
          path: '/dashboard/orders/summary',
          element: <OrdersSummary />,
          roles: [100],
        },
        {
          path: '/dashboard/orders/:storeid/live',
          element: <LiveOrders />,
          roles: [100, 150, 200, 250],
        },
      ],
    },
    {
      path: 'customers',
      element: <Outlet />,
      roles: [100],
      children: [
        {
          index: true,
          element: <Customers />,
          roles: [100],
        },
        {
          path: '/dashboard/customers/:id/orders',
          element: <CustomerOrders />,
          roles: [100],
        },
      ],
    },
    {
      path: 'kiosks',
      element: <Outlet />,
      roles: [100],
      children: [
        {
          index: true,
          element: <Kiosks />,
          roles: [100],
        },
      ],
    },
    {
      path: 'menus',
      element: <Outlet />,
      roles: [100, 150, 200, 250, 300],
      children: [
        {
          index: true,
          element: <Menus />,
          roles: [100, 150, 200, 250, 300],
        },
      ],
    },
    {
      path: 'stores',
      element: <Outlet />,
      roles: [100, 150, 200],
      children: [
        {
          index: true,
          element: <Stores />,
          roles: [100, 150, 200],
        },
        {
          path: '/dashboard/stores/:id',
          element: <StoreDetail />,
          roles: [100, 150, 200],
        },
        {
          path: '/dashboard/stores/:id/settings',
          element: <StoreSettings />,
          roles: [100, 150, 200],
        },
      ],
    },
    {
      path: 'finance',
      element: <Outlet />,
      roles: [100, 150, 200],
      children: [
        {
          index: true,
          element: <Finance />,
          roles: [100, 150, 200],
        },
        {
          path: '/dashboard/finance/:id/orders',
          element: <OrderList />,
          roles: [100, 150, 200],
        },
        {
          path: '/dashboard/finance/account-activity',
          element: <AccountActivity />,
          roles: [100],
        },
      ],
    },
    {
      path: 'reports',
      element: <Outlet />,
      roles: [100],
      children: [
        {
          index: true,
          element: <Report />,
          roles: [100],
        },
        {
          path: '/dashboard/reports/company-sales',
          element: <CompanySales />,
          roles: [100],
        },
      ],
    },
    {
      path: 'reports',
      element: <Outlet />,
      roles: [100, 150, 200],
      children: [
        {
          index: true,
          element: <ReportStore />,
          roles: [100, 150, 200],
        },
      ],
    },
    {
      path: 'coupons',
      element: <Outlet />,
      roles: [100, 150, 200],
      children: [
        {
          index: true,
          element: <Coupons />,
          roles: [100, 150, 200],
        },
      ],
    },
    {
      path: 'settings',
      element: <Outlet />,
      roles: [100, 150, 200, 250, 300],
      children: [
        {
          index: true,
          path: 'profile',
          element: <Profile />,
          roles: [100, 150, 200, 250, 300],
        },
        {
          index: false,
          path: 'bank-accounts',
          element: <BankAccounts />,
          roles: [100, 150, 200],
        },
        {
          index: false,
          path: 'permissions',
          element: <Permission />,
          roles: [100],
        },
        {
          index: false,
          path: 'users',
          element: <Users />,
          roles: [100, 150, 200],
        },
        {
          index: false,
          path: 'cities',
          element: <Cities />,
          roles: [100],
        },
        {
          index: false,
          path: 'subscription',
          element: <Subscription />,
          roles: [100, 150, 200],
        },
        {
          index: false,
          path: 'subscriptions',
          element: <Subscriptions />,
          roles: [100],
        },
        {
          index: false,
          path: 'templates',
          element: <Templates />,
          roles: [100],
        },
        {
          index: false,
          path: 'finance-types',
          element: <FinanceTypes />,
          roles: [100],
        },
        {
          index: false,
          path: 'preferences',
          element: <Preferences />,
          roles: [100, 150, 200, 250, 300],
        },
        {
          index: false,
          path: 'terms-and-conditions',
          element: <TermsAndConditions />,
          roles: [100],
        },
      ],
    },
  ];

  const hasRoutePermisions = (roles) => {
    const filteredRoutes = routes
      .map((item) => {
        if (item.children) {
          const filteredChildren = item.children.filter((child) =>
            roles?.some((role) => child.roles.includes(role))
          );
          return filteredChildren.length
            ? { ...item, children: filteredChildren, hidden: false }
            : { ...item, hidden: true };
        } else {
          return roles?.some((role) => item.roles.includes(role))
            ? { ...item, hidden: false }
            : { ...item, hidden: true };
        }
      })
      .filter((item) => !item.hidden);
    return filteredRoutes;
  };

  const router = createBrowserRouter([
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/new-password',
      element: <NewPassword />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/receipt/:id',
      element: <Receipt />,
    },
    {
      path: '/dashboard',
      element: (
        <AuthProvider>
          <Layout />
        </AuthProvider>
      ),
      children: hasRoutePermisions(roles),
    },
  ]);

  if (tokenService.getLocalAccessToken()) {
    dispatch(setIsAuthenticated());
  }
  return <RouterProvider router={router} />;
};

export default Router;
