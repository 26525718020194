import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useParams, useSearchParams } from 'react-router-dom';
import Notification from '../../../components/notification/notification';
import WeekSlider from '../../../components/week-slider/week-slider';
import orderApi from '../../../services/orderApi';
import financeApi from '../../../services/financeApi';
import NoItems from '../../../assets/no-items.svg';
import { Pagination, Spin } from 'antd';
import { OrderStatus } from '../../../data/enums';
import NoStoreLogo from '../../../assets/no-store-logo.svg';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { setStartAndEndDate } from '../../../utils/setStartAndEndDate';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const OrderList = () => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [searchParams, setSearchParams] = useSearchParams();
  const [finance, setFinance] = useState([]);
  const [thisWeekDays, setThisWeekDays] = useState({
    startdate: '',
    enddate: '',
  });
  const [filteredOrderList, setFilteredOrderList] = useState([]);
  const [thisWeekOrders, setThisWeekOrders] = useState([]);
  const [totalStats, setTotalStats] = useState([]);
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [isColumnVisible, setIsColumnVisible] = useState({
    crvtax: false,
    itemfee: false,
  });

  const handleWeekChange = (startOfWeek, endOfWeek) => {
    setThisWeekDays({ startdate: startOfWeek, enddate: endOfWeek });

    const newSearchParams = new URLSearchParams(searchParams.toString());

    newSearchParams.set(
      'startdate',
      setStartAndEndDate(startOfWeek, 'startdate', '0')
    );
    newSearchParams.set(
      'enddate',
      setStartAndEndDate(endOfWeek, 'enddate', '0')
    );

    setSearchParams(newSearchParams);
  };

  const getFinance = useCallback(async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setFinance([]);
    setThisWeekOrders([]);
    try {
      const isThisWeek = searchParams.get('thisWeek');
      const storeid = searchParams.get('storeid');

      if (isThisWeek) {
        const startdate = searchParams.get('startdate');
        const enddate = searchParams.get('enddate');

        setThisWeekDays({ startdate, enddate });

        const query = {
          companyid: globalCompany.id,
          pageSize: 1000,
          paymentstatus: 20,
          storeid,
          startdate,
          enddate,
        };

        const list = await orderApi.list(query);
        if (list.data.data.length > 0) {
          setPagination({
            ...pagination,
            totalRecords: Math.ceil(list.data.data.length),
          });
          setThisWeekOrders(
            list.data.data.map((order) => {
              return {
                order,
                total: order.total,
              };
            })
          );
          const totalStats = calculateThisWeekOrdersTotal(list.data.data);
          setTotalStats(totalStats);
          setFilteredOrderList(
            list.data.data
              .map((order) => {
                return {
                  order,
                  total: order.total,
                };
              })
              .sort((a, b) => {
                return (
                  new Date(b.order.createdate) - new Date(a.order.createdate)
                );
              })
              .slice(0, pagination.pageSize)
          );

          setIsColumnVisible({
            crvtax: list.data.data.some((order) => order.crvtax > 0),
            itemfee: list.data.data.some((order) => order.itemfee > 0),
          });
        } else {
          setFilteredOrderList([]);
          setTotalStats([
            { value: 0, name: 'Total Order' },
            { value: 0, name: 'Total Tax' },
            { value: 0, name: 'Total Tip' },
            { value: 0, name: 'Total Discount' },
            { value: 0, name: 'Total Refund' },
            { value: 0, name: 'Total Net Payout' },
          ]);
        }
      } else {
        const query = `?id=${searchParams.get('financeid')}`;

        const list = await financeApi.getById(query);
        setPagination({
          ...pagination,
          totalRecords: Math.ceil(list.data.data.details.length),
        });
        setFinance(list.data.data);
        calculateTotalOrderPrices(list.data.data.details);
        setFilteredOrderList(
          list.data.data.details
            .sort((a, b) => {
              return (
                new Date(b.order.createdate) - new Date(a.order.createdate)
              );
            })
            .slice(0, pagination.pageSize)
        );
        setIsColumnVisible({
          crvtax: list.data.data.details.some((order) => order.crvtax > 0),
          itemfee: list.data.data.details.some((order) => order.itemfee > 0),
        });
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  }, [thisWeekDays.startdate]);

  const calculateThisWeekOrdersTotal = (orderList) => {
    const totalStats = [
      { value: 0, name: 'Total Order' },
      { value: 0, name: 'Total Tax' },
      { value: 0, name: 'Total Tip' },
      { value: 0, name: 'Total Discount' },
      { value: 0, name: 'Total Refund' },
      { value: 0, name: 'Total Net Payout' },
    ];

    const totals = orderList.reduce((acc, order) => {
      const updatedTotals = [...acc];

      updatedTotals[0].value += 1;
      updatedTotals[1].value += order.tax;
      updatedTotals[2].value += order.tip;
      updatedTotals[3].value += order.discount;
      updatedTotals[4].value += order.refund;
      updatedTotals[5].value +=
        order.subtotal +
        order.tip +
        order.crvtax +
        order.tax -
        order.itemfee -
        ((order.stripefee > order.cardfee ? order.stripefee : order.cardfee) +
          order.discount +
          order.thiswayfee +
          order.refund);

      return updatedTotals;
    }, totalStats);

    return totals;
  };

  const handleChangePage = (pageNumber, pageSize) => {
    setPagination((prev) => ({ ...prev, pageNumber, pageSize }));

    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const orders = searchParams.get('thisWeek')
      ? thisWeekOrders
      : finance.details;

    const filteredList = orders
      .sort((a, b) => {
        return new Date(b.order.createdate) - new Date(a.order.createdate);
      })
      .slice(startIndex, endIndex);

    setFilteredOrderList(filteredList);
    setIsColumnVisible({
      crvtax: orders.some((order) => order.crvtax > 0),
      itemfee: orders.some((order) => order.itemfee > 0),
    });
  };

  const calculateTotalOrderPrices = (orderList) => {
    const totalStats = [
      { value: 0, name: 'Total Order' },
      { value: 0, name: 'Total Tax' },
      { value: 0, name: 'Total Tip' },
      { value: 0, name: 'Total Discount' },
      { value: 0, name: 'Total Refund' },
      { value: 0, name: 'Total Net Payout' },
    ];

    const totals = orderList.reduce((acc, order) => {
      const updatedTotals = [...acc];

      updatedTotals[0].value += 1;
      updatedTotals[1].value += order.order.tax;
      updatedTotals[2].value += order.order.tip;
      updatedTotals[3].value += order.order.discount;
      updatedTotals[4].value += order.order.refund;
      updatedTotals[5].value += order.total;

      return updatedTotals;
    }, totalStats);

    setTotalStats(totals);

    return totals;
  };

  useEffect(() => {
    getFinance();
  }, [getFinance]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      <>
        <div>
          {searchParams.get('thisWeek') && (
            <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
              <div className="sm:pr-4">
                <dd className="mt-2 text-lg text-gray-500">
                  <img
                    className="inline-flex h-14 w-14 items-center justify-center rounded-full pr-2"
                    src={
                      filteredOrderList[0]?.order.store.storelogo || NoStoreLogo
                    }
                    alt=""
                  />
                  <span className="font-semibold text-gray-900 dark:text-slate-200">
                    {searchParams.get('storeid')
                      ? filteredOrderList[0]?.order?.store?.name
                      : 'All Stores'}
                  </span>
                  <br />
                </dd>
              </div>
              <div className="mt-2 sm:mt-0 sm:pl-4"></div>
              {loading.listLoading ? (
                <div className="w-full flex justify-center py-4">
                  <Spin />
                </div>
              ) : (
                <div className="my-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="inline font-semibold text-gray-500 dark:text-slate-400">
                    Statement{' '}
                    {moment(thisWeekDays.startdate)
                      .startOf('isoWeek')
                      .format('MMMM DD, YYYY')}{' '}
                    -{' '}
                    {moment(thisWeekDays.enddate)
                      .endOf('isoWeek')
                      .format('MMMM DD, YYYY')}{' '}
                  </dt>
                </div>
              )}
              {searchParams.get('thisWeek') && (
                <div className="my-6 border-t border-gray-900/5 pt-6 sm:pr-4 w-full flex justify-end">
                  <WeekSlider
                    onWeekChange={handleWeekChange}
                    initialDate={dayjs(searchParams.get('startdate'))}
                  />
                </div>
              )}
            </dl>
          )}
          {finance?.details?.length > 0 && (
            <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
              <div className="sm:pr-4">
                <dd className="mt-2 text-lg text-gray-500 dark:text-slate-400">
                  <img
                    className="flex my-2 h-14 w-14 items-center justify-center rounded-full"
                    src={finance.store.storelogo || NoStoreLogo}
                    alt=""
                  />
                  <span className="font-semibold text-gray-900 dark:text-slate-200">
                    {finance.store.name}
                  </span>
                  <br />
                  {finance.store.address || null}{' '}
                  {finance.store.apartment && finance.store.apartment !== 'null'
                    ? finance.store.apartment
                    : ''}
                  {'  '}
                  <br />
                  {finance.store.city}
                  {', '}
                  {finance.store.state}
                  {'  '}
                  {finance.store.postalcode}
                </dd>
              </div>
              <div className="mt-2 sm:mt-0 sm:pl-4"></div>
              <div className="my-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                <dt className="inline font-semibold text-gray-500 dark:text-slate-400">
                  Statement{' '}
                  {moment(finance.startdate)
                    .utcOffset(0)
                    .format('MMMM DD, YYYY')}{' '}
                  -{' '}
                  {moment(finance.enddate).utcOffset(0).format('MMMM DD, YYYY')}{' '}
                </dt>
              </div>
              <div className="text-end my-8 sm:my-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                <dd className="font-semibold inline text-gray-500 dark:text-slate-400">
                  <time dateTime="2023-31-01">
                    Issued On{' '}
                    {moment(finance.createdate)
                      .utcOffset(0)
                      .format('MMMM DD, YYYY')}
                  </time>
                </dd>
              </div>
            </dl>
          )}
          {loading.listLoading ? (
            <div className="w-full flex justify-center py-4">
              <Spin />
            </div>
          ) : (
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-6">
              {totalStats.map((stat, i) => (
                <div
                  key={stat.name}
                  className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 dark:bg-slate-700"
                >
                  <dt className="truncate text-sm font-medium text-gray-500 dark:text-slate-200 dark:text-slate-200">
                    {stat.name}
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-slate-200">
                    {stat.name === 'Total Order'
                      ? stat.value
                      : stat.value.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                  </dd>
                </div>
              ))}
            </dl>
          )}
        </div>
        <div className="sm:flex sm:items-center"></div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {!loading.listLoading ? (
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
                <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                      >
                        Order#
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 dark:text-slate-200"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="items-center flex py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Subtotal
                        <Tooltip id="subtotal" html />
                        <InformationCircleIcon
                          data-tooltip-id="subtotal"
                          data-tooltip-html={`
                            Subtotal includes the Customer Fee, which is paid to Thisway by the Customer.
                      `}
                          className="pl-1 h-5 w-5 text-blue-500"
                        />
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Tax
                      </th>
                      {isColumnVisible.itemfee && (
                        <th
                          scope="col"
                          className="items-center flex py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          Customer Fee
                          <Tooltip id="crvtax" html />
                          <InformationCircleIcon
                            data-tooltip-id="crvtax"
                            data-tooltip-html={`
                            This Fee paid by customer to Thisway during checkout process
                      `}
                            className="pl-1 h-5 w-5 text-blue-500"
                          />
                        </th>
                      )}
                      {isColumnVisible.crvtax && (
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                        >
                          CRV Tax
                        </th>
                      )}
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Tip
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Card Fees
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Discount
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Fees
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Refund
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200"
                      >
                        Net Payout
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                    {filteredOrderList.map((order, orderIdx) => (
                      <tr
                        className="cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-700"
                        key={orderIdx}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                          {order.order.orderid}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                          {moment(order.order.createdate)
                            .utcOffset(order.order.timezone * 60)
                            .format('MM/DD/YYYY hh:mm A' || '')}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0">
                          {OrderStatus[order.order.orderstatus]}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {order.order.subtotal.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {order.order.tax.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </td>
                        {isColumnVisible.itemfee && (
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {`(${order.order?.itemfee?.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })})`}
                          </td>
                        )}
                        {isColumnVisible.crvtax && (
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {order.order.crvtax.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </td>
                        )}
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {order.order.tip.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {`(${(order.order.stripefee > order.order.cardfee
                            ? order.order.stripefee
                            : order.order.cardfee
                          ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })})`}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {`(${order.order.discount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })})`}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {`(${order.order.thiswayfee.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })})`}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {`(${order.order.refund.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })})`}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                          {order.total < 0
                            ? `(${(
                                order.order.subtotal +
                                order.order.tip +
                                order.order?.crvtax +
                                order.order.tax -
                                order.order?.itemfee -
                                ((order.order.stripefee > order.order.cardfee
                                  ? order.order.stripefee
                                  : order.order.cardfee) +
                                  order.order.discount +
                                  order.order.thiswayfee +
                                  order.order.refund)
                              )
                                .toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })
                                .replace('-', '')})`
                            : (
                                order.order.subtotal +
                                order.order.tip +
                                order.order?.crvtax +
                                order.order.tax -
                                order.order?.itemfee -
                                ((order.order.stripefee > order.order.cardfee
                                  ? order.order.stripefee
                                  : order.order.cardfee) +
                                  order.order.discount +
                                  order.order.thiswayfee +
                                  order.order.refund)
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="w-full justify-between flex mt-4">
                  <div>
                    <p className="text-sm text-gray-700 dark:text-slate-200">
                      Showing{' '}
                      <span className="font-medium">
                        {pagination.pageNumber}
                      </span>{' '}
                      to{' '}
                      <span className="font-medium">{pagination.pageSize}</span>{' '}
                      of{' '}
                      <span className="font-medium">
                        {pagination.totalRecords}
                      </span>{' '}
                      results
                    </p>
                  </div>
                  <Pagination
                    onChange={(pageNumber, pageSize) =>
                      handleChangePage(pageNumber, pageSize)
                    }
                    defaultPageSize={pagination.pageSize}
                    current={pagination.pageNumber}
                    total={pagination.totalRecords}
                  />
                </div>
              </div>
            ) : (
              <div className="grid justify-center py-20">
                <img src={NoItems} className="h-24 pb-2" alt="" />
                <p className=" text-slate-400 font-light text-center">
                  No Orders
                </p>
              </div>
            )}
          </div>
        </div>
      </>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default OrderList;
