import React, { useEffect, useState } from 'react';
import { Select, DatePicker, Spin, Pagination } from 'antd';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dashboardApi from '../../services/dashboardApi';
import storeApi from '../../services/storeApi';
import terminalApi from '../../services/terminalApi';
import NoData2 from '../../assets/no-data-2.svg';
import { formatCurrency } from '../../utils/baseUtils';
import { setStartAndEndDate } from '../../utils/setStartAndEndDate';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.Ls.en.weekStart = 1;
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
const { RangePicker } = DatePicker;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ReportStore = () => {
  const reduxCompany = useSelector((state) => state.companyReducer.selected);
  const [globalCompany, setGlobalCompany] = useState(reduxCompany);
  const [filteredList, setFilteredList] = useState([]);
  const [productUpsellFilteredList, setProductUpsellFilteredList] = useState(
    []
  );
  const [reportSearch, setReportSearch] = useState({
    startdate: setStartAndEndDate(
      dayjs().subtract(60, 'day').startOf('day'),
      'startdate',
      '0'
    ),
    enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
    storeids: [],
  });
  const [productSearch, setProductSearch] = useState({
    startdate: setStartAndEndDate(
      dayjs().subtract(60, 'day').startOf('day'),
      'startdate',
      '0'
    ),
    enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
    storeids: [],
  });

  const [productUpsellSearch, setProductUpsellSearch] = useState({
    startdate: setStartAndEndDate(
      dayjs().subtract(60, 'day').startOf('day'),
      'startdate',
      '0'
    ),
    enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
    storeids: [],
  });

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [loading, setLoading] = useState({
    topOrderItems: true,
    productUpsellItems: true,
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });

  const [upsellPagination, setUpsellPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: null,
  });
  const [isShowReportCustomRange, setIsShowReportCustomRange] = useState(false);
  const [isShowProductCustomRange, setIsShowProductCustomRange] =
    useState(false);
  const [isShowProductUpsertCustomRange, setIsShowProductUpsertCustomRange] =
    useState(false);
  const [topOrderItems, setTopOrderItems] = useState([]);
  const [productUpsellItems, setProductUpsellItems] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [orderInformations, setOrderInformations] = useState({
    orderTotal: 0,
    orderCount: 0,
    orderRefundTotal: 0,
    orderDaily: [],
    orderTop10: [],
    orderCountByTerminal: [],
    totalClickCount: 0,
    clickCount: 0,
    allOrderCount: 0,
    tip: 0,
    refund: 0,
    ordertotal: 0,
    profit: 0,
    tax: 0,
    payout: 0,
  });

  const [chart, setChart] = useState({
    series: [
      {
        name: 'Total Sales',
        data: [],
      },
      {
        name: 'Total Orders',
        data: [],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      yaxis: {
        show: false,
        min: 0,
        tickAmount: 10,
      },
      xaxis: {
        show: false,
        type: 'date',
        categories: [],
        labels: {
          show: false,
        },
      },
      tooltip: {
        x: {
          format: 'MM/DD/YYYY',
        },
      },
    },
  });

  const dateOptions = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'weekToD‌​ate', label: 'Week to Date' },
    { value: 'last7Days', label: 'Last 7 Days' },
    { value: 'last14Days', label: 'Last 14 Days' },
    { value: 'last21Days', label: 'Last 21 Days' },
    { value: 'last28Days', label: 'Last 28 Days' },
    { value: 'last30Days', label: 'Last 30 Days' },
    { value: 'monthToD‌​ate', label: 'Month to Date' },
    { value: 'last60Days', label: 'Last 60 Days' },
    { value: 'customRange', label: 'Custom Range' },
  ];

  const topOrderItemsHeaders = [
    { key: 'product.name', label: 'Product Name', sortable: false },
    { key: 'qty', label: 'Quantity', sortable: true },
    { key: 'total', label: 'Total Price', sortable: true },
  ];

  const handleDateRangeSelectChange = (key, selected) => {
    if (!selected) return;

    const updateState = (setter, setShowCustomRange) => {
      let enddate = dayjs();
      let startdate;

      setShowCustomRange(false);

      switch (selected) {
        case 'today':
          startdate = enddate.startOf('day');
          break;
        case 'yesterday':
          startdate = enddate.subtract(1, 'day').startOf('day');
          enddate = enddate.subtract(1, 'day').endOf('day');
          break;
        case 'weekToD‌​ate':
          startdate = enddate.startOf('week');
          break;
        case 'last7Days':
          startdate = enddate.subtract(7, 'day').startOf('day');
          break;
        case 'last14Days':
          startdate = enddate.subtract(14, 'day').startOf('day');
          break;
        case 'last21Days':
          startdate = enddate.subtract(21, 'day').startOf('day');
          break;
        case 'last28Days':
          startdate = enddate.subtract(28, 'day').startOf('day');
          break;
        case 'last30Days':
          startdate = enddate.subtract(30, 'day').startOf('day');
          break;
        case 'monthToD‌​ate':
          startdate = enddate.startOf('month');
          break;
        case 'last60Days':
          startdate = enddate.subtract(60, 'day').startOf('day');
          break;
        case 'customRange':
          setShowCustomRange(true);
          return;
        default:
          return;
      }

      setter((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(startdate, 'startdate', '0'),
        enddate: setStartAndEndDate(enddate, 'enddate', '0'),
      }));
    };

    if (key === 'report') {
      updateState(setReportSearch, setIsShowReportCustomRange);
    } else if (key === 'upsell') {
      updateState(setProductUpsellSearch, setIsShowProductUpsertCustomRange);
    } else {
      updateState(setProductSearch, setIsShowProductCustomRange);
    }
  };

  const onDateChange = (key, dateString) => {
    const updateState = (setter) => {
      setter((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(dateString[0], 'startdate', '0'),
        enddate: setStartAndEndDate(dateString[1], 'enddate', '0'),
      }));
    };

    if (key === 'report') {
      updateState(setReportSearch);
    } else if (key === 'upsell') {
      updateState(setProductUpsellSearch);
    } else {
      updateState(setProductSearch);
    }
  };

  const getTopOrderItems = async () => {
    try {
      const query = { ...productSearch, companyid: globalCompany.id };
      const list = await dashboardApi.getTopOrderItems(query);
      if (list.data.data) {
        setTopOrderItems(list.data.data);
        setFilteredList(list.data.data.slice(0, pagination.pageSize));

        setPagination({
          ...pagination,
          totalRecords: Math.ceil(list.data.data.length),
        });
      } else {
        setTopOrderItems([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        topOrderItems: false,
      }));
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    orderby: 0,
  });

  const requestSort = (key) => {
    let orderby = 1;
    if (sortConfig.key === key && sortConfig.orderby === 1) {
      orderby = 0;
    }
    setSortConfig({ key, orderby });

    const sortedList = topOrderItems

      .sort((a, b) => {
        if (a[key] < b[key]) {
          return orderby === 1 ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return orderby === 1 ? 1 : -1;
        }
        return 0;
      })
      .slice(
        (pagination.pageNumber - 1) * pagination.pageSize,
        pagination.pageSize * pagination.pageNumber
      );

    setFilteredList(sortedList);
  };

  const handleChangePage = (pageNumber, pageSize) => {
    setPagination((prev) => ({ ...prev, pageNumber, pageSize }));

    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredList = topOrderItems.slice(startIndex, endIndex);

    setFilteredList(filteredList);
  };

  const handleUpsellProductChangePage = (pageNumber, pageSize) => {
    setUpsellPagination((prev) => ({ ...prev, pageNumber, pageSize }));

    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredProductUpsellList = productUpsellItems.slice(
      startIndex,
      endIndex
    );

    setProductUpsellFilteredList(filteredProductUpsellList);
  };

  const getStoreList = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        storeListLoading: true,
      }));
      const query = globalCompany.id ? `companyid=${globalCompany.id}` : '';
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      } else {
        setStoreList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
      setStoreList([]);
    }
  };

  const onSelectStore = (key, storeid) => {
    const storeids = storeid ? [storeid] : [];
    const updateState = (searchState, setSearch) => {
      setSearch((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(searchState.startdate, 'startdate', '0'),
        enddate: setStartAndEndDate(searchState.enddate, 'enddate', '0'),
        storeids,
      }));
    };

    if (key === 'report') {
      updateState(reportSearch, setReportSearch);
    } else if (key === 'upsell') {
      updateState(productUpsellSearch, setProductUpsellSearch);
    } else {
      updateState(productSearch, setProductSearch);
    }
  };

  const getOrderTotal = async () => {
    try {
      const query = { ...reportSearch, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderTotal(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderTotal: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderTotal: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getReports = async () => {
    try {
      const query = { ...reportSearch, companyid: globalCompany.id };
      const res = await dashboardApi.getReports(query);

      const { tip, refund, ordertotal, profit, tax, payout } =
        res.data.counts[0];

      setOrderInformations((prev) => ({
        ...prev,
        tip,
        refund,
        ordertotal,
        profit,
        tax,
        payout,
      }));

      setLoading((prev) => ({ ...prev, initialLoading: false }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, initialLoading: false }));
    }
  };

  const getOrdersDaily = async () => {
    try {
      const query = { ...reportSearch, companyid: globalCompany.id };
      const list = await dashboardApi.getOrdersDaily(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderDaily: list.data.data,
        }));
        setChart((prev) => ({
          ...prev,
          series: [
            {
              name: 'Sales',
              data: list.data.data.map((item) => item.total),
            },
          ],
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: list.data.data.map((item) => item.date),
            },
            yaxis: {
              show: false,
              labels: {
                formatter: function (value) {
                  return `$${parseFloat(value).toFixed(2)}`;
                },
              },
            },
          },
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderDaily: [],
        }));
        setChart((prev) => ({
          ...prev,
          series: [
            {
              name: 'Sales',
              data: [],
            },
          ],
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: [],
            },
            yaxis: {
              show: false,
              labels: {
                formatter: function (value) {
                  return `$${parseFloat(value).toFixed(2)}`;
                },
              },
            },
          },
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderCount = async () => {
    try {
      const query = { ...reportSearch, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderCount(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderCount: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getAllOrderCount = async () => {
    try {
      const query = {
        companyid: globalCompany.id,
        enddate: dayjs().add(1, 'day'),
        startdate: '2020-01-01',
      };
      const list = await dashboardApi.getOrderCount(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          allOrderCount: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          allOrderCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderRefunds = async () => {
    try {
      const query = { ...reportSearch, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderRefundTotal(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderRefundTotal: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderRefundTotal: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getTotalClickCountKiosk = async () => {
    setLoading((prev) => ({
      ...prev,
      clickCountLoading: true,
    }));
    try {
      let query = `?pageSize=1000&addclickcount=true&companyid=${globalCompany.id}`;

      const list = await terminalApi.list(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          totalClickCount: list.data.data.reduce((a, b) => a + b.clickcount, 0),
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          totalClickCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        clickCountLoading: false,
      }));
    }
  };

  const getOrderCountByTerminal = async () => {
    try {
      const query = { ...reportSearch, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderCountByTerminal(query);
      if (list.data.data.length > 0) {
        setOrderInformations((prev) => ({
          ...prev,
          orderCountByTerminal: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderCountByTerminal: [],
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getClickCountKiosk = async () => {
    try {
      let query = `?pageSize=1000&startdate=${reportSearch.startdate}&enddate=${reportSearch.enddate}&addclickcount=true&companyid=${globalCompany.id}`;

      const list = await terminalApi.list(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          clickCount: list.data.data.reduce((a, b) => a + b.clickcount, 0),
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          clickCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getUpsellOrderItems = async () => {
    setLoading((prev) => ({
      ...prev,
      productUpsellItems: true,
    }));
    try {
      const query = { ...productUpsellSearch, companyid: globalCompany.id };

      const list = await dashboardApi.getUpsellOrderItems(query);

      if (list.data.data.length > 0) {
        setProductUpsellItems(list.data.data);
        setProductUpsellFilteredList(
          list.data.data.slice(0, upsellPagination.pageSize)
        );
        setUpsellPagination({
          ...upsellPagination,
          totalRecords: Math.ceil(list.data.data.length),
        });
      } else {
        setProductUpsellItems([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        productUpsellItems: false,
      }));
    }
  };

  useEffect(() => {
    getOrdersDaily();
    getOrderTotal();
    getOrderRefunds();
    getTotalClickCountKiosk();
    getOrderCount();
    getOrderCountByTerminal();
    getClickCountKiosk();
    getReports();
  }, [globalCompany, reportSearch]);

  useEffect(() => {
    getTopOrderItems();
  }, [productSearch]);

  useEffect(() => {
    getUpsellOrderItems();
  }, [productUpsellSearch]);

  useEffect(() => {
    getStoreList();
    getAllOrderCount();
  }, [globalCompany]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Reports
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              Track your sales metrics and optimize your business performance.
            </p>
          </div>
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
        <div className="py-6 sm:py-4  bg-white  dark:bg-slate-800">
          <div className="space-y-10 ">
            <div className="flex sm:flex-row  items-start gap-5">
              <div className=" md:contents flex-1 flex flex-col gap-5 w-full">
                <div className="w-full">
                  <label
                    htmlFor="image"
                    className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                  >
                    Date Range
                  </label>
                  <Select
                    allowClear
                    size="large"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Date Range"
                    defaultValue={dateOptions.find(
                      (a) => a.value === 'last60Days'
                    )}
                    onChange={(a) => {
                      if (a) {
                        handleDateRangeSelectChange('report', a);
                      } else {
                        handleDateRangeSelectChange('report', 'last60Days');
                      }
                    }}
                    options={dateOptions.map((a) => ({
                      label: a.label,
                      value: a.value,
                    }))}
                  />
                </div>
                {isShowReportCustomRange && (
                  <div className="w-full">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Custom Range
                    </label>
                    <div className="pt-1 w-full">
                      <RangePicker
                        style={{ width: '100%' }}
                        format="MM/DD/YYYY"
                        size="large"
                        value={[
                          reportSearch.startdate
                            ? dayjs(reportSearch.startdate, 'YYYY-MM-DD')
                            : null,
                          reportSearch.enddate
                            ? dayjs(reportSearch.enddate, 'YYYY-MM-DD')
                            : null,
                        ]}
                        onChange={(e) => {
                          if (e) {
                            onDateChange('report', e);
                          } else {
                            handleDateRangeSelectChange('report', 'last60Days');
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="col-span-2 sm:col-span-1 w-full">
                  <div className="">
                    <label
                      htmlFor="image"
                      className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                    >
                      Stores
                    </label>
                    <Select
                      disabled={!globalCompany.id}
                      allowClear
                      size="large"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Stores"
                      onChange={(e) => {
                        onSelectStore('report', e);
                      }}
                      options={storeList.map((a) => ({
                        label: a.name,
                        value: a.id,
                      }))}
                      value={reportSearch.storeids}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="chart" className="bg-white dark:bg-slate-700">
              <ReactApexChart
                className={classNames(
                  orderInformations.orderTotal > 0 ? 'block' : 'hidden'
                )}
                options={chart.options}
                series={chart.series}
                type="area"
                height={350}
              />
              <div
                className={classNames(
                  orderInformations.orderTotal === 0 ? 'block' : 'hidden',
                  'grid justify-center py-20 dark:bg-slate-700'
                )}
              >
                <img src={NoData2} className="h-24 pb-2" alt="" />
                <p className=" text-slate-400 font-light text-center">
                  No Data
                </p>
              </div>
            </div>
            <div>
              <dl className="my-10 grid grid-cols-2 divide-y divide-gray-200 dark:divide-slate-500 overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow sm:grid-cols-4 sm:divide-y-3 divide-x">
                <div className="px-4 py-5 sm:p-6 dark:bg-slate-700 border-t">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Sales
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {(
                        orderInformations.orderTotal -
                        orderInformations.orderRefundTotal
                      ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Order Volume
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {orderInformations.orderCount}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Kiosk Interactions
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {loading.clickCountLoading ? (
                        <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
                          <Spin />
                        </div>
                      ) : (
                        <>{orderInformations.clickCount}</>
                      )}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Avg. Ticket Size
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {(
                        orderInformations.orderTotal /
                          orderInformations.orderCount || 0
                      ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6 dark:bg-slate-700 border-t">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Sales Tax
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {orderInformations.tax?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Tip
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {orderInformations.tip?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Refund
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {orderInformations.refund?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Net Payout
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {orderInformations.payout?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <div className="my-10 mx-auto max-w-7xl">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <span className=" text-5xl font-black">
                {orderInformations.allOrderCount * 4.25} mins
              </span>
              <br />
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                spent by your customers ordering on Thisway.
              </p>
            </h2>

            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>

        <div className="sm:flex sm:items-center mt-10">
          <div className="sm:flex-auto mt-10">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Product Sales
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              Products sold in the selected date range.
            </p>
          </div>
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

        <div className="flex sm:flex-row  items-start gap-5 my-5">
          <div className=" md:contents flex-1 flex flex-col gap-5 w-full">
            <div className="w-full">
              <label
                htmlFor="image"
                className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
              >
                Date Range
              </label>
              <Select
                allowClear
                size="large"
                style={{
                  width: '100%',
                }}
                placeholder="Date Range"
                defaultValue={dateOptions.find((a) => a.value === 'last60Days')}
                onChange={(a) => {
                  if (a) {
                    handleDateRangeSelectChange('product', a);
                  } else {
                    handleDateRangeSelectChange('product', 'last60Days');
                  }
                }}
                options={dateOptions.map((a) => ({
                  label: a.label,
                  value: a.value,
                }))}
              />
            </div>
            {isShowProductCustomRange && (
              <div className="w-full">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 dark:text-slate-200"
                >
                  Custom Range
                </label>
                <div className="pt-1 w-full">
                  <RangePicker
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    size="large"
                    value={[
                      productSearch.startdate
                        ? dayjs(productSearch.startdate, 'YYYY-MM-DD')
                        : null,
                      productSearch.enddate
                        ? dayjs(productSearch.enddate, 'YYYY-MM-DD')
                        : null,
                    ]}
                    onChange={(e) => {
                      if (e) {
                        onDateChange('product', e);
                      } else {
                        handleDateRangeSelectChange('product', 'last60Days');
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="col-span-2 sm:col-span-1 w-full">
              <div className="">
                <label
                  htmlFor="image"
                  className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                >
                  Stores
                </label>
                <Select
                  disabled={!globalCompany.id}
                  allowClear
                  size="large"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Stores"
                  onChange={(e) => {
                    onSelectStore('product', e);
                  }}
                  options={storeList.map((a) => ({
                    label: a.name,
                    value: a.id,
                  }))}
                  value={productSearch.storeids}
                />
              </div>
            </div>
          </div>
        </div>

        {loading.topOrderItems ? (
          <div className="w-full flex justify-center py-4">
            <Spin />
          </div>
        ) : (
          <div className="mt-2 flow-root">
            {filteredList.length > 0 ? (
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        {topOrderItemsHeaders.map((header) => (
                          <th
                            scope="col"
                            key={header.key}
                            onClick={() =>
                              header.sortable ? requestSort(header.key) : null
                            }
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            <a className="group inline-flex cursor-pointer">
                              {header.label}
                              {sortConfig.key === header.key &&
                                header.sortable && (
                                  <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                    {sortConfig.orderby === 1 ? (
                                      <ChevronUpIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                )}
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      {filteredList.map((item) => (
                        <tr key={item.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                {item.product?.imagepath ? (
                                  <img
                                    alt="logo"
                                    className="w-8 h-8 rounded-full"
                                    src={item.product?.imagepath}
                                  />
                                ) : (
                                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                    <span className="text-xs font-medium leading-none text-white">
                                      {item.product?.name
                                        .split(' ')
                                        .map((x) => x[0])}
                                    </span>
                                  </span>
                                )}
                              </div>
                              <div className="ml-4">
                                <div className="grid font-medium text-gray-900 dark:text-slate-200">
                                  <span className="font-bold text-md text-gray-500 dark:text-slate-200">
                                    {item.product?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {item.qty}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {formatCurrency(item.total)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full text-end">
                    <Pagination
                      current={pagination.pageNumber}
                      total={pagination.totalRecords}
                      defaultPageSize={pagination.pageSize}
                      onChange={(pageNumber, pageSize) =>
                        handleChangePage(pageNumber, pageSize)
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid justify-center py-20">
                <img src={NoData2} alt="No Data" className="h-24 pb-2" />
                <p className=" text-slate-400 font-light text-center">
                  No Data
                </p>
              </div>
            )}
          </div>
        )}

        <div className="sm:flex sm:items-center mt-10">
          <div className="sm:flex-auto mt-10">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Upsell Order Items
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              Products sold in the selected date range.
            </p>
          </div>
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />

        <div className="flex sm:flex-row  items-start gap-5 my-5">
          <div className=" md:contents flex-1 flex flex-col gap-5 w-full">
            <div className="w-full">
              <label
                htmlFor="image"
                className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
              >
                Date Range
              </label>
              <Select
                allowClear
                size="large"
                style={{
                  width: '100%',
                }}
                placeholder="Date Range"
                defaultValue={dateOptions.find((a) => a.value === 'last60Days')}
                onChange={(a) => {
                  if (a) {
                    handleDateRangeSelectChange('upsell', a);
                  } else {
                    handleDateRangeSelectChange('upsell', 'last60Days');
                  }
                }}
                options={dateOptions.map((a) => ({
                  label: a.label,
                  value: a.value,
                }))}
              />
            </div>
            {isShowProductUpsertCustomRange && (
              <div className="w-full">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 dark:text-slate-200"
                >
                  Custom Range
                </label>
                <div className="pt-1 w-full">
                  <RangePicker
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    size="large"
                    value={[
                      productUpsellSearch.startdate
                        ? dayjs(productUpsellSearch.startdate, 'YYYY-MM-DD')
                        : null,
                      productUpsellSearch.enddate
                        ? dayjs(productUpsellSearch.enddate, 'YYYY-MM-DD')
                        : null,
                    ]}
                    onChange={(e) => {
                      if (e) {
                        onDateChange('upsell', e);
                      } else {
                        handleDateRangeSelectChange('upsell', 'last60Days');
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="col-span-2 sm:col-span-1 w-full">
              <div className="">
                <label
                  htmlFor="image"
                  className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                >
                  Stores
                </label>
                <Select
                  disabled={!globalCompany.id}
                  allowClear
                  size="large"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Stores"
                  onChange={(e) => {
                    onSelectStore('upsell', e);
                  }}
                  options={storeList.map((a) => ({
                    label: a.name,
                    value: a.id,
                  }))}
                  value={productUpsellSearch.storeids}
                />
              </div>
            </div>
          </div>
        </div>

        {loading.productUpsellItems ? (
          <div className="w-full flex justify-center py-4">
            <Spin />
          </div>
        ) : (
          <div className="mt-2 flow-root">
            {productUpsellFilteredList.length > 0 ? (
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        {topOrderItemsHeaders.map((header) => (
                          <th
                            scope="col"
                            key={header.key}
                            onClick={() =>
                              header.sortable ? requestSort(header.key) : null
                            }
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            <a className="group inline-flex cursor-pointer">
                              {header.label}
                              {sortConfig.key === header.key &&
                                header.sortable && (
                                  <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                    {sortConfig.orderby === 1 ? (
                                      <ChevronUpIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                )}
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      {productUpsellFilteredList.map((item) => (
                        <tr key={item.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                {item.product?.imagepath ? (
                                  <img
                                    alt="logo"
                                    className="w-8 h-8 rounded-full"
                                    src={item.product?.imagepath}
                                  />
                                ) : (
                                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                    <span className="text-xs font-medium leading-none text-white">
                                      {item.product?.name
                                        .split(' ')
                                        .map((x) => x[0])}
                                    </span>
                                  </span>
                                )}
                              </div>
                              <div className="ml-4">
                                <div className="grid font-medium text-gray-900 dark:text-slate-200">
                                  <span className="font-bold text-md text-gray-500 dark:text-slate-200">
                                    {item.product?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {item.qty}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            {formatCurrency(item.total)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full text-end">
                    <Pagination
                      current={upsellPagination.pageNumber}
                      total={upsellPagination.totalRecords}
                      defaultPageSize={upsellPagination.pageSize}
                      onChange={(pageNumber, pageSize) =>
                        handleUpsellProductChangePage(pageNumber, pageSize)
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid justify-center py-20">
                <img src={NoData2} alt="No Data" className="h-24 pb-2" />
                <p className=" text-slate-400 font-light text-center">
                  No Data
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportStore;
