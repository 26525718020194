import dayjs from "dayjs";
import React from "react";
import { CheckIcon, PlusIcon, NewspaperIcon } from "@heroicons/react/20/solid";

const OrderLogs = ({ logs }) => {
  const getIconProps = (note) => {
    if (note.includes("created")) {
      return {
        icon: <PlusIcon className="text-white h-5 w-5" />,
        background: "bg-blue-500",
      };
    } else if (note.includes("completed")) {
      return {
        icon: <CheckIcon className="text-white h-5 w-5" />,
        background: "bg-green-500",
      };
    } else
      return {
        icon: <NewspaperIcon className="text-white h-5 w-5" />,
        background: "bg-yellow-500",
      };
  };

  return (
    <ul>
      {logs && logs.length > 0
        ? logs
            .sort((a, b) => dayjs(a.createdate).diff(dayjs(b.createdate)))
            .map((log, idx) => {
              const iconProps = getIconProps(log.note);
              return (
                <li key={idx}>
                  <div className="relative pb-8">
                    {idx !== logs.length - 1 ? (
                      <span
                        className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-slate-300"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-transparent ${iconProps.background}`}
                        >
                          {iconProps.icon}
                        </span>
                      </div>
                      <div className="flex flex-col sm:flex-row min-w-0 flex-1 justify-between space-y-2 sm:space-y-0 sm:space-x-4 pt-1.5">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-slate-300">
                            {log.note}.
                          </p>
                        </div>
                        <div className="whitespace-nowrap sm:text-right text-xs text-gray-500 dark:text-slate-400">
                          {dayjs(log.createdate).format("MM/DD/YYYY hh:mm A")}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
        : "There is no log."}
    </ul>
  );
};

export default OrderLogs;
