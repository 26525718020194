import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { useSelector } from 'react-redux';
import Notification from '../../notification/notification';
import { Button, Select, Spin } from 'antd';
import storeApi from '../../../services/storeApi';
import {
  TrashIcon,
  ChevronUpIcon,
  MinusCircleIcon,
} from '@heroicons/react/24/outline';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { weekDaysEnum } from '../../../data/constants';
import { differenceBy } from 'lodash';
const format = 'hh:mm a';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const MenuUpsertModal = ({
  show,
  onFormChange,
  onFormSubmit,
  onCloseUpsert,
  onClickDeleteMenu,
  menuForm,
  errors,
  loading,
}) => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const [storeList, setStoreList] = useState([]);
  const cancelButtonRef = useRef(null);
  const [loadingStoreList, setLoadingStoreList] = useState(false);
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [days, setDays] = useState([]);
  const [daysLoading, setDaysLoading] = useState(true);

  // Day Hours Management Start //
  const remainingDays = differenceBy(
    weekDaysEnum,
    days.map((day) => weekDaysEnum.find((a) => a.value === day.day)),
    'value'
  );

  useEffect(() => {
    if (menuForm.days) {
      setDays(menuForm.days.sort((a, b) => a.day - b.day));
      setDaysLoading(false);
    }
  }, [menuForm]);

  const onClickAddDay = () => {
    let payload = {
      day: remainingDays[0].value,
      starthour: 10,
      startmin: 30,
      endhour: 13,
      endmin: 30,
    };
    setDays([...days, payload]);
  };

  const onClickDeleteDay = (day) => {
    setDaysLoading(true);
    let tempDays = days.filter((a) => a.day !== day);
    setDays(tempDays);
    setTimeout(() => {
      setDaysLoading(false);
    }, 150);
  };

  const handleDayChange = (value, index, type) => {
    if (type === 'day') {
      setDaysLoading(true);
      let tempArray = days;
      tempArray[index].day = value;
      setDays(tempArray);
      setTimeout(() => {
        setDaysLoading(false);
      }, 100);
    } else if (type === 'start') {
      let tempArray = days;
      tempArray[index].starthour = dayjs(value).hour();
      tempArray[index].startmin = dayjs(value).minute();
      setDays(tempArray);
    } else if (type === 'end') {
      let tempArray = days;
      tempArray[index].endhour = dayjs(value).hour();
      tempArray[index].endmin = dayjs(value).minute();
      setDays(tempArray);
    }
  };

  const getStoreList = async () => {
    try {
      setLoadingStoreList(true);
      const query = `companyid=${globalCompany.id}`;
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      }
    } catch (error) {
    } finally {
      setLoadingStoreList(false);
    }
  };

  useEffect(() => {
    getStoreList();
  }, []);

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Menu Name*
                    </label>
                    <div className="mt-1">
                      <input
                        value={menuForm.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500 dark:placeholder:text-slate-500"
                        placeholder="e.g ''Breakfast, Dinner Menu''"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Menu name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Menu Description
                    </label>
                    <div className="mt-1">
                      <input
                        value={menuForm.description}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'description')
                        }
                        type="text"
                        name="description"
                        id="description"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500 dark:placeholder:text-slate-500"
                        placeholder="e.g ''For Manhattan Stores''"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="stores"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Stores
                    </label>
                    {!loadingStoreList && (
                      <div className="mt-1">
                        <Select
                          mode="multiple"
                          allowClear
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          placeholder="Please select stores to integrate menu"
                          defaultValue={menuForm?.stores?.map((x) => ({
                            value: x,
                          }))}
                          onChange={(val) => onFormChange(val, 'stores')}
                          options={storeList.map((x) => ({
                            label: x.name,
                            value: x.id,
                          }))}
                        />
                        <div className="h-2 mt-1  text-xs">
                          {errors.stores && (
                            <p className="text-red-700">
                              Stores can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {menuForm?.days && (
                    <div className="w-full">
                      <div className="bg-white dark:bg-slate-800">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full items-center justify-start rounded-lg bg-white dark:bg-slate-800 py-2 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                                <div className="grid leading-6 pr-2">
                                  <span className=" font-semibold text-md dark:text-slate-300">
                                    Advanced Settings
                                  </span>
                                </div>
                                <ChevronUpIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } h-5 w-5 text-black dark:text-slate-200`}
                                />
                              </Disclosure.Button>
                              <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel className="pt-4 text-sm flex gap-x-5">
                                  <div className="flex flex-col gap-5">
                                    {daysLoading ? (
                                      <div className="w-full flex justify-center py-4">
                                        <Spin />
                                      </div>
                                    ) : (
                                      days.map((day, index) => {
                                        const selectedDay = weekDaysEnum.find(
                                          (a) => a.value === day.day
                                        );
                                        return (
                                          <div
                                            key={index}
                                            className="flex flex-row items-center gap-5"
                                          >
                                            <div className="flex flex-col gap-1 w-[150px]">
                                              <label
                                                htmlFor="image"
                                                className="block  text-sm font-medium text-gray-700 dark:text-slate-400"
                                              >
                                                Day
                                              </label>
                                              <Select
                                                size="large"
                                                style={{
                                                  width: '100%',
                                                }}
                                                placeholder="Select Day"
                                                defaultValue={selectedDay}
                                                onChange={(val) =>
                                                  handleDayChange(
                                                    val,
                                                    index,
                                                    'day'
                                                  )
                                                }
                                                options={remainingDays}
                                              />
                                            </div>
                                            <div className="flex flex-col gap-1">
                                              <label
                                                htmlFor="name"
                                                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                              >
                                                Start Time
                                              </label>
                                              <TimePicker
                                                changeOnBlur
                                                use12Hours
                                                size="large"
                                                allowClear={false}
                                                defaultValue={dayjs(
                                                  `${day.starthour}:${day.startmin}`,
                                                  'hh:mm'
                                                )}
                                                format={format}
                                                onChange={(val) =>
                                                  handleDayChange(
                                                    val,
                                                    index,
                                                    'start'
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="flex flex-col gap-1">
                                              <label
                                                htmlFor="name"
                                                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                              >
                                                End Time
                                              </label>
                                              <TimePicker
                                                changeOnBlur
                                                use12Hours
                                                size="large"
                                                allowClear={false}
                                                defaultValue={dayjs(
                                                  `${day.endhour}:${day.endmin}`,
                                                  'hh:mm'
                                                )}
                                                format={format}
                                                onChange={(val) =>
                                                  handleDayChange(
                                                    val,
                                                    index,
                                                    'end'
                                                  )
                                                }
                                              />
                                            </div>
                                            <div>
                                              <MinusCircleIcon
                                                onClick={() =>
                                                  onClickDeleteDay(day.day)
                                                }
                                                className="text-red-500 w-5 h-5 mt-5 hover:text-red-900"
                                              />
                                            </div>
                                          </div>
                                        );
                                      })
                                    )}
                                    <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-4 sm:gap-3">
                                      <Button
                                        type="ghost"
                                        disabled={
                                          loading.upsertLoading ||
                                          days.length === 7
                                        }
                                        loading={loading.upsertLoading}
                                        className={classNames(
                                          'col-span-1 col-start-1',
                                          'mt-3 inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-70'
                                        )}
                                        onClick={() => onClickAddDay()}
                                      >
                                        + Add Display Hour
                                      </Button>
                                    </div>
                                  </div>
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                  )}

                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {menuForm.id && (
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        onClick={() => onClickDeleteMenu(menuForm)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-span-2 col-span-12 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading}
                      className={classNames(
                        menuForm.id
                          ? 'sm:col-span-5 col-span-12'
                          : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading}
                      loading={loading}
                      className={classNames(
                        menuForm.id
                          ? 'sm:col-span-5 col-span-12'
                          : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => {
                        menuForm.id ? onFormSubmit(days) : onFormSubmit();
                      }}
                    >
                      {menuForm.id ? 'Edit Menu' : 'Add Menu'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};
