import React, { useState, useEffect, useRef, Fragment } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import storeApi from '../../services/storeApi';
import Notification from '../../components/notification/notification';
import { Spin, Button, Upload } from 'antd';
import { Dialog, Transition } from '@headlessui/react';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddressAutocomplete from '../../components/address-autocomplete/address-autocomplete';
import moment from 'moment/moment';
import StoreIcon from '../../assets/store-icon.svg';
import NoStoreLogo from '../../assets/no-store-logo.svg';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.Ls.en.weekStart = 1;

const Stores = () => {
  const globalCompany = useSelector((state) => state.companyReducer.selected);

  const [form, setForm] = useState({
    companyid: globalCompany.id,
    latitude: 0,
    longitude: 0,
    city: '',
    state: '',
    postalcode: '',
    address: '',
    name: '',
    id: '',
    terms: true,
    tax: 0,
    responsiblename: '',
    responsiblephone: '',
    customerfeeactive: false,
    customerfeeisrate: true,
    tipactive: true,
    customernoteactive: true,
    customernameactive: true,
    tablenumberactive: false,
    customerfee: 0,
    selectedtip: 0,
    phone: '',
    // suite: '',
    apartment: '',
    tiprate1: 25,
    tiprate2: 20,
    tiprate3: 15,
    tiprate4: 10,
    tiprate5: 0,
    timezone: moment().utcOffset() / 60,
  });

  const [errors, setErrors] = useState({
    name: false,
    responsiblename: false,
    responsiblephone: false,
    address: false,
    phone: false,
    // slug: false,
  });
  const [list, setList] = useState([]);

  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    loginAsCompany: false,
  });

  const [dialogs, setDialogs] = useState({
    userList: false,
    upsert: false,
    delete: false,
  });

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    getList();
  }, [globalCompany.id]);

  const getList = async () => {
    try {
      setLoading((prev) => ({ ...prev, listLoading: true }));
      const query = globalCompany.id ? `companyid=${globalCompany.id}` : '';
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setList(list.data.data);
      } else {
        setList([]);
      }
    } catch (error) {
      setList([]);
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const onClickAddStore = () => {
    setForm({
      companyid: globalCompany.id,
      latitude: 0,
      longitude: 0,
      city: '',
      state: '',
      postalcode: '',
      address: '',
      name: '',
      id: '',
      terms: true,
      tax: 0,
      responsiblename: '',
      responsiblephone: '',
      customerfeeactive: false,
      customerfeeisrate: true,
      tipactive: true,
      customernoteactive: true,
      customernameactive: true,
      tablenumberactive: false,
      customerfee: 0,
      selectedtip: 0,
      phone: '',
      // suite: '',
      apartment: '',
      tiprate1: 25,
      tiprate2: 20,
      tiprate3: 15,
      tiprate4: 10,
      tiprate5: 0,
      timezone: moment().utcOffset() / 60,
    });

    setDialogs((prev) => ({ ...prev, upsert: true }));
  };

  const onFormChange = (val, key) => {
    if (key === 'name') {
      const numAndLetters = /^[A-Za-z0-9 _]*$/;

      setForm((prev) => ({
        ...prev,
        [key]: numAndLetters.test(val) ? val : prev[key],
      }));
    } else {
      setForm((prev) => ({ ...prev, [key]: val }));
    }
  };

  const onFormSubmit = async () => {
    if (
      !form.name ||
      !form.responsiblename ||
      !form.responsiblephone ||
      !form.address ||
      !form.phone
      // ||
      // !form.slug
    ) {
      setErrors((prev) => ({
        ...prev,
        name: !form.name ? true : false,
        responsiblename: !form.responsiblename ? true : false,
        responsiblephone: !form.responsiblephone ? true : false,
        address: !form.address ? true : false,
        phone: !form.phone ? true : false,
        // slug: !form.slug ? true : false,
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        responsiblephone: false,
        name: false,
        responsiblename: false,
        address: false,
        phone: false,
        // slug: false,
      }));
    }
    try {
      setLoading((prev) => ({ ...prev, upsertLoading: true }));
      const body = { ...form };
      const formData = new FormData();

      for (const key of Object.keys(body)) {
        if (key === 'storelogo' && body['storelogo'] !== '') {
          if (body.storelogoid) {
            await storeApi.deleteStoreLogoById(body.id);
          }
          formData.append('storelogo', body.storelogo);
        } else {
          formData.append(key, body[key]);
        }
      }

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const res = await storeApi.upsert(formData, config);
      if (res.data.data) {
        setDialogs((prev) => ({ ...prev, upsert: false }));
      }
    } catch (error) {
    } finally {
      setLoading((prev) => ({ ...prev, upsertLoading: false }));
      await getList();
    }
  };

  const onCloseUpsert = () => {
    setErrors((prev) => ({
      ...prev,
      address: false,
      name: false,
      responsiblename: false,
      responsiblephone: false,
      phone: false,
    }));
    setDialogs((prev) => ({ ...prev, upsert: false }));
  };

  const onAddressSelect = (obj) => {
    setForm((prev) => ({
      ...prev,
      address: obj.street,
      city: obj.city,
      state: obj.state,
      postalcode: obj.zipCode,
      latitude: obj.latitude,
      longitude: obj.longitude,
    }));
  };

  const uploadButton = (
    <div>
      {loading.upsertLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-500"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const checkStoreIsOpen = (store) => {
    const timezoneOffset = store.timezone * 60;

    let currentDay = dayjs().utc().utcOffset(timezoneOffset).day();

    currentDay = currentDay === 0 ? 6 : currentDay - 1;

    if (store.storadays.length === 0) return;

    const storeHours = store.storadays.find((x) => x.day === currentDay);
    if (!storeHours) return;

    const openingHours = dayjs()
      .utc()
      .utcOffset(timezoneOffset)
      .hour(storeHours.starthour)
      .minute(storeHours.startmin)
      .second(0);

    const closingHours = dayjs()
      .utc()
      .utcOffset(timezoneOffset)
      .hour(storeHours.endhour)
      .minute(storeHours.endmin)
      .second(0);

    const now = dayjs().utc().utcOffset(timezoneOffset);

    return now.isAfter(openingHours) && now.isBefore(closingHours);
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-slate-900 dark:text-slate-200">
              Stores
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              Create new locations and manage all your stores here.
            </p>
          </div>
          <div className="hidden sm:block mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <div className="grid justify-center py-6">
              <img src={StoreIcon} alt="store_icon" />
            </div>
          </div>
        </div>

        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto ">
            <div className="mt-6 px-4 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
              {loading.listLoading ? (
                <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
                  <Spin />
                </div>
              ) : (
                <>
                  {list.map((store, storeIdx) => (
                    <div
                      key={storeIdx}
                      className="text-center relative border border-gray-300 rounded z-0 cursor-pointer hover:shadow-md hover:bg-zinc-50 dark:hover:bg-slate-700 dark:border-slate-500"
                    >
                      <Link
                        className="flex h-full items-center justify-center"
                        to={`${store.id}`}
                      >
                        <div className="p-6">
                          <img
                            className="inline-flex h-14 w-14 items-center justify-center rounded-full"
                            src={store.storelogo || NoStoreLogo}
                            alt=""
                          />
                          <h1 className="mt-2 text-lg font-medium text-gray-900 dark:text-slate-200">
                            {store.name}
                          </h1>
                          <p className="mt-1 text-base text-gray-500 dark:text-slate-400">
                            {store.address}
                          </p>
                          {checkStoreIsOpen(store) ? (
                            <span className="mt-1 inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                              Open
                            </span>
                          ) : (
                            <span className="mt-1 inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
                              Closed
                            </span>
                          )}
                        </div>
                      </Link>
                    </div>
                  ))}
                  <div className="text-center p-6 border border-gray-300 rounded shadow dark:border-slate-400">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <p className="mt-1 text-sm text-gray-500 dark:test-slate-500">
                      Create a New Store
                    </p>
                    <div className="mt-6">
                      <Button
                        type="button"
                        onClick={() => onClickAddStore()}
                        className="inline-flex items-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 px-4 h-10 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        New Store
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={dialogs.upsert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="grid grid-cols-8 gap-6">
                    <div className="col-span-8 sm:col-span-8">
                      <label
                        htmlFor="logo"
                        className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-500"
                      >
                        Store Logo
                      </label>
                      <div className="mt-5">
                        <>
                          <Upload
                            multiple={false}
                            maxCount={1}
                            name="avatar"
                            onChange={(e) => onFormChange(e.file, 'storelogo')}
                            listType="picture-circle"
                            className="avatar-uploader"
                            beforeUpload={() => false}
                          >
                            {form.storelogo ? (
                              <img
                                src={form.storelogo}
                                alt="avatar"
                                className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                        <div className="h-2 mt-1  text-xs">
                          {errors.imageid && (
                            <p className="text-red-700">
                              Image can not be empty
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-8">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Store Name *
                      </label>
                      <input
                        value={form.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                        placeholder="e.g. 'ABC Pizza'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Store name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Store Phone Number *
                      </label>
                      <InputMask
                        value={form.phone}
                        mask="(999) 999 9999"
                        maskPlaceholder={' '}
                        onChange={(e) => onFormChange(e.target.value, 'phone')}
                        type="text"
                        name="phone"
                        id="phone"
                        className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.phone && (
                          <p className="text-red-700">
                            Store phone can not be empty
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="tax"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Sales Tax % *
                      </label>
                      <input
                        value={form.tax}
                        onChange={(e) => onFormChange(e.target.value, 'tax')}
                        type="number"
                        name="tax"
                        id="tax"
                        className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                        placeholder="0"
                      />
                    </div>

                    <div className="col-span-8 sm:col-span-8">
                      <h1 className="text-base font-extrabold text-gray-900 dark:text-slate-500">
                        Store Address
                      </h1>
                      <p className=" text-sm text-gray-500 dark:text-slate-500">
                        Your address will be displayed publicly!
                      </p>
                    </div>
                    <div className="col-span-8 sm:col-span-6 ">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400 "
                      >
                        Street Name
                      </label>
                      <AddressAutocomplete
                        value={form.address}
                        onChange={(e) => onAddressSelect(e)}
                      />

                      <div className="h-2 mt-1  text-xs">
                        {errors.address ? (
                          <p className="text-red-700">
                            Address can not be empty
                          </p>
                        ) : (
                          <p className="text-gray-500 dark:text-slate-500">
                            Please type your street name here e.g. '100 Main St'
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-8 sm:col-span-2">
                      <label
                        htmlFor="apartment"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Apt / Suite
                      </label>
                      <input
                        value={form.apartment}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'apartment')
                        }
                        type="text"
                        name="apartment"
                        id="apartment"
                        className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                        placeholder=""
                      />
                      <div className="h-2 mt-1  text-xs"></div>
                    </div>

                    <div className="col-span-8 grid grid-cols-8 gap-6">
                      <div className="col-span-8 sm:col-span-4">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400 "
                        >
                          City
                        </label>
                        <input
                          value={form.city}
                          onChange={(e) => onFormChange(e.target.value, 'city')}
                          type="text"
                          name="city"
                          id="city"
                          className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                          placeholder=""
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                      <div className="col-span-8 sm:col-span-2">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400 "
                        >
                          State
                        </label>
                        <input
                          value={form.state}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'state')
                          }
                          type="text"
                          name="state"
                          id="state"
                          className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                          placeholder=""
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                      <div className="col-span-8 sm:col-span-2">
                        <label
                          htmlFor="postalcode"
                          className="block text-sm font-medium text-gray-700 dark:text-slate-400 dark:placeholder:text-slate-500"
                        >
                          Zip Code
                        </label>
                        <input
                          value={form.postalcode}
                          onChange={(e) =>
                            onFormChange(e.target.value, 'postalcode')
                          }
                          type="text"
                          name="postalcode"
                          id="postalcode"
                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500 dark:border-slate-500"
                          placeholder=""
                        />
                        <div className="h-2 mt-1  text-xs"></div>
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-8">
                      <h1 className="text-base font-extrabold text-gray-900 dark:text-slate-500 ">
                        Store Manager
                      </h1>
                      <p className=" text-sm text-gray-500 dark:text-slate-500 dark:placeholder:text-slate-500">
                        This information <strong>will not</strong> be displayed
                        publicly.
                      </p>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="responsiblename"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400 "
                      >
                        Manager's Full Name
                      </label>
                      <input
                        value={form.responsiblename}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'responsiblename')
                        }
                        type="text"
                        name="responsiblename"
                        id="responsiblename"
                        className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                        placeholder="e.g. 'John Doe'"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.responsiblename && (
                          <p className="text-red-700">
                            Manager's full name can not be empty
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-span-8 sm:col-span-4">
                      <label
                        htmlFor="responsiblephone"
                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                      >
                        Manager's Phone Number
                      </label>
                      <InputMask
                        value={form.responsiblephone}
                        mask="(999) 999 9999"
                        maskPlaceholder={' '}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'responsiblephone')
                        }
                        type="text"
                        name="responsiblephone"
                        id="responsiblephone"
                        className="block w-full appearance-none rounded-md border dark:text-slate-500 dark:placeholder:text-slate-500 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:border-slate-500"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.responsiblephone && (
                          <p className="text-red-700">
                            Manager's phone number can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      loading={loading.upsertLoading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 dark:bg-slate-900 px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => onFormSubmit()}
                    >
                      {'Add Store'}
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Notification
        display={notf.display}
        type={notf.type}
        message={notf.message}
        onClose={() => setNotf((prev) => ({ ...prev, display: false }))}
      />
    </div>
  );
};

export default Stores;
