import api from './api';

const resource = 'Banner/';

export const list = async (query) => {
  return await api.get(resource + 'List' + query);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const upsert = async (body) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return await api.post(resource + 'Upsert', body, config);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const deleteImageById = async (companyid) => {
  return await api.delete(resource + 'DeleteImage?bannerid=' + companyid);
};

const bannerApi = { list, getById, upsert, deleteById, deleteImageById };

export default bannerApi;
