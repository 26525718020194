//Modules
import { createSlice } from '@reduxjs/toolkit';
import tokenService from '../../utils/tokenService';

const initialState = {
  isAuthenticated: false,
  user: null,
  roles: JSON.parse(tokenService.getRole()),
  rolepermissions: JSON.parse(tokenService.getRolePermissions()),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.user = payload;
      state.isAuthenticated = true;
    },
    loginFailure: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    setRole: (state, { payload }) => {
      state.roles = payload;
      tokenService.setRole(JSON.stringify(payload));
    },
    setRolePermissions: (state, { payload }) => {
      state.rolepermissions = payload;
      tokenService.setRolePermissions(JSON.stringify(payload));
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  setIsAuthenticated,
  setRole,
  setRolePermissions,
} = authSlice.actions;

export default authSlice.reducer;
