import { Pagination, Spin, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dashboardApi from '../../../services/dashboardApi';
import { setStartAndEndDate } from '../../../utils/setStartAndEndDate';
import NoData2 from '../../../assets/no-data-2.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const CompanySales = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });
  const [loading, setLoading] = useState({
    listLoading: true,
  });
  const [filter, setFilter] = useState({
    isactive: true,
  });
  const [companySales, setCompanySales] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  });

  const companySalesHeaders = [
    { key: 'logo', label: 'Logo', sortable: false },
    { key: 'companyname', label: 'Company Name', sortable: true },
    { key: 'ordertotal', label: 'Live Time Sales', sortable: true },
    { key: 'ordercount', label: 'Live Time Orders', sortable: true },
    { key: 'avgtips', label: 'Avg Tips', sortable: true },
    { key: 'profit', label: 'Profit', sortable: true },
    { key: 'companycreatedate', label: 'Start Date', sortable: true },
  ];

  const getCompanySales = async () => {
    try {
      setLoading((prev) => ({ ...prev, listLoading: true }));

      const body = {
        startdate: setStartAndEndDate(dayjs(0), 'startdate', '0'),
        enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
      };
      let res = await dashboardApi.getReports(body);

      res.data.counts = res.data.counts.filter(
        (item) => item.isactive !== null && item.isactive === filter.isactive
      );
      setCompanySales(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, listLoading: false }));
    }
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    const { key, direction } = sortConfig;

    return [...companySales.counts].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      return valueA === null || valueB === null
        ? (valueA === null ? 1 : -1) * (direction === 'asc' ? 1 : -1)
        : (valueA - valueB) * (direction === 'asc' ? 1 : -1);
    });
  };

  const onChangeFilter = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    getCompanySales();
  }, [filter, pagination]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <div>
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Company Sales
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                View and manage all company sales in one place.
              </p>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-4">
            <div className="w-full ">
              <div className="mt-1">
                <Select
                  size="large"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Status"
                  defaultValue={filter.isactive}
                  onChange={(value) => onChangeFilter('isactive', value)}
                >
                  {[
                    { id: true, name: 'Active' },
                    { id: false, name: 'Passive' },
                  ].map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
        {loading.listLoading ? (
          <div className="w-full flex justify-center py-4">
            <Spin />
          </div>
        ) : (
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              {companySales.counts.length > 0 ? (
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        {companySalesHeaders.map((header) => (
                          <th
                            scope="col"
                            key={header.key}
                            onClick={() =>
                              header.sortable ? requestSort(header.key) : null
                            }
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            <a className="group inline-flex cursor-pointer">
                              {header.label}
                              {sortConfig.key === header.key &&
                                header.sortable && (
                                  <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                    {sortConfig.direction === 'asc' ? (
                                      <ChevronUpIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                )}
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500 dark:divide-slate-500">
                      {sortedData()?.map((reports, reportsIdx) => (
                        <tr key={reportsIdx}>
                          <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-200 sm:pl-6 md:pl-0">
                            {reports.companylogo ? (
                              <img
                                alt="logo"
                                className="w-8 h-8 rounded-full"
                                src={reports.companylogo}
                              />
                            ) : (
                              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                <span className="text-xs font-medium leading-none text-white">
                                  {reports.companyname
                                    ? reports.companyname
                                        .split(' ')
                                        .map((x) => x[0])
                                    : '-'}
                                </span>
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {reports.companyname || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {reports.ordertotal.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }) || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {reports.ordercount || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {reports?.avgtips.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }) || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {reports?.profit.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }) || '-'}
                          </td>
                          <td className="whitespace-nowrap py-4 px-4 text-sm text-zinc-900 dark:text-zinc-200">
                            {reports.companycreatedate
                              ? dayjs(reports.companycreatedate).format(
                                  'MMMM YYYY'
                                )
                              : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full justify-between flex mt-4">
                    <div>
                      <p className="text-sm text-gray-700 dark:text-slate-200">
                        Showing{' '}
                        <span className="font-medium">
                          {pagination.pageNumber}
                        </span>{' '}
                        to{' '}
                        <span className="font-medium">
                          {pagination.pageSize}
                        </span>{' '}
                        of{' '}
                        <span className="font-medium">
                          {pagination.totalRecords}
                        </span>{' '}
                        results
                      </p>
                    </div>
                    <Pagination
                      showSizeChanger
                      onChange={(page) =>
                        setPagination((prev) => ({ ...prev, pageNumber: page }))
                      }
                      onShowSizeChange={(current, pageSize) => {
                        setPagination((prev) => ({
                          ...prev,
                          pageNumber: current,
                          pageSize,
                        }));
                      }}
                      pageSize={pagination.pageSize}
                      current={pagination.pageNumber}
                      total={pagination.totalRecords}
                    />
                  </div>
                </div>
              ) : (
                <div className="grid justify-center pt-20">
                  <img
                    src={NoData2}
                    className="h-24 pb-2"
                    alt="no-company-sales"
                  />
                  <p className=" text-slate-400 font-light text-center">
                    No Company Sales
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySales;
