import { Pagination, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useRef,
} from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import queryString from 'query-string';
import orderApi from '../../services/orderApi';
import {
  TrashIcon,
  PlayCircleIcon,
  MagnifyingGlassIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline';
import companyApi from '../../services/companyApi';
import storeApi from '../../services/storeApi';
import { Select, Button, DatePicker } from 'antd';
import { formatPhoneNumber } from '../../utils/baseUtils';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserRoles } from '../../data/enums';
import NoOrders from '../../assets/no-orders.svg';
import { PaymentStatus, OrderStatus } from '../../data/enums';
import moment from 'moment-timezone';
import { setStartAndEndDate } from '../../utils/setStartAndEndDate';
import { PlatformTypes } from '../../data/enums';
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Orders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = queryString.parse(location.search);

  const cancelButtonRef = useRef(null);
  const globalCompany = useSelector((state) => state.companyReducer.selected);
  const userRoles = useSelector((state) => state.authReducer.roles);
  const [orderList, setOrderList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [errors, setErrors] = useState({
    store: '',
  });

  const [search, setSearch] = useState({
    search: searchParams.search || '',
    companyid: searchParams.companyid || '',
    startdate: searchParams.startdate || '',
    enddate: searchParams.enddate || '',
    storeid: searchParams.storeid || '',
    orderstatusses: searchParams.orderstatusses || [],
    paymentstatus: searchParams.paymentstatus || '20',
    PageSize: searchParams.PageSize || 20,
    PageNumber: searchParams.PageNumber || 1,
  });

  const [dialogs, setDialogs] = useState({
    liveOrdersDialogs: false,
  });

  const [loading, setLoading] = useState({
    listLoading: true,
    upsertLoading: false,
    deleteLoading: false,
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 20,
    totalRecords: null,
  });

  const orderStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-yellow-100 text-yellow-800';
      case 10:
        return 'bg-blue-100 text-blue-800';
      case 20:
        return 'bg-purple-100 text-purple-800';
      case 30:
        return 'bg-gray-100 text-gray-800';
      case 40:
        return 'bg-pink-100 text-pink-800';
      case 100:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const paymentStatusColor = (status) => {
    switch (status) {
      case 0:
        return 'bg-red-100 text-red-800';
      case 20:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const transRef = useSpringRef();
  const transitions = useTransition(showFilters, {
    ref: transRef,
    keys: null,
    from: { maxHeight: '0px', opacity: 0 },
    enter: { maxHeight: '1000px', opacity: 1, overflowY: 'none' },
    leave: { maxHeight: '0px', opacity: 0 },
  });

  const getOrderList = useCallback(async () => {
    setLoading((prev) => ({ ...prev, listLoading: true }));
    setOrderList([]);

    navigate({
      pathname: location.pathname,
      search: queryString.stringify(search, { skipNull: true }),
    });

    try {
      const companyId = globalCompany?.id;

      const query = {
        PageNumber: pagination.pageNumber,
        PageSize: pagination.pageSize,
        deleted: false,

        startdate:
          search.startdate && search.startdate !== ''
            ? setStartAndEndDate(search.startdate, 'startdate', '0')
            : '',
        enddate:
          search.enddate && search.enddate !== ''
            ? setStartAndEndDate(search.enddate, 'enddate', '0')
            : '',
        ...search,
        companyid: userRoles.includes(UserRoles.ADMIN)
          ? ''
          : companyId
          ? companyId
          : '',
      };

      const list = await orderApi.list(query);
      if (list) {
        setOrderList(list.data.data);
        setPagination({
          pageNumber: list.data.pageNumber,
          pageSize: list.data.pageSize,
          totalRecords: list.data.totalRecords,
        });
      }
    } catch (error) {}
    setLoading(false);
  }, [search, location.pathname, navigate, globalCompany.id]);

  const orderTypeColor = (platform) => {
    if (platform === 0) {
      return 'bg-gray-100 text-gray-800';
    } else if (platform === 10) {
      return 'bg-purple-100 text-purple-800';
    } else if (platform === 20) {
      return 'bg-blue-100 text-blue-800';
    } else {
      return 'bg-green-100 text-green-800';
    }
  };

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  const getCompanyList = async () => {
    try {
      const query = `?pageSize=1000`;
      const list = await companyApi.list(query);
      if (list.data.data.length > 0) {
        setCompanyList(list.data.data);
      }
    } catch (error) {}
  };

  const getStoreList = async () => {
    const companyId = globalCompany?.id;

    const query = userRoles.includes(UserRoles.ADMIN)
      ? ''
      : companyId
      ? `&companyid=${companyId}`
      : '';

    try {
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const onClickSearch = async () => {
    await getOrderList();

    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  };
  const onFormChange = (value, key) => {
    setSearch((prev) => ({ ...prev, [key]: value }));
  };

  const onChange = (dateString) => {
    if (!dateString) {
      setSearch((prev) => ({
        ...prev,
        startdate: '',
        enddate: '',
      }));
      return;
    }

    setSearch((prev) => ({
      ...prev,
      startdate: dateString[0],
      enddate: dateString[1],
    }));
  };

  const onClickClearSearch = () => {
    setSearch({
      search: '',
      companyid: '',
      startdate: '',
      enddate: '',
      storeid: '',
      orderstatusses: [],
      paymentstatus: '',
    });
    getOrderList();
  };

  const onCloseUpsert = () => {
    setDialogs((prev) => ({ ...prev, liveOrdersDialogs: false }));
  };

  const onClickOpenLiveOrdersModal = () => {
    setDialogs((prev) => ({ ...prev, liveOrdersDialogs: true }));
  };

  const onSubmitLiveOrdersModal = async () => {
    if (!selectedStore) {
      setErrors((prev) => ({
        ...prev,
        store: !selectedStore ? true : false,
      }));
      return;
    } else {
      setDialogs((prev) => ({ ...prev, liveOrdersDialogs: false }));
      navigate(`${selectedStore}/live`);
    }
  };

  const onClickRow = (id) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    transRef.start();
  }, [showFilters]);

  useEffect(() => {
    getCompanyList();
    getStoreList();
  }, [globalCompany.id]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800 ">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <div>
              <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
                Orders
              </h1>
              <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
                View and manage all your orders in one place.
              </p>
            </div>

            <div>
              <div>
                <div className="sm:text-end">
                  {userRoles.includes(UserRoles.ADMIN) && (
                    <Button
                      type="ghost"
                      className={classNames(
                        'mt-3 mr-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                      )}
                      onClick={() => navigate(`/dashboard/orders/summary`)}
                    >
                      <DocumentChartBarIcon
                        className="h-5 w-5 mr-1"
                        aria-hidden="true"
                      />
                      Orders Summary
                    </Button>
                  )}
                  <Button
                    type="ghost"
                    className={classNames(
                      'mt-3 mr-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                    )}
                    onClick={onClickOpenLiveOrdersModal}
                  >
                    <PlayCircleIcon
                      className="h-5 w-5 mr-1"
                      aria-hidden="true"
                    />
                    Live Orders
                  </Button>
                  <Button
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                    type="ghost"
                    className={classNames(
                      'mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200'
                    )}
                  >
                    <MagnifyingGlassIcon
                      className="h-5 w-5 mr-1"
                      aria-hidden="true"
                    />
                    Filters
                  </Button>
                </div>
                {transitions(
                  (style, item) =>
                    item && (
                      <animated.div style={{ ...style }}>
                        <div>
                          <div className="pt-2 justify-between pb-2 grid grid-cols-6 gap-4">
                            <div className="col-span-6 sm:col-span-2 w-full ">
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                              >
                                Search
                              </label>
                              <div className="mt-1">
                                <input
                                  value={search.search}
                                  onChange={(e) =>
                                    onFormChange(e.target.value, 'search')
                                  }
                                  type="text"
                                  name="search"
                                  id="search"
                                  className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                  placeholder="Search"
                                />
                              </div>
                            </div>

                            {userRoles.includes(UserRoles.ADMIN) && (
                              <div className="col-span-3 sm:col-span-2 w-full ">
                                <label
                                  htmlFor="company"
                                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                >
                                  Company
                                </label>
                                <div className="mt-1">
                                  <Select
                                    size="large"
                                    style={{
                                      width: '100%',
                                    }}
                                    placeholder="Company"
                                    value={search.companyid}
                                    onChange={(e) => {
                                      setSearch((prev) => ({
                                        ...prev,
                                        companyid: e,
                                      }));
                                    }}
                                  >
                                    <Select.Option value="">
                                      All Company
                                    </Select.Option>
                                    {companyList.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                            )}
                            <div className="col-span-3 sm:col-span-2  w-full ">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                              >
                                Store
                              </label>
                              <div className="mt-1">
                                <Select
                                  size="large"
                                  style={{
                                    width: '100%',
                                  }}
                                  placeholder="Store"
                                  value={search.storeid}
                                  onChange={(e) => {
                                    setSearch((prev) => ({
                                      ...prev,
                                      storeid: e,
                                    }));
                                  }}
                                >
                                  <Select.Option value="">
                                    All Stores
                                  </Select.Option>
                                  {(search.companyid
                                    ? storeList.filter(
                                        (store) =>
                                          store.companyid === search.companyid
                                      )
                                    : storeList
                                  ).map((item) => (
                                    <Select.Option
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3 sm:col-span-2 w-full ">
                              <div className="col-span-3 sm:col-span-2  w-full ">
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                >
                                  Order Status
                                </label>
                                <div className="mt-1">
                                  <Select
                                    size="large"
                                    style={{
                                      width: '100%',
                                    }}
                                    placeholder="Order Status"
                                    value={search.orderstatusses}
                                    onChange={(e) => {
                                      setSearch((prev) => ({
                                        ...prev,
                                        orderstatusses: e,
                                      }));
                                    }}
                                  >
                                    {Object.keys(OrderStatus)
                                      .filter((x) => x !== '40')
                                      .map((key) => (
                                        <Select.Option key={key} value={key}>
                                          {OrderStatus[key]}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </div>
                              </div>
                            </div>
                            {userRoles.includes(UserRoles.ADMIN) && (
                              <div className="col-span-3 sm:col-span-2 w-full ">
                                <div className="col-span-3 sm:col-span-2  w-full ">
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                  >
                                    Payment Status
                                  </label>
                                  <div className="mt-1">
                                    <Select
                                      size="large"
                                      style={{
                                        width: '100%',
                                      }}
                                      placeholder="Payment Status"
                                      value={search.paymentstatus}
                                      onChange={(e) => {
                                        setSearch((prev) => ({
                                          ...prev,
                                          paymentstatus: e,
                                        }));
                                      }}
                                    >
                                      {Object.keys(PaymentStatus).map((key) => (
                                        <Select.Option key={key} value={key}>
                                          {PaymentStatus[key]}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-span-6 sm:col-span-2 w-full  ">
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                              >
                                Date Range
                              </label>
                              <div className="mt-1 w-full">
                                <RangePicker
                                  style={{ width: '100%' }}
                                  size="large"
                                  value={[
                                    search.startdate
                                      ? dayjs(
                                          search.startdate,
                                          'YYYY-MM-DDT00:00:00:000Z'
                                        )
                                      : null,
                                    search.enddate
                                      ? dayjs(
                                          search.enddate,
                                          'YYYY-MM-DDT23:59:59:999Z'
                                        )
                                      : null,
                                  ]}
                                  format="MM/DD/YYYY"
                                  onChange={onChange}
                                  className="dark:bg-slate-800"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="justify-end pb-2 flex gap-4 mt-3">
                            <div className="flex items-center gap-x-5 ">
                              <Button
                                onClick={() => {
                                  onClickClearSearch();
                                }}
                                type="ghost"
                                className={classNames(
                                  'col-span-6',
                                  'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                                )}
                              >
                                <TrashIcon className="text-gray-700 w-5 h-5 mr-2" />
                                Clear
                              </Button>
                              <Button
                                type="ghost"
                                onClick={() => {
                                  onClickSearch();
                                }}
                                className={classNames(
                                  'col-span-6',
                                  'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                                )}
                              >
                                <MagnifyingGlassIcon className="text-white w-5 h-5 mr-2" />
                                Search
                              </Button>
                            </div>
                          </div>
                        </div>
                      </animated.div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
        {loading.listLoading ? (
          <div className="w-full flex justify-center py-4">
            <Spin />
          </div>
        ) : (
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              {orderList.length > 0 ? (
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 md:pl-0 dark:text-slate-200"
                        >
                          Receipt#
                        </th>
                        {userRoles.includes(UserRoles.ADMIN) && (
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 md:pl-0 dark:text-slate-200"
                          >
                            Company
                          </th>
                        )}
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 md:pl-0 dark:text-slate-200"
                        >
                          Customer
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 md:pl-0 dark:text-slate-200"
                        >
                          Details
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Payment Status
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Time
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Order Type
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 "
                        >
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-slate-500">
                      {orderList.map((order, orderIdx) => (
                        <tr
                          className="cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-700"
                          key={orderIdx}
                          onClick={() => {
                            onClickRow(order.id);
                          }}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0 ">
                            {order.ordernumberstr}
                          </td>
                          {userRoles.includes(UserRoles.ADMIN) && (
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0 ">
                              {order.store.company.name}
                            </td>
                          )}
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0 ">
                            <div className="text-slate-900 dark:text-slate-200">
                              {order.name || order.customer?.fullname || '-'}
                            </div>
                            <div className="text-slate-500 font-medium">
                              {formatPhoneNumber(
                                order?.phone || order.customer?.phone
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 dark:text-slate-200 sm:pl-6 md:pl-0 ">
                            <div className="text-slate-900 dark:text-slate-200">
                              {order.ordertype === 10 ? 'Dine-In' : 'To Go'}
                            </div>
                            <div className="text-slate-500 font-medium">
                              {order.store.name}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 dark:text-slate-200">
                            <span
                              className={classNames(
                                paymentStatusColor(order.paymentstatus),
                                'items-center rounded-full px-3 py-0.5 text-sm font-medium'
                              )}
                            >
                              {PaymentStatus[order.paymentstatus]}
                            </span>
                          </td>

                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-slate-200">
                            <div className="text-slate-500 font-medium">
                              <div className="text-slate-900 dark:text-slate-200">
                                {moment(order.createdate)
                                  .utcOffset(order.timezone * 60)
                                  .format('hh:mm A')}
                              </div>
                              <div className="text-slate-500 font-medium">
                                {moment(order.createdate)
                                  .utcOffset(order.timezone * 60)
                                  .format('MMMM Do')}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 dark:text-slate-200">
                            <span
                              className={classNames(
                                orderTypeColor(order.platform),
                                'items-center rounded-full px-3 py-0.5 text-sm font-medium'
                              )}
                            >
                              {PlatformTypes[order.platform]}
                            </span>
                          </td>

                          <td className="whitespace-nowrap py-4 px-3 text-sm font-bold text-gray-500 dark:text-slate-200">
                            {(order.total - order.refund).toLocaleString(
                              'en-US',
                              {
                                style: 'currency',
                                currency: 'USD',
                              }
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500 dark:text-slate-200">
                            <span
                              className={classNames(
                                orderStatusColor(order.orderstatus),
                                'items-center rounded-full px-3 py-0.5 text-sm font-medium'
                              )}
                            >
                              {order.orderstatus === 40 &&
                              order.refund !== order.total
                                ? 'Partial Refund'
                                : OrderStatus[order.orderstatus]}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="w-full justify-between flex mt-4">
                    <div>
                      <p className="text-sm text-gray-700 dark:text-slate-200">
                        Showing{' '}
                        <span className="font-medium">
                          {pagination.pageNumber}
                        </span>{' '}
                        to{' '}
                        <span className="font-medium">
                          {pagination.pageSize}
                        </span>{' '}
                        of{' '}
                        <span className="font-medium">
                          {pagination.totalRecords}
                        </span>{' '}
                        results
                      </p>
                    </div>
                    <Pagination
                      showSizeChanger
                      onChange={(page) =>
                        setSearch((prev) => ({
                          ...prev,
                          PageNumber: page,
                        }))
                      }
                      onShowSizeChange={(current, pageSize) => {
                        setSearch((prev) => ({
                          ...prev,
                          PageSize: pageSize,
                          pageNumber: current,
                        }));
                      }}
                      pageSize={pagination.pageSize}
                      current={pagination.pageNumber}
                      total={pagination.totalRecords}
                    />
                  </div>
                </div>
              ) : (
                <div className="grid justify-center pt-20">
                  <img src={NoOrders} className="h-24 pb-2" alt="" />
                  <p className=" text-slate-400 font-light text-center">
                    No Orders
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={dialogs.liveOrdersDialogs} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-5 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div className="">
                    <label
                      htmlFor="subscription"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Store List
                    </label>
                    <Select
                      size="large"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Please select"
                      onChange={(val) => setSelectedStore(val)}
                      options={storeList.map((x) => ({
                        label: x.name + ` (${x.address})`,
                        value: x.id,
                      }))}
                    />
                    <div className="h-2 mt-1  text-xs">
                      {errors.store && (
                        <p className="text-red-700">Store can not be empty</p>
                      )}
                    </div>
                  </div>

                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    <Button
                      type="ghost"
                      disabled={loading.upsertLoading}
                      className={classNames(
                        ' col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2 sm:text-sm dark:bg-transparent dark:text-slate-200 dark:hover:text-sky-400 dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Close
                    </Button>
                    <Button
                      type="ghost"
                      onClick={() => onSubmitLiveOrdersModal()}
                      className={classNames(
                        'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                    >
                      Next
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Orders;
