import React, { useEffect, useState } from 'react';
import { Spin, Select } from 'antd';
import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
import dashboardApi from '../../services/dashboardApi';
import { setStartAndEndDate } from '../../utils/setStartAndEndDate';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.Ls.en.weekStart = 1;
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Report = () => {
  let navigate = useNavigate();

  const [tabs, setTabs] = useState([
    { name: 'Today', current: true },
    { name: 'Yesterday', current: false },
    { name: 'Week', current: false },
    { name: 'Last Week', current: false },
  ]);
  const [reports, setReports] = useState([]);
  const [liveTimeReports, setLiveTimeReports] = useState([]);
  const [loading, setLoading] = React.useState({
    reportsLoading: true,
    liveTimeLoading: true,
  });
  const [filter, setFilter] = useState({
    startdate: setStartAndEndDate(dayjs().startOf('day'), 'startdate', '0'),
    enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
  });

  const [totalOrdersPieChart, setTotalOrdersPieChart] = useState({
    series: [44, 55, 13, 43, 22],
    chartOptions: {
      chart: {
        width: 380,
        type: 'pie',
      },
      legend: {
        show: false,
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      stroke: {
        colors: ['transparent'],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
          },
        },
      ],
    },
  });
  const [totalSalesPieChart, setTotalSalesPieChart] = useState({
    series: [44, 55, 13, 43, 22],
    chartOptions: {
      chart: {
        width: 380,
        type: 'pie',
      },
      toolbar: {
        show: false,
      },
      legend: {
        show: false,
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      stroke: {
        colors: ['transparent'],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
          },
        },
      ],
    },
  });

  const getReports = async () => {
    try {
      setLoading((prev) => ({ ...prev, reportsLoading: true }));
      const res = await dashboardApi.getReports(filter);
      setReports(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, reportsLoading: false }));
    }
  };

  const getLiveTimeReports = async () => {
    try {
      const body = {
        startdate: setStartAndEndDate(dayjs(0), 'startdate', '0'),
        enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
      };
      const res = await dashboardApi.getReports(body);
      setLiveTimeReports(res.data);
      if (res.data.counts.length > 0) {
        const newData = res.data.counts.map((item) => ({
          ordercount: item.ordercount,
          ordertotal: item.ordertotal,
          companyname: item.companyname || 'No Company Name',
        }));

        const totalOrderChartConfig = {
          series: newData.map((item) => item.ordercount),
          chartOptions: {
            labels: newData.map((item) => item.companyname),
          },
        };

        const totalSalesChartConfig = {
          series: newData.map((item) => parseInt(item.ordertotal)),
          chartOptions: {
            labels: newData.map((item) => item.companyname),
          },
        };

        setTotalOrdersPieChart((prev) => ({
          ...prev,
          ...totalOrderChartConfig,
        }));
        setTotalSalesPieChart((prev) => ({
          ...prev,
          ...totalSalesChartConfig,
        }));
      } else {
        const noDataConfig = {
          series: [100],
          chartOptions: {
            labels: ['No data'],
            noData: {
              text: 'No data',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: 'white',
                fontSize: '14px',
                fontFamily: undefined,
              },
            },
          },
        };

        setTotalOrdersPieChart((prev) => ({ ...prev, ...noDataConfig }));
        setTotalSalesPieChart((prev) => ({ ...prev, ...noDataConfig }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, liveTimeLoading: false }));
    }
  };

  const selectDateFromTabs = (selected) => {
    if (selected) {
      let enddate = dayjs();
      let startdate;
      switch (selected) {
        case 'Today':
          startdate = enddate.startOf('day');
          break;
        case 'Yesterday':
          startdate = enddate.subtract(1, 'day').startOf('day');
          enddate = enddate.subtract(1, 'day').endOf('day');
          break;
        case 'Week':
          startdate = enddate.startOf('week');
          break;
        case 'Last Week':
          startdate = enddate.subtract(1, 'week').startOf('week');
          enddate = enddate.subtract(1, 'week').endOf('week');
          break;
        default:
          break;
      }
      setTabs(tabs.map((tab) => ({ ...tab, current: tab.name === selected })));
      setFilter((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(startdate, 'startdate', '0'),
        enddate: setStartAndEndDate(enddate, 'enddate', '0'),
      }));
    }
  };

  useEffect(() => {
    getReports();
  }, [tabs]);

  useEffect(() => {
    getLiveTimeReports();
  }, []);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-5xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
              Reports
            </h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
              Track your sales metrics and optimize your business performance.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => navigate('/dashboard/reports/company-sales')}
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-900 dark:hover:text-sky-400 dark:border-none dark:text-slate-200"
            >
              Company Sales
            </button>
          </div>
        </div>
        <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
        {loading.reportsLoading ? (
          <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
            <Spin />
          </div>
        ) : (
          <>
            <div className="border-b border-gray-200 pb-5 sm:pb-0 pt-5">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <Select
                    id="current-tab"
                    name="current-tab"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={tabs.find((tab) => tab.current).name}
                    onChange={(val) => selectDateFromTabs(val)}
                    options={tabs.map((tab) => ({
                      value: tab.name,
                      label: tab.name,
                    }))}
                  />
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                          tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          ' cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                        onClick={() => selectDateFromTabs(tab.name)}
                      >
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div>
              <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 dark:divide-slate-500  dark:divide-slate-500 overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
                <div className="px-4 py-5 sm:p-6 dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Gross Sales
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {reports.ordertotal.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Orders
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {reports.ordercount}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Payouts
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {reports.payout.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Profit
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {reports.profit.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </>
        )}
        {loading.liveTimeLoading ? (
          <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
            <Spin />
          </div>
        ) : (
          <>
            <h1 className="pt-10 text-xl font-semibold text-slate-900 dark:text-slate-200">
              Live Time
            </h1>
            <div className="border-b-2 border-gray-200 dark:border-slate-700 border-opacity-50 pt-5" />
            <div>
              <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 dark:divide-slate-500  dark:divide-slate-500 overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
                <div className="px-4 py-5 sm:p-6 dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Live Time Sales
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {liveTimeReports.ordertotal.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                    Live Time Orders
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {liveTimeReports.ordercount}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Avg Tips
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {liveTimeReports.avgtips.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                      {` `}
                      {`(%${liveTimeReports.avgtiprate.toFixed(2)})`}
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                  <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                    Avg Ticket Size
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                      {liveTimeReports.avgticketsize.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-2 pt-10">
              <div
                id="totalOrdersPieChart"
                className=" col-span-2 sm:col-span-1 w-full p-4 shadow-md bg-white dark:bg-slate-700"
              >
                <div className="flex items-center w-full justify-between rounded-lg bg-white dark:bg-slate-800 px-4 py-2 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                  <div className="grid leading-6">
                    <span className="font-bold text-lg dark:text-slate-200">
                      Total Orders
                    </span>
                  </div>
                </div>
                <ReactApexChart
                  options={totalOrdersPieChart.chartOptions}
                  series={totalOrdersPieChart.series}
                  type="pie"
                />
              </div>
              <div
                id="totalSalesPieChart"
                className=" col-span-2 sm:col-span-1 w-full p-4 shadow-md bg-white dark:bg-slate-700"
              >
                <div className="flex items-center w-full justify-between rounded-lg bg-white dark:bg-slate-800 px-4 py-2 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                  <div className="grid leading-6">
                    <span className="font-bold text-lg dark:text-slate-200">
                      Total Sales
                    </span>
                  </div>
                </div>
                <ReactApexChart
                  options={totalSalesPieChart.chartOptions}
                  series={totalSalesPieChart.series}
                  type="pie"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Report;
