import React, { Fragment, useRef } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Upload, Checkbox, Switch } from 'antd';
import {
  TrashIcon,
  ChevronUpIcon,
  MinusCircleIcon,
} from '@heroicons/react/24/outline';
import CurrencyInput from 'react-currency-input-field';
import dayjs from 'dayjs';
import addOnImage from '../../../assets/add-on.svg';
import { Tooltip } from 'react-tooltip';
import { XCircleIcon } from '@heroicons/react/20/solid';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductUpsertModal({
  show,
  onFormChange,
  onFormSubmit,
  onClickDeleteProduct,
  isSingleItem,
  setIsSingleItem,
  onCloseUpsert,
  productForm,
  setProductForm,
  errors,
  loading,
}) {
  const cancelButtonRef = useRef(null);

  const onClickAddItem = () => {
    setProductForm((prev) => ({
      ...prev,
      items: [...prev.items, { name: '', price: '' }],
    }));
  };

  const onItemsChange = (val, key, index) => {
    const newItems = [...productForm.items];
    newItems[index][key] = val;
    setProductForm((prev) => ({
      ...prev,
      items: newItems,
    }));
  };

  const onClickDeleteItemInMultiple = (index) => {
    const newItems = [...productForm.items];
    newItems.splice(index, 1);
    setProductForm((prev) => ({
      ...prev,
      items: newItems,
    }));
  };

  const onFormSingleItemChange = (val, key) => {
    setProductForm((prev) => ({
      ...prev,
      items: [{ ...prev.items[0], [key]: val }],
    }));
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        className="dark:text-slate-200"
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative space-y-1 transform overflow-y-auto min-h-min rounded-lg bg-white dark:bg-slate-800 dark:text-slate-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-2xl sm:p-10">
                  <div>
                    <h1 className="font-semibold mb-5 dark:text-slate-300 border-b">
                      Products
                    </h1>
                    <label
                      htmlFor="image"
                      className="block pb-3 text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Image
                    </label>
                    <div className="mt-1">
                      <>
                        <Upload
                          multiple={false}
                          maxCount={1}
                          name="avatar"
                          onChange={(e) => onFormChange(e.file, 'image')}
                          listType="picture-circle"
                          className="avatar-uploader"
                          beforeUpload={() => false}
                        >
                          {productForm.imagepath ? (
                            <div className="flex relative h-full w-full">
                              <img
                                src={productForm.imagepath}
                                alt="avatar"
                                className="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-full object-cover"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                              />
                            </div>
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                        {productForm.imagepath && (
                          <div className="flex relative h-full w-full">
                            <XCircleIcon
                              onClick={() =>
                                setProductForm((prev) => ({
                                  ...prev,
                                  imagepath: '',
                                  image: null,
                                }))
                              }
                              className="text-gray-500 w-4 h-4 hover:text-gray-900 cursor-pointer "
                            />
                          </div>
                        )}
                      </>
                      <div className="h-2 mt-1  text-xs">
                        {errors.imagepath && (
                          <p className="text-red-700">Image can not be empty</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Product Name*
                    </label>
                    <div className="mt-1">
                      <input
                        value={productForm.name}
                        onChange={(e) => onFormChange(e.target.value, 'name')}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-md dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-slate-500 dark:border-slate-500"
                        placeholder="e.g ''Ceasar Salad, Chef's Burger''"
                      />
                      <div className="h-2 mt-1  text-xs">
                        {errors.name && (
                          <p className="text-red-700">
                            Product Name can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                    >
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        onChange={(e) =>
                          onFormChange(e.target.value, 'description')
                        }
                        value={productForm.description}
                        rows={3}
                        type="text"
                        name="description"
                        id="description"
                        className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-md dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-slate-500 dark:border-slate-500"
                        placeholder="e.g ''Served with chef's special sauce and fries''"
                      />
                    </div>
                  </div>
                  {!productForm.id && (
                    <div className="py-5">
                      <h1 className="font-semibold mb-5 dark:text-slate-300 border-b">
                        Items
                      </h1>
                      <div className="flex space-x-5 pb-2">
                        <h1
                          onClick={() => setIsSingleItem(true)}
                          className={classNames(
                            isSingleItem ? 'border-blue-500' : '',
                            'cursor-pointer border-b-2 inline-block'
                          )}
                        >
                          Single Item
                        </h1>

                        <h1
                          onClick={() => setIsSingleItem(false)}
                          className={classNames(
                            isSingleItem ? '' : 'border-blue-500',
                            'cursor-pointer border-b-2 inline-block'
                          )}
                        >
                          Multiple Item
                        </h1>
                      </div>
                      {isSingleItem ? (
                        <div className="space-y-2 grid grid-cols-2">
                          <div>
                            <label
                              htmlFor="name"
                              className="mt-2 block text-sm font-medium text-gray-700 dark:text-slate-400"
                            >
                              Item Name
                            </label>
                            <div className="mt-1">
                              <p>{productForm.name}</p>
                            </div>
                          </div>
                          <div className="">
                            <label
                              htmlFor="price"
                              className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
                            >
                              Price
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <CurrencyInput
                                id="input-price"
                                name="price"
                                placeholder="0.00"
                                className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                prefix="$"
                                value={productForm?.items[0]?.price}
                                decimalSeparator="."
                                groupSeparator=","
                                decimalScale={2}
                                step={1}
                                defaultValue={0}
                                decimalsLimit={2}
                                onValueChange={(value, name, values) =>
                                  onFormSingleItemChange(value, name)
                                }
                              />
                            </div>
                            {errors.items[0].price && (
                              <div className="h-2 mt-1  text-xs">
                                <p className="text-red-700">
                                  Price name can not be empty
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          {productForm.items?.map((item, index) => {
                            return (
                              <div className="grid grid-cols-12 space-x-5">
                                <div className="col-span-7">
                                  <label
                                    htmlFor="name"
                                    className="mt-2  block text-sm font-medium text-gray-700 dark:text-slate-400"
                                  >
                                    Item Name
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      value={item.name}
                                      onChange={(e) =>
                                        onItemsChange(
                                          e.target.value,
                                          'name',
                                          index
                                        )
                                      }
                                      type="text"
                                      name="name"
                                      id="name"
                                      className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                      placeholder="e.g ''Chicken,beef'' or ''Small, Medium''"
                                    />
                                    <div className="h-2 mt-1  text-xs">
                                      {errors.items[index]?.name && (
                                        <p className="text-red-700">
                                          Item name can not be empty
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-4">
                                  <label
                                    htmlFor="price"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Price
                                  </label>
                                  <div className="relative mt-2 rounded-md shadow-sm">
                                    <CurrencyInput
                                      id="input-price"
                                      name="price"
                                      placeholder="0.00"
                                      className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                      prefix="$"
                                      value={item.price}
                                      decimalSeparator="."
                                      groupSeparator=","
                                      decimalScale={2}
                                      step={1}
                                      defaultValue={0}
                                      decimalsLimit={2}
                                      onValueChange={(value, name, values) =>
                                        onItemsChange(value, name, index)
                                      }
                                    />
                                  </div>
                                  {errors.items[index]?.price && (
                                    <div className="h-2 mt-1  text-xs">
                                      <p className="text-red-700">
                                        Price can not be empty
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="pt-4 col-span-1  self-center justify-self-center">
                                  <MinusCircleIcon
                                    onClick={() =>
                                      onClickDeleteItemInMultiple(index)
                                    }
                                    className="text-red-500 mr-3 w-5 h-5 hover:text-red-900"
                                  />
                                </div>
                              </div>
                            );
                          })}
                          <div className="col-span-12">
                            <p className="font-normal text-xs text-gray-500 dark:text-slate-400">
                              Customize your items with options like "chicken,
                              beef, lamb" or "small, medium, and large." For
                              items without customizations, simply enter the
                              item name.
                            </p>
                          </div>

                          <div className="mt-5 sm:mt-6 sm:grid z-10 sm:grid-flow-row-dense sm:grid-cols-4 sm:gap-3">
                            <Button
                              type="ghost"
                              disabled={loading.upsertLoading}
                              loading={loading.upsertLoading}
                              className={classNames(
                                'col-span-1 col-start-1',
                                'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                              )}
                              onClick={() => onClickAddItem()}
                            >
                              + Add Item
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div>
                    <h1 className="font-semibold my-2 dark:text-slate-300 border-b">
                      Product Settings
                    </h1>
                    <div className="flex items-center sm:pt-5">
                      <Tooltip id="isupsell" />
                      <Checkbox
                        data-tooltip-id="isupsell"
                        data-tooltip-html="When customers are checking out, they will be presented
                      with add-on item suggestions. You can select up to four
                      add-on items."
                        className="flex"
                        checked={productForm.isupsell}
                        onChange={(e) =>
                          onFormChange(e.target.checked, 'isupsell')
                        }
                      ></Checkbox>
                      <div className="flex pl-2 items-center">
                        <img
                          src={addOnImage}
                          className="w-5 h-5"
                          alt="addOnItem"
                        />
                        <p className="text-sm font-medium text-gray-700 dark:text-slate-300">
                          Make Upsell Item
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <p className="font-normal text-xs text-gray-500 dark:text-slate-400">
                      When customers are checking out, they will be presented
                      with add-on item suggestions. You can select up to four
                      add-on items.
                    </p>
                  </div>
                  <div className="w-full pt-5">
                    <div className="bg-white dark:bg-slate-800">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full items-center justify-start rounded-lg bg-white dark:bg-slate-800 py-2 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                              <div className="grid leading-6 pr-2">
                                <span className=" font-semibold text-md dark:text-slate-300">
                                  Advanced Settings
                                </span>
                              </div>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-black dark:text-slate-200`}
                              />
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel className="pt-4 text-sm flex gap-x-5">
                                <div>
                                  <div className="flex">
                                    <div className="pr-8 w-[120px]">
                                      <label
                                        htmlFor="default"
                                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                      >
                                        Use Tax
                                      </label>
                                      <div className="mt-2">
                                        <Switch
                                          checked={productForm.usetax}
                                          onChange={(e) => {
                                            onFormChange(e, 'usetax');
                                          }}
                                          className={classNames(
                                            productForm.usetax
                                              ? 'bg-blue-600'
                                              : 'bg-gray-200',
                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                                          )}
                                        >
                                          <span
                                            aria-hidden="true"
                                            className={classNames(
                                              productForm.usetax
                                                ? 'translate-x-5'
                                                : 'translate-x-0',
                                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                          />
                                        </Switch>
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="tax"
                                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                      >
                                        Different Tax
                                      </label>
                                      <div className="mt-1">
                                        <CurrencyInput
                                          id="input-price"
                                          name="tax"
                                          placeholder="0.00"
                                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                          prefix="%"
                                          disabled={!productForm.usetax}
                                          value={productForm.tax}
                                          decimalSeparator="."
                                          groupSeparator=","
                                          decimalScale={2}
                                          step={1}
                                          defaultValue={0}
                                          decimalsLimit={2}
                                          onValueChange={(value, name) =>
                                            onFormChange(value, 'tax')
                                          }
                                        />
                                        <div className="h-2 mt-1  text-xs">
                                          {errors.tax && (
                                            <p className="text-red-700">
                                              Please enter valid tax
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <div className="pr-8  w-[120px]">
                                      <label
                                        htmlFor="default"
                                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                      >
                                        Use CRV Tax
                                      </label>
                                      <div className="mt-2">
                                        <Switch
                                          checked={productForm.usecrvtax}
                                          onChange={(e) => {
                                            onFormChange(e, 'usecrvtax');
                                          }}
                                          className={classNames(
                                            productForm.usecrvtax
                                              ? 'bg-blue-600'
                                              : 'bg-gray-200',
                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                                          )}
                                        >
                                          <span
                                            aria-hidden="true"
                                            className={classNames(
                                              productForm.usecrvtax
                                                ? 'translate-x-5'
                                                : 'translate-x-0',
                                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                          />
                                        </Switch>
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="tax"
                                        className="block text-sm font-medium text-gray-700 dark:text-slate-400"
                                      >
                                        CRV Tax
                                      </label>
                                      <div className="mt-1">
                                        <CurrencyInput
                                          id="input-price"
                                          name="crvtaxrate"
                                          placeholder="0.00"
                                          className="block w-full appearance-none rounded-md border dark:text-slate-200 dark:bg-slate-800 border-gray-300 px-3 py-2 placeholder-black placeholder-opacity-25  focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm dark:focus:border-sky-400 dark:focus:ring-sky-400 dark:placeholder:text-white dark:border-slate-500"
                                          prefix="$"
                                          disabled={!productForm.usecrvtax}
                                          value={productForm.crvtaxrate}
                                          decimalSeparator="."
                                          groupSeparator=","
                                          decimalScale={2}
                                          step={1}
                                          defaultValue={0}
                                          decimalsLimit={2}
                                          onValueChange={(
                                            value,
                                            name,
                                            values
                                          ) => onFormChange(value, name)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                  <div className="h-2 mt-1  text-xs"></div>
                  <div className="mt-5 sm:mt-6 grid z-10 sm:grid-flow-row-dense grid-cols-12 gap-3 py-4 sm:py-0">
                    {productForm.id && (
                      <Button
                        type="ghost"
                        disabled={loading}
                        loading={loading}
                        onClick={() => onClickDeleteProduct(productForm)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 dark:bg-red-900 px-0 sm:px-4 h-10 items-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-transparent focus:ring-offset-2 col-span-2 sm:text-sm"
                      >
                        <TrashIcon className="text-white w-5 h-5" />
                      </Button>
                    )}
                    <Button
                      type="ghost"
                      disabled={loading}
                      className={classNames(
                        productForm.id ? 'col-span-5' : 'col-span-6',
                        'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 h-10 items-center   text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-transparent focus:ring-blue-500 focus:ring-offset-2  sm:mb-0 sm:mt-0 mb-3 sm:text-sm dark:bg-slate-200'
                      )}
                      onClick={() => onCloseUpsert()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="ghost"
                      disabled={loading}
                      loading={loading}
                      className={classNames(
                        productForm.id ? 'col-span-5' : 'col-span-6',
                        'inline-flex w-full justify-center h-10 items-center rounded-md border-none bg-blue-600 dark:bg-slate-900 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0 hover:border-none hover:text-white dark:hover:text-sky-400 focus:ring-transparent focus:ring-offset-0'
                      )}
                      onClick={() => onFormSubmit()}
                    >
                      {productForm.id ? 'Edit Product' : 'Add Product'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
