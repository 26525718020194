import api from "./api";

const resource = "Menu/";

export const list = async (query = "") => {
  return await api.get(resource + "List" + query + "&PageSize=1000");
};

export const getById = async (id) => {
  return await api.get(resource + "Get?id=" + id);
};

export const upsert = async (body) => {
  return await api.post(resource + "Upsert", body);
};

export const upsertHours = async (body) => {
  return await api.post(resource + "UpsertMenuHour", body);
};

export const deleteById = async (id) => {
  return await api.delete(resource + "Delete?id=" + id);
};

export const getFullMenu = async (body) => {
  return await api.get(resource + "GetFullMenu", body);
};

const menuApi = { list, getById, upsert, deleteById, getFullMenu, upsertHours };

export default menuApi;
