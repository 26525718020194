import api from './api';

const resource = 'Store/';

export const list = async (query = '') => {
  return await api.get(resource + 'List?PageSize=1000&' + query);
};

export const getById = async (id) => {
  return await api.get(resource + 'Get?id=' + id);
};

export const getByCompanyId = async (companyid) => {
  return await api.get(resource + 'List?companyid=' + companyid);
};

export const upsert = async (body, config) => {
  return await api.post(resource + 'Upsert', body, config);
};

export const deleteById = async (id) => {
  return await api.delete(resource + 'Delete?id=' + id);
};

export const deleteStoreLogoById = async (storeid) => {
  return await api.delete(resource + 'DeleteStoreLogo?storeid=' + storeid);
};

export const deleteTerminalLogoById = async (storeid) => {
  return await api.delete(resource + 'DeleteTerminalLogo?storeid=' + storeid);
};
export const upsertHours = async (body) => {
  return await api.post(resource + 'UpsertMenuHour', body);
};
export const upsertTierPrice = async (body) => {
  return await api.post(resource + 'UpsertTierPrice', body);
};

const storeApi = {
  list,
  getById,
  upsert,
  deleteById,
  getByCompanyId,
  deleteStoreLogoById,
  deleteTerminalLogoById,
  upsertHours,
  upsertTierPrice,
};

export default storeApi;
