import api from './api';

const resource = 'Role/';

export const roleList = async () => {
  return await api.get(resource + 'Role/List');
};

export const getRoleById = async (id) => {
  return await api.get(resource + 'Role/Get?id=' + id);
};

export const upsertRole = async (body) => {
  return await api.post(resource + 'Role/Upsert', body);
};

export const deleteRoleById = async (id) => {
  return await api.delete(resource + 'Role/Delete?id=' + id);
};

export const permissionList = async () => {
  return await api.get(resource + 'Permission/List?pageSize=100');
};

export const getPermissionById = async () => {
  return await api.get(resource + 'Permission/Get');
};

export const addPermissionToRole = async (roleId, permissionId) => {
  return await api.post(
    resource +
      `AddPermissionToRole?roleId=${roleId}&permissionId=${permissionId}`
  );
};
export const removePermissionToRole = async (roleId, permissionId) => {
  return await api.post(
    resource +
      `RemovePermissionToRole?roleId=${roleId}&permissionId=${permissionId}`
  );
};

const roleApi = {
  roleList,
  getRoleById,
  upsertRole,
  deleteRoleById,
  permissionList,
  getPermissionById,
  addPermissionToRole,
  removePermissionToRole,
};

export default roleApi;
