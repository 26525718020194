import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
  HomeIcon,
  BanknotesIcon,
  XMarkIcon,
  Cog8ToothIcon,
  Squares2X2Icon,
  ListBulletIcon,
  Square2StackIcon,
  CurrencyDollarIcon,
  BuildingStorefrontIcon,
  ChartPieIcon,
  UserGroupIcon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/outline';
import { Link, Outlet, useLocation } from 'react-router-dom';
import LogoBlack from '../../assets/logo-black.png';
import LogoWhite from '../../assets/logo-white-brand.svg';
import tokenService from '../../utils/tokenService';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../store/slices/authSlice';
import userApi from '../../services/userApi';
import { Spin } from 'antd';
import CompanyChanger from '../company-changer/company-changer';
import companyApi from '../../services/companyApi';
import settingsApi from '../../services/settingsApi';
import { setCompany, setGlobalCompany } from '../../store/slices/companySlice';

const adminPanelMenu = [
  {
    name: 'Dashboard',
    href: '/dashboard',
    icon: HomeIcon,
    roles: [100, 150, 200, 250],
    permissions: ['dashboard-get'],
  },
  {
    name: 'Companies',
    href: 'companies',
    icon: Square2StackIcon,
    roles: [100],
    permissions: ['company-list'],
  },
  {
    name: 'Orders',
    href: 'orders',
    icon: ListBulletIcon,
    roles: [100, 150, 200, 250],
    permissions: ['order-list'],
  },
  {
    name: 'Customers',
    href: 'customers',
    icon: UserGroupIcon,
    roles: [100],
    permissions: ['customer-list'],
  },
  {
    name: 'Kiosks',
    href: 'kiosks',
    icon: ComputerDesktopIcon,
    roles: [100],
    permissions: ['terminal-list'],
  },
  {
    name: 'Finance',
    href: 'finance',
    icon: BanknotesIcon,
    roles: [100, 150, 200],
    permissions: ['finance-list'],
  },
  {
    name: 'Reports',
    href: 'reports',
    icon: ChartPieIcon,
    roles: [100],
    permissions: ['dashboard-get'],
  },
  {
    name: 'Settings',
    href: 'settings',
    icon: Cog8ToothIcon,
    children: [
      {
        name: 'Profile',
        href: 'settings/profile',
        roles: [100, 150, 200, 250, 300],
        permissions: ['user-get'],
      },
      {
        name: 'Permissions',
        href: 'settings/permissions',
        roles: [100],
        permissions: ['permission-list'],
      },
      {
        name: 'Users',
        href: 'settings/users',
        roles: [100, 150, 200],
        permissions: ['user-list'],
      },
      {
        name: 'Cities',
        href: 'settings/cities',
        roles: [100],
        permissions: ['city-list'],
      },
      {
        name: 'Subscriptions',
        href: 'settings/subscriptions',
        roles: [100],
        permissions: ['subscription-list'],
      },
      {
        name: 'Templates',
        href: 'settings/templates',
        roles: [100],
        permissions: ['setting-list'],
      },
      {
        name: 'Finance Types',
        href: 'settings/finance-types',
        roles: [100],
        permissions: ['financetype-list'],
      },
      {
        name: 'Preferences',
        href: 'settings/preferences',
        roles: [100, 150, 200, 250, 300],
        permissions: ['bankaccount-list'],
      },
      {
        name: 'Terms & Conditions',
        href: 'settings/terms-and-conditions',
        roles: [100],
        permissions: ['term-upsert'],
      },
      {
        name: 'Kiosk',
        href: 'https://thisway.esper.cloud/dashboard',
        roles: [100],
        permissions: ['term-upsert'],
      },
    ],
  },
];

const dashboardMenu = [
  {
    name: 'Dashboard',
    href: '/dashboard',
    icon: HomeIcon,
    roles: [100, 150, 200, 250],
    permissions: ['dashboard-get'],
  },
  {
    name: 'Orders',
    href: 'orders',
    icon: ListBulletIcon,
    roles: [100, 150, 200, 250],
    permissions: ['order-list'],
  },
  {
    name: 'Menus',
    href: 'menus',
    icon: Squares2X2Icon,
    roles: [150, 200, 250, 300],
    permissions: ['menu-list'],
  },
  {
    name: 'Stores',
    href: 'stores',
    icon: BuildingStorefrontIcon,
    roles: [150, 200],
    permissions: ['store-delete'],
  },
  {
    name: 'Finance',
    href: 'finance',
    icon: BanknotesIcon,
    roles: [100, 150, 200],
    permissions: ['finance-list'],
  },
  {
    name: 'Reports',
    href: 'reports',
    icon: ChartPieIcon,
    roles: [150, 200],
    permissions: ['dashboard-get'],
  },
  {
    name: 'Coupons',
    href: 'coupons',
    icon: CurrencyDollarIcon,
    roles: [150, 200],
    permissions: ['discount-list'],
  },
  {
    name: 'Settings',
    href: 'settings',
    icon: Cog8ToothIcon,
    children: [
      {
        name: 'Profile',
        href: 'settings/profile',
        roles: [100, 150, 200, 250, 300],
        permissions: ['user-get'],
      },
      {
        name: 'Company',
        href: `companies`,
        roles: [150],
        permissions: ['company-delete'],
      },
      {
        name: 'Users',
        href: 'settings/users',
        roles: [100, 150, 200],
        permissions: ['user-list'],
      },
      {
        name: 'Subscription',
        href: 'settings/subscription',
        roles: [150, 200],
        permissions: ['subscription-get'],
      },
      {
        name: 'Bank Account',
        href: 'settings/bank-accounts',
        roles: [150, 200],
        permissions: ['bankaccount-list'],
      },
      {
        name: 'Preferences',
        href: 'settings/preferences',
        roles: [100, 150, 200, 250, 300],
        permissions: ['menu-list'],
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Dashboard = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const isDark = useSelector((state) => state.commonReducer.isDark);
  const userRoles = useSelector((state) => state.authReducer.roles);
  const userPermissions = useSelector(
    (state) => state.authReducer.rolepermissions
  );
  const selectedCompany = useSelector((state) => state.companyReducer.selected);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [sidebar, setSidebar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(
    location.pathname.split('dashboard/').length > 1
      ? location.pathname.split('dashboard/')[1]
      : '/dashboard'
  );

  const [settings, setSettings] = useState([]);
  const getDetail = async () => {
    setLoading(true);
    try {
      const res = await userApi.getUserDetail();
      dispatch(loginSuccess(res.data.data));
    } catch (error) {
      console.log(error.response.data);
    } finally {
    }
  };

  const getCompanyList = async () => {
    try {
      const compId = tokenService.getComp();
      const list = await companyApi.list();
      if (list.data.data.length > 0 && !userRoles.includes(100)) {
        dispatch(setCompany(list.data.data));
        dispatch(
          setGlobalCompany(compId ? JSON.parse(compId) : list.data.data[0])
        );
      } else {
        dispatch(setCompany([]));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSettingsList = async () => {
    try {
      const list = await settingsApi.list();
      if (list.data.data.length > 0 && !userRoles.includes(100)) {
        setSettings(list.data.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const calculateFreeTrialDate = (trialEnd) => {
    const today = new Date();
    const endDate = new Date(trialEnd);
    const diffTime = endDate - today;
    if (diffTime > 0) {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return `Trial Expires in ${diffDays} ${diffDays > 1 ? 'days' : 'day'}`;
    } else {
      return 'Your trial has expired';
    }
  };

  /* const sidebarByRoles = (roles) => {
    const filteredNavigation = navigation
      .map((item) => {
        if (item.children) {
          const filteredChildren = item.children.filter((child) =>
            roles?.some((role) => child.roles.includes(role))
          );
          return filteredChildren.length
            ? { ...item, children: filteredChildren, hidden: false }
            : { ...item, hidden: true };
        } else {
          return roles?.some((role) => item.roles.includes(role))
            ? { ...item, hidden: false }
            : { ...item, hidden: true };
        }
      })
      .filter((item) => !item.hidden);
    setCurrent('/dashboard');
    setIsSubMenuOpen(false);
    setSidebar(filteredNavigation);
  }; */

  const sidebarByRolePermissions = (permissions, userRoles) => {
    const sidebarMenu = userRoles?.some((role) => role === 100)
      ? adminPanelMenu
      : dashboardMenu;

    const filteredNavigation = sidebarMenu
      .map((item) => {
        if (item.children) {
          const filteredChildren = item.children.filter((child) =>
            permissions?.some((role) => child.permissions.includes(role))
          );
          return filteredChildren.length
            ? { ...item, children: filteredChildren, hidden: false }
            : { ...item, hidden: true };
        } else {
          return permissions?.some((role) => item.permissions.includes(role))
            ? { ...item, hidden: false }
            : { ...item, hidden: true };
        }
      })
      .filter((item) => !item.hidden);
    setCurrent('/dashboard');
    setIsSubMenuOpen(false);
    setSidebar(filteredNavigation);
  };

  useEffect(() => {
    sidebarByRolePermissions(userPermissions, userRoles);
    getDetail();
    getSettingsList();
    getCompanyList();
  }, [tokenService.getLocalAccessToken(), userPermissions]);

  return loading ? (
    <div className="w-full flex h-screen justify-center items-center">
      <Spin />
    </div>
  ) : (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:bg-slate-800 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:focus:ring-black"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white dark:text-sky-400"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={isDark ? LogoWhite : LogoBlack}
                    alt="Your Company"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {sidebar.map((item, itemIdx) =>
                      !item.children ? (
                        <Link
                          key={itemIdx}
                          to={item.href}
                          onClick={() => {
                            setCurrent(item.href);
                            setSidebarOpen(false);
                          }}
                          className={classNames(
                            current === item.href
                              ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-sky-400'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-slate-500 dark:hover:text-slate-200 dark:hover:bg-slate-700',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              current === item.href
                                ? 'text-gray-500 dark:text-sky-400'
                                : 'text-gray-400 group-hover:text-gray-500 dark:text-slate-700 dark:group-hover:text-slate-200',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ) : (
                        <div as="div" key={item.name} className="space-y-1">
                          <>
                            <Link
                              onClick={() => {
                                setCurrent(item.href);
                                setIsSubMenuOpen(!isSubMenuOpen);
                              }}
                              key={itemIdx}
                              className={classNames(
                                current === item.href
                                  ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-sky-400'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-slate-500 dark:hover:text-slate-200 dark:hover:bg-slate-700',
                                'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
                              )}
                            >
                              <item.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.name}</span>
                              <svg
                                className={classNames(
                                  isSubMenuOpen
                                    ? 'text-gray-400 rotate-90'
                                    : 'text-gray-300',
                                  'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M6 6L14 10L6 14V6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Link>
                            {isSubMenuOpen && (
                              <div className="space-y-1">
                                {item.children.map((subItem, subItemIdx) => (
                                  <Link
                                    key={subItemIdx}
                                    to={subItem.href}
                                    onClick={() => {
                                      setCurrent(subItem.href);
                                      setSidebarOpen(false);
                                    }}
                                    className={classNames(
                                      current === subItem.href
                                        ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-sky-400'
                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-slate-500 dark:hover:text-slate-200 dark:hover:bg-slate-700',
                                      'group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                    )}
                                  >
                                    {subItem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        </div>
                      )
                    )}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-10">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 dark:border-slate-700  bg-white dark:bg-slate-900 pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <img
              className="h-8 w-auto"
              src={isDark ? LogoWhite : LogoBlack}
              alt="Your Company"
            />
          </div>
          <div className="mt-5 flex flex-grow flex-col">
            <nav className="flex-1 space-y-1 px-2 pb-4">
              {sidebar.map((item, itemIdx) =>
                !item.children ? (
                  <Link
                    key={itemIdx}
                    to={item.href}
                    onClick={() => setCurrent(item.href)}
                    className={classNames(
                      current === item.href
                        ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-sky-400'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-slate-500 dark:hover:text-slate-200 dark:hover:bg-slate-700',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        current === item.href
                          ? 'text-gray-500 dark:text-sky-400'
                          : 'text-gray-400 group-hover:text-gray-500 dark:text-slate-700 dark:group-hover:text-slate-200',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ) : (
                  <div as="div" key={item.name} className="space-y-1">
                    <>
                      <Link
                        onClick={() => {
                          setCurrent(item.href);
                          setIsSubMenuOpen(!isSubMenuOpen);
                        }}
                        key={itemIdx}
                        className={classNames(
                          current === item.href
                            ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-sky-400'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-slate-500 dark:hover:text-slate-200 dark:hover:bg-slate-700',
                          'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            current === item.href
                              ? 'text-gray-500 dark:text-sky-400'
                              : 'text-gray-400 group-hover:text-gray-500 dark:text-slate-700 dark:group-hover:text-slate-200',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                        <svg
                          className={classNames(
                            isSubMenuOpen
                              ? 'text-gray-400 dark:text-sky-400 rotate-90'
                              : 'text-gray-300 dark:text-slate-600',
                            'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Link>
                      {isSubMenuOpen && (
                        <div className="space-y-1">
                          {item.children.map((subItem, subItemIdx) => (
                            <Link
                              key={subItemIdx}
                              to={subItem.href}
                              onClick={() => setCurrent(subItem.href)}
                              className={classNames(
                                current === subItem.href
                                  ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-sky-400'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-slate-500 dark:hover:text-slate-200 dark:hover:bg-slate-700',
                                'group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                              )}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </>
                  </div>
                )
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 h-screen flex-col md:pl-64 ">
        <div className="sticky top-0 z-10 flex h-[8vh] flex-shrink-0 dark:border-b dark:border-slate-800 bg-white dark:bg-slate-900 shadow">
          <button
            type="button"
            className="border-r border-gray-200 dark:border-slate-700  px-4 text-gray-500  focus:outline-none focus:ring-0 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4 ">
            <div className="flex flex-1 dark:bg-slate-900"></div>
            <div className="ml-4 flex items-center space-x-4 md:ml-2">
              {userRoles.includes(150) &&
                !selectedCompany.subscription &&
                selectedCompany.trialend && (
                  <Link to={'settings/subscription'}>
                    <div className="flex flex-col items-end">
                      <span className="text-sm dark:text-slate-500">
                        {selectedCompany.trialend &&
                          calculateFreeTrialDate(selectedCompany.trialend)}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-sky-400">
                        Update Now
                      </span>
                    </div>
                  </Link>
                )}
              <CompanyChanger />
            </div>
          </div>
        </div>

        <main className="flex-1 dark:text-slate-200 bg-white dark:bg-slate-800">
          <div className="">
            <div className="mx-auto w-full ">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
