import React, { useState, useEffect } from 'react';
import { Spin, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import WeekSlider from '../../components/week-slider/week-slider';
import ReactApexChart from 'react-apexcharts';
import storeApi from '../../services/storeApi';
import dashboardApi from '../../services/dashboardApi';
import bankAccountApi from '../../services/bankAccountApi';
import terminalApi from '../../services/terminalApi';
import { useSelector } from 'react-redux';
import NoData2 from '../../assets/no-data-2.svg';
import NoItems from '../../assets/no-items.svg';
import Alert from '../../components/alert/alert';
import moment from 'moment';
import companyApi from '../../services/companyApi';
import { isEmptyObject } from '../../utils/isEmptyObject';
import { setStartAndEndDate } from '../../utils/setStartAndEndDate';
import { ShoppingBagIcon, BanknotesIcon } from '@heroicons/react/24/solid';
import {
  ShoppingBagIcon as ShoppingBagIconOutline,
  BanknotesIcon as BanknotesIconOutline,
  BarsArrowUpIcon,
  SquaresPlusIcon,
  CursorArrowRaysIcon,
  ChartBarIcon,
  ReceiptPercentIcon,
} from '@heroicons/react/24/outline';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);
dayjs.Ls.en.weekStart = 1;

const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
dayjs.extend(utc);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Dashboard = () => {
  const reduxCompany = useSelector((state) => state.companyReducer.selected);
  const userRoles = useSelector((state) => state.authReducer.roles);
  const [isShowCustomRange, setIsShowCustomRange] = useState(false);
  const isAdmin = userRoles.includes(100);
  const [globalCompany, setGlobalCompany] = useState(reduxCompany);
  const [search, setSearch] = useState({
    startdate: setStartAndEndDate(dayjs().startOf('week'), 'startdate', '0'),
    enddate: setStartAndEndDate(dayjs(), 'enddate', '0'),
    storeids: [],
  });
  const [weekRange, setWeekRange] = useState({
    startOfWeek: setStartAndEndDate(dayjs().startOf('week'), 'startdate', '0'),
    endOfWeek: setStartAndEndDate(dayjs().endOf('week'), 'enddate', '0'),
  });
  const [weekRangeData, setWeekRangeData] = useState([]);
  const [weekRangeChartType, setWeekRangeChartType] = useState('Sales');
  const [top10Type, setTop10Type] = useState('Sales');
  const [orderInformations, setOrderInformations] = useState({
    orderTotal: 0,
    orderCount: 0,
    orderRefundTotal: 0,
    orderDaily: [],
    orderTop10: [],
    orderCountByTerminal: [],
    totalClickCount: 0,
    clickCount: 0,
  });
  const [notf, setNotf] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [companyList, setCompanyList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);
  const [loading, setLoading] = useState({
    initialLoading: true,
    storeListLoading: true,
    companyListLoading: true,
    upsertLoading: false,
    deleteLoading: false,
    clickCountLoading: false,
    graphicLoading: true,
  });
  const [pieChart, setPieChart] = useState({
    series: [44, 55, 13, 43, 22],
    chartOptions: {
      chart: {
        width: 380,
        type: 'pie',
      },
      legend: {
        position: 'bottom',
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      stroke: {
        colors: ['transparent'],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
          },
        },
      ],
    },
  });

  const [chart, setChart] = useState({
    series: [
      {
        name: 'Total Sales',
        data: [],
      },
      {
        name: 'Total Orders',
        data: [],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          show: true,
          formatter: function (value) {
            return value?.toFixed(1);
          },
        },
      },
      xaxis: {
        type: 'date',
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        labels: {
          style: {
            fontSize: '0.875rem',
            colors: '#6B7280',
            lineHeight: '1.25rem',
          },
        },
      },
      tooltip: {
        x: {
          format: 'MM/DD/YYYY',
        },
      },
    },
  });

  const dateOptions = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'weekToD‌​ate', label: 'Week to Date' },
    { value: 'last7Days', label: 'Last 7 Days' },
    { value: 'last14Days', label: 'Last 14 Days' },
    { value: 'last21Days', label: 'Last 21 Days' },
    { value: 'last28Days', label: 'Last 28 Days' },
    { value: 'last30Days', label: 'Last 30 Days' },
    { value: 'monthToD‌​ate', label: 'Month to Date' },
    { value: 'last60Days', label: 'Last 60 Days' },
    { value: 'customRange', label: 'Custom Range' },
  ];

  const getStoreList = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        storeListLoading: true,
      }));
      const query = globalCompany.id ? `companyid=${globalCompany.id}` : '';
      const list = await storeApi.list(query);
      if (list.data.data.length > 0) {
        setStoreList(list.data.data);
      } else {
        setStoreList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
      setStoreList([]);
    }
  };
  const getCompanyList = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        companyListLoading: true,
        initialLoading: true,
      }));
      const query = '?PageSize=1000';
      const list = await companyApi.list(query);
      if (list.data.data.length > 0) {
        setCompanyList([...list.data.data]);
      } else {
        setCompanyList([]);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
      setCompanyList([]);
    }
  };
  const getBankAccount = async () => {
    try {
      const query = `?companyid=${globalCompany.id}&PageSize=1`;
      const account = await bankAccountApi.list(query);
      if (account.data.data.length > 0) {
        setBankAccount(account.data.data);
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderTotal = async () => {
    try {
      const query = { ...search, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderTotal(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderTotal: list.data.data,
        }));
        setLoading((prev) => ({ ...prev, initialLoading: false }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderTotal: 0,
        }));
        setLoading((prev) => ({ ...prev, initialLoading: false }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderCountByTerminal = async () => {
    try {
      const query = { ...search, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderCountByTerminal(query);
      if (list.data.data.length > 0) {
        setOrderInformations((prev) => ({
          ...prev,
          orderCountByTerminal: list.data.data,
        }));
        setPieChart((prev) => ({
          ...prev,
          series: list.data.data.map((item) => item.count),
          chartOptions: {
            ...prev.chartOptions,
            labels: list.data.data.map((item) => item.terminal),
          },
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderCountByTerminal: [],
        }));
        setPieChart((prev) => ({
          ...prev,
          series: [100],
          chartOptions: {
            ...prev.chartOptions,
            labels: ['No data'],
            noData: {
              text: 'No data',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: 'white',
                fontSize: '14px',
                fontFamily: undefined,
              },
            },
          },
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderCount = async () => {
    try {
      const query = { ...search, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderCount(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderCount: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderRefunds = async () => {
    try {
      const query = { ...search, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderRefundTotal(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderRefundTotal: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderRefundTotal: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrderTop10 = async () => {
    try {
      const query = { ...search, companyid: globalCompany.id };
      const list = await dashboardApi.getOrderTop10(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          orderTop10: list.data.data,
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          orderTop10: [],
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const getOrdersWeekRange = async () => {
    setLoading((prev) => ({
      ...prev,
      graphicLoading: true,
    }));

    try {
      const query = {
        companyid: globalCompany.id,
        startdate: setStartAndEndDate(weekRange.startOfWeek, 'startdate', '0'),
        enddate: setStartAndEndDate(weekRange.endOfWeek, 'enddate', '0'),
      };

      let list = await dashboardApi.getOrdersDaily(query);

      if (list.data.data) {
        const today = dayjs();

        list.data.data = list.data.data.map((entry) => {
          if (dayjs(entry.date).isAfter(today)) {
            return { date: entry.date, total: null, qty: null };
          }
          return entry;
        });

        setWeekRangeData(list.data.data);
        setChart((prev) => ({
          ...prev,
          series: [
            {
              name: weekRangeChartType,
              data: list.data.data.map(
                (item) => item[weekRangeChartType === 'Sales' ? 'total' : 'qty']
              ),
            },
          ],
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
            },
            yaxis: {
              ...prev.options.yaxis,
              labels: {
                formatter: function (value) {
                  return weekRangeChartType === 'Sales'
                    ? `$${value?.toFixed(0)}`
                    : `${
                        value >= 1 || value === 0
                          ? value?.toFixed(0)
                          : value?.toFixed(1)
                      } Items`;
                },
              },
            },
            tooltip: {
              ...prev.options.tooltip,
              y: {
                formatter: function (value) {
                  if (value === null) {
                    return '';
                  }

                  return weekRangeChartType === 'Sales'
                    ? `$${value?.toFixed(2)}`
                    : `${value} Items`;
                },
              },
            },
          },
        }));
      } else {
        setWeekRangeData([]);
        setChart((prev) => ({
          ...prev,
          series: [
            {
              name: weekRangeChartType,
              data: [],
            },
          ],
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: [],
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return weekRangeChartType === 'Sales'
                    ? `$${parseFloat(value)?.toFixed(2)}`
                    : `${value} Items`;
                },
              },
            },
          },
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }

    setLoading((prev) => ({
      ...prev,
      graphicLoading: false,
    }));
  };

  const onChangeWeekRangeChartType = (type) => {
    setWeekRangeChartType(type);
  };

  const onDateChange = (dateString) => {
    const diffDays = (dateString[1] - dateString[0]) / (86400 * 1000);
    if (diffDays <= 1) {
      setSearch((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(dateString[0], 'startdate', '0'),
        enddate: setStartAndEndDate(dateString[1], 'enddate', '0'),
      }));
    } else {
      setSearch((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(dateString[0], 'startdate', '0'),
        enddate: setStartAndEndDate(dateString[1], 'enddate', '0'),
      }));
    }
  };

  const handleDateRangeSelectChange = (selected) => {
    if (selected) {
      let enddate = dayjs();
      let startdate;
      setIsShowCustomRange(false);
      switch (selected) {
        case 'today':
          startdate = enddate.startOf('day');
          break;
        case 'yesterday':
          startdate = enddate.subtract(1, 'day').startOf('day');
          enddate = enddate.subtract(1, 'day').endOf('day');
          break;
        case 'weekToD‌​ate':
          startdate = enddate.startOf('week');
          break;
        case 'last7Days':
          startdate = enddate.subtract(7, 'day').startOf('day');
          break;
        case 'last14Days':
          startdate = enddate.subtract(14, 'day').startOf('day');
          break;
        case 'last21Days':
          startdate = enddate.subtract(21, 'day').startOf('day');
          break;
        case 'last28Days':
          startdate = enddate.subtract(28, 'day').startOf('day');
          break;
        case 'last30Days':
          startdate = enddate.subtract(30, 'day').startOf('day');
          break;
        case 'monthToD‌​ate':
          startdate = enddate.startOf('month');
          break;
        case 'last60Days':
          startdate = enddate.subtract(60, 'day').startOf('day');
          break;
        case 'customRange':
          setIsShowCustomRange(true);
          break;
        default:
          break;
      }

      setSearch((prev) => ({
        ...prev,
        startdate: setStartAndEndDate(startdate, 'startdate', '0'),
        enddate: setStartAndEndDate(enddate, 'enddate', '0'),
      }));
    }
  };

  const onSelectStore = (storeid) => {
    const storeids = storeid ? [storeid] : [];

    setSearch((prev) => ({
      ...prev,
      startdate: setStartAndEndDate(search.startdate, 'startdate', '0'),
      enddate: setStartAndEndDate(search.enddate, 'enddate', '0'),
      storeids,
    }));
  };

  const getTotalClickCountKiosk = async () => {
    setLoading((prev) => ({
      ...prev,
      clickCountLoading: true,
    }));
    try {
      let query = `?pageSize=1000&addclickcount=true`;
      if (!userRoles.includes(100)) {
        query += `&companyid=${globalCompany.id}`;
      }

      const list = await terminalApi.list(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          totalClickCount: list.data.data.reduce((a, b) => a + b.clickcount, 0),
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          totalClickCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        clickCountLoading: false,
      }));
    }
  };

  const getClickCountKiosk = async () => {
    try {
      let query = `?pageSize=1000&startdate=${search.startdate}&enddate=${search.enddate}&addclickcount=true`;
      if (!userRoles.includes(100)) {
        query += `&companyid=${globalCompany.id}`;
      } else {
        if (globalCompany.id) {
          query += `&companyid=${globalCompany.id}`;
        }
      }

      const list = await terminalApi.list(query);
      if (list.data.data) {
        setOrderInformations((prev) => ({
          ...prev,
          clickCount: list.data.data.reduce((a, b) => a + b.clickcount, 0),
        }));
      } else {
        setOrderInformations((prev) => ({
          ...prev,
          clickCount: 0,
        }));
      }
    } catch (error) {
      setNotf((prev) => ({
        ...prev,
        message: error,
        display: true,
      }));
    }
  };

  const handleWeekChange = (startOfWeek, endOfWeek) => {
    setWeekRange({ startOfWeek, endOfWeek });
  };

  const orderedTop10Items = () => {
    const key = top10Type === 'Sales' ? 'total' : 'qty';
    return orderInformations.orderTop10
      .filter((x) => x.product)
      .sort((a, b) => b[key] - a[key]);
  };

  useEffect(() => {
    if (isAdmin) {
      setGlobalCompany({
        name: 'All Companies',
        id: null,
      });
    } else {
      setGlobalCompany(reduxCompany);
    }
  }, [reduxCompany, isAdmin]);

  useEffect(() => {
    getCompanyList();
    getBankAccount();
  }, []);

  useEffect(() => {
    getStoreList();
  }, [globalCompany]);

  useEffect(() => {
    getOrdersWeekRange();
  }, [weekRange, weekRangeChartType]);

  useEffect(() => {
    getOrderTotal();
    getOrderRefunds();
    getTotalClickCountKiosk();
    getOrderCount();
    getOrderTop10();
    getOrderCountByTerminal();
    getClickCountKiosk();
  }, [globalCompany, search]);

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white dark:bg-slate-800">
      {loading.initialLoading ? (
        <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
          <Spin />
        </div>
      ) : (
        <div className="mx-auto max-w-5xl">
          {!userRoles.includes(100) && !globalCompany.subscriptionid && (
            <Alert
              type="info"
              description="Please upgrade your account by selecting a subscription plan that meets your expectations."
              buttonText="Upgrade Now"
              navigate="/dashboard/settings/subscription"
            />
          )}
          {!userRoles.includes(100) && bankAccount.length === 0 && (
            <Alert
              type="info"
              description="Please add your checking account for your store’s payouts."
              buttonText="Add Now"
              navigate="/dashboard/settings/bank-accounts"
            />
          )}
          {!userRoles.includes(100) &&
            (!storeList[0]?.tax || !storeList[0]?.phone) && (
              <Alert
                type="info"
                description={`Please update your ${storeList[0]?.name}’s Store Settings (Sales Tax, Store Phone Number, etc.)`}
                buttonText="Store Settings"
                navigate={`/dashboard/stores/${storeList[0]?.id}/settings`}
              />
            )}
          {!userRoles.includes(100) &&
            !globalCompany.subscriptionid &&
            globalCompany.trialend < moment().format() && (
              <Alert
                type="error"
                description="Your trial has expired. Please upgrade to a paid subscription to continue using our services and access all of our premium features. Thank you"
                buttonText="Upgrade Now"
                navigate="/dashboard/settings/subscription"
              />
            )}
          <h1 className="text-xl font-semibold text-slate-900 dark:text-slate-200">
            Dashboard
          </h1>
          <p className="mt-2 text-sm text-gray-500 dark:text-slate-400">
            Track your sales metrics and optimize your business performance.
          </p>
          {userRoles.some((role) => [100, 150, 200].includes(role)) && (
            <div className="py-12 sm:py-8  bg-white  dark:bg-slate-800">
              <div className="space-y-10 ">
                {userRoles.includes(100) ? (
                  <div className="flex sm:flex-row  items-start gap-5">
                    <div className="flex-1 flex flex-col gap-5 w-full">
                      <div className="w-full">
                        <label
                          htmlFor="image"
                          className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                        >
                          Date Range
                        </label>
                        <Select
                          allowClear
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          placeholder="Date Range"
                          defaultValue={dateOptions[2]}
                          onChange={(a) => {
                            if (a) {
                              handleDateRangeSelectChange(a);
                            } else {
                              handleDateRangeSelectChange('weekToD‌​ate');
                            }
                          }}
                          options={dateOptions.map((a) => ({
                            label: a.label,
                            value: a.value,
                          }))}
                        />
                      </div>
                      {isShowCustomRange && (
                        <div className="w-full">
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700 dark:text-slate-200"
                          >
                            Custom Range
                          </label>
                          <div className="pt-1 w-full">
                            <RangePicker
                              style={{ width: '100%' }}
                              format="MM/DD/YYYY"
                              size="large"
                              value={[
                                search.startdate
                                  ? dayjs(search.startdate, 'YYYY-MM-DD')
                                  : null,
                                search.enddate
                                  ? dayjs(search.enddate, 'YYYY-MM-DD')
                                  : null,
                              ]}
                              onChange={(e) => {
                                if (e) {
                                  onDateChange(e);
                                } else {
                                  handleDateRangeSelectChange('weekToD‌​ate');
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex-1 w-full">
                      <label
                        htmlFor="image"
                        className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                      >
                        Company
                      </label>
                      <Select
                        allowClear
                        size="large"
                        style={{
                          width: '100%',
                        }}
                        placeholder="Company"
                        onChange={(_, object) => {
                          setSearch((prev) => ({
                            ...prev,
                            storeids: [],
                          }));
                          setGlobalCompany(
                            _
                              ? {
                                  name: object.label,
                                  id: object.value,
                                }
                              : {
                                  name: 'All Companies',
                                  id: null,
                                }
                          );
                        }}
                        options={[
                          { label: 'All Companies', value: null },
                          ...companyList.map((a) => ({
                            label: a.name,
                            value: a.id,
                          })),
                        ]}
                        value={
                          isEmptyObject(globalCompany)
                            ? null
                            : {
                                label: globalCompany?.name,
                                value: globalCompany?.id,
                              }
                        }
                      />
                    </div>

                    <div className="flex-1 w-full">
                      <label
                        htmlFor="image"
                        className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                      >
                        Stores
                      </label>
                      <Select
                        disabled={!globalCompany.id}
                        allowClear
                        size="large"
                        style={{
                          width: '100%',
                        }}
                        placeholder="Stores"
                        onChange={(e) => {
                          onSelectStore(e);
                        }}
                        options={storeList.map((a) => ({
                          label: a.name,
                          value: a.id,
                        }))}
                        value={search.storeids}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-2">
                    <div className="flex gap-2">
                      <div className="w-full">
                        <div className="">
                          <label
                            htmlFor="image"
                            className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                          >
                            Date Range
                          </label>
                          <Select
                            allowClear
                            size="large"
                            style={{
                              width: '100%',
                            }}
                            placeholder="Date Range"
                            defaultValue={dateOptions[2]}
                            onChange={(a) => {
                              if (a) {
                                handleDateRangeSelectChange(a);
                              } else {
                                handleDateRangeSelectChange('weekToD‌​ate');
                              }
                            }}
                            options={dateOptions.map((a) => ({
                              label: a.label,
                              value: a.value,
                            }))}
                          />
                        </div>
                        {isShowCustomRange && (
                          <div className="">
                            <label
                              htmlFor="description"
                              className="block text-sm font-medium text-gray-700 dark:text-slate-200"
                            >
                              Custom Range
                            </label>
                            <div className="pt-1 w-full">
                              <RangePicker
                                style={{ width: '100%' }}
                                format="MM/DD/YYYY"
                                size="large"
                                value={[
                                  search.startdate
                                    ? dayjs(search.startdate, 'YYYY-MM-DD')
                                    : null,
                                  search.enddate
                                    ? dayjs(search.enddate, 'YYYY-MM-DD')
                                    : null,
                                ]}
                                onChange={(e) => {
                                  if (e) {
                                    onDateChange(e);
                                  } else {
                                    handleDateRangeSelectChange('weekToD‌​ate');
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="image"
                          className="block pb-1  text-sm font-medium text-gray-700 dark:text-slate-200"
                        >
                          Stores
                        </label>
                        <Select
                          disabled={!globalCompany.id}
                          allowClear
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          placeholder="Stores"
                          onChange={(e) => {
                            onSelectStore(e);
                          }}
                          options={storeList.map((a) => ({
                            label: a.name,
                            value: a.id,
                          }))}
                          value={search.storeids}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                )}
                <div>
                  <dl className="mt-5 grid grid-cols-2 divide-y divide-gray-200 dark:divide-slate-500 overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow sm:grid-cols-4 sm:divide-y-3 divide-x">
                    <div className="px-4 py-5 sm:p-6 dark:bg-slate-700 border-t">
                      <BanknotesIconOutline className="h-6 w-6 dark:text-slate-200" />
                      <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                        Sales
                      </dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                          {(
                            orderInformations.orderTotal -
                            orderInformations.orderRefundTotal
                          ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                      <ChartBarIcon className="h-6 w-6 dark:text-slate-200" />
                      <dt className="text-base font-normal text-gray-900 dark:text-slate-200">
                        Order Volume
                      </dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                          {orderInformations.orderCount}
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                      <CursorArrowRaysIcon className="h-6 w-6 dark:text-slate-200" />
                      <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                        Kiosk Interactions
                      </dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                          {loading.clickCountLoading ? (
                            <div className="w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
                              <Spin />
                            </div>
                          ) : (
                            <>{orderInformations.clickCount}</>
                          )}
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-5 sm:p-6  dark:bg-slate-700">
                      <ReceiptPercentIcon className="h-6 w-6 dark:text-slate-200" />
                      <dt className="text-base font-normal text-gray-900 dark:text-slate-400">
                        Avg. Ticket Size
                      </dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-blue-600 dark:text-slate-200">
                          {(
                            orderInformations.orderTotal /
                              orderInformations.orderCount || 0
                          ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div id="chart" className=" bg-white dark:bg-slate-700">
                  <div className="mt-10">
                    <div className="py-1 px-4 sm:px-6 flex items-center justify-between ">
                      <div className="flex items-center">
                        <WeekSlider
                          onWeekChange={handleWeekChange}
                          weekOfStartMonday={false}
                        />
                      </div>
                      <div>
                        <span className="isolate inline-flex ">
                          <button
                            onClick={() => onChangeWeekRangeChartType('Sales')}
                            type="button"
                            className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                          >
                            {weekRangeChartType === 'Sales' ? (
                              <BanknotesIcon className="h-5 w-5 dark:text-slate-200" />
                            ) : (
                              <BanknotesIconOutline className="h-5 w-5 dark:text-slate-200" />
                            )}
                          </button>
                          <button
                            onClick={() => onChangeWeekRangeChartType('Items')}
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                          >
                            {weekRangeChartType === 'Items' ? (
                              <ShoppingBagIcon className="h-5 w-5 dark:text-slate-200" />
                            ) : (
                              <ShoppingBagIconOutline className="h-5 w-5 dark:text-slate-200" />
                            )}
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  {loading.graphicLoading ? (
                    <div className="h-[350px] w-full flex justify-center py-4 sm:col-span-2 lg:col-span-3">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      className={classNames(
                        weekRangeData.length > 0 ? 'block' : 'hidden'
                      )}
                      options={chart.options}
                      series={chart.series}
                      type="area"
                      height={350}
                    />
                  )}
                  <div
                    className={classNames(
                      weekRangeData.length === 0 ? 'block' : 'hidden',
                      'grid justify-center py-20 dark:bg-slate-700'
                    )}
                  >
                    <img src={NoData2} className="h-24 pb-2" alt="" />
                    <p className=" text-slate-400 font-light text-center">
                      No Data
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="col-span-2 sm:col-span-1 w-full p-4  bg-white dark:bg-slate-700 border-r">
                    <div className="sm:mx-auto w-full sm:max-w-md rounded-md bg-white dark:bg-slate-700 h-full">
                      <div className=" items-start flex w-full justify-between rounded-lg bg-white dark:bg-slate-800 py-2 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                        <div className="grid leading-6">
                          <BarsArrowUpIcon className="h-8 w-8 dark:text-slate-200 mb-4" />
                          <span className="font-bold text-lg dark:text-slate-200">
                            Top Ten Popular Items
                          </span>
                          <span className="text-sm text-gray-500 dark:text-slate-400">
                            See what's trending at your restaurant
                          </span>
                        </div>
                        <div>
                          <span className="isolate inline-flex rounded-md shadow-sm">
                            <button
                              onClick={() => setTop10Type('Sales')}
                              type="button"
                              className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                            >
                              {top10Type === 'Sales' ? (
                                <BanknotesIcon className="h-5 w-5 dark:text-slate-200" />
                              ) : (
                                <BanknotesIconOutline className="h-5 w-5 dark:text-slate-200" />
                              )}
                            </button>
                            <button
                              onClick={() => setTop10Type('Items')}
                              type="button"
                              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                            >
                              {top10Type === 'Items' ? (
                                <ShoppingBagIcon className="h-5 w-5 dark:text-slate-200" />
                              ) : (
                                <ShoppingBagIconOutline className="h-5 w-5 dark:text-slate-200" />
                              )}
                            </button>
                          </span>
                        </div>
                      </div>
                      {orderInformations.orderTop10.length > 0 ? (
                        <div className="pt-4 text-sm">
                          {orderedTop10Items().map((product, i) => (
                            <div
                              key={i}
                              className="border-t dark:border-slate-500 px-4"
                            >
                              <div className="flex justify-between py-2">
                                <div>
                                  <span className="font-bold text-md  text-black dark:text-slate-200">
                                    {product.product.name}
                                  </span>
                                </div>
                                {top10Type === 'Sales' ? (
                                  <div className="grid">
                                    <span className="font-bold text-lg text-black text-end dark:text-slate-200">
                                      {product.total.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                    </span>
                                    <span className="text-sm text-gray-500 text-end dark:text-slate-400">
                                      {`${product.qty} orders`}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="grid">
                                    <span className="font-bold text-lg text-black text-end dark:text-slate-200">
                                      {`${product.qty} orders`}
                                    </span>
                                    <span className="text-sm text-gray-500 text-end dark:text-slate-400">
                                      {product.total.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="grid justify-center h-full content-center">
                          <img
                            alt="No Popular Items"
                            className="  h-24 pb-2"
                            src={NoItems}
                          />
                          <p className=" text-slate-400 font-light text-center">
                            No Items
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="piechart"
                    className="col-span-2 sm:col-span-1 w-full p-4 bg-white dark:bg-slate-700"
                  >
                    <div className="pb-5">
                      <div className="flex items-center w-full justify-between rounded-lg bg-white dark:bg-slate-800 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                        <div className="grid leading-6">
                          <SquaresPlusIcon className="h-8 w-8 dark:text-slate-200 mb-4" />
                          <span className="font-bold text-lg dark:text-slate-200">
                            Order Channels
                          </span>
                          <span className="text-sm text-gray-500 dark:text-slate-400">
                            See your popular ordering channels
                          </span>
                        </div>
                      </div>
                      {orderInformations.orderCountByTerminal.length === 1 &&
                      orderInformations.orderCountByTerminal[0].terminal ===
                        'Online' &&
                      orderInformations.orderCountByTerminal[0].id ===
                        'Online' &&
                      orderInformations.orderCountByTerminal[0].count === 0 ? (
                        <div className="grid justify-center h-full content-center pt-5">
                          <img
                            alt="No Popular Items"
                            className="  h-24 pb-2"
                            src={NoItems}
                          />
                          <p className=" text-slate-400 font-light text-center">
                            No Items
                          </p>
                        </div>
                      ) : (
                        <ReactApexChart
                          options={pieChart.chartOptions}
                          series={pieChart.series}
                          type="pie"
                        />
                      )}
                    </div>
                    <div>
                      <div className="pt-5 flex items-center w-full justify-between rounded-lg bg-white dark:bg-slate-800 text-left text-sm font-medium text-black   focus-visible:ring-opacity-75">
                        <div className="grid leading-6">
                          <CursorArrowRaysIcon className="h-8 w-8 dark:text-slate-200 mb-4" />
                          <span className="font-bold text-lg dark:text-slate-200">
                            Customers Interactions
                          </span>
                          <span className="text-sm text-gray-500 dark:text-slate-400">
                            Discover Customer Engagement with the kiosk
                          </span>
                        </div>
                      </div>
                      <div className="border-b-2 border-gray-950 dark:border-slate-700 border-opacity-50 pt-1"></div>
                      <div className="text-center py-8">
                        <p className="font-bold text-4xl text-gray-900 dark:text-slate-200">
                          {orderInformations.totalClickCount}
                        </p>
                        <span className="text-sm text-gray-500 dark:text-slate-400">
                          customers interacted with the kiosk
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
