import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import { XMarkIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ModalList({
  title,
  headers,
  keys,
  list,
  open,
  size,
  onCloseRequest,
}) {
  const cancelButtonRef = useRef(null);

  const formatKeyValue = (key, value, idx) => {
    switch (key) {
      case 'status':
        return value === 0 ? 'Pending' : value === 10 ? 'Active' : 'Passive';
      case 'createdate':
        return moment.utc(value).local().format('MM/DD/YYYY hh:mm A');
      case 'customerfeeactive':
        return value ? 'Yes' : 'No';
      case 'customerfee':
        return list.customerfeeisrate ? value + '%' : '$' + value;
      case 'address':
        return (value = `${list[idx]?.address || ''} ${
          list[idx]?.apartment && list[idx]?.apartment !== 'null'
            ? list[idx]?.apartment
            : ''
        } ${list[idx]?.city || ''} ${
          list[idx]?.state && list[idx]?.state !== 'null'
            ? list[idx]?.state
            : ''
        }`);
      default:
        return value;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCloseRequest}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  size === 'lg' ? 'sm:max-w-4xl' : 'sm:max-w-2xl',
                  'relative transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:p-6'
                )}
              >
                <div className="flex justify-between">
                  <h1 className="text-xl dark:text-zinc-200">{title}</h1>
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-slate-800 text-gray-400 dark:text-zinc-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => onCloseRequest()}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-4 flex flex-col min-h-[250px]">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
                      <table className="min-w-full divide-y divide-gray-300 dark:divide-slate-500">
                        <thead>
                          <tr>
                            {headers.map((head, headIdx) => (
                              <th
                                key={headIdx}
                                scope="col"
                                className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-200 ${
                                  headIdx === 0 ? 'md:pl-0 sm:pl-6' : ''
                                } `}
                              >
                                {head}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-slate-500 dark:divide-slate-500">
                          {list.map((element, idx) => (
                            <tr key={idx}>
                              {keys.map((x, keyIdx) => (
                                <td
                                  key={keyIdx}
                                  className={`whitespace-nowrap py-4  text-sm font-medium text-zinc-900 dark:text-zinc-200 ${
                                    keyIdx === 0
                                      ? 'sm:pl-6 md:pl-0 pl-4 pr-3'
                                      : 'px-4'
                                  } `}
                                >
                                  {formatKeyValue(x, element[x], idx)}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
